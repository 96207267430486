import React from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import '../Resize.css';
import { Clear } from '@material-ui/icons';
import { ButtonBase } from '@material-ui/core';
import { ElementType } from '../../types';

import { StyledElement } from './styles';

interface IElementComponentProps {
  element: ElementType;
  removeItem: (element: ElementType) => void;
  setPosition: (data: DraggableData) => void;
  checkParent: (element: ElementType, data: DraggableData) => void;
}

const Element: React.FC<IElementComponentProps> = ({
  element,
  setPosition,
  checkParent,
  removeItem,
}) => (
  <Draggable
    key={element.id}
    bounds="parent"
    offsetParent={undefined}
    axis="both"
    handle={`.element-${element.id}`}
    position={element.position}
    grid={[5, 5]}
    scale={1}
    onStop={(_, data) => checkParent(element, data)}
    onDrag={(_, data) => setPosition(data)}
  >
    <StyledElement color={element.color} className={`element-${element.id}`}>
      <div>{element.content}</div>
      <ButtonBase onClick={() => removeItem(element)}>
        <Clear />
      </ButtonBase>
    </StyledElement>
  </Draggable>
);

export default Element;
