import styled from 'styled-components';

export const Container = styled.div`
  padding: 5rem;
`;

export const Content = styled.div`
  width: fit-content;
  margin: 0 auto;
`;
