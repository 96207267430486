import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS, CallBackProps } from 'react-joyride';

import {
  endTour,
  IInitialTourState,
  nextStep,
  prevStep,
} from '../../store/InitialTour';
import { StoreState } from '../../store/types';

const Tour: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tourState: IInitialTourState = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  // called everytime tour state changes
  const callback = (data: CallBackProps) => {
    const { action, type, status } = data;
    const { SKIPPED, FINISHED } = STATUS;
    const { run } = tourState;

    if (
      action === ACTIONS.CLOSE ||
      (status === SKIPPED && run) ||
      status === FINISHED
    ) {
      dispatch(endTour());
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      if (action === ACTIONS.PREV) {
        dispatch(prevStep());
      } else if (action === ACTIONS.NEXT) {
        dispatch(nextStep());
      }
    }
  };

  useLayoutEffect(() => {
    const { run } = tourState;
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (run) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [tourState]);

  return (
    <Joyride
      showSkipButton
      disableCloseOnEsc
      disableOverlayClose
      callback={callback}
      key={tourState.key}
      run={tourState.run}
      steps={tourState.steps}
      stepIndex={tourState.stepIndex}
      continuous={tourState.continuous}
      floaterProps={{ disableAnimation: true }}
      locale={{
        last: t('button.last'),
        back: t('button.back'),
        next: t('button.next'),
        skip: t('button.skip'),
        close: t('button.close'),
      }}
      styles={{
        buttonNext: { background: 'blue' },
        tooltipContent: { whiteSpace: 'pre-line' },
      }}
    />
  );
};

export default Tour;
