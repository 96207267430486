import styled from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  display: 'flex' | 'none';
}

export const Container = styled(animated.div)<ContainerProps>`
  width: 20vw;
  max-width: 360px;

  position: relative;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: ${(props) => props.display};
  flex-direction: column;

  background: var(--tourToastBackground);
  color: var(--tourToastText);
  margin: 0 auto;

  & + div {
    margin-top: 15px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  > div {
    width: 90% !important;
    margin: 10px !important;
    padding: 15px !important;
  }
`;
