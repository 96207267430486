import React from 'react';

import { Container } from './styles';

interface IProgressBarProps {
  value: number;
  color?: string;
  width?: string;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ value, color, width }) => {
  return (
    <Container color={color || 'lightBlue'} width={width || '250px'}>
      <progress value={value} max={100} />
      <span>{(value / 100) * 100}%</span>
    </Container>
  );
};

export default ProgressBar;
