import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Divider } from '@material-ui/core';

import Attribute from '../Attribute';
import Loader from '../Loader';

import { IDeployOrgCardInfo } from '../../types';

import {
  Container,
  TitleLoader,
  NodeOptCounterContainer,
  OrgDomainAndNameContainer,
} from './styles';

const DeployOrgCard: React.FC<{
  cardInfo: IDeployOrgCardInfo;
}> = ({ cardInfo }) => {
  const {
    peers,
    orgName,
    running,
    loadingInfo,
    orgDomainName,
    notificationAttrs,
    changeNotification,
  } = cardInfo;

  const { t } = useTranslation();

  const canShow = orgName && orgDomainName && peers.length;

  const getPeerTypeCount = () => {
    let [caCount, ccRestCount, peerCount, ordererCount] = [0, 0, 0, 0];

    peers.forEach((item: IPeer) => {
      const { opts } = item;
      if (opts.ca) caCount++;
      if (opts.orderer) ordererCount++;
      if (opts.peer) peerCount++;
      if (opts.ccRestHost) ccRestCount++;
    });

    const optsCounter = [
      { name: 'CA', value: caCount },
      { name: 'Peer', value: peerCount },
      { name: 'CCRest', value: ccRestCount },
      { name: 'Orderer', value: ordererCount },
    ];

    return (
      <NodeOptCounterContainer>
        {optsCounter
          .filter((item) => item.value)
          .map((item) => (
            <Typography
              style={{ color: 'var(--white)', margin: '5px' }}
              key={item.name}
            >
              {`${item.name}: ${item.value}`}
            </Typography>
          ))}
      </NodeOptCounterContainer>
    );
  };

  if (!canShow) return null;
  return (
    <Container>
      <div style={{ padding: '0 25px' }}>
        <TitleLoader>
          <OrgDomainAndNameContainer>
            <Attribute name="Org" value={orgName} size={15} />
            <Attribute
              name={t('common.words.domain')}
              value={orgDomainName}
              size={13}
            />
          </OrgDomainAndNameContainer>
          <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
            <Loader
              type="main"
              list={loadingInfo}
              running={running}
              notificationAttrs={notificationAttrs}
              changeNotification={changeNotification}
            />
          </div>
        </TitleLoader>
        <Typography
          variant="overline"
          style={{ color: 'var(--white)', fontSize: '18px' }}
        >
          <Trans>common.words.instances</Trans>:
        </Typography>
        {getPeerTypeCount()}
        <Divider />
        <Loader
          list={loadingInfo}
          running={running}
          notificationAttrs={notificationAttrs}
          changeNotification={changeNotification}
        />
      </div>
    </Container>
  );
};

export default DeployOrgCard;
