import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { withStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Grid, Typography } from '@material-ui/core';
import MenuPage from '../MenuPage';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  fabGreen: {
    marginLeft: '10%',
    color: theme.palette.common.white,
    backgroundColor: green[500],
    boxShadow: 'none',
  },
});

const APIMain: React.FC<{ classes: any }> = ({ classes }) => {
  const { t } = useTranslation();
  const options = [
    {
      icon: 'add_circle',
      text: t('title.api.addChaincodeAPI'),
      link: '/api/add',
    },
    {
      icon: 'add_circle',
      text: 'Get Certificate',
      link: '/api/certificate',
    },
    {
      icon: 'arrow_upward',
      text: 'Upgrade API',
      link: '/api/upgrade',
    },
    // {
    //   icon: 'build',
    //   text: t('title.api.upgradeAPI'),
    //   link: '/api/upgrade',
    // },
    // {
    //   icon: 'public',
    //   text: t('title.api.addWebClient'),
    //   link: '/api/add/webclient',
    // },
  ];

  return (
    <Grid
      container
      justify="center"
      className={[classes.root, classes.centerContainer].join(' ')}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography style={{ fontSize: '32px' }} variant="overline">
          <Trans>title.api.header</Trans>
        </Typography>
      </div>
      <Grid item xs={12}>
        <MenuPage options={options} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(APIMain);
