import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Edit,
  Delete,
  Refresh,
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';

import { Icon } from '@material-ui/core';
import { networkApi } from '../../../Common/axios';

import {
  changeNetworkStatus,
  openDeleteNetworkDialog,
  openEditDisplayNameDialog,
  openLaunchMoreLikeThisDialog,
} from '../../../store/Network';

import {
  ActionsCard,
  ArrowContainer,
  ActionsContainer,
  ComponentContainer,
} from './styles';

import Tooltip from '../../Tooltip';
import { useDeployForm } from '../../../Hooks/deploy';
import { useNetworks } from '../../../Contexts/Networks';

const NetworkActions: React.FC<{
  network: INetwork;
}> = ({ network }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setSelectedNetwork } = useNetworks();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const { displayName, networkName } = network;
  const handleClickOutside = (event: MouseEvent) => {
    if (open && ref.current && !ref.current.contains(event.target as Node))
      setOpen(false);
  };

  const handleNetworkRefresh = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <ComponentContainer ref={ref}>
      <ArrowContainer
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setSelectedNetwork(network);
          setOpen((prev) => !prev);
        }}
      >
        {open ? <ExpandLess /> : <ExpandMore />}
      </ArrowContainer>

      {open ? (
        <ActionsContainer>
          <ActionsCard>
            <Tooltip message={t('sidebar.refreshNetwork')}>
              <Refresh onClick={handleNetworkRefresh} />
            </Tooltip>

            <Tooltip message={t('sidebar.deleteNetwork.title')}>
              <Delete
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(openDeleteNetworkDialog(networkName));
                }}
              />
            </Tooltip>

            <Tooltip message={t('sidebar.launchMoreLikeThis.title')}>
              <Icon
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(
                    openLaunchMoreLikeThisDialog({
                      networkName,
                      displayName,
                    }),
                  );
                }}
              >
                add
              </Icon>
            </Tooltip>

            <Tooltip message={t('sidebar.editDisplayName.title')}>
              <Edit
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(
                    openEditDisplayNameDialog({
                      networkName,
                      displayName,
                    }),
                  );
                }}
              />
            </Tooltip>
          </ActionsCard>
        </ActionsContainer>
      ) : null}
    </ComponentContainer>
  );
};

export default NetworkActions;
