import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  Step,
  Button,
  StepLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { networkApi } from '../../Common/axios';
import history from '../../history';
import { openDialog } from '../../store/Dialog';
import { fetchNetwork } from '../../store/Network';
import LoadingScreen from '../LoadingScreen';
import {
  Steps,
  Container,
  ButtonGroup,
  StepContent,
  OrgFormCard,
  OrgsFormHeader,
  FillAuthContainer,
  OrgsFormContanier,
  VerifyInfoContainer,
} from './styles';

import { ipPatt } from '../../utils/regexPatterns';
import Tooltip from '../../AppComponents/Tooltip';

const NetworkRestore: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ip, setIp] = useState('');
  const [apiAuth, setApiAuth] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [networkName, setNetworkName] = useState('');
  const [channelName, setChannelName] = useState('');
  const [apisAuth, setApisAuth] = useState<string[]>([]);
  const [orgsToFill, setOrgsToFill] = useState<string[]>([]);

  const steps = [
    t('sidebar.networkRestore.step1'),
    t('sidebar.networkRestore.step2'),
  ];

  const handleStepChange = (type = 'next') => {
    let newActiveStep;

    if (type === 'back') {
      setApisAuth([]);
      setOrgsToFill([]);
      newActiveStep = activeStep - 1;
    } else {
      newActiveStep = activeStep + 1;
    }

    if (newActiveStep < 0 || newActiveStep > steps.length - 1) return;

    setActiveStep(newActiveStep);
  };

  const callRequest = (request: () => Promise<void>) => {
    try {
      request();
    } catch (error) {
      dispatch(
        openDialog({
          type: 'error',
          content: error.message,
          title: t('common.words.error'),
        }),
      );
    }
  };

  const verifyNetworkInfo = () => {
    if (!apiAuth || !ip || !channelName || !networkName) {
      throw Error(t('sidebar.networkRestore.invalidFormmessage'));
    }

    setLoading(true);
    const config = {
      params: {
        apiAuth,
        networkName,
        channelName,
        netApiIP: ip,
      },
    };

    return networkApi
      .get('/mongodata', config)
      .then((res) => {
        if (res.data.length > 0) {
          setOrgsToFill(res.data);
          setApisAuth(Array(res.data.length).fill(''));
          handleStepChange();
        } else {
          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: t('sidebar.networkRestore.restoreSuccess'),
            }),
          );
          dispatch(fetchNetwork(networkName));
          history.push('/dashboard');
        }
      })
      .catch((err) => {
        dispatch(
          openDialog({
            type: 'error',
            content: err.message,
            title: t('common.words.error'),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const sendFilledAuth = () => {
    if (apisAuth.some((i) => !i || i === '')) {
      throw Error(t('sidebar.networkRestore.invalidFormmessage'));
    }

    setLoading(true);

    const config = {
      params: {
        networkName,
        channelName,
      },
    };

    const apisAuthObj: Record<string, any> = {};

    apisAuth.forEach((item, idx) => {
      apisAuthObj[orgsToFill[idx]] = apisAuth[idx];
    });

    return networkApi
      .post('/fillauth', JSON.stringify(apisAuthObj), config)
      .then(() => {
        dispatch(
          openDialog({
            type: 'success',
            title: t('common.words.success'),
            content: t('sidebar.networkRestore.restoreSuccess'),
          }),
        );
        dispatch(fetchNetwork(networkName));
        history.push('/dashboard');
      })
      .catch((err) => {
        dispatch(
          openDialog({
            type: 'error',
            content: err.message,
            title: t('common.words.error'),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <VerifyInfoContainer>
            <TextField
              value={networkName}
              label={t('common.forms.networkName')}
              style={{ width: '100%', margin: '5px' }}
              onChange={(e) => setNetworkName(e.target.value)}
            />

            <TextField
              value={channelName}
              label={t('common.forms.channelName')}
              style={{ width: '100%', margin: '5px' }}
              onChange={(e) => setChannelName(e.target.value)}
            />

            <TextField
              value={ip}
              label={t('common.words.IP')}
              style={{ width: '100%', margin: '5px' }}
              onChange={(e) => setIp(e.target.value)}
              error={!ipPatt.test(ip) && !!ip.length}
            />

            <Tooltip message={t('sidebar.networkRestore.apiAuthTooltip')}>
              <TextField
                value={apiAuth}
                label={t('sidebar.networkRestore.apiAuth')}
                style={{ width: '100%', margin: '5px' }}
                onChange={(e) => setApiAuth(e.target.value)}
              />
            </Tooltip>

            <Button
              fullWidth
              color="primary"
              variant="contained"
              style={{ margin: '35px auto auto 4px' }}
              onClick={() => callRequest(verifyNetworkInfo)}
            >
              <Trans>button.verify</Trans>
            </Button>
          </VerifyInfoContainer>
        );
      case 1:
        return (
          <FillAuthContainer threeColumns={orgsToFill.length > 8}>
            <OrgsFormHeader>
              <Trans>sidebar.networkRestore.multipleAPIMessage</Trans>
            </OrgsFormHeader>

            <OrgsFormContanier>
              {orgsToFill.map((org, idx) => (
                <OrgFormCard key={org}>
                  <Typography style={{ margin: '-15px auto auto auto' }}>
                    <b>{org}</b>
                  </Typography>

                  <TextField
                    label={t('sidebar.networkRestore.apiAuth')}
                    value={apisAuth[idx]}
                    disabled={loading}
                    onChange={(e) => {
                      const newApisAuth = [...apisAuth];
                      newApisAuth[idx] = e.target.value;
                      setApisAuth(newApisAuth);
                    }}
                  />
                </OrgFormCard>
              ))}
            </OrgsFormContanier>

            <ButtonGroup>
              {loading ? (
                <LoadingScreen
                  content={t('sidebar.networkRestore.loadingMessage')}
                />
              ) : (
                <>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ width: '50%', margin: '0 10px 0 30px' }}
                    onClick={() => handleStepChange('back')}
                  >
                    <Trans>button.back</Trans>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    style={{ width: '50%', margin: '0 30px 0 10px' }}
                    onClick={() => callRequest(sendFilledAuth)}
                  >
                    <Trans>button.restore</Trans>
                  </Button>
                </>
              )}
            </ButtonGroup>
          </FillAuthContainer>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <div>
        <Typography
          variant="overline"
          style={{ textAlign: 'center', fontSize: '28px' }}
        >
          <Trans>sidebar.networkRestore.restoreNetInfo</Trans>
        </Typography>

        <Steps activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step} disabled={loading}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Steps>
      </div>

      <StepContent>{renderStep()}</StepContent>
    </Container>
  );
};
export default NetworkRestore;
