import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { green } from '@material-ui/core/colors';
import { Grid, Typography } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';

import MenuPage from '../MenuPage';
import { StoreState } from '../../store/types';
import { changeMountedComponents } from '../../store/InitialTour';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  fabGreen: {
    marginLeft: '10%',
    color: theme.palette.common.white,
    backgroundColor: green[500],
    boxShadow: 'none',
  },
});

const NetworkMain: React.FC<{ classes: any }> = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const options = [
    {
      target: 'deploy-option',
      icon: 'launch',
      text: t('title.network.deploy'),
      link: '/network/deploy',
    },
    {
      icon: 'library_add',
      text: t('title.organizations.addOrg'),
      link: '/organization/add',
    },
    {
      icon: 'library_add',
      text: t('title.organizations.addExtOrg'),
      link: '/organization/addExt',
    },
    {
      icon: 'share',
      text: t('title.nodes.peers.addPeer'),
      link: '/node/peer/add',
    },
    {
      icon: 'share',
      text: t('title.network.createChannel'),
      link: '/network/channel/create',
    },
  ];

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('networkTabMain'));
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      justify="center"
      className={[classes.root, classes.centerContainer].join(' ')}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography style={{ fontSize: '32px' }} variant="overline">
          <Trans>title.network.header</Trans>
        </Typography>
      </div>

      <Grid item xs={12}>
        <MenuPage options={options} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(NetworkMain);
