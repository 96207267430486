import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { Warning } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useNetworks } from '../../../Contexts/Networks';
import { useAddChaincodeApiForm } from '../../../Hooks/addChaincodeApi';
import { useUpgradeChaincodeForm } from '../../../Hooks/UpgradeChaincode';
import GenericInput from '../../../AppComponents/Channel/GenericInput';
import { networkApi } from '../../../Common/axios';
import { OperationStateCard } from '../../../AppComponents/OperationStateCard';
import { useOperation } from '../../../Contexts/Operation';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { openDialog } from '../../../store/Dialog';
import { addOperation, removeOperation } from '../../../store/AppStatus';
import { useUpgradeAPIForm } from '../../../Contexts/UpgradeAPI';
import { FileInput } from '../../../AppComponents/FileInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 500,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddChaincodeAPI = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [ccapiToEdit, setCCAPIToEdit] = useState<{
    orgName: string;
    ccapi: CCAPIOrganization;
  }>();

  const [isDefaultApiPort, setIsDefaultApiPort] = useState(
    !ccapiToEdit?.ccapi?.restPort,
  );
  const [isDefaultTLSPort, setIsDefaultTLSPort] = useState(
    !ccapiToEdit?.ccapi?.tlsPort,
  );
  const [isDefaultInterfacePort, setIsDefaultInterfacePort] = useState(true);
  const [shouldShowInterfacePort, setShouldShowInterfacePort] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedChaincode, setSelectedChaincode] = useState<string>('');

  const [files, setFile] = useState<
    Record<string, Record<string, Record<string, Record<string, File | null>>>>
  >({});

  console.log('FILES: ', files);

  const { upgradeAPIState, clearOperationsState } = useOperation();

  const { selectedNetwork, selectedChannel, fetchNetworkState } = useNetworks();

  const filteredCCAPIs = useMemo(() => {
    if (!selectedNetwork) return {};

    const ccapis: Record<string, CCAPIOrganization[]> = {};

    Object.entries(selectedNetwork.organizations).forEach(
      ([orgName, orgInfo]) => {
        ccapis[orgName] = orgInfo.ccapi.filter(
          (ccapi) =>
            ccapi.chaincodeName === selectedChaincode &&
            ccapi.channelName === selectedChannel,
        );
      },
    );

    return ccapis;
  }, [selectedChaincode, selectedChannel, selectedNetwork]);

  useEffect(() => {
    if (selectedNetwork) {
      fetchNetworkState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork]);

  const {
    updatedCCAPIs,
    addCCAPI,
    removeCCAPI,
    setUpdatedCCAPIs,
    setCACredentials,
    clearForm,
  } = useUpgradeAPIForm();

  useEffect(() => {
    clearForm();
    setCCAPIToEdit(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel]);

  const ccapiIsChecked = useCallback(
    (ccapi: CCAPIOrganization, orgName: string) => {
      const isChecked = Object.entries(
        updatedCCAPIs,
      ).some(([hostName, hostArray]) =>
        hostArray?.ccApis?.find?.(
          (api) =>
            hostName === orgName &&
            api.host === ccapi.host &&
            api.chaincodeName === ccapi.chaincodeName &&
            api.channelName === ccapi.channelName,
        ),
      );

      return isChecked;
    },
    [updatedCCAPIs],
  );

  const handleSelect = useCallback(
    (orgName, ccapi: CCAPIOrganization) => () => {
      console.log('select: ', ccapi, orgName);

      if (ccapiIsChecked(ccapi, orgName)) {
        removeCCAPI(orgName, ccapi.host);
      } else addCCAPI(orgName, ccapi);
    },
    [addCCAPI, ccapiIsChecked, removeCCAPI],
  );

  const handleChangePort = useCallback(
    (key: 'restPort' | 'tlsPort' | 'interfacePort') => (number: number) => {
      if (!ccapiToEdit?.ccapi) return;

      updatedCCAPIs[ccapiToEdit.orgName].ccApis = updatedCCAPIs[
        ccapiToEdit.orgName
      ].ccApis.map((ccapi) => {
        if (ccapi.host === ccapiToEdit?.ccapi.host) {
          return {
            ...ccapi,
            ...(key === 'interfacePort'
              ? {
                  goinitus: {
                    port: number,
                  },
                }
              : { [key]: Number(number) }),
          };
        }
        return ccapi;
      });

      console.log('updatedCCAPIs input: ', updatedCCAPIs);

      setUpdatedCCAPIs({ ...updatedCCAPIs });
    },
    [ccapiToEdit, updatedCCAPIs, setUpdatedCCAPIs],
  );

  const isInvalid = useMemo(
    () =>
      Object.keys(updatedCCAPIs).length === 0 ||
      Object.entries(updatedCCAPIs).some(
        ([orgName, orgInfo]) =>
          orgInfo.ccApis.some((ccapi) => !ccapi.chaincodeName) ||
          !orgInfo.ca.username ||
          !orgInfo.ca.password,
      ),
    [updatedCCAPIs],
  );

  const isFileType = useMemo(() => {
    if (!ccapiToEdit || !selectedNetwork) return false;

    const type =
      selectedNetwork.chaincodes[ccapiToEdit.ccapi.chaincodeName]?.ccType;

    return type === 'form';
  }, [ccapiToEdit, selectedNetwork]);

  const onSubmit = useCallback(async () => {
    if (!selectedNetwork || !selectedChannel) return;

    if (isInvalid) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: 'Please fill in all the required fields',
        }),
      );
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();

    try {
      const payload = {
        chaincodes: Object.entries(updatedCCAPIs).reduce(
          (acc, [orgName, orgInfo]) => {
            orgInfo.ccApis.forEach((ccapi) => {
              acc[ccapi.chaincodeName] = {
                type: selectedNetwork?.chaincodes[ccapi.chaincodeName].ccType,
                tarName:
                  selectedNetwork?.chaincodes[ccapi.chaincodeName].tarName,
                ccBaseName:
                  selectedNetwork?.chaincodes[ccapi.chaincodeName].ccBaseName,
              };

              // acc[ccapi.chaincodeName] =
              //   selectedNetwork?.chaincodes[ccapi.chaincodeName];
              // delete acc[ccapi.chaincodeName].peers;

              if (
                files?.[orgName]?.[ccapi.host]?.[ccapi.chaincodeName]?.[
                  selectedChannel
                ]
              )
                formData.append(
                  'ccFiles',
                  files[orgName][ccapi.host][ccapi.chaincodeName][
                    selectedChannel
                  ] as Blob,
                );
            });

            return acc;
          },
          {} as Record<
            string,
            { type: string; tarName: string; ccBaseName: string }
          >,
        ),
        organizations: updatedCCAPIs,
      };

      console.log('PAYLOAD: ', payload);

      formData.append('payload', JSON.stringify(payload));
      formData.append('networkName', selectedNetwork?.networkName);
      // if (files) {
      //   // for each file, append it to the form data
      //   // formData.append('ccFiles', file as Blob);
      // }

      networkApi
        .post('/updateCCApi', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, 'Update Chaincode API', hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: 'Update Chaincode API',
                pathname: window.location.pathname,
                name: 'upgradeccapi',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(() => {
          fetchNetworkState();

          dispatch(
            openDialog({
              title: 'Success',
              type: 'success',
              content: 'API upgraded successfully',
            }),
          );
          dispatch(removeOperation('upgradeccapi', true));
          history.push('/dashboard');
          clearOperationsState();
        })
        .catch((error: string) => {
          console.log('upgradeccapi error: ', error);
          dispatch(removeOperation('upgradeccapi', false));
          dispatch(
            openDialog({
              title: t('common.words.error'),
              type: 'error',
              content: error?.message,
            }),
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      console.log('error: ', error);
      dispatch(removeOperation('upgradeccapi', false));
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error?.message,
        }),
      );
    }
  }, [
    selectedNetwork,
    selectedChannel,
    isInvalid,
    dispatch,
    t,
    updatedCCAPIs,
    files,
    fetchNetworkState,
    history,
    clearOperationsState,
  ]);

  const handleChangeCC = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!ccapiToEdit) return;

      updatedCCAPIs[ccapiToEdit.orgName].ccApis = updatedCCAPIs[
        ccapiToEdit.orgName
      ].ccApis.map((host) => {
        if (host.host === ccapiToEdit.ccapi.host) {
          return {
            ...host,
            chaincodeName: event?.target?.value,
            channelName: selectedChannel || '',
          };
        }
        return host;
      });

      setUpdatedCCAPIs({ ...updatedCCAPIs });
    },
    [ccapiToEdit, selectedChannel, updatedCCAPIs, setUpdatedCCAPIs],
  );

  if (!selectedChannel)
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography style={{ fontSize: '32px' }} variant="overline">
          Update API
        </Typography>

        <Typography style={{ fontSize: '20px' }}>
          Select a Network and a Channel to start
        </Typography>
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography style={{ fontSize: '32px' }} variant="overline">
        Update API
      </Typography>

      <Grid container justify="center">
        <Grid item xs={3}>
          {selectedChannel && (
            <Box
              display="flex"
              flexDirection="column"
              style={{
                gap: '1rem',
              }}
            >
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    height: '24px',
                  }}
                >
                  Chaincode
                </FormLabel>
                <RadioGroup
                  aria-label="chaincode"
                  name="chaincode"
                  value={selectedChaincode}
                  // onChange={handleChangeCC}
                  onChange={(event) => setSelectedChaincode(event.target.value)}
                >
                  {selectedNetwork?.channels &&
                    selectedNetwork?.channels?.[
                      selectedChannel
                    ]?.chaincodes.map((chaincode) => {
                      return (
                        <FormControlLabel
                          value={chaincode}
                          control={<Radio />}
                          label={chaincode}
                        />
                      );
                    })}
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </Grid>

        <Grid item xs={3}>
          <List className={classes.root} subheader={<li />}>
            {Object.entries(filteredCCAPIs).map(([orgName, ccapis]) => {
              return (
                <li key={`section-${orgName}`} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader>{`${orgName}`}</ListSubheader>

                    {ccapis.map((ccapi) => {
                      return (
                        <ListItem
                          key={`item-${orgName}-${ccapi.host}`}
                          role={undefined}
                          dense
                        >
                          <Checkbox
                            onChange={handleSelect(orgName, ccapi)}
                            checked={ccapiIsChecked(ccapi, orgName)}
                          />

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            style={{ gap: '0.5rem' }}
                          >
                            {ccapi.host}
                            <ArrowForwardIosIcon
                              onClick={() => {
                                setCCAPIToEdit({
                                  orgName,
                                  ccapi,
                                });
                                addCCAPI(orgName, ccapi);
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </Box>
                        </ListItem>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </List>
        </Grid>

        <Grid item xs={3}>
          {selectedChannel && (
            <Box display="flex" flexDirection="column" alignItems="center">
              {ccapiToEdit?.ccapi && ccapiToEdit.orgName ? (
                <>
                  <Typography>
                    {ccapiToEdit?.ccapi.host} -{' '}
                    {ccapiToEdit?.ccapi.chaincodeName}
                  </Typography>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      defaultValue={
                        selectedNetwork?.organizations[
                          ccapiToEdit?.orgName
                        ].ccapi.find(
                          (api) =>
                            api.host === ccapiToEdit?.ccapi.host &&
                            api.chaincodeName ===
                              ccapiToEdit?.ccapi.chaincodeName &&
                            api.channelName === selectedChannel,
                        )?.restPort
                      }
                      value={
                        updatedCCAPIs[ccapiToEdit.orgName].ccApis.find(
                          (api) =>
                            api.host === ccapiToEdit?.ccapi.host &&
                            api.chaincodeName ===
                              ccapiToEdit?.ccapi.chaincodeName &&
                            api.channelName === selectedChannel,
                        )?.restPort
                      }
                      style={{ maxWidth: '256px' }}
                      label="API Port"
                      onChange={({ target: { value } }) =>
                        handleChangePort('restPort')(Number(value))
                      }
                      disabled={isDefaultApiPort}
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChangePort('restPort')(
                          selectedNetwork?.organizations[
                            ccapiToEdit?.orgName
                          ].ccapi.find(
                            (api) =>
                              api.host === ccapiToEdit?.ccapi.host &&
                              api.chaincodeName ===
                                ccapiToEdit?.ccapi.chaincodeName &&
                              api.channelName === selectedChannel,
                          )?.restPort ?? 80,
                        );
                        setIsDefaultApiPort(e.target.checked);
                      }}
                      checked={isDefaultApiPort}
                    />
                    <Typography>Original</Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <GenericInput
                      type="number"
                      defaultValue={
                        selectedNetwork?.organizations[
                          ccapiToEdit?.orgName
                        ].ccapi.find(
                          (api) =>
                            api.host === ccapiToEdit?.ccapi.host &&
                            api.chaincodeName ===
                              ccapiToEdit?.ccapi.chaincodeName &&
                            api.channelName === selectedChannel,
                        )?.tlsPort
                      }
                      value={
                        updatedCCAPIs[ccapiToEdit.orgName].ccApis.find(
                          (api) =>
                            api.host === ccapiToEdit?.ccapi.host &&
                            api.chaincodeName ===
                              ccapiToEdit?.ccapi.chaincodeName &&
                            api.channelName === selectedChannel,
                        )?.tlsPort
                      }
                      style={{ maxWidth: '256px' }}
                      label="TLS Port"
                      onChange={({ target: { value } }) =>
                        handleChangePort('tlsPort')(Number(value))
                      }
                      disabled={isDefaultTLSPort}
                    />
                    <Checkbox
                      onChange={(e) => {
                        handleChangePort('tlsPort')(
                          selectedNetwork?.organizations[
                            ccapiToEdit?.orgName
                          ].ccapi.find(
                            (api) =>
                              api.host === ccapiToEdit?.ccapi.host &&
                              api.chaincodeName ===
                                ccapiToEdit?.ccapi.chaincodeName &&
                              api.channelName === selectedChannel,
                          )?.tlsPort ?? 443,
                        );
                        setIsDefaultTLSPort(e.target.checked);
                      }}
                      checked={isDefaultTLSPort}
                    />
                    <Typography>Original</Typography>
                  </Box>

                  {shouldShowInterfacePort && (
                    <Box display="flex" alignItems="center">
                      <GenericInput
                        type="number"
                        defaultValue={
                          selectedNetwork?.organizations[
                            ccapiToEdit?.orgName
                          ].ccapi.find(
                            (api) =>
                              api.host === ccapiToEdit?.ccapi.host &&
                              api.chaincodeName ===
                                ccapiToEdit?.ccapi.chaincodeName &&
                              api.channelName === selectedChannel,
                          )?.goinitus?.port
                        }
                        value={ccapiToEdit.ccapi.goinitus?.port || ''}
                        style={{ maxWidth: '256px' }}
                        label="Interface Port"
                        onChange={({ target: { value } }) =>
                          handleChangePort('interfacePort')(Number(value))
                        }
                        disabled={isDefaultInterfacePort}
                      />
                      <Checkbox
                        onChange={(e) => {
                          handleChangePort('interfacePort')(
                            selectedNetwork?.organizations[
                              ccapiToEdit?.orgName
                            ].ccapi.find(
                              (api) =>
                                api.host === ccapiToEdit?.ccapi.host &&
                                api.chaincodeName ===
                                  ccapiToEdit?.ccapi.chaincodeName &&
                                api.channelName === selectedChannel,
                            )?.goinitus?.port ?? 8080,
                          );
                          setIsDefaultInterfacePort(e.target.checked);
                        }}
                        checked={isDefaultInterfacePort}
                      />
                      <Typography>Original</Typography>
                    </Box>
                  )}

                  <FormLabel
                    style={{
                      margin: '0.75rem 0',
                      display: 'flex',
                      alignItems: 'end',
                      height: '24px',
                    }}
                  >
                    {ccapiToEdit.orgName} CA authentication{' '}
                    {(!updatedCCAPIs[ccapiToEdit?.orgName]?.ca?.username ||
                      !updatedCCAPIs[ccapiToEdit?.orgName]?.ca?.password) && (
                      <Warning style={{ color: 'var(--warning)' }} />
                    )}
                  </FormLabel>
                  <GenericInput
                    value={
                      updatedCCAPIs[ccapiToEdit?.orgName]?.ca?.username || ''
                    }
                    variant="standard"
                    disabled={isSubmitting}
                    onChange={(e) =>
                      setCACredentials(ccapiToEdit?.orgName, {
                        username: e.target.value,
                      })
                    }
                    label={<Trans>common.forms.CAUser</Trans>}
                    style={{
                      margin: '0',
                      marginBottom: '1rem',
                    }}
                  />
                  <GenericInput
                    type="password"
                    value={
                      updatedCCAPIs[ccapiToEdit?.orgName]?.ca?.password || ''
                    }
                    variant="standard"
                    disabled={isSubmitting}
                    onChange={(e) =>
                      setCACredentials(ccapiToEdit?.orgName, {
                        password: e.target.value,
                      })
                    }
                    label={<Trans>common.forms.CAPassword</Trans>}
                    style={{
                      margin: '0',
                    }}
                  />

                  {isFileType && (
                    <>
                      <Typography
                        variant="overline"
                        style={{
                          marginTop: '1rem',
                        }}
                      >
                        File upload
                      </Typography>
                      <FileInput
                        file={
                          files?.[ccapiToEdit.orgName]?.[
                            ccapiToEdit.ccapi.host
                          ]?.[ccapiToEdit.ccapi.chaincodeName]?.[
                            selectedChannel
                          ] || null
                        }
                        onChange={(filelist) => {
                          setFile((previousFiles) => ({
                            ...previousFiles,
                            [ccapiToEdit.orgName]: {
                              ...(previousFiles?.[ccapiToEdit.orgName] || {}),
                              [ccapiToEdit.ccapi.host]: {
                                ...(previousFiles?.[ccapiToEdit.orgName]?.[
                                  ccapiToEdit.ccapi.host
                                ] || {}),
                                [ccapiToEdit.ccapi.chaincodeName]: {
                                  ...(previousFiles?.[ccapiToEdit.orgName]?.[
                                    ccapiToEdit.ccapi.host
                                  ]?.[ccapiToEdit.ccapi.chaincodeName] || {}),
                                  [selectedChannel]: filelist?.[0] || null,
                                },
                              },
                            },
                          }));
                        }}
                        containerStyle={{
                          padding: 0,
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                <Typography align="center">Select a CC API to edit</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      {/* {isSubmitting && (
        <Button
          color="secondary"
          variant="contained"
          style={{ width: '45%', margin: '0 auto', pointerEvents: 'all' }}
          onClick={() => cancelRequest()}
        >
          <Trans>button.cancel</Trans>
        </Button>
      )} */}

      <Button
        color="primary"
        variant="contained"
        className="deploy-start-btn"
        disabled={isSubmitting || !selectedChannel || isInvalid}
        style={{ width: '45%', margin: '1rem auto', pointerEvents: 'all' }}
        onClick={onSubmit}
      >
        <Trans>button.start</Trans>
      </Button>

      <OperationStateCard taskOperation={upgradeAPIState} />
    </Box>
  );
};

export default AddChaincodeAPI;
