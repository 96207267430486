import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Axios from 'axios';
import {
  List,
  Button,
  Checkbox,
  ListItem,
  Typography,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from '@material-ui/core';

import history from '../../../history';
import { networkApi } from '../../../Common/axios';
import { openDialog } from '../../../store/Dialog';
import { addOperation, removeOperation } from '../../../store/AppStatus';

import LoadingScreen from '../../LoadingScreen';
import LoadingContainer from '../../../AppComponents/Notifications';

import { StoreState } from '../../../store/types';
import { ISelectedNetwork } from '../../../store/Network';
import {
  INode,
  IMappedOrg,
  useAddWebClientForm,
} from '../../../Hooks/addWebClient';

import Select from '../../../AppComponents/Select';
import CAauthForm, { Auth } from '../../../AppComponents/CAauth';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { mapChannelOrgs } from '../../../utils/network/parseChannelMembers';
import { clearAddWebClientNotifications } from '../../../store/AddWebClientNotifications';

import {
  Card,
  Section,
  PageTitle,
  LoadContainer,
  PageContainer,
  SelectListItem,
} from './styles';

interface IPeer {
  value: string;
  webClient: boolean;
  ccRestHost: boolean;
}

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddWebClient = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedNetwork }: { selectedNetwork: ISelectedNetwork } =
    useSelector((state: StoreState) => state.networkState);

  const {
    selectedOrgIdx,
    setSelectedOrgIdx,
    selectedPeer,
    setSelectedPeer,
    loading,
    setLoading,
    sendingData,
    setSendingData,
    orgs,
    setOrgs,
    channelName,
    setChannelName,
    netStateOrgs,
    setNetStateOrgs,
    channelDefs,
    setChannelDefs,
    clearFormData,
  } = useAddWebClientForm();

  const selectedOrg: IMappedOrg | undefined = orgs[selectedOrgIdx];

  const [CAauth, setCAauth] = useState<Auth>({} as Auth);

  const changeCAauth = useCallback((caAuth) => setCAauth(caAuth), [setCAauth]);

  // Loads the peer configuration from the rest
  useEffect(() => {
    if (selectedNetwork.name && !sendingData) {
      clearFormData();
      setLoading(true);

      const config = { params: { networkName: selectedNetwork.name } };

      networkApi
        .get(`/networkstate`, config)
        .then((res) => {
          const { organizations, networkDefs } = res.data.states[0];

          setNetStateOrgs(organizations);
          setChannelDefs(networkDefs.channelDefs);
        })
        .catch((error) => {
          dispatch(
            openDialog({
              type: 'error',
              title: t('common.words.error'),
              content: error.message.includes('Request failed with status code')
                ? t('common.messages.failedToSelectNet')
                : error.message,
            }),
          );
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [
    t,
    dispatch,
    selectedNetwork.name,
    setLoading,
    clearFormData,
    setChannelDefs,
    setNetStateOrgs,
  ]);

  const send = () => {
    try {
      dispatch(clearAddWebClientNotifications());

      const formData = new FormData();

      if (!channelName) throw Error(t('common.messages.noChannelSelected'));
      if (!selectedOrg || !selectedOrg.firstName)
        throw Error(t('common.messages.noOrgSelected'));
      if (!selectedPeer.value) throw Error(t('common.messages.noPeerSelected'));

      formData.append(
        'payload',
        JSON.stringify({
          channelName,
          peerIP: selectedPeer.value,
          orgName: selectedOrg.firstName,
          networkName: selectedNetwork.name,
        }),
      );

      setSendingData(true);

      networkApi
        .post('/addwebclient', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, t('title.api.addWebClient'), hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.api.addWebClient'),
                pathname: window.location.pathname,
                cancel: withDialogCancel,
                name: 'addwebclient',
              }),
            );
          }),
        })
        .then(() => {
          dispatch(removeOperation('addwebclient', true));
          clearFormData();
          if (window.location.pathname === '/api/add/webclient') {
            history.push('/dashboard');
          }
        })
        .catch(() => dispatch(removeOperation('addwebclient', false)))
        .finally(() => setSendingData(false));
    } catch (error) {
      setSendingData(false);
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message,
        }),
      );
    }
  };

  const getTooltipMessage = (peer: INode) => {
    if (peer.webClient) return t('asset.api.addWebClient.alreadyHaveWebClient');

    if (!peer.ccRestHost)
      return t('asset.api.addWebClient.needToAddCCAPIFirst');

    return t('common.words.unavailable');
  };

  const getOrgPeerObj = (
    stateOrg: INetStateOrg,
    chPeer: INetStateChannelDefOrg['Peers'][0],
  ) => {
    const orgCCRestHost = (stateOrg.ccRestHost || {})[channelName];

    const ccHost = (orgCCRestHost || []).find(
      (restHost: CCRestHost) => restHost.host === stateOrg.peers[chPeer].host,
    );

    return {
      ccRestHost: !!ccHost,
      value: stateOrg.peers[chPeer].host,
      webClient: ccHost && ccHost.webClient,
    };
  };

  const getOrgOrdererObj = (
    stateOrg: INetStateOrg,
    chOrderer: INetStateChannelDefOrg['Orderers'][0],
  ) => {
    const orgCCRestHost = (stateOrg.ccRestHost || {})[channelName];

    const ccHost = (orgCCRestHost || []).find(
      (restHost: CCRestHost) => restHost.host === stateOrg.orderers[chOrderer],
    );

    return {
      ccRestHost: !!ccHost,
      value: stateOrg.orderers[chOrderer],
      webClient: ccHost && ccHost.webClient,
    };
  };

  const getOrgObj = (stateOrg: INetStateOrg) => ({
    firstName: stateOrg.orgName,
    name: `${stateOrg.orgName}.${stateOrg.orgDomainName}`,
  });

  const getChannelMembers = useCallback(() => {
    const channelDef = channelDefs.find(
      (chDef) => chDef.channelName === channelName,
    );

    if (channelDef) {
      const { mappedOrgs } = mapChannelOrgs({
        getOrgObj,
        getOrgPeerObj,
        ipKey: 'value',
        getOrgOrdererObj,
        uniqueHosts: true,
        chOrgs: channelDef.orgs,
        organizations: netStateOrgs,
      });

      setOrgs(mappedOrgs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelName, netStateOrgs, channelDefs, setOrgs]);

  useEffect(() => {
    getChannelMembers();
  }, [channelName, getChannelMembers]);

  if (!selectedNetwork.name) return <SelectNetwork />;

  if (loading)
    return (
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <CircularProgress style={{ marginTop: '30px' }} />
      </div>
    );

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <PageTitle>
        <Trans>title.api.addWebClient</Trans>
      </PageTitle>

      <PageContainer>
        <Card>
          <div style={{ width: '100%', display: 'flex' }}>
            <Section>
              <Typography>
                <Trans>common.forms.channelsToJoin</Trans>
              </Typography>

              <List>
                {selectedNetwork.channels.map((channel) => (
                  <ListItem
                    button
                    divider
                    key={channel}
                    onClick={() => setChannelName(channel)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        disabled={sendingData}
                        checked={channel === channelName}
                      />
                    </ListItemIcon>

                    <ListItemText primary={channel} />
                  </ListItem>
                ))}
              </List>
            </Section>

            <Section>
              <Typography>
                <Trans>common.words.organizations</Trans>
              </Typography>

              {!channelName ? (
                <Typography
                  variant="body1"
                  style={{ marginTop: 32, color: 'var(--darkGray)' }}
                >
                  <Trans>common.messages.selectChannelFirst</Trans>
                </Typography>
              ) : (
                <List>
                  {orgs.map((org, idx) => (
                    <SelectListItem
                      key={org.name}
                      disabled={sendingData}
                      selected={selectedOrg && selectedOrg.name === org.name}
                      onClick={() => setSelectedOrgIdx(idx)}
                    >
                      {org.name}
                    </SelectListItem>
                  ))}
                </List>
              )}
            </Section>

            <Section>
              <Typography style={{ marginBottom: 8, textAlign: 'left' }}>
                Select a peer
              </Typography>

              <Select
                hasTooltip
                keyToRender="value"
                placeholder="Select a peer"
                tooltipMessage={getTooltipMessage}
                disabled={sendingData || !selectedOrg}
                options={selectedOrg ? selectedOrg.nodes : []}
                onChange={(option) => setSelectedPeer(option)}
                isDisabled={(option) => !option.ccRestHost || option.webClient}
              />

              <Typography style={{ marginTop: '16px', textAlign: 'left' }}>
                Certificate Authority info
              </Typography>

              <CAauthForm
                initialState={CAauth}
                callback={changeCAauth}
                disabled={sendingData || !selectedOrg}
                userInputStyles={{ width: '100%', margin: '3px 0' }}
                passwordInputStyles={{ width: '100%', margin: '3px 0' }}
              />
            </Section>
          </div>

          {sendingData ? (
            <LoadContainer>
              <LoadingScreen
                content={t('asset.api.addWebClient.loadingMessage')}
              />
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => cancelRequest()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            </LoadContainer>
          ) : (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              style={{ marginTop: '16px' }}
              onClick={send}
            >
              <Trans>button.send</Trans>
            </Button>
          )}
        </Card>

        {selectedOrg && selectedPeer.value ? (
          <div style={{ margin: '0 auto' }}>
            <LoadingContainer
              cardInfo={{
                type: 'addwebclient',
                running: sendingData,
                title: t('asset.api.addWebClient.webClient'),
                attributes: {
                  channelName,
                  organization: selectedOrg.name,
                  peerIP: selectedPeer.value || null,
                },
              }}
            />
          </div>
        ) : null}
      </PageContainer>
    </div>
  );
};

export default AddWebClient;
