import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GenericInput from '../Screens/Deploy/Components/GenericInput';
import { useDeployForm } from '../Hooks/deploy';
import { channelNamePatt } from '../utils/regexPatterns';
import { ChannelPeerAdder } from '../AppComponents/ChannelPeerAdder';

interface ChannelFormProps {
  channelIndex: number;
}

export const ChannelForm: React.FC<ChannelFormProps> = ({ channelIndex }) => {
  const { channels, setChannels } = useDeployForm();
  const { t } = useTranslation();

  const channel = useMemo(() => channels?.[channelIndex], [
    channelIndex,
    channels,
  ]);

  const invalidChName = useMemo(
    () => !channelNamePatt.test(channel?.channelName),
    [channel],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value } = event.target;
      channels[channelIndex] = { ...channel, channelName: value };
      setChannels([...channels]);
    },
    [channelIndex, channel, channels, setChannels],
  );

  return (
    <form>
      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: '2rem' }}
        width="fit-content"
        margin="0 auto"
      >
        <Typography variant="overline">Channel basic info</Typography>

        <GenericInput
          variant="standard"
          value={channel?.channelName}
          disabled={false}
          onChange={handleChange}
          label={t('common.forms.channelName')}
          helperText={
            invalidChName ? t('common.messages.invalidChannelName') : ''
          }
          error={invalidChName}
        />

        <Box>
          <ChannelPeerAdder channelIndex={channelIndex} />
        </Box>
      </Box>
    </form>
  );
};
