import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

export interface IUpgradeCCApiNotificationsState extends Record<string, any> {
  loadingInfo: CommonNotificationObject;
  loadingInfoAttrs: INotificationAttrs;
}

export const initialState: IUpgradeCCApiNotificationsState = {
  loadingInfo: {},
  loadingInfoAttrs: {} as INotificationAttrs,
};

const upgradeCCApiNotificationsSlice = createSlice({
  name: 'upgradeCCApiNotifications',
  initialState,
  reducers: {
    changeUpgradeCCApi(state, action: PayloadAction<CommonNotificationObject>) {
      state.loadingInfo = action.payload;

      if (Object.keys(state.loadingInfoAttrs).length <= 0) {
        state.loadingInfoAttrs = {
          mainLoad: 0,
          failed: false,
          fullyComplete: false,
        };
      }
    },
    changeUpgradeCCApiAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      state.loadingInfoAttrs = {
        ...state.loadingInfoAttrs,
        ...action.payload,
      };
    },
    clearUpgradeCCapiNotifications() {
      return initialState;
    },
  },
});

export const {
  changeUpgradeCCApi,
  changeUpgradeCCApiAttrs,
  clearUpgradeCCapiNotifications,
} = upgradeCCApiNotificationsSlice.actions;

export default upgradeCCApiNotificationsSlice.reducer;
