import React, { createContext, useContext, useMemo, useState } from 'react';

export const AddChannelContextDefaultValues: AddChannelContextType = {
  channels: [],
  setChannels: () => ({}),
  started: false,
  setStarted: () => ({}),
};

export const AddChannelContext = createContext<AddChannelContextType>(
  AddChannelContextDefaultValues,
);

export const AddChannelProvider: React.FC = ({ children }) => {
  const [channels, setChannels] = useState<IAddChannel>([
    { channelName: '', chaincodes: [], peers: {} },
  ] as IAddChannel);

  //   const [networkName, setNetworkName] = useState('');
  //   const [peers, setPeers] = useState({});
  const [started, setStarted] = useState(false);

  const values = useMemo(
    () => ({
      channels,
      setChannels,
      started,
      setStarted,
    }),
    [channels, started],
  );

  return (
    <AddChannelContext.Provider value={values}>
      {children}
    </AddChannelContext.Provider>
  );
};

export function useAddChannel() {
  const context = useContext(AddChannelContext);

  if (!context) {
    throw new Error('useAddChannel must be used within a AddChannelProvider');
  }

  return context;
}
