import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  margin: 50px 10px 0 10px;
  padding: 15px 0;
  background: #212121;
  border-radius: 20px;
  width: 35vw;
  width: 490px;
`;

export const TitleLoader = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
`;

export const OrgDomainAndNameContainer = styled.div`
  display: flex;
  max-width: 50%;
  word-break: break-word;
  flex-direction: column;
`;

export const NodeOptCounterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
