import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Input,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { Error as Warning } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { useDeployForm } from '../../Hooks/deploy';
import { useNetworks } from '../../Contexts/Networks';

export const NetworkNameInput = () => {
  const { networks } = useNetworks();

  const { setNetworkName, networkName, started, setIsValid } = useDeployForm();

  const existsNetwork = useMemo(() => {
    return networks?.find((net) => net.networkName === networkName);
  }, [networkName, networks]);

  const { t } = useTranslation();

  return (
    <TextField
      label={t('common.forms.networkName')}
      value={networkName}
      disabled={started}
      style={{ width: '256px' }}
      error={networkName?.indexOf(' ') >= 0}
      onChange={({ target: { value } }: { target: { value: string } }) =>
        setNetworkName(value)
      }
      {...(existsNetwork && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="start">
              <Warning style={{ color: '#ffcc00' }} />
            </InputAdornment>
          ),
        },
        helperText: (
          <Typography
            component="span"
            style={{
              color: '#ffcc00',
              fontSize: '12px',
              fontWeight: 700,
            }}
          >
            <Trans
              values={{
                netName: existsNetwork?.networkName,
              }}
            >
              asset.network.deploy.messages.networkNameUsed
            </Trans>
            <span>{` ${existsNetwork.displayName}`}</span>
          </Typography>
        ),
      })}
    />
  );
};
