import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import history from '../../../history';
import { StoreState } from '../../../store/types';
import { Operation, cancelOperation } from '../../../store/AppStatus';

import LoadingScreen from '../../../Screens/LoadingScreen';

import { Container, LoadingContainer } from './styles';

interface ToastProps {
  message: {
    title: string;
    name: string;
  };
}

const Toast: React.FC<ToastProps> = ({ message }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const appStatus = useSelector((state: StoreState) => state.appStatusState);
  const { operations } = appStatus;

  const handleCancel = useCallback(() => {
    cancelOperation(message.name);
  }, [message]);

  const handleRedirect = useCallback(() => {
    const operation = operations.find(
      (op: Operation) => op.name === message.name,
    );

    history.push(`${operation.pathname}`);
  }, [message, operations]);

  const handleChangeVisibility = useCallback(() => {
    if (
      operations.some(
        (op: Operation) =>
          op.name === message.name && window.location.pathname !== op.pathname,
      )
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [message.name, operations]);

  useEffect(() => {
    handleChangeVisibility();
    // eslint-disable-next-line
  }, [window.location.pathname, handleChangeVisibility]);

  return (
    <Container
      display={visible ? 'flex' : 'none'}
      onClick={() => handleRedirect()}
    >
      <div>
        <strong>{message.title}</strong>
      </div>
      <LoadingContainer>
        <LoadingScreen content={`${t('common.words.loading')}...`} />
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={() => {
            handleCancel();
          }}
        >
          <Trans>button.cancel</Trans>
        </Button>
      </LoadingContainer>
    </Container>
  );
};

export default Toast;
