import React from 'react';

import { AttributeTypography } from './styles';

const Attribute: React.FC<{
  value: any;
  name: string;
  size: string | number;
}> = ({ name, value, size }) => (
  <AttributeTypography fontSize={size}>
    {name}: {value}
  </AttributeTypography>
);

export default Attribute;
