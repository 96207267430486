import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { AppThunk } from '../../store';
import { networkApi } from '../../Common/axios';

export interface IInstance extends Record<string, any> {
  publicIP: string;
  name: string;
}

export interface IInstanceState extends Record<string, any> {
  instances: IInstance[];
  fetchingInstance: string;
}

const initialState: IInstanceState = {
  instances: [],
  fetchingInstance: 'nothing', // nothing, loading, success, failure
};

const instanceSlice = createSlice({
  name: 'instance',
  initialState,
  reducers: {
    changeEC: {
      reducer(state, action: PayloadAction<IInstance[]>) {
        state.instances = action.payload;
      },
      prepare(ec2: Record<string, any>) {
        const instances: IInstance[] = [];

        Object.keys(ec2.Reservations).forEach((key0) => {
          const reserv = ec2.Reservations[key0];

          Object.keys(ec2.Reservations).forEach((key1) => {
            const inst = reserv.Instances[key1];

            if (inst.PublicIpAddress) {
              instances.push({
                publicIP: inst.PublicIpAddress,
                name: inst.Tags
                  ? inst.Tags.find((tag: { Key: string }) => {
                      return tag.Key === 'Name';
                    }).Value
                  : 'no-name',
              });
            }
          });
        });

        return { payload: instances };
      },
    },
    toggleECFetch(state, action: PayloadAction<string>) {
      state.fetchingInstance = action.payload;
    },
  },
});

export const fetchEC = (): AppThunk => (dispatch) => {
  dispatch(instanceSlice.actions.toggleECFetch('loading'));

  networkApi
    .get('/ecinstances')
    .then((res: AxiosResponse<any>) => {
      dispatch(instanceSlice.actions.changeEC(res.data));
      dispatch(instanceSlice.actions.toggleECFetch('success'));
    })
    .catch(() => {
      dispatch(instanceSlice.actions.toggleECFetch('failure'));
    });
};

export const { changeEC, toggleECFetch } = instanceSlice.actions;

export default instanceSlice.reducer;
