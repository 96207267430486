import React, { useState, useEffect, useRef, useCallback } from 'react';
import Lottie from 'lottie-react-web';
import { CircularProgress, Typography } from '@material-ui/core';
import '../../assets/spinning.css';

import ErrorAnimation from '../../assets/error.json';
import SuccessAnimation from '../../assets/success.json';
import LoadingAnimation from '../../assets/loading.json';
import MinorSuccessAnimation from '../../assets/minorSuccess.json';

import { INotificationAttrs } from '../../types';
import { NotificationValue } from '../../../../store/types';

import { LoadingNumber, LoadingsContainer, LoadingItem } from './styles';

interface ILoading extends Record<string, any> {
  title: string;
  status: NotificationValue;
}

interface ILoaderProps extends Record<string, any> {
  // using any until we change all operations to use redux to save notifications
  // after that we should type this as WithOrgNotificationObject | CommonNotificationObj
  list: any;
  type?: string;
  running: boolean;
  notificationAttrs: INotificationAttrs;
  changeNotification: (params: Record<string, any>) => void;
}

const Loader: React.FC<ILoaderProps> = ({
  list,
  type,
  running,
  notificationAttrs,
  changeNotification,
}) => {
  const interval = useRef(0);
  const [bruteLoad, setBruteLoad] = useState(0);
  const [callback, setCallback] = useState(false);
  const [loadings, setLoadings] = useState<ILoading[]>([]);

  const { failed, mainLoad, fullyComplete } = notificationAttrs;

  const setNotification = useCallback(
    (newParams: Record<string, any>) => {
      changeNotification({
        ...newParams,
        // If the operation does not have a orgName key on its notification
        // we will send it as undefined, each notification reducer will handle this value on its own way
        orgName: list && (list.orgName || undefined),
      });
    },
    [changeNotification, list],
  );

  // if the component unmount the interval must be cleared
  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  // if the running prop is false the interval
  // must be cleared and bruteLoad changed to 0
  useEffect(() => {
    if (!running) {
      setBruteLoad(0);
      clearInterval(interval.current);
    }
  }, [running]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (bruteLoad > 0) {
        if (
          Number.isNaN(mainLoad) ||
          Number.isNaN(bruteLoad) ||
          mainLoad >= bruteLoad
        )
          clearInterval(interval.current);
        else {
          setNotification({ mainLoad: mainLoad + 0.4 });
        }

        interval.current = setTimeout(() => setCallback(!callback), 16.6);
      }
      // this timeout avoids the maximum update depth exceeded problem
    }, 5);

    return () => {
      clearTimeout(timer);
      clearInterval(interval.current);
    };
  }, [mainLoad, bruteLoad, callback, setNotification]);

  useEffect(() => {
    let hasError = false;
    let successCount = 0;

    if (list) {
      const newLoadings: ILoading[] = Object.entries(list)
        .filter(
          (item) =>
            item[0] !== 'orgName' &&
            item[0] !== 'name' &&
            item[0] !== 'type' &&
            item[0] !== 'failed' &&
            item[0] !== 'mainLoad' &&
            item[0] !== 'fullyComplete',
        )
        .map((item) => {
          if (item[1] === 'success') successCount++;
          if (item[1] === 'error') hasError = true;

          return {
            title: item[0],
            status: `${item[1]}` as NotificationValue,
          };
        });

      if (hasError) {
        setNotification({ mainLoad: 0, failed: true });
        setBruteLoad(0);
        setLoadings(newLoadings);
        clearInterval(interval.current);
        return;
      }

      setNotification({ failed: false });

      const changeLoading = () => {
        let doneCount = 0;
        newLoadings.forEach((item) =>
          item.status === 'success' ? doneCount++ : null,
        );
        setBruteLoad((doneCount / newLoadings.length) * 100);
      };

      const isAllLoading = !newLoadings.some(
        (item) => item.status !== 'loading',
      );

      if (isAllLoading) {
        setNotification({ mainLoad: 0 });
        clearInterval(interval.current);
      } else changeLoading();

      setLoadings(newLoadings);

      // I'm checking if it's false, because if it's undefined I don't want
      // it to enter this if block, it will start the card with a fail animation
      // If the developer do not forget to pass the running attr on card info
      // everything will be fine, but to prevent it I'll let it that way
      if (running === false && successCount !== loadings.length) {
        setNotification({ mainLoad: 0, failed: true });
        clearInterval(interval.current);
        setBruteLoad(0);
      }

      if (
        loadings.length > 0 &&
        successCount > 0 &&
        (successCount === loadings.length || mainLoad >= 100)
      ) {
        setNotification({ fullyComplete: true });
        clearInterval(interval.current);
        setBruteLoad(0);
      }
    }
    // The loadings state is changed in this useEffect
    // so it can not be used as dependency
    // eslint-disable-next-line
  }, [list, running]);

  const renderItemStatus = (item: ILoading) => {
    switch (item.status) {
      case 'idle':
        return <div style={{ width: 20 }} />;
      case 'success':
        return (
          <Lottie
            options={{
              animationData: MinorSuccessAnimation,
              loop: false,
            }}
            width={20}
          />
        );

      case 'loading':
        return (
          <Lottie
            options={{
              animationData: LoadingAnimation,
              loop: true,
            }}
            width={20}
          />
        );

      case 'error':
        return (
          <Lottie
            options={{
              animationData: ErrorAnimation,
              loop: false,
            }}
            width={20}
          />
        );
      default:
        return <div />;
    }
  };

  if (type === 'main')
    return loadings.length > 0 ? (
      <div style={{ width: 'fit-content', margin: '0 auto' }}>
        {fullyComplete ? (
          <Lottie
            options={{
              animationData: SuccessAnimation,
              loop: false,
            }}
            width={130}
            height={130}
          />
        ) : failed ? (
          <Lottie
            options={{
              animationData: ErrorAnimation,
              loop: false,
            }}
            width={130}
            height={130}
          />
        ) : (
          <div>
            <LoadingNumber>{`${parseInt(`${mainLoad}`, 10)}%`}</LoadingNumber>

            <div className="loading">
              <CircularProgress
                size={130}
                thickness={1.6}
                variant="determinate"
                value={mainLoad}
              />
            </div>
          </div>
        )}
      </div>
    ) : null;

  return (
    <LoadingsContainer>
      {loadings.map((item) => (
        <LoadingItem key={item.title}>
          <div style={{ margin: '5px' }}>{renderItemStatus(item)}</div>

          <Typography
            style={{ color: 'var(--white)', fontSize: '10px' }}
            variant="overline"
          >
            {item.title}
          </Typography>
        </LoadingItem>
      ))}
    </LoadingsContainer>
  );
};

export default Loader;
