import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { mountCardAttributes } from '../utils';
import FillingCard from '../components/Card';
import { changeAddOrdererNotificationAttrs } from '../../../store/AddOrdererNotifications';

import { StoreState } from '../../../store/types';
import { ILoadingContainerCardInfo } from '../types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const {
    notification,
    notificationAttrs,
  } = state.addOrdererNotificationsState;
  const { title, running, attributes, notLoadingWidth } = cardInfo;
  const { ordererIP, orgName, channelName } = attributes || {};

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeAddOrdererNotificationAttrs(params));
  };

  const newCardInfo = {
    running,
    notLoadingWidth,
    notificationAttrs,
    changeNotification,
    loadingInfo: notification,
    canShow: ordererIP && orgName && channelName,
    attributes: mountCardAttributes(attributes || {}, t),
    loadingStarted: Object.keys(notification).length > 0,
  };

  return <FillingCard cardInfo={{ ...newCardInfo, title }} />;
};
