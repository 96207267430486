import React from 'react';
import { Typography, Divider } from '@material-ui/core';

import Loader from '../Loader';
import { renderAttributes } from '../../utils';

import { ICardInfo } from '../../types';
import { FillingCardHeader, FillingCardContainer } from './styles';

interface IFillingCardProps {
  cardInfo: ICardInfo;
  hasMainLoad?: boolean;
}

const FillingCard: React.FC<IFillingCardProps> = ({
  cardInfo,
  hasMainLoad,
}) => {
  const {
    title,
    canShow,
    running,
    attributes,
    loadingInfo,
    titleFontSize,
    loadingStarted,
    notLoadingWidth,
    notificationAttrs,
    customAttributes,
    changeNotification,
  } = cardInfo;

  if (!canShow) return null;
  return (
    <FillingCardContainer
      loadingStarted={loadingStarted}
      notLoadingWidth={notLoadingWidth}
    >
      <div style={{ padding: '0 25px' }}>
        <FillingCardHeader>
          <Typography
            variant="overline"
            style={{ color: 'var(--white)', fontSize: titleFontSize || '25px' }}
          >
            {title}
          </Typography>

          {hasMainLoad ? (
            <div
              style={{ width: '50%', display: 'flex', alignItems: 'center' }}
            >
              <Loader
                type="main"
                running={running}
                list={loadingInfo}
                started={loadingStarted}
                notificationAttrs={notificationAttrs}
                changeNotification={changeNotification}
              />
            </div>
          ) : null}
        </FillingCardHeader>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {customAttributes
            ? customAttributes()
            : attributes && Object.keys(attributes).length > 0
            ? renderAttributes(attributes)
            : null}
        </div>

        <Divider />

        <Loader
          running={running}
          list={loadingInfo}
          changeNotification={changeNotification}
          notificationAttrs={notificationAttrs}
        />
      </div>
    </FillingCardContainer>
  );
};

FillingCard.defaultProps = {
  hasMainLoad: true,
};

export default FillingCard;
