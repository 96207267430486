import React, { useCallback, useEffect } from 'react';
import Axios from 'axios';
import {
  List,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import history from '../../../history';
import { networkApi } from '../../../Common/axios';
import { openDialog } from '../../../store/Dialog';
import {
  OrgsOrderersMap,
  ISelectedOrg,
  useAddOrdererForm,
} from '../../../Hooks/addOrderer';
import { addOperation, removeOperation } from '../../../store/AppStatus';

import { StoreState } from '../../../store/types';
import { ISelectedNetwork } from '../../../store/Network';
import { clearAddOrdererNotifications } from '../../../store/AddOrdererNotifications';

import {
  Card,
  Section,
  LoadContainer,
  PageContainer,
  SelectListItem,
} from './styles';

import LoadingScreen from '../../LoadingScreen';
import CAauthForm from '../../../AppComponents/CAauth';
import { ipPatt, fqdnPatt } from '../../../utils/regexPatterns';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import LoadingContainer from '../../../AppComponents/Notifications';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { useNetworks } from '../../../Contexts/Networks';

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddOrderer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedNetwork, fetchNetworkState, getAllNetworks } = useNetworks();

  const {
    loading,
    setLoading,
    host,
    setHost,
    sendingData,
    setSendingData,
    CAauth,
    setCAauth,
    selectedOrg,
    setSelectedOrg,
    ordererAlreadyExists,
    setOrgsOrderers,
    orgs,
    setOrgs,
    setNetStateOrgs,
    clearFormData,
  } = useAddOrdererForm();

  const changeCAauth = useCallback((caAuth) => setCAauth(caAuth), [setCAauth]);

  useEffect(() => {
    if (selectedNetwork?.networkName && !sendingData) {
      clearFormData();
      setLoading(true);

      const config = { params: { networkName: selectedNetwork?.networkName } };

      networkApi
        .get(`/networkstate`, config)
        .then((res) => {
          const newOrgOrderers: OrgsOrderersMap = {};
          const mappedOrgs: ISelectedOrg[] = [];
          const { organizations } = res.data.states[0];

          setNetStateOrgs(organizations);

          organizations.forEach((org: INetStateOrg) => {
            mappedOrgs.push({
              ip: org.restHost,
              name: org.orgName,
            });
            Object.values(org.orderers).forEach((ordererIP) => {
              newOrgOrderers[ordererIP] = true;
            });
          });

          setOrgsOrderers(newOrgOrderers);
          setOrgs(mappedOrgs);
        })
        .catch((error) => {
          dispatch(
            openDialog({
              type: 'error',
              title: t('common.words.error'),
              content: error.message.includes('Request failed with status code')
                ? t('common.messages.failedToSelectNet')
                : error.message,
            }),
          );
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [t, dispatch, selectedNetwork?.networkName, setLoading, setOrgsOrderers]);

  const isHostInvalid = () =>
    !host ||
    (!(ipPatt.test(host) || fqdnPatt.test(host)) && host.length >= 1) ||
    ordererAlreadyExists;

  const sendData = () => {
    setSendingData(true);

    try {
      dispatch(clearAddOrdererNotifications());

      if (!selectedOrg.name) throw Error(t('common.messages.noOrgSelected'));
      if (ordererAlreadyExists)
        throw Error(t('common.messages.alreadyUsedOrdererIP'));
      if (isHostInvalid()) throw Error(t('common.messages.invalidIP'));
      if (!CAauth.user || !CAauth.passwd)
        throw Error(t('common.messages.addCredentials'));

      const ordererData = {
        CAauth,
        ordererIP: host,
        orgName: selectedOrg.name,
        networkName: selectedNetwork?.networkName,
      };

      networkApi
        .post('/addorderer', ordererData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(
                c,
                t('title.nodes.orderers.addOrderer'),
                hasDialog,
              );
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.nodes.orderers.addOrderer'),
                cancel: withDialogCancel,
                name: 'addorderer',
                pathname: window.location.pathname,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();

          await fetchNetworkState();
          dispatch(removeOperation('addorderer', true));
          clearFormData();

          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: 'Peer added successfully',
            }),
          );

          if (window.location.pathname === '/node/orderer/add') {
            history.push('/dashboard');
          }
        })
        .catch(() => dispatch(removeOperation('addorderer', false)))
        .finally(() => setSendingData(false));
    } catch (error) {
      setSendingData(false);
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message,
        }),
      );
    }
  };

  if (!selectedNetwork?.networkName) return <SelectNetwork />;

  if (loading)
    return (
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <CircularProgress style={{ marginTop: '30px' }} />
      </div>
    );

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        <Trans>title.nodes.orderers.addOrderer</Trans>
      </Typography>

      <PageContainer>
        <Card>
          <div style={{ display: 'flex', gap: '2.5rem' }}>
            <Section>
              <Typography style={{ fontSize: '1.1rem' }}>
                <Trans>common.words.organizations</Trans>
              </Typography>

              <List>
                {orgs.map((org) => (
                  <SelectListItem
                    key={org.name}
                    disabled={sendingData}
                    selected={selectedOrg.name === org.name}
                    onClick={() => setSelectedOrg({ ...org })}
                  >
                    {`${org.name}-${org.ip}`}
                  </SelectListItem>
                ))}
              </List>
            </Section>

            <Section>
              <Typography style={{ textAlign: 'left', marginBottom: 16 }}>
                <Trans>common.forms.ordererIP</Trans>
              </Typography>

              <TextField
                name="host"
                value={host}
                style={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                error={host !== '' && isHostInvalid()}
                label={t('common.forms.ordererAddress')}
                disabled={sendingData || !selectedOrg.name}
                onChange={(e: any) => setHost(e.target.value)}
              />

              <Typography style={{ textAlign: 'left', marginTop: '16px' }}>
                <Trans>common.forms.CAauth</Trans>
              </Typography>

              <CAauthForm
                initialState={CAauth}
                callback={changeCAauth}
                disabled={sendingData || !selectedOrg.name}
                containerStyles={{ marginBottom: '16px' }}
                userInputStyles={{ width: '100%', margin: '3px 0' }}
                passwordInputStyles={{ width: '100%', margin: '3px 0' }}
              />
            </Section>
          </div>

          {sendingData ? (
            <LoadContainer>
              <LoadingScreen
                content={t('asset.nodes.addOrderer.loadingMessage')}
              />

              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => cancelRequest()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            </LoadContainer>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={sendData}
              disabled={!selectedOrg}
            >
              <Trans>button.send</Trans>
            </Button>
          )}
        </Card>

        {selectedOrg && host ? (
          <div style={{ margin: '0 auto' }}>
            <LoadingContainer
              cardInfo={{
                type: 'addorderer',
                running: sendingData,
                title: t('title.nodes.orderers.addOrderer'),
                attributes: {
                  ordererIP: host,
                  orgName: selectedOrg.name,
                },
              }}
            />
          </div>
        ) : null}
      </PageContainer>
    </div>
  );
};

export default AddOrderer;
