import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { mountCardAttributes } from '../utils';
import FillingCard from '../components/Card';
import GlobalFillingCard from '../components/GlobalCard';
import { changeSetupNotificationAttrs } from '../../../store/SetupMachinesNotifications';

import { ILoadingContainerCardInfo } from '../types';
import { StoreState, WithOrgNotificationObject } from '../../../store/types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const {
    notifications,
    notificationsAttrs,
  } = state.setupMachinesNotificationsState;
  const { title, running, attributes, canShow, notLoadingWidth } = cardInfo;
  const { username, sshImage } = attributes || {};

  const newCardInfo = {
    canShow,
    notLoadingWidth,
    loadingStarted: notifications.length > 0,
  };

  const globalCardInfo = {
    title,
    running,
    canShow: username,
    attributes: mountCardAttributes({ username, sshImage }, t),
  };

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeSetupNotificationAttrs(params));
  };

  return (
    <>
      <GlobalFillingCard cardInfo={globalCardInfo} />

      {notifications.map(
        (notification: WithOrgNotificationObject, index: number) => (
          <FillingCard
            key={notification.orgName}
            cardInfo={{
              ...newCardInfo,
              running,
              changeNotification,
              attributes: {},
              titleFontSize: '20px',
              loadingInfo: notification,
              title: `IP: ${notification.orgName}`,
              notificationAttrs: notificationsAttrs[index],
            }}
          />
        ),
      )}
    </>
  );
};
