import history from '../../history';
import {
  addPropToAssetOnTour,
  changeDeployStepOnTour,
  changeDeployCCOpteOnTour,
  addAssetToTemplateOnTour,
  changeDeployTemplateOnTour,
  changeTemplateViewOrCreateOnTour,
} from '../../store/InitialTour';
import { Dispatch, TranslationFunction } from '../../store/types';
import {
  ChapterChangeAction,
  PageChangesPoints,
  StepChangeAction,
  TourStep,
} from './types';

export interface ITourChapter {
  name: string;
  stepRange: [number, number];
}

export const getSteps = (t: TranslationFunction): TourStep[] => [
  {
    disableBeacon: true,
    placement: 'auto',
    target: '.setupmachines',
    content: t('tour.steps.homePage.1'),
  },
  {
    disableBeacon: true,
    placement: 'auto',
    target: '.username-input',
    content: t('tour.steps.setupMachines.1'),
  },
  {
    placement: 'auto',
    target: '.ssh-image-input',
    content: t('tour.steps.setupMachines.2'),
  },
  {
    placement: 'auto',
    target: '.address-input',
    content: t('tour.steps.setupMachines.3'),
  },
  {
    placement: 'auto',
    target: '.add-more-addresses',
    content: t('tour.steps.setupMachines.4'),
  },
  {
    placement: 'auto',
    target: '.remove-address-btn',
    content: t('tour.steps.setupMachines.5'),
  },
  {
    placement: 'auto',
    target: '.start-setup',
    content: t('tour.steps.setupMachines.6'),
  },
  {
    placement: 'auto',
    target: '.network-app-header',
    content: t('tour.steps.setupMachines.7'),
  },
  {
    placement: 'auto',
    target: '.deploy-option',
    content: t('tour.steps.setupMachines.8'),
  },
  {
    placement: 'auto',
    target: '.deploy-steps',
    content: t('tour.steps.deploy.firstStep.1'),
  },
  {
    disableBeacon: true,
    placement: 'auto',
    target: '.define-orgs-step',
    content: t('tour.steps.deploy.firstStep.2'),
  },
  {
    placement: 'auto',
    target: '.add-new-org-tab',
    content: t('tour.steps.deploy.firstStep.3'),
  },
  {
    placement: 'auto',
    target: '.org-name-and-domain',
    content: t('tour.steps.deploy.firstStep.4'),
  },
  {
    placement: 'auto',
    target: '.org-ca-info',
    content: t('tour.steps.deploy.firstStep.5'),
  },
  {
    placement: 'auto',
    target: '.org-instances',
    content: t('tour.steps.deploy.firstStep.6'),
  },
  {
    placement: 'auto',
    target: '.org-instance-address-input',
    content: t('tour.steps.deploy.firstStep.7'),
  },
  {
    placement: 'auto',
    target: '.org-instance-ccapi-opt',
    content: t('tour.steps.deploy.firstStep.8'),
  },
  {
    placement: 'auto',
    target: '.org-instance-peer-opt',
    content: t('tour.steps.deploy.firstStep.9'),
  },
  {
    placement: 'auto',
    target: '.org-instance-orderer-opt',
    content: t('tour.steps.deploy.firstStep.10'),
  },
  {
    placement: 'auto',
    target: '.org-instance-address-add-btn  ',
    content: t('tour.steps.deploy.firstStep.11'),
  },
  {
    placement: 'auto',
    target: '.deploy-next-step-btn',
    content: t('tour.steps.deploy.firstStep.12'),
  },
  {
    placement: 'auto',
    target: '.deploy-chaincode-options',
    content: t('tour.steps.deploy.secondStep.1'),
  },
  {
    placement: 'auto',
    target: '.deploy-template-option',
    content: t('tour.steps.deploy.secondStep.template.1'),
  },
  // now it goes to the template management page, step by step
  {
    placement: 'auto',
    target: '.chaincodes-app-header',
    content: t('tour.steps.deploy.chaincodeTemplates.1'),
  },
  {
    placement: 'auto',
    target: '.chaincode-templates',
    content: t('tour.steps.deploy.chaincodeTemplates.2'),
  },
  {
    disableBeacon: true,
    placement: 'auto',
    target: '.manage-templates',
    content: t('tour.steps.deploy.chaincodeTemplates.3'),
  },
  {
    placement: 'auto',
    target: '.template-management-container',
    content: t('tour.steps.deploy.chaincodeTemplates.5'),
  },
  {
    placement: 'auto',
    target: '.add-new-template',
    content: t('tour.steps.deploy.chaincodeTemplates.4'),
  },
  {
    placement: 'auto',
    target: '.template-name-and-description',
    content: t('tour.steps.deploy.chaincodeTemplates.6'),
  },
  {
    placement: 'auto',
    target: '.add-asset-to-template',
    content: t('tour.steps.deploy.chaincodeTemplates.7'),
  },
  {
    placement: 'auto',
    target: '.asset-label-and-tag',
    content: t('tour.steps.deploy.chaincodeTemplates.8'),
  },
  {
    placement: 'left-start',
    target: '.remove-asset-from-template',
    content: t('tour.steps.deploy.chaincodeTemplates.9'),
  },
  {
    placement: 'auto',
    target: '.add-asset-prop-btn',
    content: t('tour.steps.deploy.chaincodeTemplates.10'),
  },
  {
    placement: 'auto',
    target: '.asset-prop-label-and-tag',
    content: t('tour.steps.deploy.chaincodeTemplates.11'),
  },
  {
    placement: 'auto',
    target: '.asset-prop-opts',
    content: t('tour.steps.deploy.chaincodeTemplates.12'),
  },
  {
    placement: 'auto',
    target: '.submit-template-btn',
    content: t('tour.steps.deploy.chaincodeTemplates.13'),
  },
  {
    placement: 'auto',
    target: '.network-app-header',
    content: t('tour.steps.deploy.chaincodeTemplates.14'),
  },
  {
    placement: 'auto',
    target: '.deploy-option',
    content: t('tour.steps.deploy.chaincodeTemplates.15'),
  },
  {
    disableBeacon: true,
    placement: 'auto',
    target: '.deploy-net-channel-name',
    content: t('tour.steps.deploy.secondStep.template.2'),
  },
  {
    placement: 'auto',
    target: '.base-templates-list',
    content: t('tour.steps.deploy.secondStep.template.3'),
  },
  {
    placement: 'auto',
    target: '.deploy-template-select',
    content: t('tour.steps.deploy.secondStep.template.4'),
  },
  {
    placement: 'auto',
    target: '.template-permissions-explanation',
    content: t('tour.steps.deploy.secondStep.template.5'),
  },
  {
    placement: 'auto',
    target: '.template-assets-permissions',
    content: t('tour.steps.deploy.secondStep.template.6'),
  },
  // now it goes to the preloaded chaincode option
  {
    disableBeacon: true,
    placement: 'auto',
    target: '.deploy-preload-option',
    content: t('tour.steps.deploy.secondStep.preload.1'),
  },
  {
    placement: 'auto',
    target: '.preloaded-chaincodes-list',
    content: t('tour.steps.deploy.secondStep.preload.2'),
  },
  {
    placement: 'right',
    target: '.prealoded-info-btn',
    content: t('tour.steps.deploy.secondStep.preload.3'),
  },
  // now it goes to the custom chaincode option
  {
    disableBeacon: true,
    placement: 'auto',
    target: '.deploy-custom-option',
    content: t('tour.steps.deploy.secondStep.custom.1'),
  },
  {
    placement: 'auto',
    target: '.chaincode-name-input',
    content: t('tour.steps.deploy.secondStep.custom.2'),
  },
  {
    placement: 'auto',
    target: '.chaincode-upload-btn',
    content: t('tour.steps.deploy.secondStep.custom.3'),
  },
  {
    placement: 'auto',
    target: '.define-endorsement',
    content: t('tour.steps.deploy.secondStep.2'),
  },
  {
    placement: 'auto',
    target: '.deploy-start-btn',
    content: t('tour.steps.deploy.secondStep.3'),
  },
  {
    placement: 'auto',
    target: '.app-header-help-tour',
    content: `${t('tour.steps.deploy.secondStep.finalStep')} \n\n ${t(
      'tour.steps.finalStep',
    )}`,
  },
];

export const getChapters = (t: TranslationFunction): ITourChapter[] => [
  { name: t('tour.chapters.setupMachines'), stepRange: [1, 6] },
  { name: t('tour.chapters.deployFirstStep'), stepRange: [10, 20] },
  { name: t('tour.chapters.createTemplate'), stepRange: [26, 35] },
  { name: t('tour.chapters.deployWithTemplate'), stepRange: [38, 42] },
  { name: t('tour.chapters.deployWithCloudCC'), stepRange: [43, 45] },
  { name: t('tour.chapters.deployWithCustomCC'), stepRange: [46, 49] },
];

export const chapterChangeActions: ChapterChangeAction = {
  '0': {
    nextComponent: 'setupMachines',
    action() {
      history.push('/setupmachines');
    },
  },
  '1': {
    nextComponent: 'deployFirstStep',
    action() {
      history.push('/network/deploy');
    },
  },
  '2': {
    nextComponent: 'chaincodeTemplatesManage',
    action() {
      history.push('/chaincode/templates/manage');
    },
  },
  '3': {
    nextComponent: 'deploySecondStep',
    action(reduxDispatch: Dispatch) {
      history.push('/network/deploy');
      reduxDispatch(changeDeployStepOnTour(1));
    },
  },
  '4': {
    nextComponent: 'deployTemplatePermissions',
    action(reduxDispatch: Dispatch) {
      history.push('/network/deploy');
      reduxDispatch(changeDeployStepOnTour(1));
      reduxDispatch(changeDeployCCOpteOnTour('template'));
      reduxDispatch(changeDeployTemplateOnTour());
    },
  },
  '5': {
    nextComponent: 'preloadedChaincodeOption',
    action(reduxDispatch: Dispatch) {
      history.push('/network/deploy');
      reduxDispatch(changeDeployStepOnTour(1));
      reduxDispatch(changeDeployCCOpteOnTour('preload'));
    },
  },
};

// this saves which steps have the next step
// in another page and the route to the page
export const pageChangePoints: PageChangesPoints = {
  0: {
    component: 'homePage',
    nextComponent: 'setupMachines',
    nextRoute: '/setupmachines',
    prevRoute: '/',
  },
  7: {
    component: 'setupMachines',
    nextComponent: 'networkTabMain',
    nextRoute: '/network',
    prevRoute: '/setupmachines',
  },
  8: {
    component: 'networkTabMain',
    nextComponent: 'deployFirstStep',
    nextRoute: '/network/deploy',
    prevRoute: '/network',
  },
  23: {
    component: 'deploySecondStep',
    nextComponent: 'chaincodeMain',
    nextRoute: '/chaincode',
    prevRoute: '/network/deploy',
    goBackAction(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployStepOnTour(1));
    },
  },
  24: {
    component: 'chaincodeMain',
    nextComponent: 'chaincodeTemplatesMain',
    nextRoute: '/chaincode/templates',
    prevRoute: '/chaincode',
  },
  25: {
    component: 'chaincodeTemplatesMain',
    nextComponent: 'chaincodeTemplatesManage',
    nextRoute: '/chaincode/templates/manage',
    prevRoute: '/chaincode/templates',
  },
  36: {
    component: 'chaincodeTemplatesManage',
    nextComponent: 'networkTabMain',
    nextRoute: '/network',
    prevRoute: '/chaincode/templates/manage', // not sure
  },
  37: {
    component: 'networkTabMain',
    nextComponent: 'deploySecondStep',
    nextRoute: '/network/deploy',
    prevRoute: '/network',
    //  not sure
    goBackAction(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployStepOnTour(1));
    },
  },
};

// this saves which steps have a certain
// action to perform before changing step
export const stepChangeActions: StepChangeAction = {
  '20': {
    nextComponent: 'deploySecondStep',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployStepOnTour(1));
    },
  },
  undo20: {
    prevComponent: 'deployFirstStep',
    action: (reduxDispatch: Dispatch) => {
      reduxDispatch(changeDeployStepOnTour(0));
    },
  },
  '27': {
    nextComponent: 'templateCreate',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeTemplateViewOrCreateOnTour('create'));
    },
  },
  undo27: {
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeTemplateViewOrCreateOnTour('view'));
    },
  },
  '29': {
    nextComponent: 'templateAssetFragment',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(addAssetToTemplateOnTour());
    },
  },
  '32': {
    nextComponent: 'templateAssetPropsFragment',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(addPropToAssetOnTour());
    },
  },
  '37': {
    nextComponent: 'deploySecondStep',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployStepOnTour(1));
    },
  },
  undo37: {
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeTemplateViewOrCreateOnTour('create'));
      reduxDispatch(addAssetToTemplateOnTour());
      reduxDispatch(addPropToAssetOnTour());
    },
  },
  '40': {
    nextComponent: 'deployTemplatePermissions',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployTemplateOnTour());
    },
  },
  undo40: {
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployTemplateOnTour(null));
    },
  },
  '43': {
    nextComponent: 'preloadedChaincodeOption',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployCCOpteOnTour('preload'));
    },
  },
  undo43: {
    prevComponent: 'templateChaincodeOption',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployCCOpteOnTour('template'));
      reduxDispatch(changeDeployTemplateOnTour());
    },
  },
  '46': {
    nextComponent: 'customChaincodeOption',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployCCOpteOnTour('custom'));
    },
  },
  undo46: {
    prevComponent: 'preloadedChaincodeOption',
    action(reduxDispatch: Dispatch) {
      reduxDispatch(changeDeployCCOpteOnTour('preload'));
    },
  },
};
