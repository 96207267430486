import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MTableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StoreState } from 'react-joyride';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { StepData } from '..';
import ReportStatusIcon from '../../../AppComponents/Report/StatusIcon';
import { Container } from '../styles';

export interface IStepTableData {
  status: string;
  step: string;
  reason: string;
}

interface IHeadCell {
  disablePadding: boolean;
  label: JSX.Element | string;
  id: keyof IStepTableData | 'actions';
}

const getInitialHeadCells = (): IHeadCell[] => [
  {
    id: 'status',
    disablePadding: false,
    label: <Trans>common.words.status</Trans>,
  },
  {
    id: 'step',
    disablePadding: false,
    label: <Trans>asset.reports.step</Trans>,
  },
  {
    id: 'reason',
    disablePadding: false,
    label: <Trans>asset.reports.reason</Trans>,
  },
];

const TableHead: React.FC<{ headCells: IHeadCell[] }> = ({ headCells }) => (
  <MTableHead>
    <TableRow>
      {headCells.map((headCell) => (
        <TableCell key={headCell.id} align="center">
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </MTableHead>
);

const ReportSteps: React.FC<{
  tasks: StepData[];
  currentOPHistory: OPHistory;
}> = ({ currentOPHistory, tasks }) => {
  const { t } = useTranslation();
  const { reportId } = useParams<{ reportId: string }>();
  console.log('taa', tasks);
  const headCells = getInitialHeadCells();
  return (
    <Container>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead headCells={headCells as IHeadCell[]} />
            <TableBody>
              {tasks?.length &&
                tasks?.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell align="center">
                      <ReportStatusIcon status={row?.status} />
                    </TableCell>
                    <TableCell align="center">{row.label}</TableCell>
                    <TableCell align="center" style={{ maxWidth: '340px' }}>
                      {row?.status === 'stopped' || row?.status === 'failed'
                        ? currentOPHistory?.reason
                        : '-'}
                    </TableCell>
                    <TableCell align="center" />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default ReportSteps;
