import { Canceler } from 'axios';
import i18n from 'i18next';
import { store } from '../store';
import { openDialog } from '../store/Dialog';

const CancelRequest = (c: Canceler, opTitle: string, hasDialog = true) => {
  if (hasDialog) {
    const wishToCancelMsg = i18n.t('common.messages.wishToCancel', {
      opName: opTitle,
    });

    store.dispatch(
      openDialog({
        type: 'question',
        content: wishToCancelMsg,
        confirmAction: () => c(),
        title: i18n.t('common.words.warning'),
      }),
    );
  } else {
    c();
  }
};

export default CancelRequest;
