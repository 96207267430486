import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Container, Typography } from '@material-ui/core';
import { NetworkStatus } from '../../store/Network';
import { Colors } from '../../styles/globals';

interface ISideBarProps {
  sidebarWidth: number;
}

interface IListItemProps {
  selected: boolean;
  disabled: boolean;
}

interface INetStatusIndicatorProps {
  status: NetworkStatus;
}

const networkStatusMap: Record<NetworkStatus, any> = {
  online: css`
    background: var(--success);
  `,
  offline: css`
    background: var(--error);
  `,
  partial: css`
    background: var(--reportNotRun);
  `,
};

export const SideBar = styled.div<ISideBarProps>`
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
  float: left;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #95a5a6;
  background-color: var(--primary);
  width: ${({ sidebarWidth }) => `${sidebarWidth}px`};
`;

export const UserInfo = styled.div`
  padding: 0 5px;
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  min-height: 35px;
  justify-content: space-around;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    outline: 0;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
`;

export const ListItem = styled(Container)<IListItemProps>`
  && {
    width: 95%;
    padding: 0;
    height: 48px;
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
    background: ${({ selected }) =>
      selected ? lighten(0.1, Colors.primary) : ''};

    ${({ disabled }) =>
      disabled &&
      css`
        && {
          cursor: default;
        }
      `};

    &:hover {
      cursor: pointer;
      background: ${({ disabled }) =>
        !disabled ? lighten(0.1, Colors.primary) : ''};
    }
  }
`;

export const NetInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
  box-sizing: border-box;
  justify-content: space-between;
`;

export const ChannelInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
  box-sizing: border-box;
`;

export const NetSelectionBar = styled.div`
  bottom: 0;
  width: 100%;
  height: 8px;
  margin: 0 auto;
  position: absolute;
  border-radius: 0 0 8px 8px;
  background: var(--tourToastButton);
`;

export const NetworkName = styled(Typography)`
  && {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    color: var(--white);
    text-overflow: ellipsis;
  }
`;

export const NetStatusIndicator = styled.div<INetStatusIndicatorProps>`
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  margin-right: 16px;
  border-radius: 50%;

  ${({ status }) => networkStatusMap[status]};
`;
