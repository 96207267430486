import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Grid, Typography } from '@material-ui/core';
import MenuPage from '../MenuPage';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  fabGreen: {
    marginLeft: '10%',
    color: theme.palette.common.white,
    backgroundColor: green[500],
    boxShadow: 'none',
  },
});

const PeerMain = (props) => {
  const { t } = useTranslation();
  const { classes } = props;

  const options = [
    {
      icon: 'share',
      text: t('title.nodes.peers.addPeer'),
      link: '/node/peer/add',
    },
    // {
    //   icon: 'delete',
    //   text: t('title.nodes.peers.removePeer'),
    //   link: '/node/peer/remove',
    // },
    // {
    //   icon: 'share',
    //   text: t('title.nodes.orderers.addOrderer'),
    //   link: '/node/orderer/add',
    // },
    // {
    //   icon: 'add_circle',
    //   text: t('title.nodes.joinNodesToChannel'),
    //   link: '/node/channel/join',
    // },
  ];

  return (
    <Grid
      container
      justify="center"
      className={[classes.root, classes.centerContainer].join(' ')}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography style={{ fontSize: '32px' }} variant="overline">
          <Trans>title.nodes.header</Trans>
        </Typography>
      </div>
      <Grid item xs={12}>
        <MenuPage title={t('header.title')} options={options} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PeerMain);
