import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/GoLedger.png';

import { Container, SelectNetMsg } from './styles';

const SelectNetwork: React.FC<{ isNetSelected?: boolean }> = ({
  isNetSelected,
}) => {
  const { t } = useTranslation();

  return (
    <Container windowHeight={window.innerHeight}>
      <img src={Logo} alt="Goledger logo" width="150" height="150" />

      <SelectNetMsg selected={isNetSelected}>
        {t(
          `common.messages.${
            isNetSelected ? 'pleaseSelectChannel' : 'selectNet'
          }`,
        )}
      </SelectNetMsg>
    </Container>
  );
};

export default SelectNetwork;
