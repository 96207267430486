import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { DialogContentText } from '@material-ui/core';
import { StoreState } from '../../store/types';

import { closeSuccessModal } from '../../store/AddOrg';
import APIAuthSuccessModal from '../../AppComponents/APIAuthSuccessModal';

const AddOrgSuccessModal = () => {
  const dispatch = useDispatch();
  const { selectedNetwork } = useSelector(
    (state: StoreState) => state.networkState,
  );

  const { successModalOpened } = useSelector(
    (state: StoreState) => state.addOrgState,
  );

  return (
    <APIAuthSuccessModal
      opened={successModalOpened}
      networkName={selectedNetwork.name}
      closeModal={() => dispatch(closeSuccessModal())}
    >
      <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        <Trans>common.words.congratulations</Trans>!
        <br />
        <br />
        <Trans>asset.organizations.addOrg.messages.orgAddedSuccessfully</Trans>
        <br />
        <br />
        <Trans>common.messages.storeKeysSafely</Trans>
        <br />
        <br />
        <Trans>asset.dashboard.listAuthApi</Trans>:
      </DialogContentText>
    </APIAuthSuccessModal>
  );
};

export default AddOrgSuccessModal;
