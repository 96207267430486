import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '../store/types';
import { Auth } from '../AppComponents/CAauth';
import { changeSelectedNetInfo } from '../store/Network';
import { getUniqueRestHostsMap } from '../utils/network/parseNetworkState';
import { clearJoinChannelNotifications } from '../store/JoinChannelNotifications';
import { ISelectedNodes } from '../Screens/Channel/utils';

export interface JoinChannelContextData {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  sending: boolean;
  setSending: Dispatch<SetStateAction<boolean>>;
  orgs: IOrganization[];
  setOrgs: Dispatch<SetStateAction<IOrganization[]>>;
  selectedOrgIdx: number;
  setSelectedOrgIdx: Dispatch<SetStateAction<number>>;
  restHostsMap: Record<string, boolean>;
  setRestHostsMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  channelNodesMap: Record<string, boolean>;
  setChannelNodesMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  CAauth: Auth;
  setCAauth: Dispatch<SetStateAction<Auth>>;
  selectedNodes: ISelectedNodes[];
  setSelectedNodes: Dispatch<SetStateAction<ISelectedNodes[]>>;
  clearFormData(): void;
}

const JoinChannelContext = createContext<JoinChannelContextData>(
  {} as JoinChannelContextData,
);

const JoinChannelProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [CAauth, setCAauth] = useState<Auth>({});
  const [orgs, setOrgs] = useState<IOrganization[]>([]);
  const [selectedOrgIdx, setSelectedOrgIdx] = useState(-1);
  const [selectedNodes, setSelectedNodes] = useState<ISelectedNodes[]>([]);
  const [restHostsMap, setRestHostsMap] = useState<Record<string, boolean>>({});
  const [channelNodesMap, setChannelNodesMap] = useState<
    Record<string, boolean>
  >({});

  const dispatch = useDispatch();

  const { selectedNetwork } = useSelector(
    (state: StoreState) => state.networkState,
  );

  const { channelName } = selectedNetwork;

  const clearFormData = useCallback(() => {
    setOrgs([]);
    setCAauth({});
    setLoading(false);
    setSending(false);
    setRestHostsMap({});
    setSelectedOrgIdx(-1);

    dispatch(clearJoinChannelNotifications());
  }, [dispatch]);

  const getOrgNodes = useCallback(
    (type: 'o' | 'p', nodes: any[], ccRestHosts: CCRestHost[]): INode[] =>
      nodes.map((item) => {
        const nodeIp: string = type === 'p' ? item[1].host : item[1];
        const restHost = ccRestHosts.find((ccHost) => ccHost.host === nodeIp);

        return {
          name: item[0].split('.')[0],
          value: nodeIp,
          selected: false,
          opts: {
            ccRestHost: !!restHost,
            ccWebClient: restHost ? restHost.webClient : false,
          },
        };
      }),
    [],
  );

  const getOrgsInfo = useCallback(
    (organizations: INetStateOrg[]) => {
      let restHosts: { host: string }[] = [];

      const mappedOrgs = organizations.map((org) => {
        const orgCCRestHost = org.ccRestHost || {};
        Object.keys(orgCCRestHost).forEach((channel) => {
          restHosts = [...restHosts, ...orgCCRestHost[channel]];
        });

        return {
          firstName: org.orgName,
          name: `${org.orgName}.${org.orgDomainName}`,
          peers: getOrgNodes(
            'p',
            Object.entries(org.peers),
            orgCCRestHost[channelName] || [],
          ),
          orderers: getOrgNodes(
            'o',
            Object.entries(org.orderers),
            orgCCRestHost[channelName] || [],
          ),
        };
      });

      return { mappedOrgs, restHosts: getUniqueRestHostsMap(restHosts) };
    },
    [channelName, getOrgNodes],
  );

  const getChannelNodesMap = useCallback((channelDef: INetStateChannelDef) => {
    const chNodesMap: Record<string, boolean> = {};

    channelDef.orgs.forEach((chOrg) => {
      chOrg.Peers.forEach((peer) => {
        chNodesMap[peer] = true;
      });

      chOrg.Orderers.forEach((orderer) => {
        chNodesMap[orderer] = true;
      });
    });

    return chNodesMap;
  }, []);

  const updateNetworkData = useCallback(
    (infoJson: string) => {
      try {
        const parsedJson = JSON.parse(infoJson);
        const { organizations, networkDefs } = parsedJson.states[0];
        const channelDef = networkDefs.channelDefs.find(
          (chDef: INetStateChannelDef) => chDef.channelName === channelName,
        );

        if (channelDef) {
          const { mappedOrgs, restHosts } = getOrgsInfo(organizations);

          setOrgs(mappedOrgs);
          setRestHostsMap(restHosts);
          setChannelNodesMap(getChannelNodesMap(channelDef));
        }
      } catch (error) {
        dispatch(changeSelectedNetInfo({ jsonError: 'invalid JSON' }));
      }
    },
    [dispatch, channelName, getOrgsInfo, getChannelNodesMap],
  );

  useEffect(() => {
    if (
      selectedNetwork.json &&
      selectedNetwork.json !== '{}' &&
      selectedNetwork.name &&
      channelName
    ) {
      updateNetworkData(selectedNetwork.json);
    }
  }, [
    selectedNetwork.name,
    selectedNetwork.json,
    channelName,
    updateNetworkData,
  ]);

  return (
    <JoinChannelContext.Provider
      value={{
        loading,
        setLoading,
        sending,
        setSending,
        orgs,
        setOrgs,
        selectedOrgIdx,
        setSelectedOrgIdx,
        restHostsMap,
        setRestHostsMap,
        CAauth,
        setCAauth,
        channelNodesMap,
        setChannelNodesMap,
        selectedNodes,
        setSelectedNodes,
        clearFormData,
      }}
    >
      {children}
    </JoinChannelContext.Provider>
  );
};

function useJoinChannelForm(): JoinChannelContextData {
  const context = useContext(JoinChannelContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { JoinChannelProvider, useJoinChannelForm };
