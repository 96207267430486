import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAddOrganizationForm } from '../../../../Hooks/addOrganization';
import { OrgPeerAdder } from './orgAdder';

interface ChannelFormProps {
  channelIndex: number;
}

export const ChannelForm: React.FC<ChannelFormProps> = ({ channelIndex }) => {
  const { channels, setChannels } = useAddOrganizationForm();
  console.log('CANAIS', channels);
  const { t } = useTranslation();

  return (
    <form>
      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: '2rem' }}
        width="fit-content"
        margin="0 auto"
      >
        <Typography variant="overline">Channel basic info</Typography>

        <Box>
          <OrgPeerAdder channelIndex={channelIndex} />
        </Box>
      </Box>
    </form>
  );
};
