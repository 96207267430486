import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { changeMountedComponents } from '../../../../store/InitialTour';
import { StoreState } from '../../../../store/types';

import { Title, Content, Container, EmptyRightSide } from './styles';
import ListTemplates from './ListTemplates';
import ViewTemplate from './ViewTemplate';
import CreateTemplate from './CreateTemplate';
import EditTemplate from './EditTemplate';

const TemplatesPage: React.FC = () => {
  const dispatch = useDispatch();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const { chapterToChange, setTemplateViewOrCreateTo } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const [viewOrCreate, setViewOrCreate] = useState<
    'create' | 'view' | 'edit' | null
  >('view');

  const RenderRightSide = () => {
    switch (viewOrCreate) {
      case 'create':
        return <CreateTemplate />;
      case 'view':
        return <ViewTemplate />;
      case 'edit':
        return <EditTemplate setViewToNull={() => setViewOrCreate(null)} />;
      default:
        return <EmptyRightSide />;
    }
  };

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('chaincodeTemplatesManage'));
    // eslint-disable-next-line
  }, [chapterToChange]);

  // change the template mode on the initial tour
  useEffect(() => {
    if (tourMode && setTemplateViewOrCreateTo)
      setViewOrCreate(setTemplateViewOrCreateTo);
    // eslint-disable-next-line
  }, [setTemplateViewOrCreateTo]);

  return (
    <Container>
      <Title>
        <Trans>title.templates.header</Trans>
      </Title>

      <Content>
        <ListTemplates
          setViewOrCreate={setViewOrCreate}
          viewOrCreate={viewOrCreate}
        />

        <RenderRightSide />
      </Content>
    </Container>
  );
};

export default TemplatesPage;
