import { store } from '../store';
import { StoreState } from '../store/types';
import { changeAppLanguage } from '../store/AppStatus';

export default () => {
  const state = store.getState() as StoreState;
  const { language, supportedLanguages } = state.appStatusState;

  const savedLanguage = localStorage.getItem('@GoFabric:appLanguage');
  const isSavedLngValid = supportedLanguages.some(
    (lng: string) => lng === savedLanguage,
  );

  if (!savedLanguage || !isSavedLngValid) {
    localStorage.setItem('@GoFabric:appLanguage', language);

    return language;
  }

  store.dispatch(changeAppLanguage(savedLanguage));

  return savedLanguage;
};
