import React, { useState, useEffect } from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import history from '../../history';

import { ReactComponent as NotFoundLogo } from '../../assets/goledger_404.svg';

import { Container, Content } from './styles';

let unlisten: Function = () => null;

const PageNotFound: React.FC = () => {
  const { t } = useTranslation();

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    unlisten = history.listen(() => {
      setHidden(true);
    });

    return () => {
      unlisten();
    };
  }, []);

  if (hidden) return null;

  return (
    <Container>
      <Content>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={['column', 'column', 'row']}
          justifyContent={['center', 'spaceBetween']}
        >
          <NotFoundLogo width="200px" />

          <Box ml={[0, 0, '4rem']} mt={{ sm: '2rem', md: '3rem', lg: 0 }}>
            <Typography variant="h5" gutterBottom>
              {t('asset.pageNotFound.header')}
            </Typography>

            <Typography>{t('asset.pageNotFound.description')}</Typography>

            <Typography paragraph>
              {t('asset.pageNotFound.instruction')}
            </Typography>

            <Button
              variant="contained"
              style={{ color: 'var(--white)', background: 'var(--primary)' }}
              onClick={() => {
                setHidden(true);
                history.push('/');
              }}
            >
              {t('asset.pageNotFound.button')}
            </Button>
          </Box>
        </Box>
      </Content>
    </Container>
  );
};

export default PageNotFound;
