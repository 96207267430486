import styled from 'styled-components';

interface IStyledElementProps {
  color: string;
}

export const StyledElement = styled.div<IStyledElementProps>`
  height: 25;
  padding: 5px;
  display: flex;
  z-index: 11000;
  cursor: pointer;
  position: absolute;
  align-items: center;
  color: var(--white);
  justify-content: center;
  border: 2px solid var(--black);
  background: ${({ color }) => color};
`;
