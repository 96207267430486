import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TextField, List, Button, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  IAsset,
  ITemplate,
  editTemplate,
  setEditedTemplate,
} from '../../../../../store/TemplateCC';
import {
  Container,
  Subtitle,
  SPaper,
  AssetsTitle,
  AssetsTitleContainer,
} from './styles';

import { openDialog } from '../../../../../store/Dialog';
import AssetFragment from './AssetFragment';

import { validateTemplate } from '../utils';
import { StoreState } from '../../../../../store/types';
import { templateNamePatt } from '../../../../../utils/regexPatterns';

const EditTemplate: React.FC<{ setViewToNull: () => void }> = ({
  setViewToNull,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    editedTemplate,
  }: {
    editedTemplate: ITemplate;
  } = useSelector((state: StoreState) => state.templateCCState);

  const [loading, setLoading] = useState(false);

  const getCustomTypesList = () => {
    const { assets } = editedTemplate;

    const customDataTypes: string[] = [
      ...(editedTemplate.customDataTypes || []),
    ];

    assets.forEach((asset) => {
      (asset.props || []).forEach((prop) => {
        if (prop.isCustom) customDataTypes.push(prop.dataType);
      });
    });

    return customDataTypes;
  };

  const sendEditedTemplate = async () => {
    try {
      setLoading(true);

      if (!validateTemplate(editedTemplate, t)) return;

      await dispatch(editTemplate(getCustomTypesList()));

      dispatch(
        setEditedTemplate({
          name: '',
          description: '',
          assets: [],
          customDataTypes: [],
        }),
      );
      setViewToNull();

      dispatch(
        openDialog({
          title: t('common.words.success'),
          type: 'success',
          content: t('asset.chaincodes.templates.manageTemplates.editSuccess'),
        }),
      );
    } catch (err) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: err.response ? err.response.data : err.message,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const addToAssetList = (asset?: IAsset) => {
    if (asset === undefined)
      dispatch(
        setEditedTemplate({
          ...editedTemplate,
          assets: [
            ...editedTemplate.assets,
            { tag: '', label: '', props: [], selectOpened: false },
          ],
        }),
      );
    else {
      dispatch(
        setEditedTemplate({
          ...editedTemplate,
          assets: [...editedTemplate.assets, { ...asset }],
        }),
      );
    }
  };

  const editAsset = useCallback(
    (asset: IAsset, idx: number) => {
      const newAssets = [...editedTemplate.assets];
      newAssets[idx] = asset;
      dispatch(setEditedTemplate({ ...editedTemplate, assets: newAssets }));
    },
    [editedTemplate, dispatch],
  );

  const removeAsset = useCallback(
    (idx: number) => {
      const assets = [...editedTemplate.assets];
      assets.splice(idx, 1);
      dispatch(setEditedTemplate({ ...editedTemplate, assets }));
    },
    [editedTemplate, dispatch],
  );

  const renderAssetList = useCallback(() => {
    return editedTemplate.assets.map((asset, idx) => (
      <AssetFragment
        key={idx}
        asset={asset}
        editedTemplateAssets={editedTemplate.assets}
        edit={(toEditAsset) => editAsset(toEditAsset, idx)}
        remove={() => removeAsset(idx)}
      />
    ));
  }, [editedTemplate.assets, editAsset, removeAsset]);

  if (loading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  return (
    <Container>
      <Subtitle>
        <Trans>asset.chaincodes.templates.manageTemplates.editTemplate</Trans>
      </Subtitle>

      <SPaper>
        <TextField
          disabled
          label={t('common.words.name')}
          value={editedTemplate.name}
          error={!templateNamePatt.test(editedTemplate.name)}
          onChange={(e) =>
            dispatch(
              setEditedTemplate({ ...editedTemplate, name: e.target.value }),
            )
          }
        />

        <TextField
          label={t('common.words.description')}
          value={editedTemplate.description}
          onChange={(e) =>
            dispatch(
              setEditedTemplate({
                ...editedTemplate,
                description: e.target.value,
              }),
            )
          }
        />

        <AssetsTitleContainer>
          <AssetsTitle>
            <Trans>common.words.assets</Trans>
          </AssetsTitle>

          <Button variant="outlined" onClick={() => addToAssetList()}>
            <Trans>button.createNew</Trans>
          </Button>
        </AssetsTitleContainer>

        <List>{renderAssetList()}</List>
      </SPaper>

      <Button variant="outlined" color="primary" onClick={sendEditedTemplate}>
        <Trans>button.submit</Trans>
      </Button>
    </Container>
  );
};

export default EditTemplate;
