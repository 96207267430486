import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import { networkApi } from '../Common/axios';
import { openDialog } from '../store/Dialog';
import {
  INetworkState,
  clearSelectedNetwork,
  closeEditDisplayNameDialog,
  fetchNetwork,
} from '../store/Network';
import { StoreState } from '../store/types';

const EditNetDisplayNameModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { networks, editDisplayNameDialog } = useSelector(
    (state: StoreState) => state.networkState,
  ) as INetworkState;

  const { open, networkName, displayName } = editDisplayNameDialog;

  const [loading, setLoading] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState('');

  const deleteNetwork = () => {
    setLoading(true);

    networkApi
      .post('/editDisplayName', null, {
        params: { networkName, displayName: newDisplayName },
      })
      .then(() => {
        dispatch(fetchNetwork());
        dispatch(clearSelectedNetwork());

        dispatch(
          openDialog({
            title: t('common.words.success'),
            type: 'success',
            content: t('sidebar.editDisplayName.requestSuccess'),
          }),
        );
      })
      .catch(() => {
        dispatch(
          openDialog({
            title: t('common.words.error'),
            type: 'error',
            content: t('sidebar.editDisplayName.requestFailure'),
          }),
        );
      })
      .finally(() => {
        dispatch(closeEditDisplayNameDialog());
        setLoading(false);
        setNewDisplayName('');
      });
  };

  const closeModal = () => {
    setNewDisplayName('');
    dispatch(closeEditDisplayNameDialog());
  };

  const isDisplayNameInvalid = useCallback(
    () =>
      newDisplayName !== '' &&
      (networks?.some(
        (net) =>
          (net.name === newDisplayName || net.displayName === newDisplayName) &&
          net.name !== networkName,
      ) as boolean),
    [networks, networkName, newDisplayName],
  );

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="xs" onClose={closeModal}>
        <DialogTitle style={{ backgroundColor: 'var(--backgroundSuccess)' }}>
          <Trans>sidebar.editDisplayName.title</Trans>
        </DialogTitle>

        <DialogContent>
          <DialogContentText style={{ textAlign: 'justify' }}>
            <Trans>sidebar.editDisplayName.explanation</Trans>
            <br />
            <br />
            <Trans>sidebar.editDisplayName.question</Trans>
            <b> {`${networkName}, `}</b>
            <Trans>sidebar.editDisplayName.withDisplayName</Trans>
            <b> {displayName}</b>?
            <br />
            <br />
            <Trans>sidebar.editDisplayName.action</Trans>
          </DialogContentText>

          <TextField
            fullWidth
            autoFocus
            margin="dense"
            value={newDisplayName}
            error={isDisplayNameInvalid()}
            label={t('common.forms.networkName')}
            helperText={
              isDisplayNameInvalid()
                ? t('sidebar.editDisplayName.invalidNameMsg')
                : ''
            }
            onChange={(event) => setNewDisplayName(event.target.value)}
          />

          {loading ? (
            <div style={{ margin: '30px 0', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" onClick={closeModal}>
            <Trans>button.cancel</Trans>
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={deleteNetwork}
            disabled={newDisplayName === '' || isDisplayNameInvalid()}
          >
            <Trans>button.edit</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditNetDisplayNameModal;
