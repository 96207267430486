import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import history from '../../../../history';
import cancelWithDialog from '../../../../utils/cancelRequestWithModal';
import { networkApi } from '../../../../Common/axios';
import { addOperation, removeOperation } from '../../../../store/AppStatus';
import FileInput from '../../../../AppComponents/FileInput';
import LoadingScreen from '../../../LoadingScreen';

import {
  PageTitle,
  Container,
  Card,
  LoadContainer,
  CardContent,
} from './styles';

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const TemplateImport: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleImportTemplate = useCallback(() => {
    setSending(true);

    const formData = new FormData();
    formData.append('templateDef', file as Blob);

    networkApi
      .post('/importtemplatedef', formData, {
        cancelToken: new CancelToken((c) => {
          const withDialogCancel = (hasDialog = true) => {
            cancelWithDialog(c, 'importtemplatedef', hasDialog);
          };

          cancelRequest = withDialogCancel;
          dispatch(
            addOperation({
              title: t('title.templates.importTemplate'),
              cancel: withDialogCancel,
              name: 'importtemplatedef',
              pathname: window.location.pathname,
            }),
          );
        }),
      })
      .then(() => {
        dispatch(removeOperation('importtemplatedef', true));
        history.push('/chaincode/templates/manage');
      })
      .catch(() => dispatch(removeOperation('importtemplatedef', false)))
      .finally(() => {
        setSending(false);
      });
  }, [file, dispatch, t]);

  return (
    <div style={{ margin: '0 auto' }}>
      <PageTitle>
        <Trans>title.templates.importTemplate</Trans>
      </PageTitle>

      <Container>
        <Card>
          <CardContent>
            <FileInput
              cloudIcon
              file={file}
              accept=".gz,.xz,.tar"
              message={t('asset.importTemplate.fileRequired')}
              disabled={false}
              onChange={(files: FileList | null) => {
                if (files && files[0]) setFile(files[0]);
                else setFile(null);
              }}
            />

            {sending ? (
              <LoadContainer>
                <LoadingScreen
                  content={t('asset.importTemplate.loadingMessage')}
                />

                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => cancelRequest()}
                  style={{ display: 'block' }}
                >
                  <Trans>button.cancel</Trans>
                </Button>
              </LoadContainer>
            ) : (
              <Button
                fullWidth
                disabled={!file}
                style={{ marginTop: '30px' }}
                color="primary"
                variant="contained"
                onClick={handleImportTemplate}
              >
                <Trans>button.send</Trans>
              </Button>
            )}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default TemplateImport;
