import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import FillingCard from '../components/Card';
import { mountCardAttributes } from '../utils';
import { changeChaincodeAttrs } from '../../../store/ChaincodeNotifications';

import { StoreState } from '../../../store/types';
import { ILoadingContainerCardInfo } from '../types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const { chaincode, chaincodeAttrs } = state.chaincodeNotificationsState;

  const { title, canShow, running, attributes, notLoadingWidth } = cardInfo;

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeChaincodeAttrs(params));
  };

  const newCardInfo = {
    canShow,
    running,
    loadingInfo: chaincode,
    notificationAttrs: chaincodeAttrs,
    changeNotification,
    notLoadingWidth,
    attributes: mountCardAttributes(attributes || {}, t),
    loadingStarted: Object.keys(chaincode).length > 0,
  };

  return <FillingCard cardInfo={{ ...newCardInfo, title }} />;
};
