import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useDeployForm } from '../Hooks/deploy';

import FileInput from '../AppComponents/FileInput';
import { fileInputCustomBtnStyle } from '../Screens/Deploy/styles';

interface CCFileUploadProps {
  // channelValue: number;
  handleFileUpload: (file: FileList | null) => void;
  file: File | null;
}

export const CCFileUpload: React.FC<CCFileUploadProps> = ({
  // channelValue,
  handleFileUpload,
  file,
}) => {
  const deployStart = useDeployForm();
  const { channels, setChannels, started, chaincodeIndex } = deployStart;

  //   const [file, setFile] = useState<FileList | null>(null);

  // const handleChainCodeUpload = useCallback(
  //   (files: FileList | null) => {
  //     if (files && files.length > 0) {
  //       channels[channelValue].chaincodes[chaincodeIndex].chaincodeFile =
  //         files?.[0];
  //       channels[channelValue].chaincodes[chaincodeIndex].tarName =
  //         files[0].name;
  //     }

  //     setChannels([...channels]);
  //   },
  //   [chaincodeIndex, channelValue, channels, setChannels],
  // );

  const handleChange = useCallback(
    (files: FileList | null) => {
      console.log('files', files);
      //   setFile(files);
      handleFileUpload((files as unknown) as FileList);
    },
    [handleFileUpload],
  );

  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Typography variant="h6" gutterBottom>
          <Trans>common.chaincode.uploadFile</Trans>
        </Typography>

        <div style={{ width: 'fit-content' }} className="chaincode-upload-btn">
          <FileInput
            cloudIcon
            disabled={started}
            //   file={
            //     channels?.[index]?.chaincodes?.[chaincodeIndex]
            //       ?.chaincodeFile
            //   }
            file={file}
            message={t('common.messages.chaincodeRequired')}
            customBtnStyle={fileInputCustomBtnStyle}
            //   onChange={handleChainCodeUpload}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};
