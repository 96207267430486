// eslint-disable-next-line import/no-duplicates
import format from 'date-fns/format';
// eslint-disable-next-line import/no-duplicates
import { ptBR as ptBRLocale, enUS as enUSLocale } from 'date-fns/locale';

import store from '../../store';
import { StoreState } from '../../store/types';

export const localeMap: Record<string, any> = {
  en: enUSLocale,
  ptbr: ptBRLocale,
};

export const localeFormatMap: Record<string, string> = {
  en: 'MM/dd/yyyy',
  ptbr: 'dd/MM/yyyy',
};

export const localeFormatHourMap: Record<string, string> = {
  en: 'MM/dd/yyyy hh:mm a',
  ptbr: 'dd/MM/yyyy HH:mm',
};

export const formatDate = (date: Date | string, hasHour = false) => {
  const state: StoreState = store.getState();

  const { language } = state.appStatusState;

  return format(
    new Date(date),
    hasHour ? localeFormatHourMap[language] : localeFormatMap[language],
  );
};
