import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { CSSProperties } from 'styled-components';

import { IAsset } from '../../store/TemplateCC';

const PropDataType: React.FC<{
  dataType: string;
  assets?: IAsset[];
  customStyle?: object;
}> = ({ dataType, assets, customStyle }) => {
  const [isList, setIsList] = useState(false);
  const [isAsset, setIsAsset] = useState(false);
  const [formattedDataType, setFormattedDataType] = useState('');

  useEffect(() => {
    let newFormattedDataType = '';

    const newIsList = dataType.startsWith('[]');

    newFormattedDataType = newIsList ? dataType.replace('[]', '') : dataType;

    const newIsAsset = assets?.some(
      (asset) => asset.tag === newFormattedDataType.replace('->', ''),
    );

    setIsList(newIsList);

    setIsAsset(newIsAsset as boolean);

    setFormattedDataType(
      newIsAsset
        ? newFormattedDataType.replace('->', '')
        : newFormattedDataType,
    );
  }, [assets, dataType]);

  return (
    <Typography component="span" style={customStyle as CSSProperties}>
      {isList ? (
        <>
          <Trans>asset.chaincodes.templates.manageTemplates.listOf</Trans>{' '}
        </>
      ) : null}

      {isAsset ? (
        <b style={{ color: 'var(--primary)' }}>{formattedDataType}</b>
      ) : (
        formattedDataType
      )}
    </Typography>
  );
};

PropDataType.defaultProps = {
  assets: [],
  customStyle: {},
};

export default PropDataType;
