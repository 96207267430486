import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ListItem,
  ListItemText,
  Icon,
  Collapse,
  List,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import {
  Container,
  SPaper,
  Subtitle,
  TemplateAttribute,
  AssetsTitle,
  BooleanAttributes,
  BooleanAttrContainer,
  SingleAssetMainInfo,
  SingleAssetContainer,
} from './styles';
import { ITemplate, IAsset, IAssetProp } from '../../../../../store/TemplateCC';
import { StoreState } from '../../../../../store/types';
import PropDataType from '../../../../../utils/template/PropDataType';

const ViewAsset: React.FC = () => {
  const { selectedTemplate }: { selectedTemplate: ITemplate } = useSelector(
    (state: StoreState) => state.templateCCState,
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleScreenResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  });

  const getPropSecondaryText = useCallback(
    (item: IAssetProp) => (
      <>
        <span>
          <Trans>common.words.tag</Trans>: {item.tag}
        </span>
        {windowWidth < 1200 ? <br /> : ' | '}
        <PropDataType
          dataType={item.dataType}
          assets={selectedTemplate.assets}
          customStyle={{ fontSize: '14px' }}
        />
      </>
    ),
    [windowWidth, selectedTemplate.assets],
  );

  const renderAssets = useCallback(
    (assets: IAsset[] | undefined) => {
      if (!assets) return null;
      return assets.map((asset) => (
        <SingleAsset key={asset.tag} asset={asset} />
      ));
    },
    // The prop information display will not change if the windowHeight is removed
    // And the other dependency is used to the PropDataType component receive the assets properly
    // eslint-disable-next-line
    [windowWidth, selectedTemplate.assets],
  );

  const renderProps = useCallback(
    (props: IAssetProp[] | undefined) => {
      if (!props) return null;
      return props.map((item) => (
        <ListItem key={item.label} divider>
          <ListItemText
            primary={item.label}
            secondary={getPropSecondaryText(item)}
          />

          <BooleanAttrContainer>
            <BooleanAttributes>
              <Trans>common.words.required</Trans>
            </BooleanAttributes>

            <Icon>{item.required ? 'done' : 'close'}</Icon>
          </BooleanAttrContainer>

          <BooleanAttrContainer>
            <BooleanAttributes>
              <Trans>asset.chaincodes.templates.common.readOnly</Trans>
            </BooleanAttributes>

            <Icon>{item.readOnly ? 'done' : 'close'}</Icon>
          </BooleanAttrContainer>

          <BooleanAttrContainer>
            <BooleanAttributes>
              <Trans>asset.chaincodes.templates.common.isKey</Trans>
            </BooleanAttributes>

            <Icon>{item.isKey ? 'done' : 'close'}</Icon>
          </BooleanAttrContainer>
        </ListItem>
      ));
    },
    [getPropSecondaryText],
  );

  const SingleAsset: React.FC<{ asset: IAsset }> = ({ asset }) => {
    const [open, setOpen] = useState(false);
    return (
      <ListItem button divider key={asset.label} onClick={() => setOpen(!open)}>
        <SingleAssetContainer>
          <SingleAssetMainInfo>
            <ListItemText
              primary={asset.label}
              secondary={`Tag: ${asset.tag} | Label: ${asset.label}`}
            />

            {open ? <ExpandLess /> : <ExpandMore />}
          </SingleAssetMainInfo>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>{renderProps(asset.props)}</List>
          </Collapse>
        </SingleAssetContainer>
      </ListItem>
    );
  };

  if (!selectedTemplate.name) return <Container />;
  return (
    <Container>
      <Subtitle>
        <Trans>asset.chaincodes.templates.manageTemplates.templateInfo</Trans>
      </Subtitle>

      <SPaper>
        <TemplateAttribute>
          <Trans>common.words.name</Trans>: {selectedTemplate.name}
        </TemplateAttribute>

        <TemplateAttribute>
          <Trans>common.words.description</Trans> :{' '}
          {selectedTemplate.description}
        </TemplateAttribute>

        <AssetsTitle>
          <Trans>common.words.assets</Trans>
        </AssetsTitle>

        <List>{renderAssets(selectedTemplate.assets)}</List>
      </SPaper>
    </Container>
  );
};

export default ViewAsset;
