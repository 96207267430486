import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';

interface Props {
  hasError: boolean;
  heightProp?: number;
}

interface IArrowIconBtnProps {
  color: string;
  isdisabled: string;
}

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const WarnContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: 10px;
  z-index: 3;

  svg {
    color: var(--warning);
    height: 31px;
    width: 31px;
  }
`;

export const SingleBlockInfoContainer = styled.div<Props>`
  position: absolute;
  z-index: 2;
  width: 390px;
  right: 0px;
  bottom: 0px;
  opacity: ${(props) => (props.hasError ? '0.5' : '1')};
`;

export const SliderContainer = styled.div<Props>`
  z-index: 2;
  top: 8px;
  left: 40px;
  position: absolute;
  width: calc(100% - 100px) !important;
  opacity: ${(props) => (props.hasError ? '0.5' : '1')};
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;

  div {
    position: absolute;
    z-index: 2;
    right: 5px;
    top: 5px;
  }
`;

export const Main = styled.div<Props>`
  height: 100%;
  border: 1px solid var(--black);
  opacity: ${(props) => (props.hasError ? '0.5' : '1')};
`;

export const SliderControlContainer = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: center;
`;

export const SliderLabel = styled.div`
  top: 1px;
  left: -8px;
  position: absolute;
  width: fit-content;
`;

export const ArrowIconBtn = styled(IconButton).attrs(
  (props: IArrowIconBtnProps) => ({
    color: props.color,
  }),
)<IArrowIconBtnProps>`
  && {
    padding: 0;
    /* Disabled CSS */
    ${({ isdisabled }) =>
      isdisabled === 'true' &&
      css`
        color: var(--disabled);
        text-decoration: none;
        background-color: transparent;
      `};
  }
`;
