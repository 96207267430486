import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Icon, ListItemText, Typography } from '@material-ui/core';
import { CSSObject } from 'styled-components';
import {
  OptionsCard,
  OptionsList,
  SelectContainer,
  OptionsContainer,
  SelectedListItem,
  ComponentContainer,
  FixedOption,
} from './styles';
import Tooltip from '../Tooltip';

interface IIconOpts {
  icon: string;
  iconStyle?: object;
  checkedIcon?: string;
  checkedIconStyle?: object;
}

type Option = string | number | object;
type FixedOption = { label: string; action?(): void; icon?: string };

// The options can be string, number or objects, in case of string
// and number what will be rendered in the screen will be the value itself
// For objects it will use by default a "label" key, but any key can be
// passed as prop, the prop name is "keyToRender" of type string

interface ISelectProps {
  width?: string;
  options: Option[];
  className?: string;
  disabled?: boolean;
  defaultValue?: any;
  toWrapNum?: number;
  itemHeight?: number;
  twoColumns?: boolean;
  hasTooltip?: boolean;
  placeholder?: string;
  keyToRender?: string;
  iconOpts?: IIconOpts;
  customSelectStyle?: object;
  fixedOption?: FixedOption;
  onChange: (options: any) => void;
  isDisabled?: (value: any) => boolean;
  tooltipMessage?: (option: any) => string | JSX.Element;
}

const Select: React.FC<ISelectProps> = ({
  width,
  options,
  disabled,
  // should be wrapped in a useCallback on parent component
  onChange,
  iconOpts,
  className,
  // It's used to let a element disabled, it receive one parameter
  // that is the current value on the list and must return a boolean
  isDisabled,
  hasTooltip,
  keyToRender,
  placeholder,
  defaultValue,
  // the height of the item element in the options list
  itemHeight = 39,
  // if the options should be list in two columns (on overflow)
  twoColumns = false,
  // the minimum number of options in which it should list in two columns
  toWrapNum = 10,
  // custom select container style
  customSelectStyle,
  // only shown when a option is disabled
  tooltipMessage,
  fixedOption,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (open && ref.current && !ref.current.contains(event.target as Node))
      setOpen(false);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue, selectedOption]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const getLabel = () => {
    const type = typeof selectedOption;

    if (!selectedOption) return placeholder || '';

    if (type === 'string' || type === 'number') {
      return selectedOption;
    }

    return selectedOption[keyToRender || 'label'] || '';
  };

  const getListText = (option: any) => {
    const type = typeof option;

    if (type === 'string' || type === 'number') {
      return option;
    }

    return option[keyToRender || 'label'] || '';
  };

  const getListMaxHeight = useCallback(
    () =>
      twoColumns && options.length >= toWrapNum
        ? ((options.length + 1) * itemHeight) / 2
        : -1,
    [twoColumns, toWrapNum, itemHeight, options.length],
  );

  const handleSelection = (option: any) => {
    if (open && !disabled && isDisabled && !isDisabled(option)) {
      setSelectedOption(option);
      onChange(option);
      setOpen(false);
    }
  };

  return (
    <ComponentContainer
      ref={ref}
      disabled={disabled || false}
      className={className || ''}
    >
      <SelectContainer
        open={open}
        width={width || ''}
        styles={customSelectStyle as CSSObject}
        onClick={() => {
          if (!disabled) setOpen((prev) => !prev);
        }}
      >
        {iconOpts && iconOpts.icon !== '' ? (
          iconOpts.checkedIcon ? (
            selectedOption ? (
              <Icon
                style={{ ...iconOpts.checkedIconStyle, marginLeft: '10px' }}
              >
                {iconOpts.checkedIcon}
              </Icon>
            ) : (
              <Icon style={{ ...iconOpts.iconStyle, marginLeft: '10px' }}>
                {iconOpts.icon}
              </Icon>
            )
          ) : null
        ) : null}

        <Typography>{getLabel()}</Typography>

        <Icon style={{ margin: ' 5px 0 0 10px' }}>keyboard_arrow_down</Icon>
      </SelectContainer>

      {open ? (
        <OptionsContainer width={width || ''}>
          <OptionsCard>
            <OptionsList maxheight={getListMaxHeight()}>
              {options.map((option, idx: number) => (
                <Tooltip
                  key={idx}
                  message={tooltipMessage ? tooltipMessage(option) : ''}
                  canShow={
                    isDisabled ? isDisabled(option) && hasTooltip : false
                  }
                >
                  <SelectedListItem
                    itemheight={itemHeight}
                    maxheight={getListMaxHeight()}
                    selected={selectedOption === option}
                    isdisabled={isDisabled ? `${isDisabled(option)}` : 'false'}
                    onClick={() => handleSelection(option)}
                  >
                    <ListItemText
                      primary={getListText(option)}
                      style={{ wordBreak: 'break-word' }}
                    />
                  </SelectedListItem>
                </Tooltip>
              ))}
            </OptionsList>
            {fixedOption && (
              <FixedOption onClick={fixedOption?.action}>
                {fixedOption?.icon && (
                  <Icon fontSize="small" style={{ marginRight: '5px' }}>
                    {fixedOption.icon}
                  </Icon>
                )}
                <Typography variant="overline">{fixedOption.label}</Typography>
              </FixedOption>
            )}
          </OptionsCard>
        </OptionsContainer>
      ) : null}
    </ComponentContainer>
  );
};

Select.defaultProps = {
  width: '100%',
  toWrapNum: 10,
  itemHeight: 39,
  disabled: false,
  twoColumns: false,
  hasTooltip: false,
  keyToRender: 'label',
  customSelectStyle: {},
  iconOpts: {
    icon: '',
    iconStyle: {},
    checkedIcon: '',
    checkedIconStyle: {},
  },
  isDisabled: () => false,
};

export default Select;
