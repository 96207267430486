import styled from 'styled-components';

interface ILabelContainerProps {
  customMargin?: string;
}

export const LabelContainer = styled.div<ILabelContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ customMargin }) => customMargin || ''};
`;

export const FileName = styled.p`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  max-lines: 2;

  text-overflow: ellipsis;
`;
