import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  flex-direction: column;
`;

export const StepperContainer = styled.div`
  width: 60%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DefOrgsStepContainer = styled.div`
  width: 85%;
  max-width: 1085px;
  margin: 30px auto 10px auto;
  padding-left: ${(props: { hasCards: boolean }) =>
    props.hasCards ? '30px' : '0'};
`;
