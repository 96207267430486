import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';

export const PageTitle = styled(Typography).attrs({
  variant: 'overline',
})`
  && {
    display: flex;
    margin: 20px 0;
    font-size: 25px;
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  width: 100%;
`;

export const Card = styled(Paper).attrs({
  elevation: 2,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px;
  width: 45%;
  max-width: 500px;
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
