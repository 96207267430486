import { store } from '../store';
import { Operation } from '../store/AppStatus';

export default (operations: Operation[]) => {
  const { operationsModifyBlockchain } = store.getState().appStatusState;

  return (
    operations.length === 0 ||
    !operations.some((op) => operationsModifyBlockchain.includes(op.name))
  );
};
