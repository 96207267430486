import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import FillingCard from '../components/Card';
import { mountCardAttributes } from '../utils';
import {
  changeBackupGlobalAttrs,
  changeBackupPeersAttrs,
} from '../../../store/BackupNotifications';

import {
  ICardInfo,
  ILoadingContainerCardInfo,
  INotificationAttrs,
} from '../types';
import { StoreState, WithOrgNotificationObject } from '../../../store/types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const { backupGlobal, backupGlobalAttrs } = state.backupNotificationsState;
  const { backupPeers, backupPeersAttrs } = state.backupNotificationsState;
  const { title, running, attributes, notLoadingWidth } = cardInfo;
  const { network, channel } = attributes || {};

  const changeNotificationGlobal = (params: Record<string, any>) => {
    dispatch(changeBackupGlobalAttrs(params));
  };

  const changeNotificationPeer = (params: Record<string, any>) => {
    dispatch(changeBackupPeersAttrs(params));
  };

  const newCardInfo = {
    running,
    notLoadingWidth,
    changeNotification: changeNotificationGlobal,
    notificationAttrs: backupGlobalAttrs,
    loadingInfo: backupGlobal,
    canShow: network && channel,
    attributes: mountCardAttributes(attributes || {}, t),
    loadingStarted: backupGlobal ? Object.keys(backupGlobal).length > 0 : false,
  };

  return (
    <>
      <FillingCard cardInfo={{ ...newCardInfo, title } as ICardInfo} />

      {backupPeers.map((orgNotification: WithOrgNotificationObject) => (
        <FillingCard
          key={orgNotification.orgName}
          cardInfo={{
            running,
            changeNotification: changeNotificationPeer,
            notificationAttrs:
              backupPeersAttrs.find(
                (peer) => peer.orgName === orgNotification.orgName,
              ) || ({} as INotificationAttrs),
            attributes: {},
            title: orgNotification.orgName,
            loadingInfo: orgNotification,
            canShow: Object.keys(orgNotification).length > 0,
            loadingStarted: Object.keys(orgNotification).length > 0,
          }}
        />
      ))}
    </>
  );
};
