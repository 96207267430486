import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { List } from '@material-ui/core';
import { Share } from '@material-ui/icons';

import { useDeployForm } from '../../../Hooks/deploy';

import { NetworkStatus as NetworkStatusType } from '../../../store/Network';

import Tooltip from '../../Tooltip';
import NetworkActions from '../NetworkActions';

import {
  ListItem,
  NetworkName,
  NetSelectionBar,
  NetInfoContainer,
  NetStatusIndicator,
  ChannelInfoContainer,
} from '../styles';
import { useNetworks } from '../../../Contexts/Networks';

const NetworkList: React.FC = () => {
  const { t } = useTranslation();

  //   const { networkState, appStatusState } = useSelector(
  //     (state: StoreState) => state,
  //   ) as StoreState;

  //   const { networks, selectedNetwork } = networkState;
  //   const { operations, dashboardLoading } = appStatusState;

  const deployStart = useDeployForm();

  const netStatusIndicatorMap: Record<NetworkStatusType, JSX.Element> = {
    online: (
      <Tooltip message={t('sidebar.networkOnline')}>
        <NetStatusIndicator status="online" />
      </Tooltip>
    ),
    partial: (
      <Tooltip message={t('sidebar.networPartiallyOnline')}>
        <NetStatusIndicator status="partial" />
      </Tooltip>
    ),
    offline: (
      <Tooltip message={t('sidebar.networkOffline')}>
        <NetStatusIndicator status="offline" />
      </Tooltip>
    ),
  };

  //   const isNetDisabled = () =>
  //     dashboardLoading ||
  //     (!(operations.length === 1 && operations[0].name === 'setup') &&
  //       (deployStart.started || operations.length > 0));

  //   const canListChannels = useCallback(
  //     (network: INetwork) =>
  //       network && selectedNetwork.name && selectedNetwork.name === network.name,
  //     [selectedNetwork],
  //   );

  //   const openUnavailableNetModal = (error: any) => {
  //     dispatch(
  //       openDialog({
  //         title: t('common.words.error'),
  //         type: 'error',
  //         content: error.message.includes('Request failed with status code')
  //           ? t('common.messages.networkUnavailable')
  //           : error.message,
  //       }),
  //     );
  //   };

  //   const handleNetSelection = (networkName: string) => {
  //     try {
  //       if (/dashboard\/.+/.test(history.location.pathname)) {
  //         history.push('/dashboard');
  //       }
  //       if (networkName !== selectedNetwork.name) {
  //         getNetwork(networkName)
  //           .then(() => {
  //             dispatch(clearDeployNotifications());
  //             dispatch(selectNetwork({ type: 'net', name: networkName }));
  //             setActiveStep(0);
  //           })
  //           .catch((error) => {
  //             openUnavailableNetModal(error);
  //           });
  //       }
  //     } catch (error) {
  //       openUnavailableNetModal(error);
  //     }
  //   };

  const {
    networks,
    selectedNetwork,
    setSelectedNetwork,
    setSelectedChannel,
    selectedChannel,
    setCurrentDashboardView,
  } = useNetworks();

  if (!networks) return null;
  //  console.log('networks', networks);
  return (
    <>
      {networks &&
        networks?.length > 0 &&
        networks.map((network) => (
          <div key={network?.displayName}>
            <ListItem
              disabled={false}
              selected={selectedNetwork?.displayName === network?.displayName}
              onClick={() => {
                const firstChannel = Object?.keys(network?.channels)?.[0];
                setSelectedNetwork(network);
                setCurrentDashboardView('generalDashboard');
                // if (!network?.channels) return;
                setSelectedChannel(firstChannel);
                // if (!isNetDisabled()) handleNetSelection(network?.displayName);
              }}
            >
              <NetInfoContainer>
                {netStatusIndicatorMap[network?.status || 'offline']}

                <Tooltip message={network.displayName || network?.displayName}>
                  <NetworkName>
                    {network.displayName || network?.displayName}
                  </NetworkName>
                </Tooltip>

                <NetworkActions network={network} />
              </NetInfoContainer>

              {selectedNetwork?.displayName === network?.displayName && (
                <NetSelectionBar />
              )}
            </ListItem>
            {network?.channels ? (
              <div style={{ padding: '0 8px', margin: '0 auto' }}>
                <List style={{ width: '100%' }}>
                  {Object?.keys(network?.channels).map((channel, idx) => (
                    <ListItem
                      key={channel}
                      disabled={false}
                      selected={
                        channel === selectedChannel &&
                        selectedNetwork?.displayName === network?.displayName
                      }
                      style={{
                        height: '40px',
                        marginTop: idx > 0 ? '8px' : '',
                      }}
                      onClick={() => {
                        setSelectedNetwork(network);
                        setSelectedChannel(channel);
                        setCurrentDashboardView('channelDashboard');
                        // if (
                        //   history.location.pathname.includes('/dashboard') &&
                        //   channel === selectedNetwork.channelName
                        // )
                        //   history.push('/dashboard');
                        // else if (
                        //   history.location.pathname.includes('/dashboard')
                        // ) {
                        //   history.push(`/dashboard/${channel}`);
                        // }

                        // dispatch(
                        //   changeSelectedNetInfo({
                        //     channelName:
                        //       channel !== selectedNetwork.channelName
                        //         ? channel
                        //         : '',
                        //   }),
                        // );
                        console.log('das');
                      }}
                    >
                      <ChannelInfoContainer>
                        <Share
                          style={{ color: 'var(--white)', marginRight: '24px' }}
                        />

                        <Tooltip message={channel}>
                          <NetworkName>{channel}</NetworkName>
                        </Tooltip>
                      </ChannelInfoContainer>
                    </ListItem>
                  ))}
                </List>
              </div>
            ) : null}
          </div>
        ))}
    </>
  );
};

export default NetworkList;
