import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import { networkApi } from '../Common/axios';
import { openDialog } from '../store/Dialog';
import {
  fetchNetwork,
  clearSelectedNetwork,
  closeDeleteNetworkDialog,
} from '../store/Network';
import { StoreState } from '../store/types';
import { useNetworks } from '../Contexts/Networks';

const DeleteNetworkModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { deleteDialog } = useSelector(
    (state: StoreState) => state.networkState,
  );

  const { open, networkName } = deleteDialog;
  const { selectedNetwork, setSelectedNetwork, getAllNetworks } = useNetworks();
  const [loading, setLoading] = useState(false);
  const [typedNetName, setTypedNetName] = useState('');

  const closeModal = () => {
    setTypedNetName('');
    dispatch(closeDeleteNetworkDialog());
  };

  const deleteNetwork = () => {
    setLoading(true);

    networkApi
      .delete('/deleteNetwork', { params: { networkName } })
      .then(() => {
        getAllNetworks();

        if (selectedNetwork?.networkName === networkName)
          setSelectedNetwork(null);

        dispatch(
          openDialog({
            title: t('common.words.success'),
            type: 'success',
            content: t('sidebar.deleteNetwork.requestSuccess'),
          }),
        );
      })
      .catch(() => {
        dispatch(
          openDialog({
            title: t('common.words.error'),
            type: 'error',
            content: t('sidebar.deleteNetwork.requestFailure'),
          }),
        );
      })
      .finally(() => {
        dispatch(closeDeleteNetworkDialog());
        dispatch(fetchNetwork());
        setLoading(false);
        setTypedNetName('');
      });
  };

  console.log('typedName', typedNetName, networkName);
  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="xs" onClose={closeModal}>
        <DialogTitle style={{ backgroundColor: 'var(--errorBackground)' }}>
          <Trans>sidebar.deleteNetwork.title</Trans>
        </DialogTitle>

        <DialogContent>
          <DialogContentText style={{ textAlign: 'justify' }}>
            <Trans>sidebar.deleteNetwork.explanation</Trans>
            <br />
            <br />
            <Trans>sidebar.deleteNetwork.question</Trans>
            <b> {networkName}</b>?
            <br />
            <br />
            <Trans>sidebar.deleteNetwork.action</Trans>
          </DialogContentText>

          <TextField
            error={!(typedNetName === networkName)}
            fullWidth
            autoFocus
            margin="dense"
            label={t('common.forms.networkName')}
            value={typedNetName}
            onChange={(event) => setTypedNetName(event.target.value)}
          />

          {loading ? (
            <div style={{ margin: '30px 0', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" onClick={closeModal}>
            <Trans>button.cancel</Trans>
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={deleteNetwork}
            disabled={!(typedNetName === networkName)}
          >
            <Trans>button.delete</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteNetworkModal;
