import React, { useRef, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { Box, Button } from '@material-ui/core';
import { Check, CloudUpload } from '@material-ui/icons';
import { CSSProperties } from 'styled-components';

import { FileName, LabelContainer } from './styles';

interface IFileInputsProps extends Partial<HTMLInputElement> {
  file: File | null;
  accept?: string;
  message?: string;
  disabled?: boolean;
  className?: string;
  cloudIcon?: boolean;
  customMargin?: string;
  customBtnStyle?: object;
  containerStyle?: object;
  onChange: (files: FileList | null) => void;
}

export const FileInput: React.FC<IFileInputsProps> = ({
  file,
  accept,
  message,
  onChange,
  disabled,
  cloudIcon,
  customMargin,
  customBtnStyle,
  containerStyle,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);

  const cleanInputValue = useCallback(() => {
    if (!file && inputRef?.current.value) inputRef.current.value = '';
  }, [file]);
  return (
    <div style={containerStyle}>
      <input
        type="file"
        ref={inputRef}
        accept={accept}
        disabled={disabled}
        id={rest?.name || 'general-file-input'}
        style={{ display: 'none' }}
        onChange={(e) => onChange(e.target.files)}
        {...rest}
      />
      <label htmlFor={rest?.name || 'general-file-input'}>
        <LabelContainer customMargin={customMargin}>
          <Button
            component="span"
            variant="outlined"
            disabled={disabled}
            style={(customBtnStyle as CSSProperties) || { margin: '0 20px' }}
            startIcon={
              cloudIcon ? (
                <CloudUpload style={{ color: 'var(--primary)' }} />
              ) : null
            }
            onClick={() => cleanInputValue()}
          >
            <Trans>button.upload</Trans>
          </Button>

          {file === null ? (
            <div>{message}</div>
          ) : (
            <FileName>
              {file.name} <Check />
            </FileName>
          )}
        </LabelContainer>
      </label>
    </div>
  );
};

FileInput.defaultProps = {
  file: null,
  disabled: false,
  cloudIcon: false,
  customMargin: '',
  accept: '.gz,.xz,.tar',
  message: 'File required',
  containerStyle: {
    padding: '20px',
    width: 'fit-content',
    height: 'fit-content',
  },
};

export default FileInput;
