import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { DialogContentText } from '@material-ui/core';
import { StoreState } from '../../../store/types';

import { useDeployForm } from '../../../Hooks/deploy';
import { closeSuccessModal } from '../../../store/DeployStart';
import APIAuthSuccessModal from '../../../AppComponents/APIAuthSuccessModal';

const DeploySuccessModal = () => {
  const dispatch = useDispatch();

  const { networkName } = useDeployForm();
  const { selectedNetwork } = useSelector(
    (state: StoreState) => state.networkState,
  );
  const { successModalOpened } = useSelector(
    (state: StoreState) => state.deployStartState,
  );

  return (
    <APIAuthSuccessModal
      opened={successModalOpened}
      networkName={networkName || selectedNetwork.name}
      closeModal={() => dispatch(closeSuccessModal())}
    >
      <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        <Trans>common.words.congratulations</Trans>!
        <br />
        <br />
        <Trans>asset.network.deploy.successModal.deployedBlockchain</Trans>
        <br />
        <br />
      </DialogContentText>

      <DialogContentText
        style={{ marginTop: '10px', color: 'rgba(0, 0, 0, 0.87)' }}
      >
        <Trans>asset.network.deploy.successModal.enjoyBlockchain</Trans>
      </DialogContentText>
    </APIAuthSuccessModal>
  );
};

export default DeploySuccessModal;
