import { Box, Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';
import * as S from './styles';
import { authAPI } from '../../Common/axios';

export const PlanCardSkeleton = () => {
  return (
    <S.PlanCard>
      <S.Header>
        <Skeleton
          variant="text"
          width="55%"
          height={23}
          style={{
            margin: 0,
            backgroundColor: '#ddd',
          }}
        />
        <S.Price>
          <p>
            <Skeleton
              variant="text"
              width={90}
              height={32}
              style={{
                margin: 0,
                backgroundColor: '#ddd',
              }}
            />
          </p>
          {/* <span>/month</span> */}
          <Skeleton
            variant="text"
            width="55%"
            height={25}
            style={{
              margin: 0,
              backgroundColor: '#ddd',
              marginLeft: 'auto',
            }}
          />
        </S.Price>
      </S.Header>

      <S.Features>
        <Skeleton
          height={24}
          style={{
            margin: 0,
            backgroundColor: '#ddd',
          }}
        />
        <Skeleton
          height={24}
          style={{
            margin: 0,
            backgroundColor: '#ddd',
          }}
        />
        <Skeleton
          height={24}
          style={{
            margin: 0,
            backgroundColor: '#ddd',
          }}
        />
        <Skeleton
          height={24}
          style={{
            margin: 0,
            backgroundColor: '#ddd',
          }}
        />
        <Skeleton
          height={24}
          style={{
            margin: 0,
            backgroundColor: '#ddd',
          }}
        />
        <Skeleton
          height={24}
          style={{
            margin: 0,
            backgroundColor: '#ddd',
          }}
        />
      </S.Features>

      <S.ChooseButton style={{ display: 'block' }}>
        {/* <Skeleton
          variant="text"
          style={{
            minHeight: '42.25px',
            borderRadius: '9999px',
            margin: 0,
            backgroundColor: '#ddd',
          }}
        /> */}

        <Button
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          disabled
        >
          <CircularProgress color="secondary" size={26.25} />
        </Button>
      </S.ChooseButton>
    </S.PlanCard>
  );
};
