export const keepOnPage = (e: BeforeUnloadEvent) => {
  const message =
    "Navigating away from this page will delete your text if you haven't already saved it.";

  e.returnValue = message;

  return message;
};

export default keepOnPage;
