import React, { createContext, useContext, useMemo, useState } from 'react';

export const AddExternalOrganizationContextDefaultValues: AddExternalOrganizationContextType = {
  selectedChannel: '',
  setSelectedChannel: () => ({}),
  started: false,
  setStarted: () => ({}),
  tlsFile: null,
  setTlsFile: () => ({}),
  caFile: null,
  setCAFile: () => ({}),
  orgName: '',
  setOrgName: () => ({}),
  orgMSP: '',
  setOrgMSP: () => ({}),
};

export const AddExternalOrganizationContext = createContext<AddExternalOrganizationContextType>(
  AddExternalOrganizationContextDefaultValues,
);

export const AddExternalOrganizationProvider: React.FC = ({ children }) => {
  const [selectedChannel, setSelectedChannel] = useState<string>('');
  const [orgName, setOrgName] = useState<string>('');
  const [orgMSP, setOrgMSP] = useState<string>('');
  const [tlsFile, setTlsFile] = useState<File | null>(null);
  const [caFile, setCAFile] = useState<File | null>(null);

  //   const [networkName, setNetworkName] = useState('');
  //   const [peers, setPeers] = useState({});
  const [started, setStarted] = useState(false);

  const values = useMemo(
    () => ({
      selectedChannel,
      setSelectedChannel,
      started,
      setStarted,
      orgName,
      setOrgName,
      orgMSP,
      setOrgMSP,
      tlsFile,
      setTlsFile,
      caFile,
      setCAFile,
    }),
    [caFile, orgMSP, orgName, selectedChannel, started, tlsFile],
  );

  return (
    <AddExternalOrganizationContext.Provider value={values}>
      {children}
    </AddExternalOrganizationContext.Provider>
  );
};

export function useAddExternalOrganization() {
  const context = useContext(AddExternalOrganizationContext);

  if (!context) {
    throw new Error(
      'useAddExternalOrganization must be used within a AddExternalOrganizationProvider',
    );
  }

  return context;
}
