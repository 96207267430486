import styled from 'styled-components';

export const FillingCardContainer = styled.div`
  text-align: left;
  margin: 10px;
  padding: 15px 0;
  background-color: #212121;
  border-radius: 20px;
  width: ${(props: { loadingStarted: boolean; notLoadingWidth: string }) =>
    props.loadingStarted ? '490px' : props.notLoadingWidth || '490px'};
`;
