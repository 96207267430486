import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

interface IUpdatePeerNotificationsState extends Record<string, any> {
  notificationAttrs: INotificationAttrs;
  notification: CommonNotificationObject;
}

export const initialState: IUpdatePeerNotificationsState = {
  notification: {},
  notificationAttrs: {} as INotificationAttrs,
};

const updatePeerNotificationsSlice = createSlice({
  name: 'updatePeerNotifications',
  initialState,
  reducers: {
    changeUpdatePeerNotification(
      state,
      action: PayloadAction<CommonNotificationObject>,
    ) {
      state.notification = action.payload;

      if (Object.keys(state.notificationAttrs).length <= 0) {
        state.notificationAttrs = {
          mainLoad: 0,
          failed: false,
          fullyComplete: false,
        };
      }
    },
    changeUpdatePeerNotificationAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      state.notificationAttrs = {
        ...state.notificationAttrs,
        ...action.payload,
      };
    },
    clearUpdatePeerNotifications() {
      return initialState;
    },
  },
});

export const {
  changeUpdatePeerNotification,
  clearUpdatePeerNotifications,
  changeUpdatePeerNotificationAttrs,
} = updatePeerNotificationsSlice.actions;

export default updatePeerNotificationsSlice.reducer;
