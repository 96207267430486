import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: -8px;
  padding-left: 24px;
  align-items: center;
  color: var(--white);

  svg {
    font-size: 16px;
    margin-right: 8px;
  }
`;
