import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  List,
  ListItem,
  ListItemText,
  Link,
  Typography,
  IconButton,
  makeStyles,
  createStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Theme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Add, Check, Remove } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { StoreState } from '../../../../store/types';
import { ipPatt, fqdnPatt, orgNamePatt } from '../../../../utils/regexPatterns';

import {
  PeerOpt,
  PeerItem,
  PeerOptsContainer,
  AdderCardInside,
  AdderCardContainer,
} from './styles';
import GenericInput from '../GenericInput';
import { useValidatePort } from '../../../../Hooks/useValidatePort';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeButton: {
      color: 'var(--black)',
      backgroundColor: 'transparent',
      '&:hover': {
        color: 'var(--white)',
        backgroundColor: 'rgb(255, 0, 0, 0.5)',
      },
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      flexBasis: '30%',
      flexShrink: 0,
      textTransform: 'uppercase',
      alignSelf: 'center',
      letterSpacing: '0.08333em',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.secondary,
      alignSelf: 'center',
    },
  }),
);

type PeerState = {
  value: string;
  valid: boolean;
  opts: {
    peer: boolean;
    orderer: boolean;
    peerPort?: number;
    peerOperationPort?: number;
    peerCouchPort?: number;
    ordererPort?: number;
    ordererOperationPort?: number;
    ccRestHost: boolean;
    ccWebClient: boolean;
  };
  hsm: {
    peer: {
      user: string;
      pin: string;
    };
    orderer: {
      user: string;
      pin: string;
    };
  };
};

const initialPeerState: PeerState = {
  value: '',
  valid: true,
  opts: {
    peer: false,
    orderer: false,
    peerPort: undefined,
    peerOperationPort: undefined,
    ordererPort: undefined,
    ordererOperationPort: undefined,
    ccRestHost: false,
    ccWebClient: false,
  },
  hsm: {
    peer: {
      user: '',
      pin: '',
    },
    orderer: {
      user: '',
      pin: '',
    },
  },
};

interface IPeerAdderProps {
  peers: IPeer[];
  disableInputs: boolean;
  hasCCRest: boolean | undefined;
  handleChange: (value: any) => void;
  org: IOrg;
  handleCCApiChange: (value: any) => void;
}

const PeerAdder: React.FC<IPeerAdderProps> = (props) => {
  const { handleChange, disableInputs, peers, org, handleCCApiChange } = props;

  const [peer, setPeer] = useState(initialPeerState);
  const [peersState, setPeersState] = useState(peers);

  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const styles = useStyles();

  const [isDefaultPeerPort, setIsDefaultPeerPort] = useState(true);
  const [isDefaultPeerOperationPort, setIsDefaultPeerOperationPort] = useState(
    true,
  );
  const [isDefaultOrdererPort, setIsDefaultOrdererPort] = useState(true);
  const [
    isDefaultOrdererOperationPort,
    setIsDefaultOrdererOperationPort,
  ] = useState(true);
  const [isDefaultCouchPort, setIsDefaultCouchPort] = useState(true);

  const validatePort = useValidatePort();

  const hasCustomPorts = (instance: PeerState) =>
    Boolean(
      instance.opts.peerPort ||
        instance.opts.peerOperationPort ||
        instance.opts.ordererPort ||
        instance.opts.ordererOperationPort,
    );

  const netName = useSelector(
    (state: StoreState) => state.networkState.selectedNetwork.name,
  );

  const addPeer = () => {
    if (!(peer.opts.orderer || peer.opts.peer)) return;
    let newPeers = [];
    const onStorePeer = peers.find((p) => p.value === peer.value);

    if (onStorePeer) {
      Object.assign(onStorePeer, peer);

      newPeers = peers;
    } else {
      newPeers = [...peers, peer].map((item, idx) => ({
        ...item,
        id: idx,
      }));
    }

    handleChange(newPeers);
    setPeer(initialPeerState);
  };

  const validatePeerHSM = useMemo<boolean>(() => {
    if (!org?.hsm?.enabled) return true;
    let isValid = true;
    if (peer.opts.peer) {
      isValid = !!peer.hsm.peer.user && !!peer.hsm.peer.pin;
    }
    if (peer.opts.orderer) {
      isValid = isValid && !!peer.hsm.orderer.user && !!peer.hsm.orderer.pin;
    }

    return isValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.hsm?.enabled, peer]);

  const handleClickOutside = (event: MouseEvent) => {
    if (!ref || !ref.current) return;
    if (!(peer.opts.orderer || peer.opts.peer)) return;

    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (org.hsm?.enabled && validatePeerHSM) {
        addPeer();
        return;
      }
      if (peer.value && ipPatt.test(peer.value)) addPeer();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  useEffect(() => {
    setPeersState(peers);
  }, [peers]);

  const removePeer = (peerId: number) => {
    const newPeers = peers
      .filter((obj) => obj.id !== peerId)
      .map((p, idx) => ({ ...p, id: idx }));

    // Check if peer is a ccapi host and remove it
    const thisPeer = peers.find((p) => p.id === peerId);
    org.ccapi =
      org?.ccapi?.filter?.((api) => api.host !== thisPeer?.value) || [];

    handleCCApiChange(org.ccapi);
    handleChange(newPeers);
  };

  const getCCRestUrl = (ip: string) => {
    return `http://${ip}:8080`;
  };

  return (
    <div style={{ width: '100%' }}>
      <Paper elevation={0}>
        <AdderCardInside ref={ref}>
          <AdderCardContainer>
            <div style={{ marginBottom: '10px' }}>
              <Typography style={{ marginBottom: '10px' }}>
                <Trans>asset.organizations.addOrg.machineConfig</Trans>
              </Typography>
            </div>

            <TextField
              variant="outlined"
              className="org-instance-address-input"
              label={t('common.forms.instanceAddress')}
              placeholder={t('common.forms.instanceAddress')}
              error={
                !!peer.value &&
                !(ipPatt.test(peer.value) || fqdnPatt.test(peer.value))
              }
              value={peer.value}
              onKeyPress={(e) =>
                e.key === 'Enter' &&
                (fqdnPatt.test(peer.value) || ipPatt.test(peer.value)) &&
                validatePeerHSM
                  ? addPeer()
                  : null
              }
              onChange={(e) => setPeer({ ...peer, value: e.target.value })}
              InputProps={{
                endAdornment: (
                  <IconButton
                    color="primary"
                    onClick={addPeer}
                    className="org-instance-address-add-btn"
                    disabled={
                      !(ipPatt.test(peer.value) || fqdnPatt.test(peer.value)) ||
                      disableInputs ||
                      !(peer.opts.orderer || peer.opts.peer) ||
                      !validatePeerHSM
                    }
                  >
                    <Add />
                  </IconButton>
                ),
              }}
              disabled={disableInputs}
            />

            <FormGroup row>
              <FormControlLabel
                className="org-instance-peer-opt"
                control={
                  <Checkbox
                    disabled={disableInputs}
                    checked={peer.opts.peer}
                    onChange={(e) =>
                      setPeer({
                        ...peer,
                        opts: { ...peer.opts, peer: e.target.checked },
                      })
                    }
                    value="peer"
                  />
                }
                label={t('common.words.peer')}
              />

              <FormControlLabel
                className="org-instance-orderer-opt"
                control={
                  <Checkbox
                    disabled={disableInputs}
                    checked={peer.opts.orderer}
                    onChange={(e) =>
                      setPeer({
                        ...peer,
                        opts: { ...peer.opts, orderer: e.target.checked },
                      })
                    }
                    value="orderer"
                  />
                }
                label={t('common.words.orderer')}
              />
            </FormGroup>
            {peer.opts.peer && org?.hsm?.enabled && (
              <Box
                display="flex"
                alignItems="center"
                marginBottom="1rem"
                style={{ gap: '2rem' }}
              >
                <GenericInput
                  value={peer?.hsm?.peer?.user}
                  variant="standard"
                  disabled={disableInputs}
                  label="Peer HSM User"
                  onChange={(e) => {
                    setPeer({
                      ...peer,
                      hsm: {
                        ...peer?.hsm,
                        peer: {
                          ...peer.hsm.peer,
                          user: e.target.value,
                        },
                      },
                    });
                  }}
                  required
                  error={!orgNamePatt.test(peer.hsm.peer.user)}
                />

                <GenericInput
                  variant="standard"
                  value={peer?.hsm?.peer?.pin}
                  disabled={disableInputs}
                  onChange={(e) => {
                    setPeer({
                      ...peer,
                      hsm: {
                        ...peer.hsm,
                        peer: {
                          ...peer.hsm.peer,
                          pin: e.target.value,
                        },
                      },
                    });
                  }}
                  label="Peer HSM PIN"
                  error={!orgNamePatt.test(peer?.hsm?.peer?.pin)}
                  required
                />
              </Box>
            )}

            {peer.opts.orderer && org?.hsm?.enabled && (
              <Box
                display="flex"
                alignItems="center"
                marginBottom="1rem"
                style={{ gap: '2rem' }}
              >
                <GenericInput
                  value={peer?.hsm?.orderer?.user}
                  variant="standard"
                  disabled={disableInputs}
                  label="Orderer HSM User"
                  onChange={(e) => {
                    setPeer({
                      ...peer,
                      hsm: {
                        ...peer.hsm,
                        orderer: {
                          ...peer.hsm.orderer,
                          user: e.target.value,
                        },
                      },
                    });
                  }}
                  required
                  error={!orgNamePatt.test(peer.hsm.orderer.user)}
                />

                <GenericInput
                  variant="standard"
                  value={peer?.hsm?.orderer?.pin}
                  disabled={disableInputs}
                  onChange={(e) => {
                    setPeer({
                      ...peer,
                      hsm: {
                        ...peer.hsm,
                        orderer: {
                          ...peer.hsm.orderer,
                          pin: e.target.value,
                        },
                      },
                    });
                  }}
                  label="Orderer HSM PIN"
                  error={!orgNamePatt.test(peer?.hsm?.orderer?.pin)}
                  required
                />
              </Box>
            )}

            <Accordion
              style={{
                boxShadow: 'none',
                background: 'none',
                border: '1px solid #ddd',
              }}
              disabled={!peer?.opts?.peer && !peer?.opts?.orderer}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={styles.heading}>Advanced</Typography>
                <Typography className={styles.secondaryHeading}>
                  Configure Instance ports
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ flexDirection: 'column', gap: '1.5rem' }}
              >
                {peer?.opts?.peer && (
                  <Box display="flex" style={{ gap: '1.5rem' }}>
                    <Box display="flex" flexDirection="column">
                      <GenericInput
                        type="number"
                        value={peer?.opts?.peerPort}
                        style={{ maxWidth: '256px' }}
                        label="Peer Port"
                        onChange={(e) =>
                          setPeer({
                            ...peer,
                            opts: {
                              ...peer.opts,
                              peerPort: Number(e.target.value),
                            },
                          })
                        }
                        disabled={isDefaultPeerPort}
                        helperText={(() => {
                          const isValid = validatePort(
                            peer?.opts?.peerPort as number,
                            peer?.value,
                            'peerPort',
                            peer?.value,
                          );
                          return isValid?.portInUse
                            ? `Port already in use by ${isValid.info?.org}${
                                isValid.info?.peer
                                  ? `@${isValid.info?.peer}`
                                  : ''
                              }'s ${isValid.info?.portName}`
                            : '';
                        })()}
                        error={
                          validatePort(
                            peer?.opts?.peerPort as number,
                            peer?.value,
                            'peerPort',
                            peer?.value,
                          )?.portInUse
                        }
                      />
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          onChange={(e) => {
                            setPeer({
                              ...peer,
                              opts: {
                                ...peer.opts,
                                peerPort: ('' as unknown) as number,
                              },
                            });
                            setIsDefaultPeerPort(e.target.checked);
                          }}
                          checked={isDefaultPeerPort}
                        />
                        <Typography>Default Port</Typography>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <GenericInput
                        type="number"
                        value={peer?.opts?.peerOperationPort}
                        style={{ maxWidth: '256px' }}
                        label="Peer Operation Port"
                        onChange={(e) =>
                          setPeer({
                            ...peer,
                            opts: {
                              ...peer.opts,
                              peerOperationPort: Number(e.target.value),
                            },
                          })
                        }
                        disabled={isDefaultPeerOperationPort}
                        helperText={(() => {
                          const isValid = validatePort(
                            peer?.opts?.peerOperationPort as number,
                            peer?.value,
                            'peerOperationPort',
                            peer?.value,
                          );
                          return isValid?.portInUse
                            ? `Port already in use by ${isValid.info?.org}${
                                isValid.info?.peer
                                  ? `@${isValid.info?.peer}`
                                  : ''
                              }'s ${isValid.info?.portName}`
                            : '';
                        })()}
                        error={
                          validatePort(
                            peer?.opts?.peerOperationPort as number,
                            peer?.value,
                            'peerOperationPort',
                            peer?.value,
                          )?.portInUse
                        }
                      />
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          onChange={(e) => {
                            setPeer({
                              ...peer,
                              opts: {
                                ...peer.opts,
                                peerOperationPort: ('' as unknown) as number,
                              },
                            });
                            setIsDefaultPeerOperationPort(e.target.checked);
                          }}
                          checked={isDefaultPeerOperationPort}
                        />
                        <Typography>Default Operation Port</Typography>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <GenericInput
                        type="number"
                        value={
                          isDefaultCouchPort ? '' : peer.opts.peerCouchPort
                        }
                        style={{ maxWidth: '256px' }}
                        label="Couch Port"
                        onChange={(e) =>
                          setPeer({
                            ...peer,
                            opts: {
                              ...peer.opts,
                              peerCouchPort: Number(e.target.value),
                            },
                          })
                        }
                        disabled={isDefaultCouchPort}
                        helperText={(() => {
                          const isValid = validatePort(
                            peer?.opts?.peerCouchPort as number,
                            peer?.value,
                            'peerCouchPort',
                            peer?.value,
                          );
                          return isValid?.portInUse
                            ? `Port already in use by ${isValid.info?.org}${
                                isValid.info?.peer
                                  ? `@${isValid.info?.peer}`
                                  : ''
                              }'s ${isValid.info?.portName}`
                            : '';
                        })()}
                        error={
                          validatePort(
                            peer?.opts?.peerCouchPort as number,
                            peer?.value,
                            'peerCouchPort',
                            peer?.value,
                          )?.portInUse
                        }
                      />
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          onChange={(e) => {
                            setPeer({
                              ...peer,
                              opts: {
                                ...peer.opts,
                                peerCouchPort: ('' as unknown) as number,
                              },
                            });
                            setIsDefaultCouchPort(e.target.checked);
                          }}
                          checked={isDefaultCouchPort}
                        />
                        <Typography>Default Couch port</Typography>
                      </Box>
                    </Box>
                  </Box>
                )}

                {peer?.opts.orderer && (
                  <Box display="flex" style={{ gap: '1.5rem' }}>
                    <Box display="flex" flexDirection="column">
                      <GenericInput
                        type="number"
                        value={peer.opts?.ordererPort}
                        style={{ maxWidth: '256px' }}
                        label="Orderer Port"
                        onChange={(e) =>
                          setPeer({
                            ...peer,
                            opts: {
                              ...peer.opts,
                              ordererPort: Number(e.target.value),
                            },
                          })
                        }
                        disabled={isDefaultOrdererPort}
                        helperText={(() => {
                          const isValid = validatePort(
                            peer?.opts?.ordererPort as number,
                            peer?.value,
                            'ordererPort',
                            peer?.value,
                          );
                          return isValid?.portInUse
                            ? `Port already in use by ${isValid.info?.org}${
                                isValid.info?.peer
                                  ? `@${isValid.info?.peer}`
                                  : ''
                              }'s ${isValid.info?.portName}`
                            : '';
                        })()}
                        error={
                          validatePort(
                            peer?.opts?.ordererPort as number,
                            peer?.value,
                            'ordererPort',
                            peer?.value,
                          )?.portInUse
                        }
                      />
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          onChange={(e) => {
                            setPeer({
                              ...peer,
                              opts: {
                                ...peer.opts,
                                ordererPort: ('' as unknown) as number,
                              },
                            });
                            setIsDefaultOrdererPort(e.target.checked);
                          }}
                          checked={isDefaultOrdererPort}
                        />
                        <Typography>Default Port</Typography>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <GenericInput
                        type="number"
                        value={peer.opts?.ordererOperationPort}
                        style={{ maxWidth: '256px' }}
                        label="Orderer Operation Port"
                        onChange={(e) =>
                          setPeer({
                            ...peer,
                            opts: {
                              ...peer.opts,
                              ordererOperationPort: Number(e.target.value),
                            },
                          })
                        }
                        disabled={isDefaultOrdererOperationPort}
                        helperText={(() => {
                          const isValid = validatePort(
                            peer?.opts?.ordererOperationPort as number,
                            peer?.value,
                            'ordererOperationPort',
                            peer?.value,
                          );
                          return isValid?.portInUse
                            ? `Port already in use by ${isValid.info?.org}${
                                isValid.info?.peer
                                  ? `@${isValid.info?.peer}`
                                  : ''
                              }'s ${isValid.info?.portName}`
                            : '';
                        })()}
                        error={
                          validatePort(
                            peer?.opts?.ordererOperationPort as number,
                            peer?.value,
                            'ordererOperationPort',
                            peer?.value,
                          )?.portInUse
                        }
                      />
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          onChange={(e) => {
                            setPeer({
                              ...peer,
                              opts: {
                                ...peer.opts,
                                ordererOperationPort: ('' as unknown) as number,
                              },
                            });
                            setIsDefaultOrdererOperationPort(e.target.checked);
                          }}
                          checked={isDefaultOrdererOperationPort}
                        />
                        <Typography>Default Operation Port</Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </AdderCardContainer>
        </AdderCardInside>
      </Paper>

      {peersState.length ? (
        <List>
          {peersState.map((p: IPeer) => (
            <ListItem key={p.id} divider>
              <PeerItem>
                {netName && p.opts.ccRestHost && p.opts.ccWebClient ? (
                  <Typography>
                    <Trans>common.forms.instanceAddress</Trans>:{' '}
                    <Link target="_blank" href={getCCRestUrl(p.value)}>
                      {p.value}
                    </Link>
                  </Typography>
                ) : (
                  <ListItemText
                    primary={`${t('common.forms.instanceAddress')}: ${p.value}`}
                    secondary={hasCustomPorts(p) ? 'Custom Ports' : ''}
                    style={{ width: '100%', margin: '5px 0' }}
                  />
                )}

                <PeerOptsContainer>
                  {p.opts.ccRestHost ? (
                    <PeerOpt>
                      <Check />
                      <Typography>CC Rest</Typography>
                    </PeerOpt>
                  ) : null}

                  {p.opts.ccWebClient ? (
                    <PeerOpt>
                      <Check />

                      <Typography>
                        <Trans>common.forms.webInterface</Trans>
                      </Typography>
                    </PeerOpt>
                  ) : null}

                  {p.opts.peer ? (
                    <PeerOpt>
                      <Check style={{ marginRight: '2px' }} />

                      <Box>
                        <Typography>
                          <Trans>common.words.peer</Trans>
                        </Typography>
                        {(p.opts?.peerPort || p.opts?.peerOperationPort) && (
                          <Box
                            display="flex"
                            alignItems="start"
                            style={{ gap: '1rem ' }}
                          >
                            <Typography variant="overline">
                              port: {p.opts?.peerPort}
                            </Typography>
                            <Typography variant="overline">
                              operation port: {p.opts?.peerOperationPort}
                            </Typography>
                          </Box>
                        )}
                        {p.opts?.peerCouchPort && (
                          <Typography variant="overline">
                            couch port: {p.opts?.peerCouchPort}
                          </Typography>
                        )}
                      </Box>
                    </PeerOpt>
                  ) : null}

                  {p?.hsm?.peer?.user && (
                    <PeerOpt>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: '1rem ' }}
                      >
                        <Typography variant="overline">
                          HSM User: {p.hsm?.peer?.user}
                        </Typography>
                        <Typography variant="overline">
                          HSM PIN: {p.hsm?.peer?.pin}
                        </Typography>
                      </Box>
                    </PeerOpt>
                  )}

                  {p.opts.orderer ? (
                    <PeerOpt>
                      <Check style={{ marginRight: '2px' }} />

                      <Box>
                        <Typography>
                          <Trans>common.words.orderer</Trans>
                        </Typography>
                        {(p.opts?.ordererPort ||
                          p.opts?.ordererOperationPort) && (
                          <Box
                            display="flex"
                            alignItems="start"
                            style={{ gap: '1rem ' }}
                          >
                            <Typography variant="overline">
                              port: {p.opts.ordererPort}
                            </Typography>
                            <Typography variant="overline">
                              operation port: {p.opts.ordererOperationPort}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </PeerOpt>
                  ) : null}
                  {p?.hsm?.orderer?.user && (
                    <PeerOpt>
                      <Box
                        display="flex"
                        alignItems="start"
                        style={{ gap: '1rem ' }}
                      >
                        <Typography variant="overline">
                          HSM User: {p.hsm?.orderer?.user}
                        </Typography>
                        <Typography variant="overline">
                          HSM PIN: {p.hsm?.orderer?.pin}
                        </Typography>
                      </Box>
                    </PeerOpt>
                  )}
                </PeerOptsContainer>
              </PeerItem>

              {disableInputs ? null : (
                <IconButton
                  onClick={() => removePeer(p.id)}
                  size="small"
                  className={styles.removeButton}
                >
                  <Remove />
                </IconButton>
              )}
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          variant="overline"
          style={{ fontSize: '16px', margin: '10px' }}
        >
          <Trans>asset.organizations.addOrg.noMachinesMessage</Trans>
        </Typography>
      )}
    </div>
  );
};

PeerAdder.defaultProps = {
  hasCCRest: false,
};

export default PeerAdder;
