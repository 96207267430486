import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changeAddOrgNotificationAttrs } from '../../../store/AddOrgNotifications';

import OrgCard from '../components/OrgCard';

import { StoreState } from '../../../store/types';
import { IDeployOrgCardInfo, ILoadingContainerCardInfo } from '../types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const addOrgNotifications = state.addOrgNotificationsState;

  const { notification, notificationAttrs } = addOrgNotifications;

  const { running, attributes } = cardInfo;
  const { org } = attributes || {};

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeAddOrgNotificationAttrs(params));
  };

  const newCardInfo: IDeployOrgCardInfo = {
    ...org,
    running,
    notificationAttrs,
    changeNotification,
    loadingInfo: notification,
  };

  return <OrgCard cardInfo={newCardInfo} />;
};
