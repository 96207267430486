import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearRestoreNotification } from '../store/BackupRestoreNotifications';

interface BackupRestoreContextData {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  users: string[];
  setUsers: Dispatch<SetStateAction<string[]>>;
  passwords: string[];
  setPasswords: Dispatch<SetStateAction<string[]>>;
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  orgs: string[];
  setOrgs: Dispatch<SetStateAction<string[]>>;
  networkName: string;
  setNetworkName: Dispatch<SetStateAction<string>>;
  loadingOrgs: boolean;
  setLoadingOrgs: Dispatch<SetStateAction<boolean>>;
  progressUpload: number;
  setProgressUpload: Dispatch<SetStateAction<number>>;
  clearFormData(): void;
}

const BackupRestoreContext = createContext<BackupRestoreContextData>(
  {} as BackupRestoreContextData,
);

const BackupRestoreProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const [orgs, setOrgs] = useState(['']);
  const [users, setUsers] = useState(['']);
  const [networkName, setNetworkName] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [passwords, setPasswords] = useState(['']);
  const [file, setFile] = useState<File | null>(null);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [progressUpload, setProgressUpload] = useState(0);

  const clearFormData = useCallback(() => {
    setLoading(false);
    setActiveStep(0);
    setUsers(['']);
    setPasswords(['']);
    setFile(null);
    setOrgs(['']);
    setNetworkName('');
    setLoadingOrgs(false);
    setProgressUpload(0);

    dispatch(clearRestoreNotification());
  }, [dispatch]);

  return (
    <BackupRestoreContext.Provider
      value={{
        loading,
        setLoading,
        activeStep,
        setActiveStep,
        users,
        setUsers,
        passwords,
        setPasswords,
        file,
        setFile,
        orgs,
        setOrgs,
        networkName,
        setNetworkName,
        loadingOrgs,
        setLoadingOrgs,
        clearFormData,
        progressUpload,
        setProgressUpload,
      }}
    >
      {children}
    </BackupRestoreContext.Provider>
  );
};

function useBackupRestoreForm(): BackupRestoreContextData {
  const context = useContext(BackupRestoreContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { BackupRestoreProvider, useBackupRestoreForm };
