import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Button, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';

import {
  closeDialog,
  IDialogInfo,
  refuseAction,
  confirmAction,
  unmountCallback,
} from '../../store/Dialog';

import { StoreState } from '../../store/types';
import {
  CloseBtn,
  OptionBtn,
  ModalTitle,
  ModalContent,
  ModalActions,
} from './styles';

interface ITitleProps {
  children?: string | JSX.Element | JSX.Element[];
  onClose: Function;
  style: object;
  classes?: any;
}

const GlobalModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dialog = useSelector((state: StoreState) => state.dialogState);
  const [dialogInfo, setDialogInfo] = useState<IDialogInfo>({} as IDialogInfo);
  const {
    type,
    title,
    content,
    useRefuseOnClose,
    typographyVariant,
  } = dialogInfo;

  useEffect(() => {
    return () => {
      if (unmountCallback) unmountCallback();
    };
  }, []);

  useEffect(() => {
    const { predefinedDialog } = dialog.info;

    if (!predefinedDialog) {
      setDialogInfo({ ...dialog.info });
    } else {
      setDialogInfo({
        type: t(`common.predefinedDialogs.${predefinedDialog}.type`),
        title: t(`common.predefinedDialogs.${predefinedDialog}.title`),
        content: t(`common.predefinedDialogs.${predefinedDialog}.content`),
      });
    }
  }, [t, dialog.info]);

  const onClose = () => {
    dispatch(closeDialog());
    if (useRefuseOnClose) {
      if (refuseAction) refuseAction();
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={dialog.open} onClose={onClose}>
      <ModalTitle type={type}>
        <Typography variant="h6">{title}</Typography>

        <CloseBtn onClick={() => onClose()}>
          <Close />
        </CloseBtn>
      </ModalTitle>

      <ModalContent>
        <Typography
          variant={typographyVariant || 'overline'}
          gutterBottom
          style={{ whiteSpace: 'pre-line' }}
        >
          {content}
        </Typography>
      </ModalContent>

      <ModalActions>
        {type === 'question' ? (
          <div>
            <OptionBtn
              background="#27ae60"
              onClick={() => {
                dispatch(closeDialog());
                if (confirmAction) confirmAction();
              }}
            >
              <Trans>button.yes</Trans>
            </OptionBtn>

            <OptionBtn
              background="#c0392b"
              onClick={() => {
                dispatch(closeDialog());
                if (refuseAction) refuseAction();
              }}
            >
              <Trans>button.no</Trans>
            </OptionBtn>
          </div>
        ) : (
          <Button
            variant="contained"
            onClick={onClose}
            style={{ color: 'var(--white)', backgroundColor: 'var(--black)' }}
          >
            <Trans>button.ok</Trans>
          </Button>
        )}
      </ModalActions>
    </Dialog>
  );
};

export default GlobalModal;
