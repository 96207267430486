import { ITemplate, IAsset, IAssetProp } from '../../store/TemplateCC';

interface INetStateChaincode {
  chaincodeName: string;
  templateDef: ITemplate;
}

export const parseNetStateTemplate = (chaincode: INetStateChaincode) => ({
  ...chaincode.templateDef,
  ...(!chaincode.templateDef.name && {
    name: chaincode.chaincodeName,
  }),
  assets: chaincode.templateDef.assets.map((asset: IAsset) => {
    const newProps = asset.props?.map((prop: IAssetProp) => ({
      ...prop,
      selectOpened: false,
      ...(!prop.writers && { writersAllEnabled: true }),
    }));
    return {
      ...asset,
      props: newProps,
      selectOpened: false,
      ...(!asset.readers && { privateData: false }),
      // I must return a boolean and the "as boolean" is not working...
      privateData: Boolean(asset.readers),
    };
  }),
});

export const buildRequestTemplateDef = (template: ITemplate | null) => ({
  templateDef: {
    ...template,
    assets: template?.assets.map((asset: IAsset) => {
      const tmpAsset = { ...asset };

      return {
        ...tmpAsset,
        readers: !asset.privateData ? null : asset.readers,
        props: tmpAsset.props?.map((prop) => {
          const tmpProp = { ...prop };

          return {
            ...tmpProp,
            writers: prop.writersAllEnabled ? null : prop.writers,
          };
        }),
      };
    }),
  },
});

export const updateTemplateWithDatabase = (
  templateList: ITemplate[],
  netDefCC: ITemplate,
) => {
  let updatedCC: ITemplate;

  const databaseTemplate = templateList.find(
    (template: ITemplate) => template.name === netDefCC.name,
  );

  if (databaseTemplate) {
    updatedCC = { ...databaseTemplate, ...netDefCC };

    const newAssets = databaseTemplate?.assets.map((asset) => {
      const netDefCCAsset: IAsset | undefined = netDefCC.assets.find(
        (ass) => ass.label === asset.label && ass.tag === asset.tag,
      );

      const newProps = asset.props?.map((prop) => {
        const netDefCCProp: IAssetProp | undefined = !netDefCCAsset
          ? undefined
          : netDefCCAsset.props?.find(
              (prp) => prp.label === prop.label && prp.tag === prop.tag,
            );

        return {
          ...(netDefCCProp && { ...netDefCCProp }),
          ...prop,
          ...(prop.writersAllEnabled && {
            writersAllEnabled: prop.writersAllEnabled,
          }),
        };
      });

      return {
        ...(netDefCCAsset && { ...netDefCCAsset }),
        ...asset,
        ...(asset.privateData && { privateData: asset.privateData }),
        props: newProps,
      };
    });

    return { ...updatedCC, assets: newAssets };
  }

  return netDefCC;
};
