import styled from 'styled-components';
import {
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

interface IOptionBtnProps {
  background: string;
}

interface IModalTitleProps {
  type: string;
}

export const ModalTitle = styled(DialogTitle).attrs({
  disableTypography: true,
})<IModalTitleProps>`
  && {
    margin: 0;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: ${({ type }) =>
      type === 'error' ? 'var(--errorBackground)' : 'var(--backgroundSuccess)'};
  }
`;

export const CloseBtn = styled(DialogTitle).attrs({
  'aria-label': 'Close',
})`
  && {
    top: 8px;
    right: 8px;
    cursor: pointer;
    position: absolute;
    color: var(--black);

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ModalContent = styled(DialogContent)`
  && {
    margin: 0;
    padding: 16px;
  }
`;

export const ModalActions = styled(DialogActions)`
  && {
    margin: 0;
    padding: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const OptionBtn = styled(Button).attrs({
  variant: 'contained',
})<IOptionBtnProps>`
  && {
    margin: 0px 5px;
    color: var(--white);
    background-color: ${({ background }) => background};
  }
`;
