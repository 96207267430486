import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  width: 30%;
  padding: 20px;
  min-width: 300px;
  margin-left: 20px;
  max-width: 450px !important;

  @media (min-width: 2000px) {
    margin-right: 100px;
  }
`;

export const ManagementHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Subtitle = ({ ...props }) => (
  <Typography variant="overline" style={{ fontSize: '18px' }}>
    {props.children}
  </Typography>
);
