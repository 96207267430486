import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { withStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Grid, Typography } from '@material-ui/core';

import { changeMountedComponents } from '../../store/InitialTour';
import { StoreState } from '../../store/types';

import MenuPage from '../MenuPage';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  fabGreen: {
    marginLeft: '10%',
    color: theme.palette.common.white,
    backgroundColor: green[500],
    boxShadow: 'none',
  },
});

const ChaincodeMain: React.FC<{ classes: any }> = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const options = [
    {
      icon: 'arrow_upward',
      text: t('title.chaincodes.upgradeChaincode'),
      link: '/chaincode/upgrade',
    },
    {
      icon: 'folder',
      target: 'chaincode-templates',
      text: t('title.chaincodes.chaincodeTemplates'),
      link: '/chaincode/templates',
    },
    // {
    //   icon: 'folder',
    //   target: 'chaincode-templates',
    //   text: t('title.chaincodes.chaincodeTemplates'),
    //   link: '/chaincode/templates',
    // },
    {
      icon: 'share',
      text: t('title.chaincodes.addChaincode'),
      link: '/chaincode/create',
    },
  ];

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('chaincodeMain'));
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      justify="center"
      className={[classes.root, classes.centerContainer].join(' ')}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography style={{ fontSize: '32px' }} variant="overline">
          <Trans>title.chaincodes.header</Trans>
        </Typography>
      </div>
      <Grid item xs={12}>
        <MenuPage options={options} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(ChaincodeMain);
