import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, Button, Tooltip, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { Clear } from '@material-ui/icons';
import { DefOrgsStepContainer } from './styles';

import { StoreState } from '../../../store/types';
import { changeMountedComponents } from '../../../store/InitialTour';
import { useAddOrganizationForm } from '../../../Hooks/addOrganization';
import AdvancedTab from '../../Deploy/Steps/Contents/Advanced';
import { StyledTab, StyledTabs } from '../../../AppComponents/StyledTabs';
import { TabLabelContainer } from '../../Deploy/styles';
import OrganizationForm from '../../../Forms/OrganizationForm';
import { useNetworks } from '../../../Contexts/Networks';
import { openDialog } from '../../../store/Dialog';

interface DefineOrganizationsProps {
  handleStepChange: (type?: string) => void;
}
const DefineOrganizations: React.FC<DefineOrganizationsProps> = ({
  handleStepChange,
}) => {
  const { selectedNetwork } = useNetworks();

  const {
    orgs,
    setOrgs,
    started,
    isValid,
    setIsValid,
  } = useAddOrganizationForm();
  const [advanced, setAdvanced] = useState(false);
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();
  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);
  const { chapterToChange } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const { t } = useTranslation();

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('deployFirstStep'));
    // eslint-disable-next-line
  }, [chapterToChange]);

  const handleOrgChange = (val: any, org: IOrg, field: string) => {
    if (!selectedNetwork) return;
    const newOrgs = [...orgs];
    const idx: number = newOrgs.findIndex(
      (obj) => obj.id === org.id && obj.orgName === org.orgName,
    );

    newOrgs[idx][field] = val;
    setOrgs(newOrgs);
  };

  console.log('orgs', orgs);

  const removeOrg = (org: IOrg) => {
    let newOrgs = [...orgs];

    if (org.orgName === '') {
      if (orgs.length === 1) return;

      const toRemoveIndex = newOrgs.findIndex(
        (item) => item.orgName === org.orgName,
      );

      newOrgs.splice(toRemoveIndex, 1);
    } else {
      newOrgs = newOrgs.filter(
        (item) =>
          `${item.orgName}.${item.orgDomainName}` !==
          `${org.orgName}.${org.orgDomainName}`,
      );
    }
  };

  const addOrg = () => {
    const newId = orgs.length === 0 ? 0 : orgs[orgs.length - 1].id + 1;

    orgs.push({
      id: newId,
      orgDomainName: '',
      orgName: '',
      authServer: {
        authMethod: 'none',
        oauthURL: '',
        oauthOpenID: '',
        oauthClientID: '',
        oauthClientSecret: '',
      },
      peers: [],
      ccapi: [],
      host: '',
      user: '',
      passwd: '',
      confirmPasswd: '',
      caIP: '',
      caPort: undefined,
      caOperationPort: undefined,
    });

    setOrgs(orgs);

    if (orgs.length === 1) setValue(0);
    else setValue(orgs.length - 1);
  };

  const renderTabLabel = (org: IOrg) => (
    <TabLabelContainer>
      <Typography>
        {org.orgName ? (
          org.orgName
        ) : (
          <Trans>asset.network.deploy.unnamedOrg</Trans>
        )}
      </Typography>
      <Tooltip title={<Trans>asset.network.deploy.deleteOrgTooltip</Trans>}>
        <Clear
          onDoubleClick={() => {
            if (!started) {
              removeOrg(org);
              if (value >= 1) setValue(value - 1);
            }
          }}
          style={{ fontSize: '15px', opacity: '0.5', marginLeft: '15px' }}
        />
      </Tooltip>
    </TabLabelContainer>
  );

  return advanced ? (
    <div style={{ width: '1024px', margin: '10px 20px' }}>
      <Button onClick={() => setAdvanced(!advanced)}>
        <Trans>common.words.basic</Trans>
      </Button>

      <Typography style={{ fontSize: '25px' }} variant="overline">
        JSON
      </Typography>

      <AdvancedTab />
    </div>
  ) : (
    <DefOrgsStepContainer hasCards={false}>
      {/* When we decide to use the advanced again this should be reviewed */}
      {/* <Button onClick={() => setAdvanced(!advanced)}>
        <Trans>common.words.advanced</Trans>
      </Button> */}
      <div>
        <Typography style={{ fontSize: '25px' }} variant="overline">
          <Trans>common.words.organizations</Trans>
        </Typography>

        {/* <OrganizationsTab /> */}

        <div>
          <AppBar
            style={{ backgroundColor: 'var(--primary)' }}
            position="static"
          >
            <StyledTabs
              value={value}
              scrollButtons="on"
              variant="scrollable"
              onChange={(_: any, v: any) => setValue(v)}
            >
              {orgs.map((org) => (
                <StyledTab key={org.id} label={renderTabLabel(org)} />
              ))}
            </StyledTabs>

            <Button
              variant="outlined"
              onClick={addOrg}
              className="add-new-org-tab"
              disabled={started}
              style={{ color: 'var(--white)' }}
            >
              <Trans>button.addMore</Trans>
            </Button>
          </AppBar>

          {orgs.map((org) => (
            <div
              key={org.id}
              style={{ display: value === org.id ? 'block' : 'none' }}
            >
              <OrganizationForm
                org={org}
                hasCCRest
                handleChange={(val: any, field: string) =>
                  handleOrgChange(val, org, field)
                }
                disableInputs={started}
                isAddOrg
              />
            </div>
          ))}
        </div>
      </div>

      <Button
        className="deploy-next-step-btn"
        fullWidth
        color="primary"
        variant="contained"
        style={{ margin: '50px auto' }}
        onClick={() => handleStepChange()}
      >
        <Trans>button.nextStep</Trans>
      </Button>
    </DefOrgsStepContainer>
  );
};

export default DefineOrganizations;
