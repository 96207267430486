import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 30px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const NotFound = styled(Typography).attrs({ variant: 'h6' })`
  && {
    margin-top: 30px;
    font-weight: 300;
    color: var(--lightText);
  }
`;
