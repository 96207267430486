import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ClearAll } from '@material-ui/icons';
import { Button, Typography, CircularProgress } from '@material-ui/core';

import { formatDate } from '../../utils/date';
import { networkApi } from '../../Common/axios';

import { StoreState } from '../../store/types';
import { openDialog } from '../../store/Dialog';

import ReportList from '../../AppComponents/Reports/List';
import SelectNetwork from '../../AppComponents/SelectNetwork';

import { Container, NoOperation, RemoveIcon } from './styles';
import { useNetworks } from '../../Contexts/Networks';
import { useOPHistory } from '../../Contexts/OPHistory';

const REPORT_TYPE = 'session';

const OperationsHistory: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { selectedNetwork, networks } = useNetworks();
  const network = (networks || []).find(
    (item: INetwork) => item.displayName === selectedNetwork?.networkName,
  );

  const [loading, setLoading] = useState(false);
  const { operationsHistory, getHistory } = useOPHistory();

  useEffect(() => {
    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeReport = (reportID: string) => {
    setLoading(true);

    networkApi
      .delete('/deleteOneHistory', { params: { reportID } })
      .then(() => {
        dispatch(
          openDialog({
            type: 'success',
            title: t('common.words.success'),
            content: t('asset.operationsHistory.successDeleteAllMsg'),
          }),
        );
      })
      .catch(() => {
        dispatch(
          openDialog({
            type: 'error',
            title: t('common.words.error'),
            content: t('asset.operationsHistory.unableToDeleteAllMsg'),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const handleRemoveReport = (reportId: string) => {
    dispatch(
      openDialog({
        type: 'question',
        title: t('asset.operationsHistory.removeReport'),
        content: t('asset.operationsHistory.removeReportQuestion'),
        confirmAction() {
          removeReport(reportId);
        },
      }),
    );
  };

  const removeAll = () => {
    console.log('not implemented');
    // setLoading(true);
    // networkApi
    //   .delete('/deleteAllHistory', {
    //     params: { networkName: selectedNetwork?.networkName },
    //   })
    //   .then(() => {
    //     setReports([]);
    //     dispatch(changeReports(REPORT_TYPE, []));
    //     dispatch(
    //       openDialog({
    //         type: 'success',
    //         title: t('common.words.success'),
    //         content: t('asset.operationsHistory.successDeleteAllMsg'),
    //       }),
    //     );
    //   })
    //   .catch(() => {
    //     dispatch(
    //       openDialog({
    //         type: 'error',
    //         title: t('common.words.error'),
    //         content: t('asset.operationsHistory.unableToDeleteAllMsg'),
    //       }),
    //     );
    //   })
    //   .finally(() => setLoading(false));
  };

  const deleteAllReports = () => {
    dispatch(
      openDialog({
        type: 'question',
        title: t('asset.operationsHistory.deleteAll'),
        content: t('asset.operationsHistory.sureWantToDeleteAll'),
        confirmAction: removeAll,
      }),
    );
  };

  return (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="overline" style={{ fontSize: '22px' }}>
          {t('asset.operationsHistory.header')}
        </Typography>

        <Button
          color="secondary"
          startIcon={<ClearAll />}
          style={{ marginLeft: 16 }}
          disabled={loading || operationsHistory?.length <= 0}
          onClick={deleteAllReports}
        >
          {t('asset.operationsHistory.deleteAll')}
        </Button>
      </div>

      {loading ? (
        <CircularProgress style={{ marginTop: 16 }} />
      ) : operationsHistory?.length ? (
        <div style={{ width: '70%', marginTop: 8 }}>
          <ReportList
            // reportType={REPORT_TYPE}
            RemoveIcon={<RemoveIcon />}
            // removeReport={handleRemoveReport}
          />
        </div>
      ) : (
        <NoOperation>{t('header.reports.noReports')}</NoOperation>
      )}
    </Container>
  );
};

export default OperationsHistory;
