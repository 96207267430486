export const ipPatt = new RegExp(
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
);

export const fqdnPatt = new RegExp(
  /^(?!.*-\.)(?:(?!-)[A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,63}$/,
);

export const orgNamePatt = new RegExp(/^[a-zA-Z0-9-]*$/);

export const templateNamePatt = new RegExp(/^[a-zA-Z0-9-_]*$/);

export const assetAndPropTagPatt = new RegExp(/^[a-zA-Z0-9-_]*$/);

export const channelNamePatt = new RegExp(/^[a-z0-9-.]*$/);
