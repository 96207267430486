import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  Button,
  Select,
  Typography,
  ButtonBase,
} from '@material-ui/core';
import {
  Close,
  Clear,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { ResizeCallbackData } from 'react-resizable';
import { DraggableData } from 'react-draggable';
import {
  IIdentitie,
  changeElements,
  closeEndorsement,
  clearEndorsement,
  changeEndorsementWithCallBack,
} from '../../../store/Endorsement';

import { StoreState } from '../../../store/types';
import { ElementType, IEndorsementEvent } from '../types';

import getColor from '../Colors';
import { getElementsInfo } from '../utils';

import Block from './Block';
import Element from './Element';

import {
  ModalTitle,
  ClearButton,
  CloseButton,
  ModalActions,
  ModalContent,
  CanvasHeader,
  ContentHeader,
  CanvasContainer,
} from './styles';

interface IEndorsementModalProps {
  orgs: { orgName: string }[];
  endorsementGUI: ElementType[] | null;
  currentEndorsement: IEndorsement | null;
  onFinish: (value: IEndorsementEvent) => void;
}

const EndorsementModal: React.FC<IEndorsementModalProps> = ({
  orgs,
  onFinish,
  endorsementGUI,
  currentEndorsement,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const endorsement = useSelector(
    (state: StoreState) => state.endorsementState,
  );
  const [idCounter, setIdCounter] = useState(5);
  const { elements }: { elements: ElementType[] } = endorsement;
  const [baseElementContent, setBaseElementContent] = useState(1);
  const [elementCreation, setElementCreation] = useState({
    orgName: '',
    role: t('common.endorsement.member'),
  });

  const setEndorsementWithCallBack = useCallback(
    (value, cb) => {
      dispatch(changeEndorsementWithCallBack(value)).then(
        (endorsemen: IEndorsement) => cb && cb(endorsemen),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentEndorsement && endorsementGUI && !elements.length) {
      const { higherId } = getElementsInfo(endorsementGUI);
      const newBaseElementContent = Object.keys(
        currentEndorsement ? currentEndorsement.policy : {},
      )[0][0];

      dispatch(changeElements(endorsementGUI));
      dispatch(changeElements(endorsementGUI || []));
      setIdCounter(higherId + 1);
      setBaseElementContent(parseInt(newBaseElementContent, 10));
    }
    // I JUST WANT TO RUN IT WHEN THOSE PROPS CHANGE
    // eslint-disable-next-line
  }, [currentEndorsement, endorsementGUI, elements]);

  const addGroup = () => {
    const newElements = [...elements];
    newElements.push({
      id: idCounter,
      size: 300,
      bordercolor: 'var(--black)',
      color: getColor(),
      content: 1,
      position: { x: 0, y: 0 },
      elements: [],
    });
    dispatch(changeElements(newElements));
    setIdCounter(idCounter + 1);
  };

  const addElement = () => {
    const newElements = [...elements];
    const { orgName, role } = elementCreation;

    newElements.push({
      id: idCounter,
      content: `${orgName}.${role}`,
      position: { x: 0, y: 0 },
      color: '#E91E63',
    });
    dispatch(changeElements(newElements));
    setIdCounter(idCounter + 1);
  };

  const setSize = (
    root: ElementType[],
    element: ElementType,
    data: ResizeCallbackData,
  ): ElementType[] => {
    return root.map((item) => {
      if (item.id === element.id) {
        return {
          ...item,
          size: data.size.width, // could be height too
        };
      }
      if (item.elements) {
        return {
          ...item,
          elements: setSize(item.elements, element, data),
        };
      }
      return item;
    });
  };

  const removeItem = (element: ElementType) => {
    const removeRecursion = (
      root: ElementType[],
      toRemove: ElementType,
    ): ElementType[] => {
      // Generate a random number that's going
      // to be used to remove all necessary elements
      const toRemoveValue = Math.random();

      return root
        .map((item) => {
          if (!item.elements) {
            // case is an element
            if (item.id !== toRemove.id) return item;
            // the toRemoveValue is used only to find the element later
            return { ...item, id: toRemoveValue };
          }
          // case is a group
          if (item.id !== toRemove.id)
            return {
              ...item,
              elements: removeRecursion(item.elements, toRemove),
            };
          // the toRemoveValue is used only to find the element later
          return { ...item, id: toRemoveValue };
        })
        .filter((item) => item.id !== toRemoveValue);
    };

    dispatch(changeElements(removeRecursion(elements, element)));
  };

  const changeContent = (element: ElementType) => {
    const changeRecursion = (
      root: ElementType[],
      newElement: ElementType,
    ): ElementType[] => {
      return root.map((item) => {
        if (item.elements) {
          // case is a group
          if (item.id === newElement.id) return newElement;
          return {
            ...item,
            elements: changeRecursion(item.elements, newElement),
          };
        }
        return item;
      });
    };
    const newElements = changeRecursion(elements, element);
    dispatch(changeElements(newElements));
  };

  const moveChilds = (
    root: ElementType,
    data: DraggableData,
  ): ElementType[] => {
    if (!root.elements) return [];

    const result = root.elements.map((item: ElementType) => {
      if (!item.elements) {
        return {
          ...item,
          position: {
            x: item.position.x + data.deltaX,
            y: item.position.y + data.deltaY,
          },
        };
      }
      return {
        ...item,
        position: {
          x: item.position.x + data.deltaX,
          y: item.position.y + data.deltaY,
        },
        elements: moveChilds(item, data),
      };
    });
    return result;
  };

  const setPosition = (
    root: ElementType[],
    element: ElementType,
    data: DraggableData,
  ): ElementType[] => {
    return root.map((item) => {
      if (item.id === element.id) {
        if (item.elements)
          return {
            ...item,
            position: { x: data.x, y: data.y },
            elements: moveChilds(item, data),
          };
        return { ...item, position: { x: data.x, y: data.y } };
      }
      if (item.elements) {
        return {
          ...item,
          elements: setPosition(item.elements, element, data),
        };
      }
      return item;
    });
  };

  const checkParent = (child: ElementType, data: DraggableData) => {
    const candidates: ElementType[] = [];
    const position = { x: data.x, y: data.y };
    let [childWidth, childHeight] = [0, 0];
    if (child.elements) {
      childHeight = child.size ? child.size : 25;
      childWidth = child.size ? child.size : 100;
    } else {
      childWidth = 100;
      childHeight = 25;
    }
    const recursion = (root: ElementType[], pos: { x: number; y: number }) => {
      root.forEach((element) => {
        const elementSize = element.size ? element.size : 0;

        if (
          element.elements &&
          element.id !== child.id &&
          pos.x >= element.position.x &&
          pos.x + childWidth <= element.position.x + elementSize &&
          pos.y >= element.position.y &&
          pos.y + childHeight <= element.position.y + elementSize
        ) {
          candidates.push(element);
          recursion(element.elements, pos);
        }
      });
    };
    recursion(elements, position);

    // Generate a random number that's going
    // to be used to remove all necessary elements
    const toRemoveValue = Math.random();

    const result = candidates.reduce(
      (lowest, item) => {
        const itemSize = item.size ? item.size : 0;
        const lowestSize = lowest.size ? lowest.size : 0;

        return itemSize > lowestSize ? lowest : item;
      },
      // the toRemoveValue is used only to find it later
      { ...child, size: 99999, id: toRemoveValue },
    );

    const removeChild = (
      root: ElementType[],
      toRemove: ElementType,
    ): ElementType[] => {
      return root
        .map((item) => {
          if (!item.elements) {
            // case is an element
            if (item.id !== toRemove.id) return item;
            // the toRemoveValue is used only to find it later
            return { ...item, id: toRemoveValue };
          }
          // case is a group
          if (item.id !== toRemove.id)
            return {
              ...item,
              elements: removeChild(item.elements, toRemove),
            };
          // the toRemoveValue is used only for find it later
          return { ...item, id: toRemoveValue };
        })
        .filter((item) => item.id !== toRemoveValue);
    };

    const removedElementList = removeChild(elements, child);

    const addToParent = (
      root: ElementType[],
      parentToAdd: ElementType,
    ): ElementType[] => {
      return root.map((group) => {
        if (group.elements) {
          if (parentToAdd.id === group.id) {
            const newItem = { ...group };
            if (
              !group.elements.some(
                (itemCheck: ElementType) => itemCheck.id === child.id,
              )
            ) {
              if (child.elements) {
                // if moving element is a group
                newItem.elements = [
                  ...group.elements,
                  { ...child, borderColor: group.color },
                ];
              } else {
                // if moving element is a single element
                newItem.elements = [
                  ...group.elements,
                  { ...child, color: group.color },
                ];
              }
              return newItem;
            }
            return group;
          }
          return {
            ...group,
            elements: addToParent(group.elements, parentToAdd),
          };
        }
        return group;
      });
    };

    if (result.id !== toRemoveValue)
      dispatch(changeElements(addToParent(removedElementList, result)));
    else if (child.elements)
      dispatch(
        changeElements([
          ...removedElementList,
          { ...child, bordercolor: 'var(--black)' },
        ]),
      );
    else
      dispatch(
        changeElements([...removedElementList, { ...child, color: '#E91E63' }]),
      );
  };

  const renderElements = (
    element: ElementType,
  ): JSX.Element | JSX.Element[] => {
    if (element.elements) {
      return [
        <Block
          key={element.id}
          block={element}
          changeContent={changeContent}
          setSize={(data) =>
            dispatch(changeElements(setSize(elements, element, data)))
          }
          setPosition={(data) =>
            dispatch(changeElements(setPosition(elements, element, data)))
          }
          removeItem={removeItem}
          // Perhabs the element passed should be the first param of the function
          // But it is working...
          checkParent={(_, data) => checkParent(element, data)}
        />,
        element.elements.map((item: ElementType) => renderElements(item)),
      ];
    }
    return (
      <Element
        key={element.id}
        removeItem={removeItem}
        element={element}
        // Perhabs the element passed should be the first param of the function
        checkParent={(_, data) => checkParent(element, data)}
        setPosition={(data) =>
          dispatch(changeElements(setPosition(elements, element, data)))
        }
      />
    );
  };

  const getEndorsementObject = () => {
    let identities: IIdentitie[] = [];
    const fillIdentities = (root: ElementType[]) => {
      root.forEach((item) => {
        if (item.elements) {
          fillIdentities(item.elements);
        } else {
          const content = `${item.content}`;
          const name = content.substring(
            content.indexOf('.') + 1,
            content.length,
          );

          identities.push({
            role: {
              name,
              mspId: `${content.substring(0, content.indexOf('.'))}MSP`,
            },
          });
        }
      });
    };
    fillIdentities(elements);

    const setIdentities = new Set(identities.map((e) => JSON.stringify(e)));
    identities = Array.from(setIdentities).map((e) => JSON.parse(e));

    const policy: Record<string, any> = {};

    const policyRecursion = (root: ElementType[]): object[] => {
      return root.map((item) => {
        if (!item.elements)
          return {
            'signed-by': identities.findIndex(
              (identity) =>
                `${identity.role.mspId.substring(
                  0,
                  identity.role.mspId.indexOf('MSP'),
                )}.${identity.role.name}` === `${item.content}`,
            ),
          };
        return {
          [`${item.content}-${t('common.endorsement.of')}`]: policyRecursion(
            item.elements,
          ),
        };
      });
    };

    policy[
      `${baseElementContent}-${t('common.endorsement.of')}`
    ] = policyRecursion(elements);

    return {
      endorsementGUI: elements,
      endorsement: { identities, policy },
    };
  };

  return (
    <Dialog
      style={{ zIndex: 10001 }}
      open={endorsement.dialog}
      onClose={() => dispatch(closeEndorsement())}
      fullWidth
      fullScreen
    >
      <ModalTitle disableTypography>
        <Typography style={{ color: 'var(--white)' }} variant="h6">
          <Trans>common.endorsement.endorsementOptions</Trans>
        </Typography>

        <CloseButton
          aria-label="Close"
          onClick={() => dispatch(closeEndorsement())}
        >
          <Close />
        </CloseButton>
      </ModalTitle>

      <ModalContent>
        <div style={{ height: '600px', width: '100%' }}>
          <ContentHeader id="header">
            <Button
              onClick={addGroup}
              style={{ margin: '0 20px' }}
              variant="contained"
            >
              <Trans>common.endorsement.addBlock</Trans>
            </Button>

            <div>
              <Select
                native
                value={elementCreation.orgName}
                onChange={(e) =>
                  setElementCreation({
                    ...elementCreation,
                    orgName: e.target.value as string,
                  })
                }
              >
                <option value="">{t('common.endorsement.orgName')}</option>

                {orgs &&
                  orgs.map((org) => (
                    <option key={org.orgName} value={org.orgName}>
                      {org.orgName}
                    </option>
                  ))}
              </Select>

              <Select
                native
                value={elementCreation.role}
                onChange={(e) =>
                  setElementCreation({
                    ...elementCreation,
                    role: e.target.value as string,
                  })
                }
              >
                <option value="member">{t('common.endorsement.member')}</option>
              </Select>

              <Button
                onClick={addElement}
                style={{ margin: '0 20px' }}
                variant="contained"
                disabled={
                  elementCreation.role === '' || elementCreation.orgName === ''
                }
              >
                <Trans>common.endorsement.addElement</Trans>
              </Button>
            </div>
          </ContentHeader>

          <CanvasHeader>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ButtonBase
                onClick={() =>
                  elements.length > baseElementContent
                    ? setBaseElementContent(baseElementContent + 1)
                    : null
                }
              >
                <KeyboardArrowUp />
              </ButtonBase>
              <ButtonBase
                onClick={() =>
                  baseElementContent > 1
                    ? setBaseElementContent(baseElementContent - 1)
                    : null
                }
              >
                <KeyboardArrowDown />
              </ButtonBase>
            </div>

            <div style={{ margin: '0 auto 0 10px', fontSize: '20px' }}>
              {`${baseElementContent} ${t('common.endorsement.of')} ${
                elements.length
              }`}
            </div>

            <ClearButton
              startIcon={<Clear style={{ marginBottom: '2px' }} />}
              onClick={() => {
                dispatch(clearEndorsement());
                setEndorsementWithCallBack(
                  { endorsement: null, endorsementGUI: null },
                  onFinish,
                );
              }}
            >
              <Trans>button.clear</Trans>
            </ClearButton>
          </CanvasHeader>

          <CanvasContainer className="main">
            {elements.map((element) => renderElements(element))}
          </CanvasContainer>
        </div>
      </ModalContent>

      <ModalActions>
        <Button
          variant="contained"
          onClick={() => {
            setEndorsementWithCallBack(getEndorsementObject(), onFinish);
            dispatch(closeEndorsement());
          }}
          style={{ color: 'var(--white)', backgroundColor: 'var(--black)' }}
        >
          <Trans>button.finish</Trans>
        </Button>
      </ModalActions>
    </Dialog>
  );
};

EndorsementModal.defaultProps = {
  endorsementGUI: null,
  currentEndorsement: null,
};

export default EndorsementModal;
