import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { openDialog } from '../../store/Dialog';
import loginAPI from '../../Common/fetch';
import { changeLanguageCallback } from '../../store/AppStatus';
import { openLoading, closeLoading } from '../../store/GlobalLoading';
import { StoreState } from '../../store/types';

import * as S from './styles';
import { useLicense } from '../../Contexts/License';
import { LicenseModal } from '../../AppComponents/LicenseModal';
import { SigninForm } from '../../AppComponents/SigninForm';

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { supportedLanguages, supportedLanguagesName } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const { licenseInfo } = useLicense();

  const [open, setOpen] = useState(false);

  const changeLanguage = (index: number) => {
    const language: string = supportedLanguages[index];

    i18next.changeLanguage(
      language,
      (error: object, trans: (arg0: string) => string) => {
        dispatch(changeLanguageCallback(language, error, trans));
      },
    );
  };

  //   const login = () => {
  //     try {
  //       dispatch(openLoading());

  //       loginAPI('/signin', { redirect: 'manual' })
  //         .then((res: { url?: string }) => {
  //           window.location.href = res.url ? res.url : window.location.href;

  //           sessionStorage.setItem(
  //             '@GoFabric:lastCheck',
  //             `${new Date().toJSON()}`,
  //           );
  //         })
  //         .catch(() => {
  //           dispatch(
  //             openDialog({
  //               title: t('common.words.error'),
  //               type: 'error',
  //               content: t('asset.login.loginError'),
  //             }),
  //           );
  //         })
  //         .finally(() => dispatch(closeLoading()));
  //     } catch (error) {
  //       dispatch(closeLoading());
  //       dispatch(
  //         openDialog({
  //           title: t('common.words.error'),
  //           type: 'error',
  //           content: error.message,
  //         }),
  //       );
  //     }
  //   };

  return (
    <S.Auth>
      <S.Box>
        <S.Logo to="/">
          <img
            src="/GoLedger.png"
            width={110}
            height={110}
            alt="Goledger Logo"
          />
        </S.Logo>
        <SigninForm />
      </S.Box>
      <S.RedirectLink>
        <Typography variant="overline">Dont have an account?</Typography>
        <Link to="/signup">
          <Typography variant="overline"> Sign up</Typography>
        </Link>
      </S.RedirectLink>
      {process.env?.REACT_APP_SHOW_LICENSE?.toLowerCase?.() !== 'false' && (
        <S.FooterImgContainer>
          <Box
            style={{
              margin: '1rem auto',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              License details
            </Button>
          </Box>
        </S.FooterImgContainer>
      )}

      <LicenseModal
        license={licenseInfo}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
      {/* <Button variant="contained" color="primary" onClick={login}>
        login
      </Button> */}
    </S.Auth>
  );
};

export default Login;
