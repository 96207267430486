import React, { useEffect, useCallback } from 'react';
import Axios, { Canceler } from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  List,
  Button,
  ListItem,
  Checkbox,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';

import history from '../../../history';
import { networkApi } from '../../../Common/axios';

import {
  Operation,
  addOperation,
  removeOperation,
} from '../../../store/AppStatus';
import { openDialog } from '../../../store/Dialog';
import { IMappedOrg, useRemovePeerForm } from '../../../Hooks/removePeer';
import { clearRemovePeerNotifications } from '../../../store/RemovePeerNotifications';

import { StoreState } from '../../../store/types';
import { ISelectedNetwork } from '../../../store/Network';

import {
  Card,
  Section,
  Message,
  PageTitle,
  PageContainer,
  SelectListItem,
  FormSectionTitle,
  LoadContainer,
} from './styles';

import LoadingScreen from '../../LoadingScreen';
import Select from '../../../AppComponents/Select';
import Tooltip from '../../../AppComponents/Tooltip';
import canRunOperation from '../../../utils/canRunOperation';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import LoadingContainer from '../../../AppComponents/Notifications';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { mapChannelOrgs } from '../../../utils/network/parseChannelMembers';
import CAauthFields, { Auth as ICAauth } from '../../../AppComponents/CAauth';

interface IPeer {
  name: string;
  value: string;
}

const isValueValid = (value: any): boolean =>
  value && value !== '' && value !== 0;

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const RemovePeer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const { selectedNetwork }: { selectedNetwork: ISelectedNetwork } =
    useSelector((reduxState: StoreState) => reduxState.networkState);

  const {
    loading,
    setLoading,
    CAauth,
    setCAauth,
    sendingData,
    setSendingData,
    channelName,
    setChannelName,
    selectedPeer,
    setSelectedPeer,
    selectedOrgIdx,
    setSelectedOrgIdx,
    orgs,
    setOrgs,
    netStateOrgs,
    setNetStateOrgs,
    channelDefs,
    setChannelDefs,
    clearFormData,
  } = useRemovePeerForm();

  const selectedOrg: IMappedOrg | undefined = orgs[selectedOrgIdx];

  const changeCAauth = useCallback(
    (auth: ICAauth) => setCAauth((prev) => ({ ...prev, ...auth })),
    [setCAauth],
  );

  const cantRunOperation = useCallback(
    () => !canRunOperation(operations),
    [operations],
  );

  // Loads the peer configuration from the rest
  useEffect(() => {
    if (selectedNetwork.name && !sendingData) {
      clearFormData();
      setLoading(true);

      const config = { params: { networkName: selectedNetwork.name } };

      networkApi
        .get('/networkstate', config)
        .then((res) => {
          const { organizations, networkDefs } = res.data.states[0];

          setNetStateOrgs(organizations);
          setChannelDefs(networkDefs.channelDefs);
        })
        .catch((error) => {
          dispatch(
            openDialog({
              type: 'error',
              title: t('common.words.error'),
              content: error.message.includes('Request failed with status code')
                ? t('common.messages.failedToSelectNet')
                : error.message,
            }),
          );
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [
    t,
    dispatch,
    selectedNetwork.name,
    setLoading,
    clearFormData,
    setChannelDefs,
    setNetStateOrgs,
  ]);

  const send = () => {
    try {
      dispatch(clearRemovePeerNotifications());

      if (!selectedOrg || !selectedOrg.firstName)
        throw Error(t('common.messages.noOrgSelected'));
      if (!selectedPeer.value) throw Error(t('common.messages.noPeerSelected'));
      if (!CAauth.user || !CAauth.passwd)
        throw Error(t('common.messages.addCredentials'));
      if (!channelName) throw Error(t('common.messages.pleaseSelectChannel'));

      setSendingData(true);

      const peerInfo = {
        CAauth,
        channelName,
        peerName: `${selectedPeer.name}.${selectedOrg.name}`,
        orgName: selectedOrg.firstName,
        networkName: selectedNetwork.name,
      };

      networkApi
        .post('/removepeer', peerInfo, {
          cancelToken: new CancelToken((c: Canceler) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, t('title.nodes.peers.removePeer'), hasDialog);
            };

            cancelRequest = withDialogCancel;

            dispatch(
              addOperation({
                title: t('title.nodes.peers.removePeer'),
                cancel: withDialogCancel,
                name: 'removepeer',
                pathname: window.location.pathname,
              }),
            );
          }),
        })
        .then(() => {
          dispatch(removeOperation('removepeer', true));
          clearFormData();

          if (window.location.pathname === '/node/peer/remove')
            history.push('/dashboard');
        })
        .catch(() => dispatch(removeOperation('removepeer', false)))
        .finally(() => setSendingData(false));
    } catch (error) {
      setSendingData(false);

      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message,
        }),
      );
    }
  };

  const handlePeerSelection = (peer: IPeer) => {
    setSelectedPeer(peer);
  };

  const noAvailablePeer = useCallback(
    () =>
      selectedOrg &&
      selectedOrg.peers.length === 1 &&
      selectedOrg.peers.some((p) => p.name === 'peer0'),
    [selectedOrg],
  );

  const getOrgPeerObj = (
    stateOrg: INetStateOrg,
    chPeer: INetStateChannelDefOrg['Peers'][0],
  ) => {
    return {
      name: chPeer.split('.')[0],
      value: stateOrg.peers[chPeer].host,
    };
  };

  const getOrgObj = (stateOrg: INetStateOrg) => ({
    firstName: stateOrg.orgName,
    name: `${stateOrg.orgName}.${stateOrg.orgDomainName}`,
  });

  const getChannelMembers = useCallback(() => {
    const channelDef = channelDefs.find(
      (chDef) => chDef.channelName === channelName,
    );

    if (channelDef) {
      const { mappedOrgs } = mapChannelOrgs({
        getOrgObj,
        getOrgPeerObj,
        chOrgs: channelDef.orgs,
        separateNodesTypes: true,
        organizations: netStateOrgs,
      });

      setOrgs(mappedOrgs);
    }
  }, [channelName, netStateOrgs, channelDefs, setOrgs]);

  useEffect(() => {
    getChannelMembers();
    setSelectedOrgIdx(-1);
    setSelectedPeer({ name: '', value: '' });
  }, [channelName, getChannelMembers, setSelectedOrgIdx, setSelectedPeer]);

  if (!selectedNetwork.name) return <SelectNetwork />;

  if (loading)
    return (
      <div
        style={{ display: 'flex', margin: '0 auto', justifyContent: 'center' }}
      >
        <CircularProgress style={{ marginTop: '30px' }} />
      </div>
    );

  return (
    <div style={{ margin: '0 auto' }}>
      <PageTitle>
        <Trans>title.nodes.peers.removePeer</Trans>
      </PageTitle>

      <PageContainer>
        <Card>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Section>
              <FormSectionTitle>
                <Trans>common.words.channel</Trans>
              </FormSectionTitle>

              <List>
                {selectedNetwork.channels.map((channel) => (
                  <ListItem
                    button
                    divider
                    key={channel}
                    onClick={() => setChannelName(channel)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        disabled={sendingData}
                        checked={channel === channelName}
                      />
                    </ListItemIcon>

                    <ListItemText primary={channel} />
                  </ListItem>
                ))}
              </List>
            </Section>

            <Section>
              <FormSectionTitle>
                <Trans>common.messages.selectOrg</Trans>
              </FormSectionTitle>

              {!channelName ? (
                <Typography
                  variant="body1"
                  style={{ marginTop: 32, color: 'var(--darkGray)' }}
                >
                  <Trans>common.messages.selectChannelFirst</Trans>
                </Typography>
              ) : (
                <List>
                  {orgs.map((org, idx) => (
                    <SelectListItem
                      key={org.name}
                      disabled={sendingData}
                      selected={selectedOrg && selectedOrg.name === org.name}
                      onClick={() => {
                        setSelectedOrgIdx(idx);
                        setSelectedPeer({
                          name: '',
                          value: '',
                        });
                      }}
                    >
                      {org.name}
                    </SelectListItem>
                  ))}
                </List>
              )}
            </Section>

            <Section>
              <FormSectionTitle>
                <Trans>common.messages.selectPeer</Trans>
              </FormSectionTitle>

              {selectedOrgIdx < 0 ? (
                <Typography
                  variant="body1"
                  style={{ marginTop: 16, color: 'var(--darkGray)' }}
                >
                  <Trans>common.messages.selectOneOrg</Trans>
                </Typography>
              ) : (
                <div style={{ marginTop: 8, width: '100%' }}>
                  <Select
                    keyToRender="value"
                    disabled={sendingData}
                    placeholder={t('common.messages.selectPeer')}
                    options={selectedOrg ? selectedOrg.peers : []}
                    isDisabled={(option) => option.name === 'peer0'}
                    onChange={(option) => handlePeerSelection(option)}
                  />
                </div>
              )}

              <FormSectionTitle style={{ marginTop: '20px' }}>
                <Trans>common.forms.CAauth</Trans>
              </FormSectionTitle>

              <CAauthFields
                initialState={CAauth}
                disabled={sendingData}
                callback={changeCAauth}
                userInputStyles={{ margin: 0 }}
                passwordInputStyles={{ margin: 0 }}
                containerStyles={{ marginBottom: '30px' }}
              />
            </Section>
          </div>

          {noAvailablePeer() ? (
            <Message>
              <Trans values={{ orgName: selectedOrg.firstName }}>
                asset.nodes.removePeer.noAvailablePeer
              </Trans>
            </Message>
          ) : null}

          {sendingData ? (
            <LoadContainer>
              <LoadingScreen
                content={t('asset.nodes.removePeer.loadingMessage')}
              />

              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => cancelRequest()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            </LoadContainer>
          ) : (
            <Tooltip
              canShow={cantRunOperation()}
              message={t('common.messages.onlyOneOperation')}
            >
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={sendingData || cantRunOperation()}
                style={{ pointerEvents: 'all' }}
                onClick={() => {
                  if (!sendingData || !cantRunOperation()) send();
                }}
              >
                <Trans>button.send</Trans>
              </Button>
            </Tooltip>
          )}
        </Card>

        {selectedOrg && selectedPeer.value ? (
          <div style={{ margin: '0 auto' }}>
            <LoadingContainer
              cardInfo={{
                type: 'removepeer',
                running: sendingData,
                title: t('title.nodes.peers.removePeer'),
                canShow:
                  isValueValid(selectedOrg.firstName) &&
                  isValueValid(selectedPeer.value),
                attributes: {
                  channelName,
                  peer: selectedPeer.value,
                  organization: selectedOrg.firstName,
                },
              }}
            />
          </div>
        ) : null}
      </PageContainer>
    </div>
  );
};

export default RemovePeer;
