// TODO
export const buildOrganizationData = (
  data: Record<string, any>,
  networkName: string,
) => {
  try {
    // global FormData
    const formData = new FormData();

    //   formData.append('chaincode', data.chaincode.chaincodeFile as Blob);

    if (
      data.endorsementGUI &&
      !data.template?.assets.some((asset: any) => asset.privateData === true)
    ) {
      formData.append(
        'endorsementgui',
        JSON.stringify({
          name: networkName || data.networkName,
          elements: data.endorsementGUI,
        }),
      );
    }

    if (data.usingCloudChaincode) {
      formData.append('template', data.cloudChaincode);
    }

    const channels = {} as Record<string, { peers: Record<string, any> }>;

    data.channels.forEach((channel: Channel) => {
      channels[channel.channelName] = {
        peers: {},
      };
    });

    const organizations = {} as Record<string, any>;
    const orderers = {} as Record<string, any>;
    const allPeers = {} as Record<string, any>;
    const couchs = {} as Record<string, any>;
    data.orgs.forEach((org: IOrg) => {
      const peers = org.peers.map(
        (peer, index) => `peer${index}.${org.orgName}.${org.orgDomainName}`,
      );
      const orderesrsInOrg = [] as string[];
      const couchsInOrg = [] as string[];

      let peerCounter = 0;
      let ordererCounter = 0;

      // orderers
      org.peers.forEach((peer) => {
        if (peer.opts.peer) {
          if (peer.opts?.peerCouchPort) {
            couchs[
              `couch${peerCounter}.${org.orgName}.${org.orgDomainName}`
            ] = {
              port: Number(peer.opts?.peerCouchPort),
            };
            couchsInOrg.push(
              `couch${peerCounter}.${org.orgName}.${org.orgDomainName}`,
            );
          }
          allPeers[`peer${peerCounter}.${org.orgName}.${org.orgDomainName}`] = {
            name: `peer${peerCounter}`,
            pw: `peer${peerCounter}pw`,
            host: peer.value,
            ...(peer.opts?.peerPort && { port: Number(peer.opts.peerPort) }),
            ...(peer.opts?.peerOperationPort && {
              operationPort: Number(peer.opts.peerOperationPort),
            }),
            ...(org?.hsm?.enabled && {
              hsm: {
                user: peer?.hsm?.peer?.user,
                pin: peer?.hsm?.peer?.pin,
              },
            }),
          };

          data.channels.forEach((channel: Channel) => {
            const peerIndex = channel?.peers?.[org.orgName]
              ? channel?.peers?.[org.orgName]?.findIndex(
                  (v) => v === peer.value,
                )
              : -1;
            if (peerIndex !== -1) {
              // eslint-disable-next-line no-param-reassign
              if (!channels?.[channel.channelName]?.peers[org.orgName])
                channels[channel.channelName].peers[org.orgName] = [];

              channels[channel.channelName].peers[org.orgName].push(
                `peer${peerCounter}.${org.orgName}.${org.orgDomainName}`,
              );

              Object.assign(allPeers, {
                [`peer${peerCounter}.${org.orgName}.${org.orgDomainName}`]: {
                  name: `peer${peerCounter}`,
                  pw: `peer${peerCounter}pw`,
                  host: peer.value,
                  ...(peer.opts?.peerPort && {
                    port: Number(peer.opts.peerPort),
                  }),
                  ...(peer.opts?.peerOperationPort && {
                    operationPort: Number(peer.opts.peerOperationPort),
                  }),
                  ...(org?.hsm?.enabled && {
                    hsm: {
                      user: peer?.hsm?.peer?.user,
                      pin: peer?.hsm?.peer?.pin,
                    },
                  }),
                },
              });
            }
          });
          peerCounter++;
        }
        if (peer.opts.orderer) {
          orderers[
            `orderer${ordererCounter}.${org.orgName}.${org.orgDomainName}`
          ] = {
            name: `orderer${ordererCounter}`,
            pw: `orderer${ordererCounter}pw`,
            host: peer.value,
            ...(peer.opts?.ordererPort && {
              port: Number(peer.opts.ordererPort),
            }),
            ...(peer.opts?.ordererOperationPort && {
              operationPort: Number(peer.opts.ordererOperationPort),
            }),
            ...(org?.hsm?.enabled && {
              hsm: {
                user: peer?.hsm?.orderer?.user,
                pin: peer?.hsm?.orderer?.pin,
              },
            }),
          };

          orderesrsInOrg.push(
            `orderer${ordererCounter}.${org.orgName}.${org.orgDomainName}`,
          );
          ordererCounter++;
        }
      });

      const ccapi = org.ccapi.map((cc) => {
        const tempCcapi = { ...cc };
        if (((tempCcapi?.restPort as unknown) as string) === '')
          delete tempCcapi.restPort;
        if (((tempCcapi?.TLSPort as unknown) as string) === '')
          delete tempCcapi.TLSPort;
        if (((tempCcapi?.goinitus?.port as unknown) as string) === '')
          delete tempCcapi?.goinitus?.port;

        return tempCcapi;
      });

      organizations[org.orgName] = {
        name: org.orgName,
        domain: org.orgDomainName,
        netapi: {
          host: org.caIP,
          ...(org.netAPIPort && {
            port: Number(org.netAPIPort),
          }),
        },
        ca: {
          containerName: `ca_${org.orgName}`,
          host: org.caIP,
          username: org.user,
          password: org.passwd,
          ...(org?.caPort && { port: Number(org.caPort) }),
          ...(org?.caOperationPort && {
            operationPort: Number(org?.caOperationPort),
          }),
          ...(org?.hsm?.enabled && {
            hsm: {
              ...(org.caHSM.user && { user: org.caHSM.user }),
              ...(org.caHSM.pin && { pin: org.caHSM.pin }),
            },
          }),
        },
        peers,
        orderers: orderesrsInOrg,
        ccapi,
        caPort: org.caPort,
        caOperationPort: org.caOperationPort,
        ...(org.prometheusPort && {
          prometheus: { port: Number(org.prometheusPort) },
        }),
        ...(org.grafanaPort && { grafana: { port: Number(org.grafanaPort) } }),
        ...(couchsInOrg?.length > 0 && { couchs: couchsInOrg }),
        ...(org?.hsm?.enabled && {
          hsm: org?.hsm,
        }),
      };
    });

    // Remove channels with empty peers
    Object.keys(channels).forEach((channelName) => {
      if (Object.keys(channels[channelName].peers).length === 0)
        delete channels[channelName];
    });

    const definitions = {
      channels,
      organizations,
      peers: allPeers,
      orderers,
      ...(Object?.keys(couchs)?.length > 0 && { couchs }),
    };

    console.log('DEFINITIONS', definitions);

    formData.append('payload', JSON.stringify(definitions));
    formData.append('networkName', networkName);

    return formData;
  } catch (error) {
    console.log('ERROR', error);
    throw error;
  }
};

const validateChaincodes = (chaincode: IChaincodes) => {
  // throw Error('No Base template selected for chaincode');
  if (chaincode.ccType === 'template') {
    if (!chaincode.ccBaseName)
      throw Error('No Base template selected for chaincode');
    if (!chaincode.templateDefinition)
      throw Error('No template definition selected for chaincode');
  } else if (chaincode.ccType === 'form') {
    if (!chaincode.tarName) throw Error('Chaincode file not selected');
  }
};

export default (
  data: Record<string, any>,
  orgs?: IOrg[],
  networkName?: string,
  channelDefs: any[] = [],
) => {
  try {
    // global FormData
    const formData = new FormData();

    //   formData.append('chaincode', data.chaincode.chaincodeFile as Blob);

    // if (
    //   data.endorsementGUI &&
    //   !data.template?.assets.some((asset: any) => asset.privateData === true)
    // ) {
    //   formData.append(
    //     'endorsementgui',
    //     JSON.stringify({
    //       name: networkName || data.networkName,
    //       elements: data.endorsementGUI,
    //     }),
    //   );
    // }

    if (data.usingCloudChaincode) {
      formData.append('template', data.cloudChaincode);
    }

    const channels = {} as Record<
      string,
      { peers: Record<string, any>; chaincodes: string[] }
    >;

    const chaincodes = {} as Record<string, any>;

    data.channels.forEach((channel: Channel) => {
      channels[channel.channelName] = {
        chaincodes: channel.chaincodes.map(
          (cc) => cc.chaincodeName?.split?.('/')?.[1] || cc.chaincodeName,
        ),
        peers: {},
      };

      channel.chaincodes.forEach((cc: IChaincodes) => {
        validateChaincodes(cc);
        chaincodes[cc.chaincodeName] = {
          ...(cc.tarName ? { tarName: cc.tarName } : {}),
          ccType: cc.ccType,
          ...(cc.templateDefinition
            ? { templateDef: cc.templateDefinition }
            : {}),
          ...(cc.ccBaseName ? { ccBaseName: cc.ccBaseName } : {}),
          ...(cc.ccBaseName
            ? {
                tarName:
                  `${cc?.chaincodeName}.tar.gz`?.split?.('/')?.[1] ||
                  `${cc?.chaincodeName}.tar.gz`,
              }
            : {}),
          peers: {},
          ...(cc.endorsement ? { endorsement: cc.endorsement } : {}),
          ...(cc.endorsementGUI ? { endorsementGUI: cc.endorsementGUI } : {}),
        };

        if (cc.ccType === 'form')
          formData.append('ccFiles', cc.chaincodeFile as Blob);
      });
    });

    const organizations = {} as Record<string, any>;
    const orderers = {} as Record<string, any>;
    const allPeers = {} as Record<string, any>;
    const couchs = {} as Record<string, any>;
    data.orgs.forEach((org: IOrg) => {
      const peers = org.peers.map(
        (peer, index) => `peer${index}.${org.orgName}.${org.orgDomainName}`,
      );
      const orderesrsInOrg = [] as string[];
      const couchsInOrg = [] as string[];

      let peerCounter = 0;
      let ordererCounter = 0;

      // orderers
      org.peers.forEach((peer) => {
        if (peer?.opts?.peer) {
          if (peer.opts?.peerCouchPort) {
            couchs[
              `couch${peerCounter}.${org.orgName}.${org.orgDomainName}`
            ] = {
              port: Number(peer.opts?.peerCouchPort),
            };
            couchsInOrg.push(
              `couch${peerCounter}.${org.orgName}.${org.orgDomainName}`,
            );
          }

          allPeers[`peer${peerCounter}.${org.orgName}.${org.orgDomainName}`] = {
            name: `peer${peerCounter}`,
            pw: `peer${peerCounter}pw`,
            host: peer.value,
            chaincodes: [],
            ...(peer.opts?.peerPort && { port: Number(peer.opts.peerPort) }),
            ...(peer.opts?.peerOperationPort && {
              operationPort: Number(peer.opts.peerOperationPort),
            }),
            // ...(peer.opts?.peerCouchPort && {
            //   couchName: `couch${peerCounter}.${org.orgName}.${org.orgDomainName}`,
            // }),
          };

          data.channels.forEach((channel: Channel) => {
            const peerIndex = channel?.peers?.[org.orgName]
              ? channel?.peers?.[org.orgName]?.findIndex(
                  (v) => v === peer.value,
                )
              : -1;
            if (peerIndex !== -1) {
              // eslint-disable-next-line no-param-reassign
              if (!channels?.[channel.channelName]?.peers[org.orgName])
                channels[channel.channelName].peers[org.orgName] = [];

              channels[channel.channelName].peers[org.orgName].push(
                `peer${peerCounter}.${org.orgName}.${org.orgDomainName}`,
              );

              channel.chaincodes.forEach((cc: IChaincodes) => {
                if (!chaincodes[cc.chaincodeName]?.peers?.[org?.orgName]) {
                  Object.assign(chaincodes[cc.chaincodeName]?.peers, {
                    [org.orgName]: [],
                  });
                }
                chaincodes[cc.chaincodeName].peers[org.orgName].push(
                  ...channels[channel.channelName].peers[org.orgName],
                );

                // remove duplicate peers from chaincode
                chaincodes[cc.chaincodeName].peers[org.orgName] = [
                  ...new Set(chaincodes[cc.chaincodeName].peers[org.orgName]),
                ];
              });

              Object.assign(allPeers, {
                [`peer${peerCounter}.${org.orgName}.${org.orgDomainName}`]: {
                  name: `peer${peerCounter}`,
                  pw: `peer${peerCounter}pw`,
                  host: peer.value,
                  chaincodes: channel?.chaincodes.map(
                    (cc) =>
                      cc?.chaincodeName?.split?.('/')?.[1] || cc?.chaincodeName,
                  ),
                  ...(peer.opts?.peerPort && {
                    port: Number(peer.opts.peerPort),
                  }),
                  ...(peer.opts?.peerOperationPort && {
                    operationPort: Number(peer.opts.peerOperationPort),
                  }),
                  ...(org?.hsm?.enabled && {
                    hsm: {
                      user: peer?.hsm?.peer?.user,
                      pin: peer?.hsm?.peer?.pin,
                    },
                  }),
                  // ...(peer.opts?.peerCouchPort && {
                  //   couchName: `couch${peerCounter}.${org.orgName}.${org.orgDomainName}`,
                  // }),
                },
              });
            }
          });

          peerCounter++;
        }
        if (peer?.opts?.orderer) {
          orderers[
            `orderer${ordererCounter}.${org.orgName}.${org.orgDomainName}`
          ] = {
            name: `orderer${ordererCounter}`,
            pw: `orderer${ordererCounter}pw`,
            host: peer.value,
            ...(peer.opts?.ordererPort && {
              port: Number(peer.opts.ordererPort),
            }),
            ...(peer.opts?.ordererOperationPort && {
              operationPort: Number(peer.opts.ordererOperationPort),
            }),
            ...(org?.hsm?.enabled && {
              hsm: {
                user: peer?.hsm?.orderer?.user,
                pin: peer?.hsm?.orderer?.pin,
              },
            }),
          };

          orderesrsInOrg.push(
            `orderer${ordererCounter}.${org.orgName}.${org.orgDomainName}`,
          );
          ordererCounter++;
        }
      });

      const peersNotOrderers = org.peers
        .filter((peer: IPeer) => peer.opts?.peer)
        .map(
          (peer, index) => `peer${index}.${org.orgName}.${org.orgDomainName}`,
        );

      // No orderer for org, search for other in the network
      if (orderesrsInOrg.length === 0) {
        const firstOrdererOrg = data.orgs.find((org: any) =>
          org.peers?.find((peer: any) => peer.opts?.orderer),
        );
        if (firstOrdererOrg) {
          orderesrsInOrg.push(
            `orderer0.${firstOrdererOrg.orgName}.${firstOrdererOrg.orgDomainName}`,
          );
        }
      }

      const ccapi = org.ccapi.map((cc) => {
        const tempCcapi = { ...cc };
        if (((tempCcapi?.restPort as unknown) as string) === '')
          delete tempCcapi.restPort;
        if (((tempCcapi?.TLSPort as unknown) as string) === '')
          delete tempCcapi.TLSPort;
        if (((tempCcapi?.goinitus?.port as unknown) as string) === '')
          delete tempCcapi?.goinitus?.port;

        tempCcapi.chaincodeName =
          tempCcapi.chaincodeName?.split?.('/')?.[1] || tempCcapi.chaincodeName;

        return tempCcapi;
      });

      organizations[org.orgName] = {
        name: org.orgName,
        domain: org.orgDomainName,
        netapi: {
          host: org.caIP,
          ...(org.netAPIPort && {
            port: Number(org.netAPIPort),
          }),
        },
        ca: {
          containerName: `ca_${org.orgName}`,
          username: org.user,
          password: org.passwd,
          host: org.caIP,
          ...(org?.caPort && { port: Number(org.caPort) }),
          ...(org?.caOperationPort && {
            operationPort: Number(org?.caOperationPort),
          }),
          ...(org?.hsm?.enabled && {
            hsm: {
              ...(org.caHSM.user && { user: org.caHSM.user }),
              ...(org.caHSM.pin && { pin: org.caHSM.pin }),
            },
          }),
        },
        peers: peersNotOrderers,
        orderers: orderesrsInOrg,
        ccapi,
        ...(org.prometheusPort && {
          prometheus: { port: Number(org.prometheusPort) },
        }),
        ...(org.grafanaPort && { grafana: { port: Number(org.grafanaPort) } }),
        ...(couchsInOrg?.length > 0 && { couchs: couchsInOrg }),
        ...(org?.hsm?.enabled && {
          hsm: org?.hsm,
        }),
      };
    });

    const definitions = {
      networkName: networkName || data.networkName,
      displayName: networkName || data.networkName,
      chaincodes,
      channels,
      organizations,
      peers: allPeers,
      orderers,
      ...(Object?.keys(couchs)?.length > 0 && { couchs }),
    };

    console.log('DEFINITIONS', definitions);

    formData.append('payload', JSON.stringify(definitions));
    formData.append('networkName', networkName || (data.networkName as string));

    return formData;
  } catch (error) {
    console.log('ERROR', error);
    throw error;
  }
};
