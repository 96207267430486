import styled from 'styled-components';

const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  column-gap: 1rem;
  /* grid-template-rows: 1fr 1fr 1fr; */
  background-color: #fafafa;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  padding: 2rem;
`;

const NodeBox = styled.div`
  background-color: #fafafa;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
`;

export { CustomGrid, NodeBox };
