import React from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';

import * as S from './styles';
import { ChangePassword } from './ChangePassword';
import { ManagePlan } from './ManagePlan';
import { ProfileForm } from './ProfileForm';

const Profile: React.FC = () => {
  const [value, setValue] = React.useState(1);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginRight="auto"
      style={{ minHeight: '90vh' }}
    >
      <S.Container>
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChangeTab}
        >
          <Tab label="Profile" />
          <Tab label="Manage plan" />
          <Tab label="Change password" />
        </Tabs>
        <S.Card>
          {value === 0 && <ProfileForm />}
          {value === 1 && <ManagePlan />}
          {value === 2 && <ChangePassword />}
        </S.Card>
      </S.Container>
    </Box>
  );
};

export default Profile;
