import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Web, Link as LinkIcon } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { useNetworks } from '../../../Contexts/Networks';
import * as S from '../styles';
import * as SChannel from './styles';
import { peersInChannelCounter } from '../utils';
import { ChaincodeSelect } from '../ChaincodeSelect';
import type { IPrevCorrectDates } from '../BlockView';
import BlockView from '../BlockView';
import TxCountChart from '../TxCountChart';

export const ChannelDashboard: React.FC = () => {
  const [currentChaincode, setCurrentChaincode] = useState('');
  const { selectedNetwork, selectedChannel } = useNetworks();
  const { networkState, setCurrentDashboardView } = useNetworks();
  const [callback, setCallback] = useState(false);
  const [searchBlocks, setSearchBlocks] = useState(true);
  const [startDateTime, setStartDateTime] = useState<Date | null>(null);
  const [endDateTime, setEndDateTime] = useState<Date | null>(null);
  const [explorerError, setExplorerError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!networkState?.chaincodes) return;
    setCurrentChaincode(networkState?.channels[selectedChannel]?.chaincodes[0]);
  }, [networkState, selectedChannel]);

  const [prevCorrectDates, setPrevCorrectDates] = useState<IPrevCorrectDates>({
    startDate: null,
    endDate: null,
  });

  const changeDatesToPrevious = useCallback(() => {
    setStartDateTime(prevCorrectDates.startDate);
    setEndDateTime(prevCorrectDates.endDate);
  }, [prevCorrectDates]);

  const changePrevCorrectDates = useCallback(
    (newCorrectDates: IPrevCorrectDates) => {
      setPrevCorrectDates(newCorrectDates);
    },
    [],
  );

  const renderBlockView = useCallback(() => {
    return (
      <BlockView
        endDate={endDateTime}
        hasError={explorerError}
        startDate={startDateTime}
        searchBlocks={searchBlocks}
        callback={() => setCallback(true)}
        network={selectedNetwork || ({} as INetwork)}
        channelName={selectedChannel}
        changeDatesToPrevious={changeDatesToPrevious}
        changePrevCorrectDates={changePrevCorrectDates}
        resetSearchBlocks={() => setSearchBlocks(false)}
      />
    );
  }, [
    endDateTime,
    explorerError,
    startDateTime,
    searchBlocks,
    selectedNetwork,
    selectedChannel,
    changeDatesToPrevious,
    changePrevCorrectDates,
  ]);
  if (!networkState) return null;

  return (
    <S.DashboardContainer>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="overline" style={{ fontSize: '25px' }}>
          {selectedChannel}
        </Typography>
        <ArrowBackIcon
          color="secondary"
          fontSize="large"
          style={{ cursor: 'pointer' }}
          onClick={() => setCurrentDashboardView('generalDashboard')}
        />
      </Box>

      <SChannel.ChannelInfo>
        <SChannel.BlockInfo>
          <Typography align="center" color="secondary" variant="h6">
            {peersInChannelCounter(
              selectedNetwork?.channels[selectedChannel]?.peers!,
            )}
          </Typography>

          <Typography variant="overline">
            {t('asset.dashboard.activePeers')}
          </Typography>
        </SChannel.BlockInfo>

        <SChannel.BlockInfo>
          <Typography align="center" color="secondary" variant="h6">
            {
              Object.entries(selectedNetwork?.channels[selectedChannel]?.peers!)
                ?.length
            }
          </Typography>

          <Typography variant="overline">Orgs</Typography>
        </SChannel.BlockInfo>

        <SChannel.BlockInfo>
          <Typography align="center" color="secondary" variant="h6">
            {Object?.keys(networkState?.orderers)?.length}
          </Typography>

          <Typography variant="overline">Orderers</Typography>
        </SChannel.BlockInfo>

        <SChannel.BlockInfo>
          <Typography variant="overline">Fabric Version</Typography>

          <Typography align="center" color="secondary" variant="h6">
            2.2
          </Typography>
        </SChannel.BlockInfo>

        <SChannel.ChaincodeBlockInfo>
          <Box
            display="flex"
            minWidth="280px"
            justifyContent="center"
            style={{ gap: '2rem' }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="overline">Chaincode</Typography>
              <ChaincodeSelect
                value={currentChaincode}
                onChange={(e) => setCurrentChaincode(e.target.value as string)}
                options={
                  networkState?.channels?.[selectedChannel]?.chaincodes || []
                }
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="overline">Version</Typography>
              <Typography variant="h6" style={{ color: 'var(--success)' }}>
                {networkState?.chaincodes?.[currentChaincode]?.version}
              </Typography>
            </Box>
          </Box>
        </SChannel.ChaincodeBlockInfo>
      </SChannel.ChannelInfo>

      <Box style={{ marginTop: '2rem' }}>
        <TxCountChart
          networkName={selectedNetwork?.networkName as string}
          chaincodeName={
            currentChaincode ||
            networkState?.channels[selectedChannel]?.chaincodes[0]
          }
        />
      </Box>
      <Grid container xs="auto" style={{ marginTop: '2rem', gap: '2rem' }}>
        {networkState?.channels?.[selectedChannel]?.peers &&
          Object.keys(networkState?.channels?.[selectedChannel]?.peers).map(
            (orgName) => {
              return (
                <S.OrgPaper>
                  <Typography style={{ fontWeight: 'bold', margin: '0 auto' }}>
                    {orgName}.{networkState?.organizations[orgName]?.domain}
                  </Typography>

                  <Box display="flex" style={{ gap: '0.3rem' }}>
                    <Typography style={{ fontWeight: 'bold' }}>ca:</Typography>
                    {networkState?.organizations[orgName]?.ca?.host}
                  </Box>

                  <Box display="flex" style={{ gap: '2rem' }}>
                    <Box display="flex" flexDirection="column">
                      {networkState?.organizations[orgName]?.orderers.map(
                        (orderer) => (
                          <Box display="flex" style={{ gap: '0.3rem' }}>
                            <li style={{ fontWeight: 'bold' }}>
                              {orderer.split('.')[0]}:
                            </li>
                            {networkState?.orderers[orderer]?.host}
                          </Box>
                        ),
                      )}
                    </Box>
                    <Box display="flex" flexDirection="column">
                      {networkState?.organizations[orgName]?.peers.map(
                        (peer) => (
                          <Box display="flex" style={{ gap: '0.3rem' }}>
                            <li style={{ fontWeight: 'bold' }}>
                              {peer.split('.')[0]}:
                            </li>
                            {networkState?.peers[peer]?.host}
                          </Box>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="top"
                    style={{ gap: '0.3rem' }}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      ccapi:
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      {networkState?.organizations[orgName]?.ccapi?.map((cc) =>
                        cc.channelName === selectedChannel &&
                        cc.chaincodeName === currentChaincode ? (
                          <Box display="flex" alignItems="center">
                            <Tooltip
                              title={`${t('asset.dashboard.accessApi')}`}
                            >
                              <a
                                href={`http://${cc.host}/api-docs`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p
                                  key={cc.host}
                                  style={{ margin: 0, marginRight: '5px' }}
                                >
                                  {cc.host}
                                </p>
                              </a>
                            </Tooltip>

                            <Tooltip
                              title={`${t('asset.dashboard.accessWeb')}`}
                            >
                              <a
                                href={`http://${cc.host}:8080`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Web />
                              </a>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Typography style={{ color: 'var(--lightText)' }}>
                            none
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<LinkIcon />}
                    style={{ marginTop: '30px' }}
                    onClick={() =>
                      window.open(
                        `http://${networkState?.organizations[orgName]?.ca?.host}:${networkState?.organizations[orgName]?.grafana?.port}`,
                      )
                    }
                  >
                    <Trans>button.enterGrafana</Trans>
                  </Button>
                </S.OrgPaper>
              );
            },
          )}
      </Grid>
      <Box marginTop="45px">
        <Typography variant="h5">Block Viewer</Typography>

        <Box display="flex" marginTop="2rem" height="500px">
          {renderBlockView()}
        </Box>
      </Box>
    </S.DashboardContainer>
  );
};
