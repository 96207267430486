import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

interface IRemovePeerNotificationsState extends Record<string, any> {
  notificationAttrs: INotificationAttrs;
  notification: CommonNotificationObject;
}

export const initialState: IRemovePeerNotificationsState = {
  notification: {},
  notificationAttrs: {} as INotificationAttrs,
};

const removePeerNotificationsSlice = createSlice({
  name: 'removePeerNotifications',
  initialState,
  reducers: {
    changeRemovePeerNotification(
      state,
      action: PayloadAction<CommonNotificationObject>,
    ) {
      state.notification = action.payload;

      if (Object.keys(state.notificationAttrs).length <= 0) {
        state.notificationAttrs = {
          mainLoad: 0,
          failed: false,
          fullyComplete: false,
        };
      }
    },
    changeRemovePeerNotificationAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      state.notificationAttrs = {
        ...state.notificationAttrs,
        ...action.payload,
      };
    },
    clearRemovePeerNotifications() {
      return initialState;
    },
  },
});

export const {
  changeRemovePeerNotification,
  clearRemovePeerNotifications,
  changeRemovePeerNotificationAttrs,
} = removePeerNotificationsSlice.actions;

export default removePeerNotificationsSlice.reducer;
