import styled, { css } from 'styled-components';

import { Close } from '@material-ui/icons';
import { List, Container, ListItem, Typography } from '@material-ui/core';

interface IListItemProps {
  selected: boolean;
  twocolumns: string;
}

interface IComponentContainerProps {
  twocolumns: string;
}

interface ITemplateListProps {
  maxheight: string;
}

export const ComponentContainer = styled.div<IComponentContainerProps>`
  max-width: 444px;
  position: relative;
  height: fit-content;
  margin-left: 20px !important;

  width: ${({ twocolumns }) =>
    twocolumns === 'true' ? '100%' : 'fit-content'};
`;

export const LabelContainer = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(Close)`
  && {
    width: 20px;
    height: 20px;
    margin-left: 16px;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

export const Label = styled(Typography)`
  color: #0000ee;

  &:hover {
    cursor: pointer;
  }
`;

export const OptionsContainer = styled(Container)`
  && {
    top: 30px;
    padding: 0;
    z-index: 10;
    outline: none;
    position: absolute;
    height: fit-content;
    transition: {
      duration: 300;
    }
  }
`;

export const TemplateList = styled(List).attrs({
  component: 'div',
  disablePadding: true,
})<ITemplateListProps>`
  display: flex;
  flex-flow: column wrap;
  max-height: ${({ maxheight }) =>
    maxheight !== 'false' ? `${maxheight}px` : '450px'};
`;

export const SelectedListItem = styled(ListItem).attrs({
  button: true,
})<IListItemProps>`
  && {
    margin: 0;
    height: 48px;
    max-width: ${({ twocolumns }) => (twocolumns === 'true' ? '50%' : '100%')};

    /* Selected CSS */
    ${({ selected }) =>
      selected &&
      css`
        color: var(--white);
        border-radius: 2px;
        background-color: var(--secondary);
      `}
  }
`;
