import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

export interface IChaincodeNotificationsState extends Record<string, any> {
  chaincode: CommonNotificationObject | {};
  chaincodeAttrs: INotificationAttrs;
}

export const initialState: IChaincodeNotificationsState = {
  chaincode: {},
  chaincodeAttrs: {} as INotificationAttrs,
};

const chaincodeNotificationsSlice = createSlice({
  name: 'chaincodeNotifications',
  initialState,
  reducers: {
    changeChaincodeNotification(
      state,
      action: PayloadAction<CommonNotificationObject>,
    ) {
      state.chaincode = action.payload;

      if (Object.keys(state.chaincodeAttrs).length <= 0) {
        state.chaincodeAttrs = {
          mainLoad: 0,
          failed: false,
          fullyComplete: false,
        };
      }
    },
    changeChaincodeAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      state.chaincodeAttrs = {
        ...state.chaincodeAttrs,
        ...action.payload,
      };
    },
    clearChaincodeNotifications() {
      return initialState;
    },
  },
});

export const {
  changeChaincodeAttrs,
  changeChaincodeNotification,
  clearChaincodeNotifications,
} = chaincodeNotificationsSlice.actions;

export default chaincodeNotificationsSlice.reducer;
