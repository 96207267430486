import { ElementType } from './types';

export const getElementsInfo = (elements: ElementType[]) => {
  let elementsNum = 0;
  let higherId = 0;

  const countRecursion = (els: ElementType[]) => {
    if (els === null) return;

    els.forEach((element: ElementType) => {
      if (element.id > higherId) higherId = element.id;

      if (element.elements) {
        elementsNum += 1;
        countRecursion(element.elements);
      } else {
        elementsNum += 1;
      }
    });
  };

  countRecursion(elements);

  return { elementsNum, higherId };
};
