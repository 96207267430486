import styled from 'styled-components';
import { DialogContentText } from '@material-ui/core';

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  flex: 1;
`;

export const ContentText = styled(DialogContentText)`
  && {
    margin: 20px auto 15px auto;
    text-align: center;
    max-width: 500px;
    font-size: 16px;
    white-space: pre-line;
  }
`;
