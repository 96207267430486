import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  padding: 24px 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const Card = styled(Paper)`
  && {
    width: fit-content;
    padding: 20px;
    display: flex;
    margin: 0 auto;
    max-width: 1000px;
    height: fit-content;
    flex-direction: column;
    background: whitesmoke;
    justify-content: space-between;

    @media (max-width: 1400px) {
      margin-bottom: 20px;
    }
  }
`;

export const fileInputCustomBtnStyle = {
  display: 'flex',
  height: '45px',
  width: '350px',
  marginRight: '10px',
};
