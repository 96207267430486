import styled from 'styled-components';
import { Paper, Stepper, Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
`;

export const VerifyInfoContainer = styled.div`
  width: 350px;
  margin-top: 10px;
`;

export const FillAuthContainer = styled.div`
  margin-bottom: 30px;
  width: ${(props: { threeColumns: boolean }) =>
    props.threeColumns ? '1200px' : '800px'};
`;

export const OrgsFormHeader = styled(Typography)`
  && {
    width: 700px;
    font-size: 18px;
    margin: 30px auto;
    text-align: center;
  }
`;

export const OrgsFormContanier = styled.div`
  display: flex;
  width: 100%;
  margin: 20px auto;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

export const OrgFormCard = styled(Paper)`
  width: 320px;
  margin: 10px;
  display: flex;
  padding: 20px;
  background: #fff;
  flex-direction: column;
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-between;
`;

export const Steps = styled(Stepper).attrs((props: { activeStep: number }) => ({
  activeStep: props.activeStep,
}))`
  && {
    padding: 0;
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
`;

export const StepContent = styled.div`
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
