import styled from 'styled-components';
import PreloadImage from 'react-preload-image';

interface IProps {
  imgLoaded: boolean;
}

export const ImgContainer = styled.div`
  width: 400px;
  height: 196px;
  position: relative;
  margin: ${(props: IProps) =>
    props.imgLoaded ? '0 auto' : '20px auto 0 auto'};
`;

export const GoledgerHomeImg = styled(PreloadImage).attrs({ lazy: true })`
  width: 400px;
  height: 196px;
  background-color: ${(props: IProps) => (props.imgLoaded ? '' : '#f7f7f7')};
`;
