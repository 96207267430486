import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Variant } from '@material-ui/core/styles/createTypography';
import { AppThunk } from '../../store';

export type DialogInfoType = 'error' | 'success' | 'question' | 'info';

export interface IDialogInfo extends Record<string, any> {
  title: string;
  type: DialogInfoType;
  useRefuseOnClose?: boolean;
  content: string | JSX.Element;
  typographyVariant?: Variant | 'inherit' | 'srOnly';
}

export interface IDialogState extends Record<string, any> {
  info: IDialogInfo;
  open: boolean;
}

interface IOpenDialogPayload extends IDialogInfo {
  refuseActionFunc?: null | Function;
  confirmActionFunc?: null | Function;
  unmountCallbackFunc?: null | Function;
}

let confirmActionFunc: null | Function = null;

let refuseActionFunc: null | Function = null;

let unmountCallbackFunc: null | Function = null;

const initialState: IDialogState = {
  info: {
    type: 'success',
    title: 'Título',
    content: 'Conteúdo conteúdo conteúdo conteúdo conteúdo conteúdo conteúdo',
    confirmAction: null,
    refuseAction: null,
    unmountCallback: null,
  },
  open: false,
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialogInfo(state, action: PayloadAction<IDialogInfo>) {
      state.open = true;
      state.info = action.payload;
    },
    closeDialog(state) {
      state.open = false;
      state.info = {} as IDialogInfo;
    },
  },
});

export const openDialog = (payload: IOpenDialogPayload): AppThunk => (
  dispatch,
) => {
  const {
    type,
    title,
    content,
    refuseAction,
    confirmAction,
    unmountCallback,
    useRefuseOnClose,
    typographyVariant,
  } = payload;

  if (confirmAction) confirmActionFunc = confirmAction;
  if (refuseAction) refuseActionFunc = refuseAction;
  if (unmountCallback) unmountCallbackFunc = unmountCallback;

  dispatch(
    dialogSlice.actions.setDialogInfo({
      type,
      title,
      content,
      useRefuseOnClose,
      typographyVariant,
    }),
  );
};

export const confirmAction = () => {
  if (confirmActionFunc) confirmActionFunc();
};

export const refuseAction = () => {
  if (refuseActionFunc) refuseActionFunc();
};

export const unmountCallback = () => {
  if (unmountCallbackFunc) unmountCallbackFunc();
};

export const { closeDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
