import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDeployForm } from '../../../Hooks/deploy';
import CCDescriptionModal from '../../CCDescriptionModal';
import { PreloadedCCContainer } from '../../Channel/styles';
import { ITemplateResponse } from '../../Template/RenderOption';
import Tooltip from '../../Tooltip';

type Operation =
  | 'startnetwork'
  | 'upgradechaincode'
  | 'addccapi'
  | 'upgradeapi';

interface IDeployPreloadOptionProps {
  loading: boolean;
  chaincode: IChaincodes | ChaincodesInNetwork[''];
  preLoadedChaincodes: ITemplateResponse;
  handleSelectFromCloud(ccName: string, item: ITemplateResponse['key']): void;
  operation: Operation;
}

export const DeployAwsBucketOption: React.FC<IDeployPreloadOptionProps> = ({
  loading,
  chaincode,
  preLoadedChaincodes,
  handleSelectFromCloud,
  operation,
}) => {
  const { t } = useTranslation();

  const { started } = useDeployForm();

  const [open, setOpen] = useState(false);

  console.log('CHAINCODA', chaincode);
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6">
          <Trans>common.chaincode.preLoadedChaincodes</Trans>
        </Typography>

        <Tooltip message={t('common.chaincode.chaincodeInfo')}>
          <IconButton
            onClick={() => setOpen(true)}
            className="prealoded-info-btn"
          >
            <Info
              color="primary"
              fontSize="large"
              style={{ width: '25px', height: '25px', cursor: 'pointer' }}
            />
          </IconButton>
        </Tooltip>
      </div>
      {loading ? (
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <PreloadedCCContainer className="preloaded-chaincodes-list">
          {Object.entries(preLoadedChaincodes).map(
            (item, idx: number) =>
              item[1].name && (
                <Button
                  key={idx}
                  component="span"
                  variant={
                    chaincode?.ccBaseName === item[0] ? 'contained' : 'outlined'
                  }
                  color={
                    chaincode?.ccBaseName === item[0] ? 'primary' : 'default'
                  }
                  style={{ margin: '5px 10px' }}
                  disabled={started || operation === 'upgradechaincode'}
                  onClick={() => {
                    handleSelectFromCloud(item[0], item[1]);
                  }}
                >
                  {item[1].name}
                </Button>
              ),
          )}
        </PreloadedCCContainer>
      )}
      <CCDescriptionModal
        opened={open}
        closeModal={() => setOpen(!open)}
        templates={preLoadedChaincodes}
      />
    </>
  );
};
