import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import { PlanCard } from '../../../AppComponents/PlanCard';
import { authAPI, networkApi } from '../../../Common/axios';
import { PlanCardSkeleton } from '../../../AppComponents/PlanCard/Skeleton';

export const ManagePlan = () => {
  const [currentPlan, setCurrentPlan] = useState<string | null>(null);

  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  useEffect(() => {
    const profile = async () => {
      setIsLoadingProfile(true);
      const res = await networkApi.get<GetProfileResponse>('/getprofile');
      setCurrentPlan(res?.data?.plantype);
      setIsLoadingProfile(false);
    };
    profile();
  }, []);

  const [isLoadingPlans, setIsLoadingPlans] = useState(true);
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);

  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setIsLoadingPlans(true);
        const response = await networkApi.get<Plan[]>('/plans');
        console.log('plans: ', response.data);
        setPlans(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingPlans(false);
      }
    };
    fetchPlans();
  }, []);

  const goToCustomerPortal = async () => {
    try {
      setIsLoadingStripe(true);
      const response = await authAPI.get<string>('/billing', {});

      console.log('response.data: ', response.data);

      window.location.href = response.data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStripe(false);
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Typography
        style={{ fontSize: '18px', textAlign: 'center' }}
        variant="overline"
      >
        Manage Plan
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="fit-content"
        margin="0 auto"
        style={{ gap: '1rem', marginBottom: '3rem' }}
      >
        <Typography
          color="textPrimary"
          style={{ maxWidth: '30ch', textAlign: 'center' }}
        >
          Manage your subscriptions, invoices and payment methods
        </Typography>

        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="secondary"
          endIcon={!isLoadingStripe ? <LaunchRoundedIcon /> : null}
          onClick={goToCustomerPortal}
          style={{
            fontWeight: 600,
          }}
          disabled={isLoadingStripe}
        >
          {!isLoadingStripe ? (
            'My Billing'
          ) : (
            <CircularProgress color="secondary" size={26.25} />
          )}
        </Button>
      </Box>

      {!currentPlan && !isLoadingProfile && (
        <Typography
          style={{
            fontSize: '18px',
            textAlign: 'center',
          }}
          variant="overline"
        >
          Choose a Plan
        </Typography>
      )}

      <Box
        marginTop={1}
        style={{
          display: 'grid',
          gridGap: '0.5rem',
          gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 280px))',
          gridAutoRows: 'max-content',
          justifyContent: 'center',
        }}
      >
        {isLoadingPlans || isLoadingProfile ? (
          <>
            <PlanCardSkeleton />
            <PlanCardSkeleton />
            <PlanCardSkeleton />
          </>
        ) : (
          plans
            ?.filter(
              (plan) =>
                plan.planName === currentPlan ||
                currentPlan === 'NoPlan' ||
                currentPlan === 'TrialDeveloper',
            )
            ?.map((plan) => (
              <PlanCard
                key={plan.planId}
                {...plan}
                isSubscribed={currentPlan === plan.planName}
              />
            ))
        )}
      </Box>
    </Box>
  );
};
