import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Container, ListItem, ListItemText } from '@material-ui/core';

import history from '../../../history';

import { formatDate } from '../../../utils/date';
import { useOPHistory } from '../../../Contexts/OPHistory';

type ReportListProps = React.FC<{
  RemoveIcon: JSX.Element;
  //   removeReport(reportId: string): void;
}>;

const ReportList: ReportListProps = ({
  RemoveIcon,
  //   removeReport,
}) => {
  const { t } = useTranslation();

  const getItemMessage = useCallback(
    (item: OPHistory) => {
      const { operation, success, message } = item;
      const successOrError = success ? 'success' : 'error';

      if (message) return Object.keys(message?.TaskMap)?.[0];

      return t(`common.reports.${successOrError}.${operation}`);
    },
    [t],
  );

  const { operationsHistory } = useOPHistory();

  const renderReports = useCallback(() => {
    return operationsHistory?.map((item, idx) => (
      <ListItem
        divider
        key={`${t('common.words.system')} - ${formatDate(
          item.date,
          true,
        )} - ${getItemMessage(item)}-${idx}`}
        button={(Object.keys(item.message?.TaskMap).length > 0) as any}
      >
        <ListItemText
          primary={`${t('common.words.system')} - ${formatDate(
            item.date,
            true,
          )}`}
          secondary={getItemMessage(item)}
          onClick={() => {
            if (Object.keys(item.message).length > 0)
              history.push(`/report/session/${item._id}`);
          }}
        />
        <Container
          //   onClick={() => removeReport(item.id)}
          style={{ padding: 0, width: 'fit-content' }}
        >
          {RemoveIcon}
        </Container>
      </ListItem>
    ));
  }, [operationsHistory, t, getItemMessage, RemoveIcon]);

  return <div style={{ width: '100%' }}>{renderReports()}</div>;
};

export default ReportList;
