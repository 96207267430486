import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import FillingCard from '../components/Card';
import { mountCardAttributes } from '../utils';
import { changeUpgradeCCApiAttrs } from '../../../store/UpgradeCCApiNotifications';

import { StoreState } from '../../../store/types';
import { ICardInfo, ILoadingContainerCardInfo } from '../types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const {
    loadingInfo,
    loadingInfoAttrs,
  } = state.upgradeCCApiNotificationsState;

  const { title, canShow, running, attributes, notLoadingWidth } = cardInfo;

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeUpgradeCCApiAttrs(params));
  };

  const newCardInfo = {
    canShow,
    running,
    changeNotification,
    notificationAttrs: loadingInfoAttrs,
    loadingInfo,
    notLoadingWidth,
    attributes: mountCardAttributes(attributes || {}, t),
    loadingStarted: Object.keys(loadingInfo).length > 0,
  };

  return <FillingCard cardInfo={{ ...newCardInfo, title } as ICardInfo} />;
};
