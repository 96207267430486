import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Network } from 'vis-network';

import { Slider, CircularProgress, LinearProgress } from '@material-ui/core';
import { Warning, ArrowBack, ArrowForward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { isEqual, max } from 'lodash';
import { networkApi } from '../../../Common/axios';

import {
  Main,
  Container,
  SliderLabel,
  ArrowIconBtn,
  WarnContainer,
  SliderContainer,
  LoadingContainer,
  SliderControlContainer,
  SingleBlockInfoContainer,
} from './styles';
import { openDialog } from '../../../store/Dialog';

import Tooltip from '../../../AppComponents/Tooltip';
import SingleBlockInfo, {
  IBlockInfo,
  IBlockInfoResponse,
} from './SingleBlockInfo/SingleBlockInfo';
import { Colors } from '../../../styles/globals';
import { useInterval } from '../../../Hooks/useInterval';

interface IEdgeInfo {
  from: number;
  to: number;
  arrows: string;
}

interface IRequestBlockData {
  edges: IEdgeInfo[];
  nodes: IBlockInfo[];
}
export interface IPrevCorrectDates {
  startDate: Date | null;
  endDate: Date | null;
}

interface IBlockViewProps {
  callback(): void;
  network: INetwork;
  channelName: string;
  hasError: boolean;
  endDate: Date | null;
  searchBlocks: boolean;
  startDate: Date | null;
  resetSearchBlocks(): void;
  changeDatesToPrevious(): void;
  changePrevCorrectDates(newCorrectDates: IPrevCorrectDates): void;
}

// some constants to make changes easy
const ARROW_HOLD_MOD = 5;
const MAX_BLOCKS_NUM = 20;
const MAX_VISIBLE_BLOCKS_NUM = 50;
const MAX_VISIBLE_RANGE_CHANGE = 10;
const POLLING_TIME = 60000;

// vis network constructor options
const networkOptions = {
  nodes: {
    shapeProperties: { borderRadius: 50, useBorderWithImage: true },
    shape: 'square',
    color: {
      border: 'var(--primaryDark)',
      background: 'var(--primary)',
    },
  },
  physics: {
    forceAtlas2Based: {
      gravitationalConstant: -30,
      centralGravity: 0.01,
      springConstant: 0.2,
      springLength: 0,
      damping: 0.6,
      avoidOverlap: 0,
    },
    solver: 'forceAtlas2Based',
  },
  edges: {
    length: 45,
  },
};

const BlockView: React.FC<IBlockViewProps> = ({
  endDate,
  network,
  channelName,
  callback,
  hasError,
  startDate,
  searchBlocks,
  resetSearchBlocks,
  changeDatesToPrevious,
  changePrevCorrectDates,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const arrowHoldInterval = useRef<number>(0);
  const netRef = useRef<Network | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [info, setInfo] = useState({});
  // count arrow hold time
  const [holdCount, setHoldCount] = useState(0);
  const [marks, setMarks] = useState<
    { value: number; label?: string | JSX.Element }[]
  >([]);
  const [loading, setLoading] = useState(false);
  // it represents the range of blocks shown. Smaller or equal to MAX_BLOCKS_NUM
  const [blocksRange, setBlocksRange] = useState([0, 0]);
  // it represents the range of visible blocks
  // it goes from 0 to allNodes.current.length - 1
  const [visibleRange, setVisibleRange] = useState([0, 0]);

  // all blocks returned from the requestBlocks
  // and their edges will be stored in those refs
  const allNodes = useRef<IBlockInfo[]>([]);
  const allEdges = useRef<IEdgeInfo[]>([]);

  // set a network using received data
  const setVisNetwork = useCallback((data: IRequestBlockData) => {
    // const net = new Network(
    //   ref.current as HTMLDivElement,
    //   data,
    //   networkOptions,
    // );
    // const start = data.nodes.findIndex((node) => node.id === blocksRange[0]);
    // const end =
    //   blocksRange[1] === 0
    //     ? data.nodes.length - 1
    //     : data.nodes.findIndex((node) => node.id === blocksRange[1]);
    // if (netRef.current) {
    //   netRef.current.setData({
    //     nodes: data.nodes.slice(start, end + 1)?.reverse(),
    //     edges: data.edges.slice(start, end + 1)?.reverse(),
    //   });
    //   // netRef.current.fit();
    // }
    if (netRef.current) {
      netRef.current.setData(data);
    }
  }, []);

  const getMarkersInBetween = (size: number, startNum: number) =>
    [...Array(size).keys()].map((item) => ({
      value: startNum + item,
    }));

  const getFinalMarkLabel = (maxNum: number) =>
    allNodes.current.length > MAX_VISIBLE_BLOCKS_NUM ? (
      <SliderLabel>
        {`${maxNum} (${t('common.words.max')} ${
          allNodes.current[allNodes.current.length - 1].id
        })`}
      </SliderLabel>
    ) : (
      `${maxNum}`
    );

  const getMarksByRange = (first: number, last: number) => [
    { value: first, label: `${first}` },
    ...getMarkersInBetween(last - first - 1, first + 1),
    { value: last, label: getFinalMarkLabel(last) },
  ];

  const setFirstRequestBlocksMarkers = (newNodes: IBlockInfo[]) => {
    const lastBlockOnRange = newNodes[newNodes.length - 1];
    // setMarks(getMarksByRange(newNodes[0].id, lastBlockOnRange.id));
    setMarks(getMarksByRange(newNodes[0].id, lastBlockOnRange.id));
  };

  // returns true if the blocks were sliced
  const checkBlocksNumber = (newNodes: IBlockInfo[], newEdges: IEdgeInfo[]) => {
    // set all blocks returned from the request
    allNodes.current = newNodes;
    allEdges.current = newEdges;
    // if the number of blocks is greater than MAX_BLOCKS_NUM
    if (newNodes.length > MAX_BLOCKS_NUM) {
      setBlocksRange([newNodes[0].id, newNodes[MAX_BLOCKS_NUM - 1].id]);

      // if the number of blocks is greater than MAX_VISIBLE_BLOCKS_NUM
      // we should cut it to fit in the visible range
      if (newNodes.length > MAX_VISIBLE_BLOCKS_NUM) {
        const lastBlockOnVisibleRange = newNodes[MAX_VISIBLE_BLOCKS_NUM - 1];

        setVisibleRange([newNodes[0].id, lastBlockOnVisibleRange.id]);
        setFirstRequestBlocksMarkers(
          newNodes.slice(newNodes[0].id, MAX_VISIBLE_BLOCKS_NUM),
        );
      } else {
        setVisibleRange([newNodes[0].id, newNodes[newNodes.length - 1].id]);
        setFirstRequestBlocksMarkers(newNodes);
      }

      return true;
    }

    setVisibleRange([newNodes[0].id, newNodes[newNodes.length - 1].id]);
    setFirstRequestBlocksMarkers(newNodes);

    return false;
  };

  const requestBlocks = useCallback(
    (): Promise<IRequestBlockData> => {
      return new Promise((resolve, reject) => {
        if (
          // both null if the user just entered the page
          (startDate === null && endDate === null) ||
          (startDate && endDate)
        ) {
          // check if range is valid (startDate <= endDate)
          if (
            startDate instanceof Date &&
            endDate instanceof Date &&
            startDate >= endDate
          )
            reject(Error(t('asset.dashboard.invalidDateRange')));

          setInfo({});
          setLoading(true);

          networkApi
            .get<IBlockInfoResponse[]>('/explore/blocks', {
              params: {
                // endDate,
                // beginDate: startDate,
                netName: network.networkName,
                // timeZone: getLocalUTCOffset(),
                channelName,
              },
            })
            .then((response) => {
              // if the response is invalid or empty
              if (!response.data || response.data.length <= 0) {
                reject(t('asset.dashboard.noBlocksOnRange'));
              } else {
                const newNodes: IBlockInfo[] = response.data.map(
                  (blockInfo) => {
                    const { header } = blockInfo;
                    const { number } = header;

                    return {
                      size: 15,
                      shape: 'image',
                      id: parseInt(number),
                      hash: blockInfo.header.data_hash,
                      block: blockInfo,
                      image: `${process.env.PUBLIC_URL}/chain.svg`,
                      color: number === '0' ? Colors.secondary : Colors.primary,
                      label:
                        number === '0'
                          ? `${t('asset.dashboard.genesisBlock')}`
                          : `${t('common.words.block')} #${number}`,
                    };
                  },
                );

                const newEdges: IEdgeInfo[] = [];
                newNodes.forEach((node) => {
                  const { id } = node;

                  if (id !== newNodes[newNodes.length - 1].id) {
                    newEdges.push({ from: id, to: id + 1, arrows: 'to' });
                  }
                });

                // if (netRef.current) netRef.current.fit();

                callback();
                changePrevCorrectDates({ startDate, endDate });
                // if the blocks were sliced
                if (checkBlocksNumber(newNodes, newEdges)) {
                  // return only the MAX_BLOCKS_NUM number of blocks
                  resolve({
                    nodes: newNodes.slice(0, MAX_BLOCKS_NUM),
                    edges: newEdges.slice(0, MAX_BLOCKS_NUM),
                  });
                } else {
                  resolve({ nodes: newNodes, edges: newEdges });
                }
              }
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, dispatch, network, callback],
  );

  const changeBlocksRange = (newValue: number[]) => {
    setBlocksRange(newValue);

    const start = allNodes.current.findIndex((item) => item.id === newValue[0]);
    const end = allNodes.current.findIndex((item) => item.id === newValue[1]);

    netRef.current.setData({
      nodes: allNodes.current.slice(start, end + 1),
      edges: allEdges.current.slice(start, end + 1),
    });

    // netRef.current.fit();
  };

  const changeMarkers = (newVisibleRange: number[]) => {
    const firstBlockOnRange = allNodes.current[newVisibleRange[0]];
    const lastBlockOnRange = allNodes.current[newVisibleRange[1]];

    setMarks(getMarksByRange(firstBlockOnRange.id, lastBlockOnRange.id));
  };

  const updateBlocksRangeWithVisibleChange = (newVisibleRange: number[]) => {
    const newBlocksRange = [...blocksRange];

    if (blocksRange[0] < newVisibleRange[0]) {
      newBlocksRange[0] = newVisibleRange[0] + 0;

      newBlocksRange[1] = newVisibleRange[0] + blocksRange[1] - blocksRange[0];
    }
    if (blocksRange[1] > newVisibleRange[1]) {
      newBlocksRange[1] = newVisibleRange[1] + 0;

      if (newBlocksRange[1] - newBlocksRange[0] + 1 < 20) {
        newBlocksRange[0] = newVisibleRange[1] - MAX_BLOCKS_NUM + 1;
      }
    }

    changeBlocksRange(newBlocksRange);
  };

  // direction = 1 is forward, direction = -1 is backward
  const handleVisibleRangeChange = (direction = 1, mod = 1) => {
    const shift = direction * MAX_VISIBLE_RANGE_CHANGE * mod;
    let newVisibleRange = visibleRange.map((item) => item + shift);

    if (newVisibleRange[0] < 0) {
      newVisibleRange[0] = 0;

      if (newVisibleRange[0] <= 0)
        newVisibleRange[1] = MAX_VISIBLE_BLOCKS_NUM - 1;
    }

    if (newVisibleRange[1] > allNodes.current.length - 1) {
      visibleRange[1] = allNodes.current.length - 1;
    }

    if (
      newVisibleRange[0] > allNodes.current.length - 1 ||
      newVisibleRange[1] > allNodes.current.length - 1
    ) {
      newVisibleRange = [
        allNodes.current.length - MAX_VISIBLE_BLOCKS_NUM,
        allNodes.current.length - 1,
      ];
    }

    setVisibleRange(newVisibleRange);
    changeMarkers(newVisibleRange);
    updateBlocksRangeWithVisibleChange(newVisibleRange);
  };

  useEffect(() => {
    if (ref.current && !netRef.current) {
      netRef.current = new Network(
        ref.current as HTMLDivElement,
        { edges: [], nodes: [] },
        networkOptions,
      );
      const setClickedNode = (params) => {
        const ids = params.nodes as number[];

        if (ids) {
          const clickedNode = allNodes.current.find(
            (item: IBlockInfo) => item.id === ids[0],
          );

          if (clickedNode) setInfo(clickedNode);
          else setInfo({});
        }
      };
      netRef.current.on('click', setClickedNode);
      netRef.current.on('dragStart', setClickedNode);
    }
    requestBlocks()
      .then((data) => {
        const nodeLength = allNodes.current.length - 1;

        const newVisibleBlocks = [
          Math.max(0, nodeLength - MAX_BLOCKS_NUM),
          nodeLength,
        ];

        const newVisibleRange = [
          Math.max(0, nodeLength - MAX_VISIBLE_BLOCKS_NUM),
          nodeLength,
        ];
        setVisibleRange(newVisibleRange);
        changeMarkers(newVisibleRange);
        updateBlocksRangeWithVisibleChange(newVisibleBlocks);

        // setVisNetwork(data);
        changeBlocksRange(newVisibleBlocks);
      })
      .catch((error) => {
        changeDatesToPrevious();

        dispatch(
          openDialog({
            type: 'error',
            // for some reason the noBlocksOnRange error msg does not come from message key
            content: error.message || error,
            title: t('common.words.error'),
          }),
        );
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocalUTCOffset = () => {
    const offsetMinutes = new Date().getTimezoneOffset();

    const minutes = offsetMinutes % 60;

    const hours = (offsetMinutes - minutes) / 60;

    return `UTC${offsetMinutes < 0 ? '-' : '+'}${
      hours.toString().length < 2 ? `0${hours}` : hours
    }${minutes < 10 ? '0' : ''}${minutes}`;
  };

  const handleArrowBtnMouseDown = (isDisabled: boolean) => {
    if (!isDisabled) {
      arrowHoldInterval.current = setInterval(() => {
        setHoldCount((prev) => prev + 1);
      }, 250);
    }
  };

  const handleArrowBtnMouseUp = (isDisabled: boolean, direction = 1) => {
    if (!isDisabled) {
      // if the button was clicked and not holded
      if (holdCount <= 0) {
        clearInterval(arrowHoldInterval.current);
        arrowHoldInterval.current = 0;
        setHoldCount(0);
        handleVisibleRangeChange(direction);
      } else {
        clearInterval(arrowHoldInterval.current);
        arrowHoldInterval.current = 0;
        handleVisibleRangeChange(direction, holdCount * ARROW_HOLD_MOD);
        setHoldCount(0);
      }
    }
  };

  const handleSliderChange = (newRange: number[]) => {
    if (newRange[1] - newRange[0] + 1 > MAX_BLOCKS_NUM) {
      if (blocksRange[0] === newRange[0] && newRange[1] > blocksRange[1]) {
        const diff = newRange[1] - blocksRange[1];
        changeBlocksRange([blocksRange[0] + diff, newRange[1]]);
        return;
      }

      if (blocksRange[1] === newRange[1] && blocksRange[0] > newRange[0]) {
        const diff = blocksRange[0] - newRange[0];
        changeBlocksRange([newRange[0], blocksRange[1] - diff]);
      }
    } else {
      changeBlocksRange(newRange);
    }
  };

  const isLeftArrowDisabled = useCallback(
    () => loading || visibleRange[0] <= 0,
    [loading, visibleRange],
  );

  const isRightArrowDisabled = useCallback(
    () => loading || visibleRange[1] >= allNodes.current.length - 1,
    [loading, visibleRange],
  );

  const areBlocksSpliced = useCallback(
    () => allNodes.current.length > MAX_VISIBLE_BLOCKS_NUM,
    // allNodes.current cannot be added so...
    // eslint-disable-next-line
    [startDate, endDate],
  );

  // if the component unmount the arrow hold interval must be cleared
  useEffect(() => {
    return () => {
      clearInterval(arrowHoldInterval.current);
    };
  }, []);

  useEffect(() => {
    // to prevent count to be counted forever
    // if it passes 30 seconds we will stop it
    if (holdCount >= 120) {
      clearInterval(arrowHoldInterval.current);
      arrowHoldInterval.current = 0;
      setHoldCount(0);
    }
  }, [holdCount]);
  const [pausePolling, setPausePolling] = useState(false);
  useInterval(
    () => {
      requestBlocks()
        .then(() => {
          const nodeLength = allNodes.current.length - 1;

          const newVisibleBlocks = [
            Math.max(0, nodeLength - MAX_BLOCKS_NUM),
            nodeLength,
          ];

          const newVisibleRange = [
            Math.max(0, nodeLength - MAX_VISIBLE_BLOCKS_NUM),
            nodeLength,
          ];
          setVisibleRange(newVisibleRange);
          changeMarkers(newVisibleRange);
          updateBlocksRangeWithVisibleChange(newVisibleBlocks);

          // setVisNetwork(data);
          changeBlocksRange(newVisibleBlocks);
        })
        .catch(() => {
          changeDatesToPrevious();

          // dispatch(
          //   openDialog({
          //     type: 'error',
          //     // for some reason the noBlocksOnRange error msg does not come from message key
          //     content: error.message || error,
          //     title: t('common.words.error'),
          //   }),
          // );
        });
    },
    !pausePolling ? POLLING_TIME : null,
  );

  return (
    <Container>
      {hasError && (
        <WarnContainer>
          <Tooltip message={t('asset.dashboard.networkError')}>
            <Warning />
          </Tooltip>
        </WarnContainer>
      )}

      <SingleBlockInfoContainer hasError={hasError} isLoading={false}>
        {info ? <SingleBlockInfo info={info as IBlockInfo} /> : null}
      </SingleBlockInfoContainer>

      <SliderContainer hasError={hasError} isLoading={false}>
        <SliderControlContainer>
          {/*
            limit movement arrows will only be available when
            blocks number is greater than visible blocks limit
          */}
          {areBlocksSpliced() ? (
            <Tooltip
              message={t('asset.dashboard.holdToMoveMore')}
              canShow={
                !isLeftArrowDisabled() && arrowHoldInterval.current === 0
              }
            >
              <ArrowIconBtn
                color="secondary"
                isdisabled={`${isLeftArrowDisabled()}`}
                onMouseDown={() => {
                  setPausePolling(true);
                  handleArrowBtnMouseDown(isLeftArrowDisabled());
                }}
                onMouseUp={() => {
                  setPausePolling(false);
                  handleArrowBtnMouseUp(isLeftArrowDisabled(), -1);
                }}
              >
                <ArrowBack style={{ width: '40px', height: '40px' }} />
              </ArrowIconBtn>
            </Tooltip>
          ) : null}

          <Slider
            marks={marks}
            disabled={loading}
            value={blocksRange}
            min={visibleRange[0]}
            valueLabelDisplay="on"
            max={visibleRange[1]}
            style={{ margin: '0 10px' }}
            aria-labelledby="range-slider"
            onChangeCommitted={(_, v: number | number[]) =>
              handleSliderChange(v as number[])
            }
            onMouseDown={() => setPausePolling(true)}
            onMouseUp={() => setPausePolling(false)}
          />

          {/*
            limit movement arrows will only be available when
            blocks number is greater than visible blocks limit
          */}
          {areBlocksSpliced() ? (
            <Tooltip
              message={t('asset.dashboard.holdToMoveMore')}
              canShow={
                !isRightArrowDisabled() && arrowHoldInterval.current === 0
              }
            >
              <ArrowIconBtn
                color="primary"
                isdisabled={`${isRightArrowDisabled()}`}
                onMouseDown={() => {
                  setPausePolling(true);
                  handleArrowBtnMouseDown(isRightArrowDisabled());
                }}
                onMouseUp={() => {
                  setPausePolling(false);
                  handleArrowBtnMouseUp(isRightArrowDisabled());
                }}
              >
                <ArrowForward style={{ width: '40px', height: '40px' }} />
              </ArrowIconBtn>
            </Tooltip>
          ) : null}
        </SliderControlContainer>
      </SliderContainer>
      <Main ref={ref} hasError={hasError} />
      {loading && <LinearProgress />}
    </Container>
  );
};
export default BlockView;
