import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { mountCardAttributes } from '../utils';
import FillingCard from '../components/Card';
import GlobalFillingCard from '../components/GlobalCard';
import { changeRestoreNotificationAttrs } from '../../../store/BackupRestoreNotifications';

import { INotificationAttrs, ILoadingContainerCardInfo } from '../types';
import { StoreState, WithOrgNotificationObject } from '../../../store/types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const { restore, restoreAttrs } = state.backupRestoreNotificationsState;
  const { title, attributes, running, notLoadingWidth } = cardInfo;
  const { networkName, channelName } = attributes || {};

  const globalCardInfo = {
    title,
    running,
    notLoadingWidth,
    canShow: networkName && channelName,
    attributes: mountCardAttributes(attributes || {}, t),
  };

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeRestoreNotificationAttrs(params));
  };

  return (
    <>
      <GlobalFillingCard cardInfo={globalCardInfo} />

      {restore.map((orgNotification: WithOrgNotificationObject) => (
        <FillingCard
          key={orgNotification.orgName}
          cardInfo={{
            running,
            attributes: {},
            changeNotification,
            notificationAttrs:
              restoreAttrs.find(
                (org) => org.orgName === orgNotification.orgName,
              ) || ({} as INotificationAttrs),
            title: orgNotification.orgName,
            loadingInfo: orgNotification,
            canShow: Object.keys(orgNotification).length > 0,
            loadingStarted: Object.keys(orgNotification).length > 0,
          }}
        />
      ))}
    </>
  );
};
