import styled from 'styled-components';
import { Delete } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  padding: 30px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const NoOperation = styled(Typography).attrs({ variant: 'h6' })`
  && {
    margin-top: 30px;
    font-weight: 300;
    color: var(--lightText);
  }
`;

export const RemoveIcon = styled(Delete).attrs({ color: 'secondary' })`
  && {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;
