import { IDialogInfo } from '../../store/Dialog';
import { DeployContextData as IDeployStartState } from '../../Hooks/deploy';
import { ipPatt, fqdnPatt, channelNamePatt } from '../../utils/regexPatterns';

// True if the string is invalid
const isStrInvalid = (value: string) =>
  !value || value === '' || value.indexOf(' ') >= 0;

const hasDuplicatedElements = (
  array: any[],
  key = '',
): { idx1: number; idx2: number } | false => {
  const uniques: string[] = [];

  // using a for loop because I need to return on the function context
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    const arrayEl = typeof el === 'object' ? el[key] : el;

    const index = uniques.findIndex((element) =>
      typeof element === 'object'
        ? element[key] === arrayEl
        : element === arrayEl,
    );

    if (index >= 0) return { idx1: index, idx2: i };

    uniques.push(arrayEl);
  }

  return false;
};

export const validateChannels = (
  channels: Channel[],
  openDialog: (p: IDialogInfo) => void,
  t: (a: string, b?: object) => string,
) => {
  const duplicatedChannel = hasDuplicatedElements(channels, 'channelName');
  const invalidChannelName = channels.some(
    (mappedChannel) => !mappedChannel.channelName,
  );

  if (invalidChannelName) {
    openDialog({
      title: 'Invalid channel name',
      type: 'error',
      content: 'Some of the channel names are invalid',
    });
    return false;
  }
  if (duplicatedChannel) {
    const { idx1, idx2 } = duplicatedChannel;
    const { channelName } = channels[idx1];
    const channelNameToDelete = channels[idx2].channelName;

    openDialog({
      title: t('deploy.validation.channel.duplicated'),
      type: 'error',
      content: t('deploy.validation.channel.duplicated.content', {
        channelName,
        channelNameToDelete,
      }),
    });

    return false;
  }

  // channels' peers must not be empty
  const emptyPeers = channels.find(
    (channel) => Object?.keys(channel?.peers)?.length === 0,
  );
  if (emptyPeers) {
    const { channelName } = emptyPeers;

    openDialog({
      title: t('deploy.validation.channel.emptyPeers'),
      type: 'error',
      content: t('deploy.validation.channel.emptyPeers.content', {
        channelName,
      }),
    });
    return false;
  }

  return true;
};

// organizations definitions checking
export const ValidateOrgs = (
  deployStart: IDeployStartState,
  networks: INetwork[] | null,
  openDialog: (p: IDialogInfo) => void,
  t: (a: string, b?: object) => string,
) => {
  try {
    console.log('validadte');
    // Checking for duplicated CA IPs
    // let idxs = hasDuplicatedElements(deployStart.orgs, 'caIP');
    if (!deployStart?.networkName)
      throw Error(t('asset.network.deploy.messages.invalidNetworkName'));

    const existsNetwork = networks?.find?.(
      (net) => net?.networkName === deployStart?.networkName,
    );
    if (existsNetwork)
      throw Error(t('asset.network.deploy.messages.networkNameUsed'));

    // Checking for duplicated peers, orderers, ccapis
    const ips: string[] = [];

    deployStart.orgs.forEach((org) => {
      org.peers.forEach((peer) => {
        ips.push(peer.value);
      });
    });

    // idxs = hasDuplicatedElements(ips);

    // if (idxs !== false) {
    //   throw Error(t('asset.network.deploy.messages.duplicatedNodes'));
    // }

    // Org checking
    const checkOrgs = deployStart.orgs.map((org: IOrg) => {
      const { caIP, user, passwd, orgName, orgDomainName, confirmPasswd } = org;

      const isOrgInvalid =
        isStrInvalid(user) ||
        isStrInvalid(passwd) ||
        isStrInvalid(caIP) ||
        passwd !== confirmPasswd ||
        !(ipPatt.test(caIP) || fqdnPatt.test(caIP)) ||
        isStrInvalid(orgName) ||
        orgName.indexOf(' ') >= 0 ||
        isStrInvalid(orgDomainName) ||
        orgDomainName.indexOf(' ') >= 0;
      if (isOrgInvalid) return false;
      return true;
    });
    if (checkOrgs.includes(false))
      throw Error(t('asset.network.deploy.messages.orgNonFilledProperly'));

    if (deployStart.orgs.length === 0) {
      throw Error(t('asset.network.deploy.messages.noFilledOrgs'));
    }

    const checkedOrgsPeers = deployStart.orgs.map(
      (org) => org.peers.length !== 0,
    );
    if (checkedOrgsPeers.includes(false))
      throw Error(t('asset.network.deploy.messages.orgsWithoutPeers'));

    const checkForPeers: Record<string, boolean[]> = {};
    const checkForOrderers: Record<string, boolean[]> = {};

    deployStart.orgs.forEach((org: IOrg) => {
      checkForOrderers[org.orgName] = [];
      checkForPeers[org.orgName] = [];

      org.peers.forEach((peer) => {
        const valid: boolean =
          (peer.value !== '' && peer.valid && ipPatt.test(peer.value)) ||
          fqdnPatt.test(peer.value);

        checkForPeers[org.orgName].push(valid && peer.opts.peer);
        checkForOrderers[org.orgName].push(valid && peer.opts.orderer);
      });
    });

    // Check if there's at least one orderer and one peer by org
    const checkedPeers: Record<string, boolean> = {};
    const checkedOrderers: Record<string, boolean> = {};
    deployStart.orgs.forEach((org) => {
      checkedOrderers[org.orgName] = checkForOrderers[org.orgName].includes(
        true,
      );
      checkedPeers[org.orgName] = checkForPeers[org.orgName].includes(true);
    });

    deployStart.orgs.forEach((org) => {
      // if (!checkedPeers[org.orgName]) {
      //   throw Error(
      //     t('asset.network.deploy.messages.atLeastOnePeer', {
      //       orgName: org.orgName,
      //     }),
      //   );
      // }
      // if (!checkedOrderers[org.orgName]) {
      //  throw Error(
      //   t('asset.network.deploy.messages.atLeastOneOrderer', {
      //     orgName: org.orgName,
      //   }),
      // );
      // }
    });
  } catch (error) {
    openDialog({
      title: t('common.words.error'),
      type: 'error',
      content: error.message,
    });
    return false;
  }

  return true;
};

// network definitions checkings
export const ValidateNetDefs = (
  deployStart: IDeployStartState,
  openDialog: (p: IDialogInfo) => void,
  t: (p: string) => string,
) => {
  try {
    const {
      template,
      chaincode,
      channelName,
      networkName,
      usingTemplate,
      usingCloudChaincode,
    } = deployStart;

    if (isStrInvalid(networkName))
      throw Error(t('common.messages.invalidNetName'));

    if (!channelName.length || !channelNamePatt.test(channelName))
      throw Error(t('common.messages.invalidChannelName'));

    if (
      (usingTemplate || usingCloudChaincode) &&
      isStrInvalid(chaincode.chaincodeName)
    )
      throw Error(t('common.messages.noChaincodeSelected'));

    if (!usingTemplate && !usingCloudChaincode) {
      const chaincodeInvalid =
        chaincode.chaincodeFile === null ||
        isStrInvalid(chaincode.chaincodeName);

      if (chaincodeInvalid)
        throw Error(t('common.messages.checkChaincodeInfo'));
    }

    if (!template && usingTemplate)
      throw Error(t('asset.network.deploy.messages.noTemplateSelected'));
  } catch (error) {
    openDialog({
      title: t('common.words.error'),
      type: 'error',
      content: error.message,
    });
    return false;
  }

  return true;
};

export const canShowCards = (orgs: IOrg[]) => {
  if (!orgs.length) return false;

  return (
    !isStrInvalid(orgs[0].orgName) &&
    !isStrInvalid(orgs[0].orgDomainName) &&
    orgs[0].peers.length > 0
  );
};
