import styled, { css } from 'styled-components';
import { Icon as MIcon } from '@material-ui/core';

const statusIcon: Record<string, any> = {
  success: css`
    color: var(--success);
  `,
  error: css`
    color: var(--error);
  `,
  notrun: css`
    color: var(--reportNotRun);
  `,
};

export const Icon = styled(MIcon)`
  ${({ status }: { status: string }) => statusIcon[status]};
`;
