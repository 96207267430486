import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MTableHead,
  TableRow,
  Button,
  Box,
} from '@material-ui/core';

import { Visibility } from '@material-ui/icons';
import { formatDate } from '../../utils/date';

import { Title, Container, NotFound } from './styles';
import { useOPHistory } from '../../Contexts/OPHistory';
import OrgsTable from '../../AppComponents/Report/OrgsTable';
import ReportSteps, { IStepTableData } from './Steps';
import ReportStatusIcon from '../../AppComponents/Report/StatusIcon';
import { ActionsTableCellContainer } from '../../AppComponents/Report/OrgsTable/styles';
import Tooltip from '../../AppComponents/Tooltip';

interface ICountObj {
  errorCount: number;
  notRunCount: number;
  successCount: number;
}

export interface ITableData {
  label: string;
  status: string;
  errorCount: number;
  notRunCount: number;
  successCount: number;
  subtasks?: Task[];
}

interface IHeadCell {
  disablePadding: boolean;
  label: JSX.Element | string;
  id: keyof ITableData | 'actions';
}

export interface StepData extends Task {
  reason: string;
}

const Report: React.FC = () => {
  const { t } = useTranslation();
  const { reportId, reportType } = useParams<{
    reportId: string;
    reportType: string;
  }>();

  const { operationsHistory } = useOPHistory();

  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [stepTableData, setStepTableData] = useState<IStepTableData[]>([]);
  const [selectedTaskData, setSelectedTaskData] = useState<
    StepData[] | undefined
  >(undefined);
  const [currentOPHistory, setCurrentOPHistory] = useState<OPHistory | null>(
    null,
  );
  const getCurrentOpHistory = useCallback(() => {
    const currentOPH = operationsHistory.find((op) => op._id === reportId);

    setCurrentOPHistory(currentOPH as OPHistory);
  }, [operationsHistory, reportId]);

  useEffect(() => {
    getCurrentOpHistory();
  }, [reportId, reportType, getCurrentOpHistory]);

  const getStatusCount = (
    list: OPHistory['message']['TaskMap'][''][],
  ): ICountObj => {
    let errorCount = 0;
    let notRunCount = 0;
    let successCount = 0;
    if (!list) return { successCount, errorCount, notRunCount };

    list.forEach((item) => {
      switch (item.status) {
        case 'success':
          successCount++;
          break;
        case 'failed':
          errorCount++;
          break;
        case 'idle':
        case 'running':
        case 'stopped':
        case 'undefined':
          notRunCount++;
          break;
        default:
          break;
      }
    });

    return { successCount, errorCount, notRunCount };
  };

  useEffect(() => {
    if (currentOPHistory) {
      const tableOpHistoryData = Object.values(
        currentOPHistory.message.TaskMap,
      )[0]?.subtasks?.map((opH) => {
        return {
          status: opH?.status,
          ...getStatusCount(opH?.subtasks!),
          subtasks: opH?.subtasks,
          label: opH?.label,
        };
      }, []) as ITableData[];

      if (tableOpHistoryData) setTableData(tableOpHistoryData);
    }
  }, [currentOPHistory, operationsHistory]);

  useEffect(() => {
    if (currentOPHistory) {
      //   const stepData = getStepTableData([] as StepData[]);
      //   setStepTableData(stepData);
    }
  }, [currentOPHistory, operationsHistory]);

  const getInitialGeneralHeadCells = (): IHeadCell[] => [
    {
      id: 'label',
      disablePadding: false,
      label: 'Label',
    },
    {
      id: 'status',
      disablePadding: false,
      label: <Trans>common.words.status</Trans>,
    },
    {
      id: 'successCount',
      disablePadding: false,
      label: <Trans>asset.reports.successCount</Trans>,
    },
    {
      id: 'errorCount',
      disablePadding: false,
      label: <Trans>asset.reports.errorCount</Trans>,
    },
    {
      id: 'notRunCount',
      disablePadding: false,
      label: <Trans>asset.reports.notRunCount</Trans>,
    },
    {
      id: 'actions',
      disablePadding: false,
      label: <Trans>common.words.actions</Trans>,
    },
  ];

  //   c
  console.log('TASK', tableData);
  const headCells = getInitialGeneralHeadCells();
  const TableHead: React.FC<{ headCells: IHeadCell[] }> = ({ headCells }) => (
    <MTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </MTableHead>
  );
  return (
    <Container>
      {currentOPHistory ? (
        <>
          <Title>
            <Typography variant="overline" style={{ fontSize: '22px' }}>
              {t(`common.reports.success.${currentOPHistory?.operation}`)}{' '}
              {selectedTaskData && 'steps'}
            </Typography>

            <Typography variant="body1">
              {formatDate(currentOPHistory.date, true)}
            </Typography>
          </Title>
          {!selectedTaskData ? (
            <Container>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead headCells={headCells as IHeadCell[]} />
                    <TableBody>
                      {tableData?.length &&
                        tableData.map((row, index) => (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell align="center">{row?.label}</TableCell>
                            <TableCell align="center">
                              <ReportStatusIcon status={row.status} />
                            </TableCell>
                            <TableCell align="center">
                              {row.successCount}
                            </TableCell>
                            <TableCell align="center">
                              {row.errorCount}
                            </TableCell>
                            <TableCell align="center">
                              {row.notRunCount}
                            </TableCell>
                            <TableCell align="center">
                              <ActionsTableCellContainer>
                                {row?.subtasks?.length && (
                                  <Tooltip
                                    message={t('asset.reports.viewTooltip')}
                                  >
                                    <Visibility
                                      onClick={() =>
                                        setSelectedTaskData(
                                          ((row?.subtasks as unknown) as StepData[]) ||
                                            [],
                                        )
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </ActionsTableCellContainer>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Container>
          ) : (
            <Box>
              <ReportSteps
                tasks={selectedTaskData}
                currentOPHistory={currentOPHistory}
              />
              <Box display="flex" width="100%" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSelectedTaskData(undefined)}
                >
                  Visualize report metrics
                </Button>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Report;
