import styled from 'styled-components';

export const AssetFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AssetFormInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const FieldsTitle = styled.p`
  font-size: 18px;
`;

export const FieldsForm = styled.div`
  display: flex;
`;

export const FieldsFormCheckBoxes = styled.div`
  width: 100%;
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1034px) {
    margin-left: 15px;
  }
`;
