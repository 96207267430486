export const peersInChannelCounter = (peersInChannel: {
  [orgName: string]: string[];
}) => {
  let counter = 0;
  if (!peersInChannel) return 0;
  Object.values(peersInChannel).forEach((peers) => {
    peers.forEach((peer) => {
      if (peer) {
        counter++;
      }
    });
  });
  return counter;
};
