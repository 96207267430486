import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../../store';
import { WithOrgNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

interface ISetupMachinesState extends Record<string, any> {
  notificationsAttrs: INotificationAttrs[];
  notifications: WithOrgNotificationObject[];
}

export const initialState: ISetupMachinesState = {
  notifications: [],
  notificationsAttrs: [],
};

const setupMachinesNotificationsSlice = createSlice({
  name: 'setupMachinesNotifications',
  initialState,
  reducers: {
    setSetupNotifications(state, action: PayloadAction<ISetupMachinesState>) {
      state.notifications = action.payload.notifications;
      state.notificationsAttrs = action.payload.notificationsAttrs;
    },
    setSetupNotificationsAttrs(
      state,
      action: PayloadAction<INotificationAttrs[]>,
    ) {
      state.notificationsAttrs = action.payload;
    },
    clearSetupMachinesNotifications() {
      return initialState;
    },
  },
});

export const changeSetupNotifications = (
  value: WithOrgNotificationObject,
): AppThunk => (dispatch, getState) => {
  const state = getState().setupMachinesNotificationsState;

  const notifications = [...state.notifications];
  const notificationsAttrs = [...state.notificationsAttrs];

  const index = notifications.findIndex((mac) => mac.orgName === value.orgName);

  if (index >= 0) {
    notifications[index] = value;
  } else {
    notifications.push({ ...value });
    notificationsAttrs.push({
      orgName: value.orgName,
      failed: false,
      mainLoad: 0,
      fullyComplete: false,
    });
  }

  dispatch(
    setupMachinesNotificationsSlice.actions.setSetupNotifications({
      notifications,
      notificationsAttrs,
    }),
  );
};

export const changeSetupNotificationAttrs = (
  payload: Record<keyof (INotificationAttrs | { orgName: string }), any>,
): AppThunk => (dispatch, getState) => {
  const state = getState().setupMachinesNotificationsState;
  const notificationsAttrs = [...state.notificationsAttrs];
  const { orgName } = payload;

  const index = notificationsAttrs.findIndex((mac) => mac.orgName === orgName);

  if (index >= 0) {
    notificationsAttrs[index] = {
      ...notificationsAttrs[index],
      ...payload,
    };

    dispatch(
      setupMachinesNotificationsSlice.actions.setSetupNotificationsAttrs(
        notificationsAttrs,
      ),
    );
  }
};

export const removeMachine = (machineIP: string): AppThunk => (
  dispatch,
  getState,
) => {
  const state = getState().setupMachinesNotificationsState;
  const newNotifications = [...state.notifications];

  const notificationIndex = newNotifications.findIndex(
    (mac) => mac.orgName === machineIP,
  );

  if (notificationIndex >= 0) newNotifications.splice(notificationIndex, 1);

  dispatch(
    setupMachinesNotificationsSlice.actions.setSetupNotifications({
      notifications: newNotifications,
      notificationsAttrs: state.notificationsAttrs,
    }),
  );
};

export const {
  clearSetupMachinesNotifications,
} = setupMachinesNotificationsSlice.actions;

export default setupMachinesNotificationsSlice.reducer;
