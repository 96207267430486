import React, { useState, useEffect, useRef } from 'react';
import {
  Icon,
  Input,
  InputLabel,
  Typography,
  FormControl,
  ListItemText,
  InputAdornment,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import {
  FloatLabel,
  OptionItem,
  OptionsList,
  OptionsPaper,
  SelectContent,
  SelectContainer,
  CustomOptionItem,
  RestoreSelectIcon,
  OptionsListContainer,
} from './styles';
import Tooltip from '../../Tooltip';
import { assetAndPropTagPatt } from '../../../utils/regexPatterns';

interface IOption {
  type: string;
  value?: string;
  label?: string;
  isList?: boolean;
  isAsset?: boolean;
}

interface IDataTypeSelectProps {
  value?: string;
  placeholder?: string;
  assetOptions?: string[];
  onChange: (option: IOption, isCustom?: boolean) => void;
}

const DataTypeSelect: React.FC<IDataTypeSelectProps> = ({
  value,
  onChange,
  placeholder,
  assetOptions,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption>({ type: '' });
  const primitiveOptions: IOption[] = [
    { type: 'number', label: t('common.words.number'), isAsset: false },
    { type: 'string', label: t('common.words.string'), isAsset: false },
    { type: 'boolean', label: t('common.words.boolean'), isAsset: false },
    { type: 'datetime', label: t('common.words.datetime'), isAsset: false },
  ];
  const customOption: IOption = {
    type: 'custom',
    label: t('asset.chaincodes.templates.manageTemplates.customDataType'),
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node))
      setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (value && selectedOption.type !== customOption.type) {
      let label = value.replace('->', '');
      label = label.replace('[]', '');
      const translation = t(`common.words.${label}`);
      label = translation.includes('common.words') ? label : translation;

      setSelectedOption({
        label,
        type: value,
        isList: value.includes('[]'),
        isAsset: assetOptions
          ? assetOptions?.some((opt: string) => value.replace('[]', '') === opt)
          : false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, value, assetOptions]);

  useEffect(() => {
    if (!options.length) {
      let newOptions: IOption[] = [];
      const assetOpts: IOption[] = [];

      if (assetOptions) {
        assetOptions.forEach((opt: string) => {
          if (opt) {
            assetOpts.push({
              type: opt,
              label: opt.replace('[]', ''),
              isAsset: true,
            });
          }
        });
      }

      newOptions = [...primitiveOptions, ...assetOpts];

      newOptions.forEach((opt: IOption) => {
        newOptions.push({
          type: `[]${opt.type}`,
          isAsset: opt.isAsset,
          label: opt.label,
          isList: true,
        });
      });

      setOptions(newOptions);
    }
  }, [primitiveOptions, assetOptions, options]);

  const getLabel = (option: IOption) => {
    const { label, isList, isAsset } = option;

    return (
      <Typography>
        {isList ? (
          <Trans>asset.chaincodes.templates.manageTemplates.listOf</Trans>
        ) : null}{' '}
        {isAsset ? <b style={{ color: 'var(--primary)' }}>{label}</b> : label}
      </Typography>
    );
  };

  return (
    <div ref={ref} style={{ position: 'relative', height: 'fit-content' }}>
      {selectedOption.type === customOption.type || selectedOption.value ? (
        <FormControl style={{ marginBottom: '8px' }}>
          <InputLabel>
            {t('asset.chaincodes.templates.common.dataType')}
          </InputLabel>

          <Input
            value={selectedOption.value}
            error={!assetAndPropTagPatt.test(selectedOption.value || '')}
            onChange={(e) => {
              setSelectedOption({
                ...selectedOption,
                value: e.target.value,
              });
              onChange({ type: e.target.value }, true);
            }}
            endAdornment={
              <InputAdornment position="end">
                <Tooltip
                  message={t(
                    'asset.chaincodes.templates.manageTemplates.restoreSelectTooltip',
                  )}
                >
                  <RestoreSelectIcon
                    onClick={() => setSelectedOption({ type: '' })}
                  />
                </Tooltip>
              </InputAdornment>
            }
          />
        </FormControl>
      ) : (
        <>
          <SelectContainer onClick={() => setOpen((prev) => !prev)}>
            {selectedOption.type ? (
              <FloatLabel>{placeholder}</FloatLabel>
            ) : null}

            <Typography component="span" style={{ marginTop: '5px' }}>
              {!selectedOption.type ? (
                <Typography style={{ color: 'rgba(0, 0, 0, 0.60)' }}>
                  {placeholder}
                </Typography>
              ) : (
                getLabel(selectedOption)
              )}
            </Typography>

            <Icon style={{ marginLeft: '10px' }}>keyboard_arrow_down</Icon>
          </SelectContainer>

          {open ? (
            <SelectContent>
              <OptionsPaper>
                <OptionsListContainer>
                  <OptionsList optionsLength={options.length}>
                    {options.map((option: IOption, idx: number) => (
                      <div key={idx}>
                        <OptionItem
                          selected={selectedOption.type === option.type}
                          onClick={() => {
                            if (open) {
                              setSelectedOption(option);
                              onChange(option);
                              setOpen(false);
                            }
                          }}
                        >
                          <ListItemText primary={getLabel(option)} />
                        </OptionItem>
                      </div>
                    ))}
                  </OptionsList>

                  <CustomOptionItem
                    selected={selectedOption.type === customOption.type}
                    onClick={() => {
                      if (open) {
                        setSelectedOption({ ...customOption, value: '' });
                        setOpen(false);
                      }
                    }}
                  >
                    <ListItemText
                      primary={getLabel(customOption)}
                      style={{ marginLeft: '-8px' }}
                    />
                  </CustomOptionItem>
                </OptionsListContainer>
              </OptionsPaper>
            </SelectContent>
          ) : null}
        </>
      )}
    </div>
  );
};

export default DataTypeSelect;
