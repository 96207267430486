import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  List,
  Button,
  Switch,
  ListItem,
  Typography,
  ListItemIcon,
  ListSubheader,
  makeStyles,
  Theme,
  createStyles,
  Collapse,
  ListItemText,
  Tooltip,
  ListItemSecondaryAction,
  Fab,
  FormControlLabel,
  Box,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpandLess, ExpandMore, StarBorder } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import {
  IAsset,
  getTemplateList,
  changeTemplateOption,
} from '../../../store/TemplateCC';

import { StoreState } from '../../../store/types';

import DefineAssetPermissions from '../DefinePermissionSelect/Asset';
import DefinePropWritersSelect from '../DefinePermissionSelect/Prop';

import {
  FormLabel,
  FillPropSpace,
  assetItemStyle,
  SelectContainer,
  ListItemContainer,
} from './styles';
import PropDataType from '../../../utils/template/PropDataType';
import { updateTemplateWithDatabase } from '../../../utils/template';
import { changeMountedComponents } from '../../../store/InitialTour';
import { useNetworks } from '../../../Contexts/Networks';
import { useUpgradeChaincodeForm } from '../../../Hooks/UpgradeChaincode';
import DefineAllPropsWriters from '../DefinePermissionSelect/Prop/DefineAllPropsWriters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
      paddingRight: '130px',
      gap: '2rem',
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      flexBasis: '30%',
      flexShrink: 0,
      textTransform: 'uppercase',
      alignSelf: 'center',
      letterSpacing: '0.08333em',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.secondary,
      alignSelf: 'center',
    },
  }),
);

export type Operation =
  | 'startnetwork'
  | 'upgradechaincode'
  | 'addccapi'
  | 'upgradeapi';

interface IDefineWritersProps {
  operation: Operation;
  orgs: { orgName: string }[];
  deployTemplate?: IGlobalTemplate | null;
  changeDeployTemplate?: (assets: IAsset[]) => void;
}

const DefineWriters: React.FC<IDefineWritersProps> = ({
  orgs,
  operation,
  deployTemplate,
  changeDeployTemplate,
}) => {
  console.log('deployTemplate', deployTemplate);

  if (!deployTemplate?.assets || !deployTemplate?.assets.length) return null;

  return (
    <List className="template-assets-permissions">
      {deployTemplate?.assets?.map((asset, idx) => (
        <SingleAsset
          key={idx}
          asset={asset}
          deployTemplate={deployTemplate}
          operation={operation}
          orgs={orgs}
          changeDeployTemplate={changeDeployTemplate}
        />
      ))}
    </List>
  );
};

const SingleAsset: React.FC<{
  asset: IAsset;
  deployTemplate?: IGlobalTemplate | null;
  operation: Operation;
  orgs: { orgName: string }[];
  changeDeployTemplate?: (assets: IAsset[]) => void;
}> = ({ asset, orgs, operation, deployTemplate, changeDeployTemplate }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const { t } = useTranslation();

  const assets = deployTemplate?.assets || [];

  const { selectedChaincode } = useUpgradeChaincodeForm();
  const { selectedNetwork } = useNetworks();

  const originalNetDefTemplate =
    selectedNetwork?.chaincodes?.[selectedChaincode]?.templateDef;
  const changeDispatch = useCallback(
    (value: IAsset[]) => {
      console.log('new', value);
      if (changeDeployTemplate) changeDeployTemplate(value);
    },
    [changeDeployTemplate],
  );

  const isOrgSelectedInOriginalNetwork = useCallback(
    (org: string) =>
      operation === 'upgradechaincode' &&
      (originalNetDefTemplate?.assets?.find(
        (a) => a?.tag === asset?.tag,
      ) as IAsset).readers?.some((itm) => itm === `${org}MSP`),
    [asset, operation, originalNetDefTemplate],
  );

  const disableUpdatePrivateData = useMemo(
    () =>
      operation === 'upgradechaincode' &&
      originalNetDefTemplate?.assets?.find((a) => a.tag === asset.tag)
        ?.readers !== null,
    [asset, operation, originalNetDefTemplate],
  );
  const changeAssetReaders = useCallback(
    (assetTag: string, readers: string[] | 'all' | 'none') => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag)
          return {
            ...asset,
            readers:
              readers === 'none'
                ? []
                : readers === 'all'
                ? orgs.map((org) => `${org.orgName}MSP`)
                : readers,
          };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [orgs, assets, changeDispatch],
  );

  const changePropWriters = useCallback(
    (assetTag: string, propTag: string, writers: string[]) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          const newProps = asset.props.map((prop) => {
            if (prop.tag === propTag) return { ...prop, writers };

            return prop;
          });

          return { ...asset, props: newProps };
        }

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changeWriterInAllProps = useCallback(
    (assetTag: string, writer: string) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          // If the org is already in the writers list of every prop, remove it
          const shouldRemoveAll = asset?.props?.every((prop) =>
            prop?.writers?.includes(writer),
          );

          const newProps = asset.props.map((prop) => {
            const { writers } = prop;
            if (writers === null) {
              return { ...prop, writers: [writer] };
            }

            if (shouldRemoveAll) {
              return {
                ...prop,
                writers: writers.filter((w: string) => w !== writer),
              };
            }

            const uniqueWriters = new Set([...writers, writer]);
            return { ...prop, writers: Array.from(uniqueWriters) };
          });

          return { ...asset, props: newProps };
        }

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changeAllEnabledInAllProps = useCallback(
    (assetTag: string) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          const newProps = asset.props.map((prop) => {
            return {
              ...prop,
              writersAllEnabled: !prop.writersAllEnabled,
              writers: !prop.writersAllEnabled ? null : prop.writers,
            };
          });

          return { ...asset, props: newProps };
        }
        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changeAssetPrivateData = useCallback(
    (assetTag: string, value: boolean) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) return { ...asset, privateData: value };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changePropAllEnabled = useCallback(
    (assetTag: string, propTag: string) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          const newProps = asset.props.map((prop) => {
            if (prop.tag === propTag)
              return {
                ...prop,
                writersAllEnabled: !prop.writersAllEnabled,
                writers: !prop.writersAllEnabled ? null : prop.writers,
              };

            return prop;
          });

          return { ...asset, props: newProps };
        }

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changeAssetSelectOpened = useCallback(
    (assetTag: string, value: boolean) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) return { ...asset, selectOpened: value };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const changePropSelectOpened = useCallback(
    (assetTag: string, propTag: string, value: boolean) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag) {
          if (!asset.props) return asset;

          const newProps = asset.props.map((prop) => {
            if (prop.tag === propTag) return { ...prop, selectOpened: value };

            return prop;
          });

          return { ...asset, props: newProps };
        }

        return asset;
      });

      changeDispatch(newAssets);
    },
    [assets, changeDispatch],
  );

  const handleAssetPrivateDataChange = useCallback(
    (assetTag: string) => {
      // eslint-disable-next-line no-shadow
      const newAssets = assets.map((asset) => {
        if (asset.tag === assetTag)
          return {
            ...asset,
            privateData: !asset.privateData,
            selectOpened: !asset.privateData,
            readers: !asset.privateData
              ? orgs.map((org) => `${org.orgName}MSP`)
              : [],
          };

        return asset;
      });

      changeDispatch(newAssets);
    },
    [orgs, assets, changeDispatch],
  );

  const getOrgNamesList = useCallback(() => {
    return orgs.map((org) => org.orgName);
  }, [orgs]);

  const isPrivateDataChecked = useMemo(() => Boolean(asset.privateData), [
    asset,
  ]);

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        // subheader={
        //   <ListSubheader component="div" id="nested-list-subheader">
        //     Define permissions
        //   </ListSubheader>
        // }
        className={classes.root}
      >
        <ListItem
          style={{
            backgroundColor: 'rgba(39, 60, 117, 0.2)',
            paddingRight: '175px',
            borderRadius: '5px',
            gap: '2rem',
          }}
        >
          <Box>
            <Tooltip title={t('common.words.label') as string} placement="top">
              <ListItemText
                primary={asset.label}
                secondary={t('common.words.asset')}
              />
            </Tooltip>
          </Box>

          <ListItemText
            primary={
              <>
                <DefineAssetPermissions
                  asset={asset}
                  orgs={getOrgNamesList()}
                  disableUpdatePrivateData={disableUpdatePrivateData}
                  isOrgSelectedInOriginalNetwork={
                    isOrgSelectedInOriginalNetwork
                  }
                  placeholder={t('common.chaincode.selectPermissions')}
                  changeOpen={(value) => {
                    changeAssetSelectOpened(asset.tag, value);
                  }}
                  changePrivateData={(value: boolean) => {
                    changeAssetPrivateData(asset.tag, value);
                  }}
                  onChange={(value: string[]) => {
                    changeAssetReaders(asset.tag, value);
                  }}
                />
              </>
            }
          />

          <ListItemSecondaryAction>
            <FormControlLabel
              control={
                <Switch
                  edge="end"
                  onChange={() => handleAssetPrivateDataChange(asset.tag)}
                  checked={isPrivateDataChecked}
                  disabled={disableUpdatePrivateData}
                  inputProps={{ 'aria-labelledby': 'asset-private-data' }}
                />
              }
              label={t('common.chaincode.privateData')}
              labelPlacement="top"
            />

            <Fab
              size="small"
              aria-label="collapse"
              onClick={() => setOpen((isOpen) => !isOpen)}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </Fab>
          </ListItemSecondaryAction>
        </ListItem>

        <Collapse in={open} timeout="auto">
          <Box paddingLeft="1.5rem" margin="0.5rem 0">
            <Typography>
              Define writers for all <strong>{asset?.label}&apos;s</strong>{' '}
              Props
            </Typography>
            {asset?.props && asset?.props?.length > 0 && (
              <DefineAllPropsWriters
                parentAsset={asset}
                orgs={getOrgNamesList()}
                placeholder={t('common.chaincode.selectPermissions')}
                changeWritersAllEnabled={() => {
                  console.log('SET ALL ENABLED IN ALL PROPS');
                  changeAllEnabledInAllProps(asset.tag);
                }}
                onChange={(writer: string) => {
                  console.log('SET WRITER IN ALL PROPS: ', writer);
                  changeWriterInAllProps(asset.tag, writer);
                }}
              />
            )}
          </Box>
        </Collapse>

        <Accordion
          style={{
            boxShadow: 'none',
            background: 'none',
            border: '1px solid #ddd',
            borderRadius: '5px',
            margin: '0.2rem 0 0 0',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              minHeight: 'unset',
              height: '48px',
            }}
          >
            <Typography className={classes.heading}>Advanced</Typography>
            <Typography className={classes.secondaryHeading}>
              Configure writers individually
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ flexDirection: 'column', gap: '1.5rem', paddingTop: 0 }}
          >
            <List component="div" disablePadding>
              {asset.props?.map((prop, idx) => (
                <ListItem key={idx} className={classes.nested}>
                  <Box>
                    <Tooltip
                      title={t('common.chaincode.labelAndDataType') as string}
                      placement="top"
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography
                          style={{ fontSize: '16px', wordBreak: 'break-word' }}
                        >
                          {prop.label}
                        </Typography>
                        <Typography
                          style={{ fontSize: '16px', wordBreak: 'break-word' }}
                        >
                          <PropDataType
                            dataType={prop.dataType}
                            assets={assets}
                          />
                        </Typography>
                        <Typography
                          style={{ fontSize: '14px', color: 'var(--disabled)' }}
                        >
                          <Trans>common.words.prop</Trans>
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>

                  <ListItemText
                    primary={
                      <>
                        <DefinePropWritersSelect
                          prop={prop}
                          parentAsset={asset}
                          orgs={getOrgNamesList()}
                          placeholder={t('common.chaincode.selectPermissions')}
                          changeOpen={(value) => {
                            changePropSelectOpened(asset.tag, prop.tag, value);
                          }}
                          changeWritersAllEnabled={() => {
                            changePropAllEnabled(asset.tag, prop.tag);
                          }}
                          onChange={(newWriters: string[]) => {
                            changePropWriters(asset.tag, prop.tag, newWriters);
                          }}
                        />
                      </>
                    }
                  />

                  <ListItemSecondaryAction>
                    <FormControlLabel
                      control={
                        <Switch
                          edge="end"
                          onChange={() => {
                            changePropAllEnabled(asset.tag, prop.tag);
                          }}
                          checked={Boolean(prop.writersAllEnabled)}
                        />
                      }
                      label={t('common.chaincode.allEnabled')}
                      labelPlacement="top"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </List>
    </>
  );
};

export default DefineWriters;
