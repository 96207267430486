import React from 'react';
import { CSSObject } from 'styled-components';
import { LinearProgress, Typography } from '@material-ui/core';

import { Container } from './styles';

const LoadingScreen: React.FC<{
  content?: string | JSX.Element;
  style?: CSSObject;
}> = ({ content, style }) => (
  <Container style={style || {}}>
    <Typography variant="overline" style={{ color: 'var(--white)' }}>
      {content || null}
    </Typography>

    <LinearProgress />
  </Container>
);

export default LoadingScreen;
