import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Axios from 'axios';
import {
  List,
  Button,
  ListItem,
  Checkbox,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  IconButton,
  Box,
  Divider,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { BackspaceOutlined } from '@material-ui/icons';
import history from '../../../history';
import { networkApi } from '../../../Common/axios';

import { openDialog } from '../../../store/Dialog';
import { PeerContent, useAddPeerForm } from '../../../Hooks/addPeer';
import { addOperation, removeOperation } from '../../../store/AppStatus';

import { StoreState } from '../../../store/types';
import { ISelectedNetwork } from '../../../store/Network';
import { clearAddPeerNotifications } from '../../../store/AddPeerNotifications';
import {
  Card,
  Section,
  LoadContainer,
  PageContainer,
  SelectListItem,
} from './styles';

import LoadingScreen from '../../LoadingScreen';
import CAauthForm from '../../../AppComponents/CAauth';
import { ipPatt, fqdnPatt } from '../../../utils/regexPatterns';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import LoadingContainer from '../../../AppComponents/Notifications';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import { mapChannelOrgs } from '../../../utils/network/parseChannelMembers';
import { useNetworks } from '../../../Contexts/Networks';
import { OperationStateCard } from '../../../AppComponents/OperationStateCard';
import { useOperation } from '../../../Contexts/Operation';

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddPeer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedNetwork, fetchNetworkState, getAllNetworks } = useNetworks();

  const { addPeerState, clearOperationsState } = useOperation();

  const [peerData, setPeerData] = useState({
    host: '',
    hsmUser: '',
    hsmPin: '',
  });

  console.log('teste', selectedNetwork?.organizations);
  const {
    loading,
    peers,
    setPeers,
    sendingData,
    setSendingData,
    CAauth,
    port,
    operationPort,
    setCAauth,
    selectedOrg,
    setSelectedOrg,
    peerAlreadyExists,
    channelDefs,
    netStateOrgs,
    channelName,
    setChannelName,
    setOrgs,
    clearFormData,
  } = useAddPeerForm();

  const changeCAauth = useCallback((caAuth) => setCAauth(caAuth), [setCAauth]);

  useEffect(() => {
    setPeers({});
  }, [selectedNetwork, selectedOrg, channelName, setPeers]);

  const isHostInvalid = (host: string) =>
    !host ||
    (!(ipPatt.test(host) || fqdnPatt.test(host)) && host.length >= 1) ||
    peerAlreadyExists;

  const isPortInvalid = useCallback((currentPort: number) => {
    return Boolean(currentPort.toString().length !== 4);
  }, []);

  const sendData = () => {
    setSendingData(true);

    try {
      dispatch(clearAddPeerNotifications());

      if (!channelName) throw Error(t('common.messages.noChannelSelected'));
      if (!selectedOrg.name) throw Error(t('common.messages.noOrgSelected'));
      if (peerAlreadyExists)
        throw Error(t('common.messages.alreadyUsedPeerIP'));
      //   if (isHostInvalid()) throw Error(t('common.messages.invalidIP'));

      if (!CAauth.user || !CAauth.passwd)
        throw Error(t('common.messages.addCredentials'));

      if (isPortInvalid(port)) {
        throw Error(t('Invalid Port'));
      }

      if (isPortInvalid(operationPort)) {
        throw Error(t('Invalid Operation Port'));
      }

      console.log('CAauth', CAauth);

      const formData = new FormData();
      formData.append('payload', JSON.stringify({ peers }));
      formData.append('networkName', selectedNetwork?.networkName as string);
      formData.append('orgName', selectedOrg?.name as string);
      formData.append('channelName', channelName);
      formData.append('caUser', CAauth.user);
      formData.append('caPassword', CAauth.passwd);
      //   formData.append(
      //     'couchs',
      //     JSON.stringify({
      //       [`couch${Object.keys(peers)?.length}`]: {
      //         username: CAauth.user,
      //         password: CAauth.passwd,
      //         port,
      //       },
      //     }),
      //   );

      networkApi
        .post('/addPeer', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              dispatch(removeOperation('addpeer', true));
              cancelWithDialog(c, t('title.nodes.peers.addPeer'), hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.nodes.peers.addPeer'),
                cancel: withDialogCancel,
                name: 'addpeer',
                pathname: window.location.pathname,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();

          await fetchNetworkState();
          dispatch(removeOperation('addpeer', true));
          clearFormData();

          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: 'Peer added successfully',
            }),
          );

          if (window.location.pathname === '/node/peer/add') {
            history.push('/dashboard');
          }

          setSendingData(false);
          clearOperationsState();
        })
        .catch((error) => {
          dispatch(removeOperation('addpeer', false));
        })
        .finally(() => setSendingData(false));
    } catch (error) {
      setSendingData(false);
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message,
        }),
      );
    }
  };

  const handleAddPeer = useCallback(() => {
    if (!selectedNetwork) return;
    if (isHostInvalid(peerData?.host)) return;

    const peerIndex =
      Object?.keys(peers)?.length +
      (Object?.keys(selectedNetwork?.peers)?.length || 0); // get next peerIndex and set as key in peers object
    const key =
      selectedNetwork?.peers &&
      selectedOrg?.name &&
      `peer${peerIndex}.${selectedOrg?.name}.${
        selectedNetwork?.organizations?.[selectedOrg?.name]?.domain
      }`;
    const newPeer: PeerContent = { host: peerData?.host };

    console.log('selectedOrg?.hsmEnabled', selectedOrg?.hsmEnabled);
    if (selectedOrg?.hsmEnabled) {
      newPeer.hsm = {
        user: peerData?.hsmUser,
        pin: peerData?.hsmPin,
      };
    }

    if (key) {
      setPeers({ ...peers, [key]: newPeer });
      setPeerData({ host: '', hsmUser: '', hsmPin: '' });
    }
  }, [isHostInvalid, peerData, peers, selectedNetwork, selectedOrg, setPeers]);

  const handleRemovePeer = useCallback(
    (peerToRemove: string) => () => {
      delete peers[peerToRemove];

      setPeers({ ...peers });
    },
    [peers, setPeers],
  );

  const getOrgPeerObj = (
    stateOrg: INetStateOrg,
    chPeer: INetStateChannelDefOrg['Peers'][0],
  ) => ({
    value: stateOrg.peers[chPeer].host,
  });

  const getOrgObj = (stateOrg: INetStateOrg) => ({
    ip: stateOrg.restHost,
    name: stateOrg.orgName,
  });

  const getChannelMembers = useCallback(() => {
    const channelDef = channelDefs.find(
      (chDef) => chDef.channelName === channelName,
    );

    if (channelDef) {
      const { mappedOrgs } = mapChannelOrgs({
        getOrgObj,
        getOrgPeerObj,
        chOrgs: channelDef.orgs,
        separateNodesTypes: true,
        organizations: netStateOrgs,
      });

      setOrgs(mappedOrgs);
    }
  }, [channelName, netStateOrgs, channelDefs, setOrgs]);

  useEffect(() => {
    getChannelMembers();
  }, [channelName, getChannelMembers]);

  if (!selectedNetwork?.networkName) return <SelectNetwork />;

  if (loading)
    return (
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <CircularProgress style={{ marginTop: '30px' }} />
      </div>
    );

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        <Trans>title.nodes.peers.addPeer</Trans>
      </Typography>

      <PageContainer>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
        >
          <Card>
            <div style={{ width: '100%', display: 'flex', textAlign: 'left' }}>
              <Section>
                <Typography>
                  <Trans>common.forms.channelsToJoin</Trans>
                </Typography>

                <List>
                  {Object.keys(selectedNetwork.channels).map((channel) => (
                    <ListItem
                      button
                      divider
                      key={channel}
                      onClick={() => setChannelName(channel)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          disabled={sendingData}
                          checked={channel === channelName}
                        />
                      </ListItemIcon>

                      <ListItemText primary={channel} />
                    </ListItem>
                  ))}
                </List>
              </Section>

              <Section>
                <Typography>
                  <Trans>common.words.organizations</Trans>
                </Typography>

                {!channelName ? (
                  <Typography
                    variant="body1"
                    style={{ marginTop: 32, color: 'var(--darkGray)' }}
                  >
                    <Trans>common.messages.selectChannelFirst</Trans>
                  </Typography>
                ) : (
                  <List>
                    {Object.entries(selectedNetwork?.organizations)?.map(
                      ([orgName, orgData]) =>
                        selectedNetwork?.channels?.[channelName]?.peers[
                          orgName
                        ] && (
                          <SelectListItem
                            key={orgName}
                            disabled={sendingData}
                            selected={selectedOrg.name === orgName}
                            onClick={() =>
                              setSelectedOrg({
                                ip: orgData?.netapi?.host,
                                name: orgName,
                                hsmEnabled: orgData?.hsm?.enabled,
                              })
                            }
                          >
                            {`${orgName}-${orgData?.netapi?.host}`}
                          </SelectListItem>
                        ),
                    )}
                  </List>
                )}
              </Section>

              <Section>
                <Typography>
                  <Trans>common.forms.peerIP</Trans>
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ gap: '0.5rem' }}
                >
                  <TextField
                    name="host"
                    value={peerData?.host}
                    label={t('common.forms.peerAddress')}
                    error={
                      peerData?.host !== '' && isHostInvalid(peerData?.host)
                    }
                    disabled={sendingData || !selectedOrg.name}
                    onChange={({ target: { value } }) => {
                      setPeerData({
                        ...peerData,
                        host: value,
                      });
                    }}
                  />

                  {selectedOrg?.hsmEnabled && (
                    <>
                      <TextField
                        value={peerData?.hsmUser || ''}
                        label="HSM User"
                        disabled={sendingData || !selectedOrg.name}
                        onChange={(e) =>
                          setPeerData({ ...peerData, hsmUser: e.target.value })
                        }
                      />

                      <TextField
                        value={peerData?.hsmPin || ''}
                        label="HSM Pin"
                        disabled={sendingData || !selectedOrg.name}
                        onChange={(e) =>
                          setPeerData({ ...peerData, hsmPin: e.target.value })
                        }
                      />
                    </>
                  )}
                  {/* {Object.keys(peers).length > 1 && (
                          <IconButton
                            onClick={handleRemovePeer(peer)}
                            size="small"
                            color="secondary"
                            edge="end"
                          >
                            <BackspaceOutlined />
                          </IconButton>
                        )} */}
                  <Box display="flex" justifyContent="center" width="100%">
                    <IconButton
                      size="medium"
                      color="primary"
                      edge="end"
                      onClick={handleAddPeer}
                      style={{ margin: '0 auto' }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Section>

              <Section>
                <Typography>
                  <Trans>common.forms.CAauth</Trans>
                </Typography>

                <CAauthForm
                  initialState={CAauth}
                  callback={changeCAauth}
                  disabled={sendingData || !selectedOrg.name}
                />
                {/*
                <TextField
                  name="port"
                  value={port}
                  type="number"
                  style={{ width: '100%' }}
                  label={t('common.forms.port')}
                  error={isPortInvalid(port)}
                  disabled={sendingData || !selectedOrg.name}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target: { value } }) => {
                    const number = parseInt(value, 10);

                    setPort(number);
                  }}
                />
                <TextField
                  name="operationPort"
                  value={operationPort}
                  type="number"
                  style={{ width: '100%' }}
                  label={t('common.forms.operationPort')}
                  error={isPortInvalid(operationPort)}
                  disabled={sendingData || !selectedOrg.name}
                  InputLabelProps={{ shrink: true }}
                  onChange={({ target: { value } }) => {
                    const number = parseInt(value, 10);

                    setOperationPort(number);
                  }}
                /> */}
              </Section>
            </div>

            <Divider />

            <Box display="flex" flexDirection="column" style={{ gap: '1rem' }}>
              {Object.entries(peers).map(([peer, data]) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{ gap: '0.5rem' }}
                >
                  <Box textAlign="left">
                    <Typography variant="h6">{peer}</Typography>
                    {selectedOrg?.hsmEnabled && (
                      <Box display="flex" style={{ gap: '1rem' }}>
                        <Box>
                          <Typography variant="caption">HSM User</Typography>
                          <Typography variant="h6">
                            {data?.hsm?.user}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption">HSM Pin</Typography>
                          <Typography variant="h6">{data?.hsm?.pin}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Typography variant="caption">IP Address</Typography>
                    <Typography variant="h5">{data.host}</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={handleRemovePeer(peer)}
                      size="medium"
                      color="secondary"
                      edge="end"
                    >
                      <BackspaceOutlined />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>

            {sendingData ? (
              <Box>
                <LoadContainer>
                  <LoadingScreen
                    content={t('asset.nodes.addPeer.loadingMessage')}
                  />

                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      cancelRequest();
                    }}
                  >
                    <Trans>button.cancel</Trans>
                  </Button>
                </LoadContainer>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={sendData}
                disabled={!selectedOrg}
              >
                <Trans>button.send</Trans>
              </Button>
            )}

            <OperationStateCard
              taskOperation={addPeerState}
              invisible={!sendingData}
            />
          </Card>

          {selectedOrg ? (
            <div>
              <LoadingContainer
                cardInfo={{
                  type: 'addpeer',
                  running: sendingData,
                  title: t('title.nodes.peers.addPeer'),
                  attributes: {
                    channelName,
                    peerIP: Object?.values(peers)?.[0]?.host,
                    orgName: selectedOrg.name,
                  },
                }}
              />
            </div>
          ) : null}
        </Box>
      </PageContainer>
    </div>
  );
};

export default AddPeer;
