import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import history from '../../history';

import { clearDeployData } from '../DeployStart';
import { closeTourMode, enterTourMode } from '../AppStatus';
import { clearTemplateCCState, setNewTemplate, ITemplate } from '../TemplateCC';

import {
  pageChangePoints,
  stepChangeActions,
  chapterChangeActions,
} from '../../utils/tour/FirstTimeUsers';

import { AppThunk } from '../../store';
import { TourStep } from '../../utils/tour/types';

export interface IInitialTourState extends Record<string, any> {
  key: string;
  run: boolean;
  loading: boolean;
  stepIndex: number;
  steps: TourStep[];
  continuous: boolean;
  // all of the keys above come from the react-joyride tour state type
  newStepIndex: number;
  lastStepAction: string;
  setDeployActiveCCOptTo: string;
  setDeployTemplateTo: ITemplate | null;
  mustChangeDeployStepTo: number | null;
  setTemplateViewOrCreateTo: string | null;
  chapterToChange: {
    chapterIdx: number;
    stepIdx: number;
  };
}

const tourTemplate = (): ITemplate => ({
  name: 'example',
  assets: [
    {
      tag: 'person',
      label: 'person',
      readers: [],
      privateData: false,
      selectOpened: false,
      props: [
        {
          tag: 'name',
          label: 'name',
          isKey: true,
          dataType: 'string',
          required: false,
          readOnly: false,
          selectOpened: false,
          writersAllEnabled: true,
        },
      ],
    },
  ],
});

const initialState: IInitialTourState = {
  steps: [],
  run: false,
  stepIndex: 0,
  loading: false,
  key: new Date().toISOString(),
  continuous: true,
  // all of the states above come from the react-joyride tour state type
  // save the last action that changed something related to the steps management
  lastStepAction: '',
  setDeployTemplateTo: null,
  setDeployActiveCCOptTo: '',
  mustChangeDeployStepTo: null,
  // used to set the template management page to create mode
  setTemplateViewOrCreateTo: null,
  // save the pending newStepIndex, waiting for a component render
  newStepIndex: 0,
  // save the chapter to change and its first step index
  chapterToChange: {
    chapterIdx: -1,
    stepIdx: -1,
  },
};

const initialTourSlice = createSlice({
  name: 'initialTour',
  initialState,
  reducers: {
    restartTour: {
      reducer(
        state,
        action: PayloadAction<{ steps: TourStep[]; stepIndex: number }>,
      ) {
        return {
          ...state,
          run: true,
          loading: false,
          key: new Date().toISOString(),
          steps: action.payload.steps,
          stepIndex: action.payload.stepIndex || 0,
        };
      },
      prepare(steps: TourStep[], stepIndex = 0) {
        return { payload: { steps, stepIndex } };
      },
    },
    stopTour(state) {
      state.run = false;
    },
    setNewStepIndex(state, action: PayloadAction<number>) {
      state.lastStepAction = 'newStepIndex';
      state.newStepIndex = action.payload;
    },
    setStepIndex(state, action: PayloadAction<number>) {
      state.stepIndex = action.payload;
      state.lastStepAction = 'directChange';
    },
    goToNextStep(state) {
      state.stepIndex += 1;
      state.lastStepAction = 'next';
    },
    goToPrevStep(state) {
      state.stepIndex -= 1;
      state.lastStepAction = 'prev';
    },
    changeDeployStepOnTour(state, action: PayloadAction<number | null>) {
      state.mustChangeDeployStepTo = action.payload;
    },
    changeDeployTemplateOnTour: {
      reducer(state, action: PayloadAction<undefined | null>) {
        state.setDeployTemplateTo =
          action.payload === null ? null : tourTemplate();
      },
      prepare(payload?: null) {
        return { payload };
      },
    },
    changeDeployCCOpteOnTour(state, action: PayloadAction<string>) {
      state.setDeployActiveCCOptTo = action.payload;
    },
    changeTemplateViewOrCreateOnTour(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.setTemplateViewOrCreateTo = action.payload;
    },
    setLastStepAction(state, action: PayloadAction<string>) {
      state.lastStepAction = action.payload;
    },
    confirmChapterChange(state, action: PayloadAction<number>) {
      state.stepIndex = action.payload;
      state.lastStepAction = 'chapterChange';
      state.chapterToChange = initialState.chapterToChange;
    },
    setChapterToChange(
      state,
      action: PayloadAction<IInitialTourState['chapterToChange']>,
    ) {
      state.lastStepAction = 'chapterChange';
      state.chapterToChange = action.payload;
    },
    prepareForChapterChange(state) {
      return {
        ...state,
        ...initialState,
        run: true,
        loading: false,
        steps: state.steps,
        key: new Date().toISOString(),
      };
    },
    clearInitialTourState() {
      return initialState;
    },
  },
});

const clearTourForms = (): AppThunk => (dispatch) => {
  dispatch(clearDeployData());
  dispatch(clearTemplateCCState());
};

export const startTour =
  (steps: TourStep[]): AppThunk =>
  (dispatch) => {
    if (window.location.pathname !== '/') history.push('/');

    dispatch(enterTourMode());
    dispatch(clearTourForms());
    dispatch(initialTourSlice.actions.restartTour(steps));
  };

export const endTour = (): AppThunk => (dispatch) => {
  dispatch(closeTourMode());
  dispatch(clearTourForms());
  dispatch(initialTourSlice.actions.clearInitialTourState());

  history.push('/');
};

export const nextStep = (): AppThunk => (dispatch, getState) => {
  const { initialTourState } = getState();
  const { stepIndex, lastStepAction } = initialTourState;

  const pageChange = pageChangePoints[stepIndex];
  const stepAction = stepChangeActions[stepIndex];

  if (lastStepAction !== 'chapterChange') {
    if (pageChange) history.push(pageChange.nextRoute);

    if (stepAction) stepAction.action(dispatch);

    if (!pageChange && (!stepAction || !stepAction.nextComponent)) {
      dispatch(initialTourSlice.actions.goToNextStep());
    } else {
      dispatch(initialTourSlice.actions.setNewStepIndex(stepIndex + 1));
    }
  }
};

export const prevStep = (): AppThunk => (dispatch, getState) => {
  const { initialTourState } = getState();
  const { stepIndex } = initialTourState;

  const pageChange = pageChangePoints[stepIndex - 1];
  const stepAction = stepChangeActions[`undo${stepIndex - 1}`];

  if (pageChange) {
    history.push(pageChange.prevRoute);
    if (pageChange.goBackAction) pageChange.goBackAction(dispatch);
  }

  if (stepAction) stepAction.action(dispatch);

  if (!pageChange && (!stepAction || !stepAction.prevComponent)) {
    dispatch(initialTourSlice.actions.goToPrevStep());
  } else dispatch(initialTourSlice.actions.setNewStepIndex(stepIndex - 1));
};

export const goToChapter =
  (chapterIdx: number, stepIdx: number): AppThunk =>
  (dispatch) => {
    const chapterInfo = chapterChangeActions[`${chapterIdx}`];

    dispatch(clearTourForms());
    dispatch(initialTourSlice.actions.prepareForChapterChange());
    chapterInfo.action(dispatch);

    dispatch(
      initialTourSlice.actions.setChapterToChange({ chapterIdx, stepIdx }),
    );
  };

export const changeMountedComponents =
  (payload: string): AppThunk =>
  (dispatch, getState) => {
    const { initialTourState } = getState();
    const { stepIndex, newStepIndex, lastStepAction, chapterToChange } =
      initialTourState;

    if (
      lastStepAction === 'chapterChange' &&
      chapterToChange.chapterIdx >= 0 &&
      chapterToChange.stepIdx >= 0
    ) {
      const { chapterIdx, stepIdx } = chapterToChange;

      const chapterInfo = chapterChangeActions[`${chapterIdx}`];

      if (chapterInfo && chapterInfo.nextComponent === payload) {
        dispatch(initialTourSlice.actions.confirmChapterChange(stepIdx));
        setTimeout(() => {
          dispatch(initialTourSlice.actions.setLastStepAction(''));
        }, 500);
        return;
      }
    }

    if (
      lastStepAction === 'newStepIndex' &&
      Math.abs(newStepIndex - stepIndex) === 1
    ) {
      if (newStepIndex > stepIndex) {
        const pageChange = pageChangePoints[stepIndex];
        const stepAction = stepChangeActions[`${stepIndex}`];

        if (
          (pageChange && pageChange.nextComponent === payload) ||
          (stepAction && stepAction.nextComponent === payload)
        ) {
          dispatch(initialTourSlice.actions.goToNextStep());
        }
      } else {
        const pageChange = pageChangePoints[stepIndex - 1];
        const stepAction = stepChangeActions[`undo${stepIndex - 1}`];

        if (
          (pageChange && pageChange.component === payload) ||
          (stepAction && stepAction.prevComponent === payload)
        ) {
          dispatch(initialTourSlice.actions.goToPrevStep());
        }
      }
    }
  };

export const addAssetToTemplateOnTour = (): AppThunk => (dispatch) => {
  dispatch(
    setNewTemplate({
      name: '',
      assets: [{ tag: '', label: '', props: [], selectOpened: false }],
    }),
  );
};

export const addPropToAssetOnTour = (): AppThunk => (dispatch) => {
  dispatch(
    setNewTemplate({
      name: '',
      assets: [
        {
          tag: '',
          label: '',
          selectOpened: false,
          props: [
            {
              label: '',
              tag: '',
              dataType: '',
              readOnly: false,
              isKey: false,
              required: false,
              selectOpened: false,
            },
          ],
        },
      ],
    }),
  );
};

export const {
  stopTour,
  restartTour,
  goToNextStep,
  goToPrevStep,
  setNewStepIndex,
  clearInitialTourState,
  changeDeployStepOnTour,
  changeDeployCCOpteOnTour,
  changeDeployTemplateOnTour,
  changeTemplateViewOrCreateOnTour,
} = initialTourSlice.actions;

export default initialTourSlice.reducer;
