import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNetworks } from '../Contexts/Networks';
import { clearAddOrgNotifications } from '../store/AddOrgNotifications';

export interface AddOrganizationContextData {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  orgs: IOrg[];
  setOrgs: Dispatch<SetStateAction<IOrg[]>>;
  channels: Channel[];
  setChannels: Dispatch<SetStateAction<Channel[]>>;
  clearFormData(): void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isValid: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  started: boolean;
  setStarted: Dispatch<SetStateAction<boolean>>;
}

const AddOrganizationContext = createContext<AddOrganizationContextData>(
  {} as AddOrganizationContextData,
);

const AddOrganizationProvider: React.FC = ({ children }) => {
  const initialOrgState: IOrg[] = [
    {
      id: 0,
      orgDomainName: '',
      orgName: '',
      authServer: {
        authMethod: 'none',
        oauthURL: '',
        oauthOpenID: '',
        oauthClientID: '',
        oauthClientSecret: '',
      },
      peers: [],
      caIP: '',
      ccapi: [],
      user: '',
      passwd: '',
      confirmPasswd: '',
      caPort: undefined,
      caOperationPort: undefined,
    },
  ];

  const dispatch = useDispatch();
  const [orgs, setOrgs] = useState<IOrg[]>(initialOrgState);
  const [loading, setLoading] = useState(false);
  const [started, setStarted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [channels, setChannels] = useState<Channel[]>([]);

  const { selectedNetwork } = useNetworks();

  useEffect(() => {
    if (!selectedNetwork?.channels) return;

    const channelsArray = Object.entries(selectedNetwork?.channels).map(
      ([channelName, channelInfo]) => {
        return {
          channelName,
          peers: channelInfo.peers,
          chaincodes: [],
        };
      },
    );
    console.log('array', channelsArray);

    setChannels(channelsArray as Channel[]);
  }, [selectedNetwork]);

  const clearFormData = useCallback(() => {
    setOrgs(initialOrgState);
    setLoading(false);

    dispatch(clearAddOrgNotifications());
  }, [initialOrgState, dispatch]);

  return (
    <AddOrganizationContext.Provider
      value={{
        orgs,
        setOrgs,
        loading,
        setLoading,
        clearFormData,
        activeStep,
        setActiveStep,
        isValid,
        setIsValid,
        started,
        setStarted,
        channels,
        setChannels,
      }}
    >
      {children}
    </AddOrganizationContext.Provider>
  );
};

function useAddOrganizationForm(): AddOrganizationContextData {
  const context = useContext(AddOrganizationContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { AddOrganizationProvider, useAddOrganizationForm };
