import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearAddWebClientNotifications } from '../store/AddWebClientNotifications';

export interface INode {
  value: string;
  webClient: boolean;
  ccRestHost: boolean;
}

export interface IMappedOrg {
  name: string;
  nodes: INode[];
  firstName: string;
}

interface AddWebClientContextData {
  selectedOrgIdx: number;
  setSelectedOrgIdx: Dispatch<SetStateAction<number>>;
  selectedPeer: INode;
  setSelectedPeer: Dispatch<SetStateAction<INode>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  sendingData: boolean;
  setSendingData: Dispatch<SetStateAction<boolean>>;
  orgs: IMappedOrg[];
  setOrgs: Dispatch<SetStateAction<IMappedOrg[]>>;
  channelName: string;
  setChannelName: Dispatch<SetStateAction<string>>;
  netStateOrgs: INetStateOrg[];
  setNetStateOrgs: Dispatch<SetStateAction<INetStateOrg[]>>;
  channelDefs: INetStateChannelDef[];
  setChannelDefs: Dispatch<SetStateAction<INetStateChannelDef[]>>;
  clearFormData(): void;
}

const AddWebClientContext = createContext<AddWebClientContextData>(
  {} as AddWebClientContextData,
);

const AddWebClientProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [orgs, setOrgs] = useState<IMappedOrg[]>([]);
  const [sendingData, setSendingData] = useState(false);
  const [selectedOrgIdx, setSelectedOrgIdx] = useState(-1);
  const [netStateOrgs, setNetStateOrgs] = useState<INetStateOrg[]>([]);
  const [channelDefs, setChannelDefs] = useState<INetStateChannelDef[]>([]);
  const [selectedPeer, setSelectedPeer] = useState<INode>({
    value: '',
  } as INode);

  const clearFormData = useCallback(() => {
    setSelectedOrgIdx(-1);
    setSelectedPeer({ value: '' } as INode);
    setLoading(false);
    setSendingData(false);
    setOrgs([]);

    dispatch(clearAddWebClientNotifications());
  }, [dispatch]);

  return (
    <AddWebClientContext.Provider
      value={{
        selectedOrgIdx,
        setSelectedOrgIdx,
        selectedPeer,
        setSelectedPeer,
        loading,
        setLoading,
        sendingData,
        setSendingData,
        orgs,
        setOrgs,
        channelName,
        setChannelName,
        netStateOrgs,
        setNetStateOrgs,
        channelDefs,
        setChannelDefs,
        clearFormData,
      }}
    >
      {children}
    </AddWebClientContext.Provider>
  );
};

function useAddWebClientForm(): AddWebClientContextData {
  const context = useContext(AddWebClientContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { AddWebClientProvider, useAddWebClientForm };
