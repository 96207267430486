import styled from 'styled-components';
import { Paper, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

const shadows = {
  soft: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
  medium:
    'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
  hard: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
  large: 'rgba(17,12,46,0.15) 0px 48px 100px 0px',
};
export const Auth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  padding: 3.2rem;
  border-radius: '5px';
  box-shadow: ${shadows.large};
  @media (max-width: 600px) {
    max-width: 95%;
    padding: 2.4rem;
  }
  & button[type='submit'] {
    margin: 2.4rem 0;
    box-shadow: ${shadows.medium};
  }
`;

export const Logo = styled(Link).attrs({
  as: 'div',
})`
  margin-bottom: 2.2rem;
  cursor: pointer;
`;

export const RedirectLink = styled.div`
  color: var('--gray');
  font-size: 0.8rem;
  margin-top: 4rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  a {
    color: ${lighten(0.2, '#003F69')};
    font-weight: 500;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const LoginCard = styled(Paper).attrs({ elevation: 8 })`
  && {
    width: 650px;
    display: flex;
    padding-top: 50px;
    padding-left: 150px;
    border-radius: 20px;
    padding-right: 150px;
    padding-bottom: 50px;
    flex-direction: column;
    background-color: var(--backgroundSecondary);
  }
`;

export const LoginMessage = styled(Typography)`
  && {
    word-wrap: break-word;
    text-align: center;
    margin: 30px 0;
  }
`;

export const LanguagesContainer = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

export const LoginBtn = styled(Button).attrs({
  variant: 'contained',
})`
  && {
    font-size: 16px;
    color: var(--white);
    background: var(--primary);
  }
`;

export const FooterImgContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;
