import styled from 'styled-components';
import { Badge } from '@material-ui/core';
import { darken } from 'polished';
import { Colors } from '../../styles/globals';

export const Container = styled.div`
  position: fixed;
  right: -5px;
  top: 45px;
  padding: 30px;
  overflow: hidden;
  z-index: 1350;
`;

export const HelpIcon = styled(Badge)`
  cursor: pointer;
  color: var(--white);

  &:hover {
    opacity: 0.7;
  }
`;

export const Toast = styled.div`
  width: 300px;

  position: relative;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--tourToastBackground);
  color: var(--tourToastText);
  margin: 0 auto;

  button {
    background: var(--tourToastButton);
    color: var(--tourToastText);
    font-weight: bold;

    &:hover {
      background: ${darken(0.06, Colors.tourToastButton)};
    }
  }

  button + button {
    position: absolute;
    right: 8px;
    top: 8px;
    border: 0;
    color: var(--tourToastText);
    background: none;
    outline: 0;
    cursor: pointer;

    &:hover {
      background: none;
      opacity: 0.9;
    }
  }
`;
