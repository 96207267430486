import React, { useState } from 'react';
import { Box, Icon, Tooltip } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';

import WidgetsIcon from '@material-ui/icons/Widgets';
import { Container, Typography } from './styles';

export interface IBlockInfo extends Record<string, any> {
  id: number;
  size: number;
  hash: string;
  shape: string;
  image: string;
  label: string;
  color: string;
  block: IBlockInfoResponse;
}

export interface IBlockInfoResponse {
  header: {
    number: string;
    previous_hash: string;
    data_hash: string;
  };
  data: {
    data: string[];
  };
  metadata: {
    metadata: string[];
  };
}
const SingleBlockInfo: React.FC<{ info: IBlockInfo }> = ({ info }) => {
  const [tooltip, setTooltip] = useState('Click to copy');
  if (Object.keys(info).length)
    return (
      <Container>
        <Typography
          style={{
            fontSize: '18px',
            textAlign: 'center',
            borderBottom: '1px solid var(--white)',
          }}
        >
          Block #{info.block.header.number}
        </Typography>

        <Typography variant="overline">Current hash</Typography>
        <CopyToClipboard
          onCopy={() => setTooltip('Copied with success')}
          text={info.hash}
        >
          <Tooltip
            TransitionProps={{ timeout: 0 }}
            onClose={() => setTooltip('Click to copy')}
            title={tooltip}
          >
            <Typography style={{ cursor: 'pointer' }}>
              {info.hash.slice(0, 25)}...
            </Typography>
          </Tooltip>
        </CopyToClipboard>

        {info.block.header.previous_hash ? (
          <Typography variant="overline">Previous hash</Typography>
        ) : null}
        {info.block.header.previous_hash ? (
          <CopyToClipboard
            onCopy={() => setTooltip('Copied with success')}
            text={info.block.header.previous_hash}
          >
            <Tooltip
              TransitionProps={{ timeout: 0 }}
              onClose={() => setTooltip('Click to copy')}
              title={tooltip}
            >
              <Typography style={{ cursor: 'pointer' }}>
                {info.block.header.previous_hash.slice(0, 25)}...
              </Typography>
            </Tooltip>
          </CopyToClipboard>
        ) : null}

        {/* <CopyToClipboard
          onCopy={() => setTooltip('Copied with success')}
          text={JSON.stringify(info.block.data.data)}
        >
          <Tooltip
            TransitionProps={{ timeout: 0 }}
            onClose={() => setTooltip('Click to copy')}
            title={tooltip}
          >
            <Typography style={{ ...styles.typo, cursor: 'pointer' }}>
              Block data: {JSON.stringify(info.block.data.data).slice(0, 25)}...
            </Typography>
          </Tooltip>
        </CopyToClipboard> */}
      </Container>
    );
  return null;
};

export default SingleBlockInfo;
