import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import OtpInput from 'react-otp-input';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { authAPI } from '../../Common/axios';
import * as S from './styles';

export const OTPForm = () => {
  const [otp, setOtp] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };

  const location = useLocation<{ username: string }>();
  const history = useHistory();
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await authAPI.post('/otp', { otp, username: location?.state?.username });
      toast.success('Account verified!');
      history.push('/');
    } catch (err) {
      const regex = /:(.*?)\n/;
      const error = err as AxiosError;
      toast.error(error.response?.data.match(regex)?.[1]?.trim());
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.OTPForm onSubmit={onSubmit}>
      <OtpInput
        numInputs={6}
        value={otp}
        onChange={handleChange}
        separator={
          <span>
            <strong>.</strong>
          </span>
        }
        inputStyle={{
          width: '3rem',
          height: '3rem',
          margin: '0 1rem',
          fontSize: '2rem',
          borderRadius: 4,
          border: '1px solid rgba(0,0,0,0.3)',
        }}
      />
      <S.FormActions>
        <Button
          size="large"
          style={{ margin: '0', width: '150px' }}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={26} /> : 'Confirm'}
        </Button>
        {/* <Link to="/recoverypassword">Resend code</Link> */}
      </S.FormActions>
    </S.OTPForm>
  );
};
