import styled, { css } from 'styled-components';

type PropProps = {
  isDragging: boolean;
};

export const Prop = styled.div<PropProps>`
  cursor: grab;

  ${({ isDragging }) =>
    isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      background: transparent;
      cursor: grabbing;
      padding: 10px;
      margin-bottom: 5px;

      opacity: 0.7 !important;
    `}
`;

export const FieldsFormCheckBoxes = styled.div`
  width: 100%;
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1034px) {
    margin-left: 15px;
  }
`;

export const FieldsForm = styled.div`
  display: flex;
`;
