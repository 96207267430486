import Axios from 'axios';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';
import history from '../../history';
import { networkApi } from '../../Common/axios';

import { StoreState } from '../../store/types';
import { openDialog } from '../../store/Dialog';
import { ITemplate } from '../../store/TemplateCC';
import CustomTimeoutModal from '../../AppComponents/CustomTimeoutModal';
import {
  addOperation,
  Operation,
  removeOperation,
} from '../../store/AppStatus';
import {
  Org,
  Peer,
  useUpgradeChaincodeForm,
} from '../../Hooks/UpgradeChaincode';
import { clearChaincodeNotifications } from '../../store/ChaincodeNotifications';

import SelectNetwork from '../../AppComponents/SelectNetwork';
import cancelWithDialog from '../../utils/cancelRequestWithModal';
import { UpgradeCCForm } from '../../Forms/UpgradeCCForm';
import { CCTypes } from '../../AppComponents/CCTypes';
import { useNetworks } from '../../Contexts/Networks';
import DefineEndorsement from '../../AppComponents/Endorsement/DefineEndorsement';
import LoadingScreen from '../LoadingScreen';
import Tooltip from '../../AppComponents/Tooltip';

import canRunOperation from '../../utils/canRunOperation';
import { OperationStateCard } from '../../AppComponents/OperationStateCard';
import { useOperation } from '../../Contexts/Operation';
import {
  parseNetStateTemplate,
  updateTemplateWithDatabase,
} from '../../utils/template';
import { useOPHistory } from '../../Contexts/OPHistory';
import EndorsementModal from '../../AppComponents/Endorsement/EndorsementModal';
import GlobalLoading from '../../store/GlobalLoading';

type RequestPeer = {
  ip: string;
  name: string;
  ccApi: boolean;
  webClient: boolean;
  grpcTimeout: number;
};

type TargetValue = {
  netApiIP: string;
  peers: RequestPeer[];
};

type Targets = Record<string, TargetValue>;

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const UpgradeChaincode = () => {
  const { t } = useTranslation();
  const dispatch = useCallback(useDispatch(), []);

  const { templateList }: { templateList: ITemplate[] } = useSelector(
    (state: StoreState) => state.templateCCState,
  );

  const {
    sending,
    setSending,
    version,
    clearFormData,
  } = useUpgradeChaincodeForm();

  const {
    selectedNetwork: originalSelectNetwork,
    // setSelectedNetwork,
    selectedChannel,
    loadingNetState,
    getAllNetworks,
    fetchNetworkState,
  } = useNetworks();

  const { templateOption } = useSelector(
    (state: StoreState) => state.templateCCState,
  );

  const {
    orgs,
    setOrgs,
    selectedChaincode,
    setSelectedChaincode,
    peers,
    ccFile,
    setCcFile,
    grpcTimeout,
    customTimeoutModalOpened,
    setCustomTimeoutModalOpened,
    setGrpcTimeout,
    setNetDefTemplate,
    netDefTemplate,
    selectedNetwork,
    setSelectedNetwork,
  } = useUpgradeChaincodeForm();

  useEffect(() => {
    fetchNetworkState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(
    'original',
    originalSelectNetwork?.chaincodes?.[selectedChaincode]
      ?.templateDef as ITemplate,
  );
  // set define permissions state
  useEffect(() => {
    if (loadingNetState) return;
    if (
      selectedNetwork?.chaincodes?.[selectedChaincode]?.ccType === 'template'
    ) {
      setNetDefTemplate(
        parseNetStateTemplate({
          chaincodeName: selectedChaincode as string,
          // templateDef:
          //   (selectedNetwork?.chaincodes?.[selectedChaincode]
          //     ?.templateDef as ITemplate) ||
          //   selectedNetwork?.chaincodes?.[selectedChaincode]
          //     ?.templateDefinition,
          templateDef:
            (originalSelectNetwork?.chaincodes?.[selectedChaincode]
              ?.templateDef as ITemplate) ||
            originalSelectNetwork?.chaincodes?.[selectedChaincode]
              ?.templateDefinition,
        }),
      );
    }
  }, [
    loadingNetState,
    originalSelectNetwork,
    selectedChaincode,
    selectedNetwork,
    setNetDefTemplate,
    templateList,
  ]);

  // useEffect(() => {
  //   if (
  //     netDefTemplate &&
  //     selectedNetwork?.chaincodes?.[selectedChaincode] &&
  //     selectedNetwork?.chaincodes?.[selectedChaincode]?.ccType === 'template'
  //   ) {
  //     selectedNetwork.chaincodes[
  //       selectedChaincode
  //     ].templateDefinition = netDefTemplate;

  //     const newCCName = netDefTemplate?.name?.includes('/')
  //       ? netDefTemplate?.name?.split('/')?.[1]
  //       : netDefTemplate?.name;

  //     if (newCCName && newCCName !== selectedChaincode) {
  //       selectedNetwork.chaincodes[newCCName] = {
  //         ...selectedNetwork.chaincodes[selectedChaincode],
  //       };
  //       delete selectedNetwork.chaincodes[selectedChaincode];
  //       setSelectedChaincode(newCCName);
  //     }

  //     setSelectedNetwork({ ...selectedNetwork });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [netDefTemplate]);

  useEffect(() => {
    const newOrgs = selectedNetwork?.channels?.[selectedChannel]?.peers
      ? Object.keys(selectedNetwork?.channels?.[selectedChannel]?.peers)
      : [];
    const orgNames = newOrgs.map((orgName) => ({ orgName }));
    setOrgs(orgNames as Org[]);
  }, [selectedChannel, selectedNetwork, setOrgs]);

  useEffect(() => {
    if (originalSelectNetwork) {
      setSelectedNetwork(originalSelectNetwork);
    }
  }, [originalSelectNetwork, setSelectedNetwork]);

  const { getHistory } = useOPHistory();

  const validatePeers = (peersValidation: Peer) => {
    if (Object.values(peersValidation)?.length < 1)
      throw Error('Check at least one peer to be upgraded');
  };

  const validateVersion = useCallback(() => {
    if (
      selectedNetwork?.chaincodes[selectedChaincode]?.version === version ||
      !version
    )
      throw Error('Upgrade the chaincode version');
  }, [selectedChaincode, selectedNetwork, version]);

  const { selectedTemplate }: { selectedTemplate: ITemplate } = templateOption;

  const sendData = useCallback(() => {
    // console.log('selectedTemplate', selectedTemplate);
    console.log('selectedTemplate: ', netDefTemplate);

    try {
      setSending(true);
      dispatch(clearChaincodeNotifications());
      const formData = new FormData();
      // const templateDef = selectedTemplate;
      const templateDef = netDefTemplate;
      const ccBaseName =
        selectedNetwork?.chaincodes[selectedChaincode]?.ccBaseName;
      const chaincode = {
        [selectedChaincode]: {
          ccType: selectedNetwork?.chaincodes[selectedChaincode]?.ccType,
          tarName: selectedNetwork?.chaincodes[selectedChaincode]?.tarName,
          ...(ccBaseName ? { ccBaseName } : {}),
          ...(templateDef ? { templateDef } : {}),
          version,
          label: `${selectedChaincode}_${version}`,
          peers,
          ...(selectedNetwork?.chaincodes[selectedChaincode]?.endorsement
            ? {
                endorsement:
                  selectedNetwork?.chaincodes[selectedChaincode]?.endorsement,
              }
            : {}),
        },
      };

      formData.append('payload', JSON.stringify(chaincode));
      if (ccFile) formData.append('ccFiles', ccFile);

      formData.append('networkName', selectedNetwork?.networkName as string);
      formData.append('channelName', selectedChannel);

      validateVersion();
      validatePeers(peers);
      setSending(true);
      networkApi
        .post('/upgradechaincode', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(
                c,
                t('title.chaincodes.upgradeChaincode'),
                hasDialog,
              );
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.chaincodes.upgradeChaincode'),
                pathname: window.location.pathname,
                name: 'upgradechaincode',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();

          await fetchNetworkState();
          dispatch(removeOperation('upgradechaincode', true));
          clearFormData();

          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: 'Chaincode upgraded successfully',
            }),
          );

          if (window.location.pathname === '/chaincode/upgrade') {
            history.push('/dashboard');
          }
        })
        .catch(() => {
          dispatch(removeOperation('upgradechaincode', false));
          setSending(false);
        });
    } catch (error) {
      dispatch(removeOperation('upgradechaincode', false));
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error?.message,
        }),
      );
      console.log(error);
      setSending(false);
    } finally {
      getHistory();
    }
  }, [
    ccFile,
    clearFormData,
    dispatch,
    fetchNetworkState,
    getAllNetworks,
    getHistory,
    netDefTemplate,
    peers,
    selectedChaincode,
    selectedChannel,
    selectedNetwork,
    setSending,
    t,
    validateVersion,
    version,
  ]);

  const handleCCTypeChange = useCallback(
    (opt: ChaincodeType) => {
      if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
        selectedNetwork.chaincodes[selectedChaincode].ccType = opt;
        setSelectedNetwork({ ...selectedNetwork });
      }
    },
    [selectedChaincode, selectedNetwork, setSelectedNetwork],
  );

  const handleFileUpload = useCallback(
    (file: FileList | null) => {
      setCcFile(file?.[0] as File);
    },
    [setCcFile],
  );

  const handleSelectTemplate = (ccName: string) => {
    if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
      selectedNetwork.chaincodes[selectedChaincode].ccBaseName = ccName;

      const { templateDefinition } = selectedNetwork.chaincodes[
        selectedChaincode
      ];

      if (templateDefinition) {
        const { name } = templateDefinition;
        const newCCName = name?.includes('/')
          ? name?.split('/')?.[1]
          : name || '';

        if (newCCName !== selectedChaincode) {
          selectedNetwork.chaincodes[newCCName] = {
            ...selectedNetwork.chaincodes[selectedChaincode],
          };
          delete selectedNetwork.chaincodes[selectedChaincode];
        }

        setSelectedChaincode(newCCName);
      }

      setSelectedNetwork({ ...selectedNetwork });
    }
  };

  const handleSelectFromCloud = (ccName: string) => {
    if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
      console.log('ccName', ccName);
      selectedNetwork.chaincodes[selectedChaincode].ccBaseName = ccName;

      // handle user cloud options to get only ccName and remove userID
      const newCCName = ccName.includes('/') ? ccName?.split('/')?.[1] : ccName;

      if (newCCName !== selectedChaincode) {
        selectedNetwork.chaincodes[newCCName] = {
          ...selectedNetwork.chaincodes[selectedChaincode],
        };
        delete selectedNetwork.chaincodes[selectedChaincode];
        setSelectedChaincode(newCCName);
      }

      setSelectedNetwork({ ...selectedNetwork });
    }
  };

  const handleSelectTemplateDefinition = (templateDefinition: {
    value: IGlobalTemplate;
    label: string;
  }) => {
    if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
      selectedNetwork.chaincodes[selectedChaincode].templateDefinition =
        templateDefinition?.value;
      selectedNetwork.chaincodes[selectedChaincode].templateDef =
        templateDefinition?.value;

      const newCCName = templateDefinition?.label?.includes('/')
        ? templateDefinition?.label?.split('/')?.[1]
        : templateDefinition?.label;

      if (newCCName !== selectedChaincode) {
        selectedNetwork.chaincodes[newCCName] = {
          ...selectedNetwork.chaincodes[selectedChaincode],
        };

        selectedNetwork.channels[
          selectedChannel
        ].chaincodes = selectedNetwork?.channels?.[
          selectedChannel
        ]?.chaincodes?.map((cc) => {
          if (cc === selectedChaincode) {
            return newCCName;
          }
          return cc;
        });

        delete selectedNetwork.chaincodes[selectedChaincode];
        setSelectedChaincode(newCCName);
      }

      setSelectedNetwork({ ...selectedNetwork });
      setNetDefTemplate(templateDefinition?.value);
    }
  };

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const cantRunOperation = useCallback(() => !canRunOperation(operations), [
    operations,
  ]);

  const canShowForm = useMemo(() => selectedChannel && selectedChaincode, [
    selectedChaincode,
    selectedChannel,
  ]);

  const { upgradeCCState } = useOperation();

  console.log('SELECTED CHAINCODE', selectedChaincode);

  if (!selectedNetwork || !selectedChannel || loadingNetState) {
    return <SelectNetwork />;
  }

  return (
    <>
      <Box
        padding="2rem"
        display="flex"
        margin="0 auto"
        flexDirection="column"
        maxWidth="800px"
      >
        <Typography variant="overline" style={{ fontSize: '25px' }}>
          <Trans>title.chaincodes.upgradeChaincode</Trans>
        </Typography>

        {canShowForm ? (
          <></>
        ) : (
          <Typography variant="overline" style={{ fontSize: '20px' }}>
            Select a chaincode
          </Typography>
        )}

        <UpgradeCCForm />

        {canShowForm && (
          <>
            <CCTypes
              // channelIndex={0}
              chaincode={
                ({
                  ...selectedNetwork?.chaincodes?.[selectedChaincode],
                  chaincodeName: selectedChaincode,
                } as unknown) as IChaincodes
              }
              orgs={orgs}
              currentChaincode={selectedChaincode}
              handleCCTypeChange={handleCCTypeChange}
              file={ccFile}
              handleFileUpload={handleFileUpload}
              operation="upgradechaincode"
              handleSelectTemplate={handleSelectTemplate}
              handleSelectFromCloud={handleSelectFromCloud}
              handleSelectTemplateDefinition={handleSelectTemplateDefinition}
            />
            <CustomTimeoutModal
              open={customTimeoutModalOpened}
              setOpen={setCustomTimeoutModalOpened}
              onSave={(v) => setGrpcTimeout(v.grpcTimeout)}
            />
          </>
        )}

        <h3 style={{ margin: '24px 0' }}>
          <Trans>common.words.endorsement</Trans>
        </h3>

        <DefineEndorsement
          endorsement={
            selectedNetwork?.chaincodes?.[selectedChaincode]?.endorsement
          }
          onFinish={(value: IEndorsementEvent) => {
            if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
              selectedNetwork.chaincodes[selectedChaincode].endorsement =
                value.endorsement;
              selectedNetwork.chaincodes[selectedChaincode].endorsementGUI =
                value.endorsementGUI;

              setSelectedNetwork({ ...selectedNetwork });
            }
          }}
          disabled={
            selectedNetwork?.chaincodes?.[
              selectedChaincode
            ]?.templateDef?.assets.some(
              (asset) => asset.privateData === true,
            ) ||
            selectedNetwork?.chaincodes?.[
              selectedChaincode
            ]?.templateDefinition?.assets.some(
              (asset) => asset.privateData === true,
            )
          }
        />

        <div style={{ marginBottom: '24px' }}>
          <h3 style={{ margin: '24px 0' }}>
            <Trans>common.words.advanced</Trans>
          </h3>

          <Button
            variant="outlined"
            disabled={sending}
            onClick={() => setCustomTimeoutModalOpened(true)}
          >
            <Trans>asset.network.deploy.timeoutSettings</Trans>
          </Button>
        </div>
        <div style={{ marginTop: '20px' }}>
          {sending ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ gap: '2rem' }}
            >
              <LoadingScreen
                content={t('asset.chaincodes.upgradeChaincode.loadingMessage')}
              />

              <Button
                fullWidth
                color="secondary"
                variant="contained"
                style={{ display: 'block' }}
                onClick={() => cancelRequest()}
              >
                <Trans>button.cancel</Trans>
              </Button>
              <OperationStateCard taskOperation={upgradeCCState} />
            </Box>
          ) : (
            <Tooltip
              canShow={cantRunOperation()}
              message={t('common.messages.onlyOneOperation')}
            >
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={sending || cantRunOperation() || !canShowForm}
                style={{ display: 'block', pointerEvents: 'all' }}
                onClick={() => {
                  if (!sending || !cantRunOperation()) sendData();
                }}
              >
                <Trans>button.send</Trans>
              </Button>
            </Tooltip>
          )}
        </div>
      </Box>

      <EndorsementModal
        orgs={orgs}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endorsementGUI={
          selectedNetwork?.chaincodes?.[selectedChaincode]?.endorsementGUI
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentEndorsement={
          selectedNetwork?.chaincodes?.[selectedChaincode]?.endorsement!
        }
        onFinish={(value: IEndorsementEvent) => {
          if (selectedNetwork?.chaincodes?.[selectedChaincode]) {
            selectedNetwork.chaincodes[selectedChaincode].endorsement =
              value.endorsement;
            selectedNetwork.chaincodes[selectedChaincode].endorsementGUI =
              value.endorsementGUI;

            setSelectedNetwork({ ...selectedNetwork });
          }
        }}
      />
    </>
  );
};

export default UpgradeChaincode;
