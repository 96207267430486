import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearBackupNotifications } from '../store/BackupNotifications';

interface BackupCreateContextData {
  sending: boolean;
  setSending: Dispatch<SetStateAction<boolean>>;
  clearFormData(): void;
}

const BackupCreateContext = createContext<BackupCreateContextData>(
  {} as BackupCreateContextData,
);

const BackupCreateProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);

  const clearFormData = useCallback(() => {
    setSending(false);

    dispatch(clearBackupNotifications());
  }, [dispatch]);

  return (
    <BackupCreateContext.Provider
      value={{
        sending,
        setSending,
        clearFormData,
      }}
    >
      {children}
    </BackupCreateContext.Provider>
  );
};

function useBackupCreateForm(): BackupCreateContextData {
  const context = useContext(BackupCreateContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { BackupCreateProvider, useBackupCreateForm };
