import { store } from '../../../../store';
import { ITemplate, IAsset } from '../../../../store/TemplateCC';
import { openDialog } from '../../../../store/Dialog';
import { TranslationFunction } from '../../../../store/types';
import {
  templateNamePatt,
  assetAndPropTagPatt,
} from '../../../../utils/regexPatterns';

const transBasePathAsset = 'asset.chaincodes.templates.manageAssets';

const transBasePathTemplate = 'asset.chaincodes.templates.manageTemplates';

// True if the string is invalid or if it's not a string
const isTemplateStrInvalid = (value: string) =>
  !value || value === '' || value.indexOf(' ') >= 0;

const isAssetStrInvalid = (value: string, allowWhiteSpace = false) =>
  !value || value === '' || (!allowWhiteSpace && value.indexOf(' ') >= 0);

const validateAsset = (asset: IAsset, t: TranslationFunction) => {
  const { tag, label, props } = asset;
  try {
    const assetLabelObj = { assetLabel: label };

    if (isAssetStrInvalid(tag) || !assetAndPropTagPatt.test(tag)) {
      throw Error(
        `${t(`${transBasePathAsset}.invalidTag`, assetLabelObj)}. ${t(
          'asset.chaincodes.templates.common.nameAndTagPattern',
        )}`,
      );
    }

    if (isAssetStrInvalid(label, true))
      throw Error(t(`${transBasePathAsset}.invalidLabel`, assetLabelObj));

    if (!props || props.length === 0)
      throw Error(t(`${transBasePathAsset}.mustHaveProp`, assetLabelObj));

    let countKeys = 0;

    props.forEach((prop) => {
      const propTagObj = { ...assetLabelObj, propTag: prop.tag };

      if (isAssetStrInvalid(prop.tag) || !assetAndPropTagPatt.test(prop.tag)) {
        throw Error(
          t(
            `${t(`${transBasePathAsset}.propInvalidTag`, propTagObj)}. ${t(
              'asset.chaincodes.templates.common.nameAndTagPattern',
            )}`,
          ),
        );
      }

      if (isAssetStrInvalid(prop.label, true)) {
        throw Error(t(`${transBasePathAsset}.propInvalidLabel`, propTagObj));
      }

      if (isAssetStrInvalid(prop.dataType))
        throw Error(t(`${transBasePathAsset}.propInvalidDataType`, propTagObj));

      if (prop.isKey) countKeys += 1;
    });

    if (countKeys < 1) {
      throw Error(
        t(`${transBasePathAsset}.mustHaveOneKey`, { assetLabel: label }),
      );
    }

    return true;
  } catch (error) {
    store.dispatch(
      openDialog({
        type: 'error',
        title: t('common.words.error'),
        content: error.message,
      }),
    );

    return false;
  }
};

export const validateTemplate = (
  template: ITemplate,
  t: TranslationFunction,
) => {
  try {
    if (
      isTemplateStrInvalid(template.name) ||
      !templateNamePatt.test(template.name)
    )
      throw Error(
        `${t(`${transBasePathTemplate}.invalidName`)}! ${t(
          'asset.chaincodes.templates.common.nameAndTagPattern',
        )}`,
      );

    if (template.assets) {
      let assetInvalid = false;
      for (let i = 0; i < template.assets.length; i++) {
        assetInvalid = !validateAsset(template.assets[i], t);

        if (assetInvalid) break;
      }

      if (assetInvalid) return false;
    }

    return true;
  } catch (error) {
    store.dispatch(
      openDialog({
        type: 'error',
        content: error.message,
        title: t('common.words.error'),
      }),
    );

    return false;
  }
};
