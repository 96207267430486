import { createSlice } from '@reduxjs/toolkit';

export interface IGlobalLoadingState extends Record<string, any> {
  open: boolean;
}

const initialState: IGlobalLoadingState = {
  open: false,
};

const globalLoadingSlice = createSlice({
  name: 'globalLoading',
  initialState,
  reducers: {
    openLoading(state) {
      state.open = true;
    },
    closeLoading(state) {
      state.open = false;
    },
  },
});

export const { openLoading, closeLoading } = globalLoadingSlice.actions;

export default globalLoadingSlice.reducer;
