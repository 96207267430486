import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDeployForm } from '../../../Hooks/deploy';
import GenericInput from '../../../Screens/Deploy/Components/GenericInput';
import { useValidatePort } from '../../../Hooks/useValidatePort';

interface PeersCheckboxProps {
  peer: string;
  orgName: string;
  chaincode: IChaincodes;
  channel: Channel;
  handleApiClick: (event: ChangeEvent<HTMLInputElement>) => void;
  handleInterfaceClick: (event: ChangeEvent<HTMLInputElement>) => void;
  handleInterfacePortChange: (
    port: number,
    peer: string,
    orgName: string,
  ) => void;
  handlePortChange: (
    port: string,
    value: number,
    peer: string,
    orgName: string,
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      flexBasis: '30%',
      flexShrink: 0,
      textTransform: 'uppercase',
      alignSelf: 'center',
      letterSpacing: '0.08333em',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.secondary,
      alignSelf: 'center',
    },
  }),
);

export const PeersCheckbox = ({
  peer,
  orgName,
  chaincode,
  channel,
  handleApiClick,
  handleInterfaceClick,
  handleInterfacePortChange,
  handlePortChange,
}: PeersCheckboxProps) => {
  const styles = useStyles();

  const { orgs } = useDeployForm();

  console.log('ORGS:', orgs);

  const validatePort = useValidatePort();

  const getPort = (portType: string) => {
    const ccapi = orgs
      .find((o) => o.orgName === orgName)
      ?.ccapi.find(
        (cc) =>
          cc.host === peer &&
          cc.channelName === channel.channelName &&
          cc.chaincodeName === chaincode?.chaincodeName,
      );

    if (!ccapi) return '' as string;

    switch (portType) {
      case 'rest': {
        const port = ccapi.restPort as number;
        return port;
      }

      case 'tls':
        return ccapi.TLSPort as number;

      case 'goinitus':
        return ccapi.goinitus?.port as number;

      default:
        return '' as string;
    }
  };

  const [isDefaultTLSPort, setIsDefaultTLSPort] = useState(!getPort('tls'));
  const [isDefaultRestPort, setIsDefaultRestPort] = useState(!getPort('rest'));
  const [isDefaultInitusPort, setIsDefaultInitusPort] = useState(
    !getPort('goinitus'),
  );

  const isChecked = () => {
    const orgIndex = orgs?.findIndex((o) => o?.orgName === orgName);

    const ccapiIndex = orgs?.[orgIndex]?.ccapi.findIndex(
      (cc) =>
        cc.host === peer &&
        cc.chaincodeName === chaincode?.chaincodeName &&
        cc.channelName === channel.channelName,
    );
    const interfaceChecked = Boolean(
      orgs?.[orgIndex]?.ccapi?.[ccapiIndex]?.goinitus,
    );

    return {
      apiChecked: ccapiIndex !== -1,
      interfaceChecked,
    };
  };

  return (
    <>
      <span>
        {orgName}@{peer}
      </span>
      <div>
        <Tooltip
          title={!chaincode?.chaincodeName ? 'Chaincode name is required' : ''}
        >
          <span>
            <Checkbox
              id={`api_${orgName}_${peer}`}
              name={`api_${orgName}_${peer}`}
              onChange={handleApiClick}
              defaultChecked={isChecked().apiChecked}
              checked={isChecked().apiChecked}
              disabled={!chaincode?.chaincodeName}
            />
          </span>
        </Tooltip>
      </div>
      <div>
        <Tooltip
          title={!chaincode?.chaincodeName ? 'Chaincode name is required' : ''}
        >
          <span>
            <Checkbox
              id={`interface_${orgName}_${peer}`}
              name={`interface_${orgName}_${peer}`}
              onChange={handleInterfaceClick}
              defaultChecked={isChecked().interfaceChecked}
              checked={isChecked().interfaceChecked}
              disabled={!chaincode?.chaincodeName}
            />
          </span>
        </Tooltip>
      </div>

      <div
        style={{
          gridColumn: '1/-1',
          paddingBottom: '1rem',
          borderBottom: '1px solid #e6e6e6',
        }}
      >
        <Accordion
          style={{
            boxShadow: 'none',
            background: 'none',
            border: '1px solid #ddd',
          }}
          disabled={!isChecked().apiChecked && !isChecked().interfaceChecked}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={styles.heading}>Advanced</Typography>
            <Typography className={styles.secondaryHeading}>
              Configure Instance ports
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column', gap: '1.5rem' }}>
            {isChecked().apiChecked && (
              <Box display="flex" style={{ gap: '1.5rem' }}>
                <Box display="flex" flexDirection="column">
                  <GenericInput
                    type="number"
                    value={getPort('rest')}
                    style={{ maxWidth: '256px' }}
                    label="Rest Port"
                    onChange={(e) =>
                      handlePortChange(
                        'restPort',
                        Number(e.target.value),
                        peer,
                        orgName,
                      )
                    }
                    disabled={isDefaultRestPort}
                    helperText={(() => {
                      const isValid = validatePort(
                        getPort('rest'),
                        orgName,
                        'restPort',
                        peer,
                      );
                      return isValid?.portInUse
                        ? `Port already in use by ${isValid.info?.org}${
                            isValid.info?.peer ? `@${isValid.info?.peer}` : ''
                          }'s ${isValid.info?.portName}`
                        : '';
                    })()}
                    error={
                      validatePort(getPort('rest'), orgName, 'restPort', peer)
                        ?.portInUse
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      id={`rest_${orgName}_${peer}_${channel.channelName}_${chaincode.chaincodeName}`}
                      name={`rest_${orgName}_${peer}_${channel.channelName}_${chaincode.chaincodeName}`}
                      onChange={(e) => {
                        handlePortChange(
                          'restPort',
                          ('' as unknown) as number,
                          peer,
                          orgName,
                        );
                        setIsDefaultRestPort(e.target.checked);
                      }}
                      checked={isDefaultRestPort}
                    />
                    <Typography>Default Port</Typography>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column">
                  <GenericInput
                    type="number"
                    value={getPort('tls')}
                    style={{ maxWidth: '256px' }}
                    label="TLS Port"
                    onChange={(e) =>
                      handlePortChange(
                        'TLSPort',
                        Number(e.target.value),
                        peer,
                        orgName,
                      )
                    }
                    disabled={isDefaultTLSPort}
                    helperText={(() => {
                      const isValid = validatePort(
                        getPort('tls'),
                        orgName,
                        'TLSPort',
                        peer,
                      );
                      return isValid?.portInUse
                        ? `Port already in use by ${isValid.info?.org}${
                            isValid.info?.peer ? `@${isValid.info?.peer}` : ''
                          }'s ${isValid.info?.portName}`
                        : '';
                    })()}
                    error={
                      validatePort(getPort('tls'), orgName, 'TLSPort', peer)
                        ?.portInUse
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      id={`tls_${orgName}_${peer}_${channel.channelName}_${chaincode.chaincodeName}`}
                      name={`tls_${orgName}_${peer}_${channel.channelName}_${chaincode.chaincodeName}`}
                      onChange={(e) => {
                        handlePortChange(
                          'TLSPort',
                          ('' as unknown) as number,
                          peer,
                          orgName,
                        );
                        setIsDefaultTLSPort(e.target.checked);
                        return e;
                      }}
                      checked={isDefaultTLSPort}
                    />
                    <Typography>Default Operation Port</Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {isChecked().interfaceChecked && (
              <Box display="flex" style={{ gap: '1.5rem' }}>
                <Box display="flex" flexDirection="column">
                  <GenericInput
                    type="number"
                    value={getPort('goinitus')}
                    style={{ maxWidth: '256px' }}
                    label="Interface Port"
                    onChange={(e) =>
                      handleInterfacePortChange(
                        Number(e.target.value),
                        peer,
                        orgName,
                      )
                    }
                    disabled={isDefaultInitusPort}
                    helperText={(() => {
                      const isValid = validatePort(
                        getPort('goninitus'),
                        orgName,
                        'goinitus',
                        peer,
                      );
                      return isValid?.portInUse
                        ? `Port already in use by ${isValid.info?.org}${
                            isValid.info?.peer ? `@${isValid.info?.peer}` : ''
                          }'s ${isValid.info?.portName}`
                        : '';
                    })()}
                    error={
                      validatePort(
                        getPort('goinitus'),
                        orgName,
                        'goinitus',
                        peer,
                      )?.portInUse
                    }
                  />
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      id={`initus_${orgName}_${peer}_${channel.channelName}_${chaincode.chaincodeName}`}
                      name={`initus_${orgName}_${peer}_${channel.channelName}_${chaincode.chaincodeName}`}
                      onChange={(e) => {
                        handleInterfacePortChange(
                          ('' as unknown) as number,
                          peer,
                          orgName,
                        );
                        setIsDefaultInitusPort(e.target.checked);
                        return e;
                      }}
                      checked={isDefaultInitusPort}
                    />
                    <Typography>Default Port</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
