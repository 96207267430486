import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import OtpInput from 'react-otp-input';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { authAPI } from '../../Common/axios';
import * as S from './styles';
import * as SS from '../../Screens/ForgotPassword/styles';

interface FormValues {
  username: string;
  otp: string;
  password: string;
  confirmPassword: string;
}
export const ForgotPasswordForm = () => {
  const location = useLocation<{ username: string }>();

  const [showOTP, setShowOTP] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const handleChange = (newValue: string) => {
    setValue('otp', newValue);
  };

  const history = useHistory();
  const onSubmit = async (data: FormValues) => {
    if (showOTP) {
      try {
        await authAPI.post('/confirmforgotpw', data);
        toast.success('Password updated successfully!');
        history.push('/');
      } catch (err) {
        const regex = /:(.*?)\n/;
        const error = err as AxiosError;
        toast.error(error.response?.data.match(regex)?.[1]?.trim());
      }
    } else {
      try {
        const response = await authAPI.post('/forgotpw', {
          username: data.username,
        });

        toast.success('OTP sent!');
        setShowOTP(true);
        setValue('username', data.username);

        const regex = /Destination:\s*"([^"]*)"/;
        const match = response.data?.match(regex);

        if (match) {
          const email = match[1];
          setUserEmail(email);
        }
      } catch (err) {
        const regex = /:(.*?)\n/;
        const error = err as AxiosError;
        toast.error(error.response?.data.match(regex)?.[1]?.trim());
      }
    }
  };

  const resendCode = async () => {
    try {
      await authAPI.post<string>('/resend', {
        username: getValues('username'),
      });
      toast.info(`Code sent to your email address`, {
        toastId: 'resendCode',
      });
    } catch (err) {
      const regex = /:(.*?)\n/;
      const error = err as AxiosError;
      toast.error(
        error.response?.data.match(regex)?.[1]?.trim() ||
          'Something went wrong. Please try again later.',
      );
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <SS.Box>
        <SS.Logo to="/">
          <img
            src="/GoLedger.png"
            width={110}
            height={110}
            alt="Goledger Logo"
          />
        </SS.Logo>

        <S.OTPForm onSubmit={handleSubmit(onSubmit)}>
          {!showOTP && (
            <Typography color="textSecondary">
              Please enter your username and we will send you a code to reset
              your password
            </Typography>
          )}
          {showOTP && (
            <Typography color="textSecondary">
              Please enter the code sent to your email address{' '}
              <span style={{ fontWeight: 'bold' }}>{userEmail}</span>
            </Typography>
          )}
          {!showOTP && (
            <TextField
              variant="outlined"
              label="Username"
              {...register('username', { required: 'Username is required' })}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
          )}
          {showOTP && (
            <>
              <OtpInput
                numInputs={6}
                value={getValues('otp')}
                onChange={handleChange}
                separator={
                  <span>
                    <strong>.</strong>
                  </span>
                }
                inputStyle={{
                  width: '3rem',
                  height: '3rem',
                  margin: '0 1rem',
                  fontSize: '2rem',
                  borderRadius: 4,
                  border: '1px solid rgba(0,0,0,0.3)',
                }}
              />

              <Box display="flex" width="100%" style={{ gap: '2rem' }}>
                <FormControl
                  style={{ flexBasis: '50%' }}
                  variant="outlined"
                  error={!!errors.password}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    {...register('password', {
                      required: 'Password is required',
                      // password validation with capital letter, number and special character
                      pattern: {
                        value:
                          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                        message:
                          'Password must have at least one capital letter, number and special character',
                      },
                      validate: (value) =>
                        value !== getValues('confirmPassword')
                          ? 'Password does not match'
                          : true,
                      minLength: {
                        value: 6,
                        message: 'Password must have at least 6 characters',
                      },
                    })}
                    error={!!errors.password}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText error>
                    {errors.password?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  style={{ flexBasis: '50%' }}
                  variant="outlined"
                  error={!!errors.confirmPassword}
                >
                  <InputLabel htmlFor="outlined-adornment-confirm">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm"
                    {...register('confirmPassword', {
                      required: 'Password is required',
                      deps: ['password'],
                      minLength: {
                        value: 6,
                        message: 'Password must have at least 6 characters',
                      },
                    })}
                    error={!!errors.confirmPassword}
                    type={showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={130}
                  />
                  <FormHelperText error>
                    {errors.confirmPassword?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </>
          )}
          <S.FormActions>
            {!showOTP && (
              <Button
                size="large"
                style={{ margin: '0', width: '150px', boxShadow: 'none' }}
                variant="outlined"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={26} /> : 'Send'}
              </Button>
            )}

            {showOTP && (
              <Button
                size="large"
                style={{ margin: '0', width: '150px' }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={26} /> : 'Confirm'}
              </Button>
            )}
          </S.FormActions>
        </S.OTPForm>
      </SS.Box>
      {showOTP && (
        <SS.RedirectLink>
          <Typography variant="overline">
            Didn&apos;t receive the code?
          </Typography>
          <Typography
            variant="overline"
            onClick={resendCode}
            style={{ cursor: 'pointer', color: '#007ccf' }}
          >
            Resend
          </Typography>
        </SS.RedirectLink>
      )}
    </>
  );
};
