import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUserState extends Record<string, any> {
  userName: string;
}

export const initialState: IUserState = {
  userName: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
  },
});

export const { changeUserName } = userSlice.actions;

export default userSlice.reducer;
