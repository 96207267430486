import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as S from './styles';
import { useLicense } from '../../Contexts/License';
import { LicenseModal } from '../../AppComponents/LicenseModal';
import { SignupForm } from '../../AppComponents/SignupForm';

const Signup = () => {
  const { licenseInfo } = useLicense();

  const [open, setOpen] = useState(false);

  return (
    <S.Auth>
      <S.Box>
        <S.Logo to="/">
          <img
            src="/GoLedger.png"
            width={110}
            height={110}
            alt="Goledger Logo"
          />
        </S.Logo>
        <SignupForm />
      </S.Box>
      <S.RedirectLink>
        <Typography variant="overline">Already have an account?</Typography>
        <Link to="/">
          <Typography variant="overline"> Sign in</Typography>
        </Link>
      </S.RedirectLink>
      {process.env?.REACT_APP_SHOW_LICENSE?.toLowerCase?.() !== 'false' && (
        <S.FooterImgContainer>
          <Box
            style={{
              margin: '1rem auto',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              License details
            </Button>
          </Box>
        </S.FooterImgContainer>
      )}

      <LicenseModal
        license={licenseInfo}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
    </S.Auth>
  );
};

export default Signup;
