import React from 'react';
import { Typography } from '@material-ui/core';

import { renderAttributes } from '../../utils';
import { ILoadingContainerCardInfo } from '../../types';
import { FillingCardContainer } from './styles';

interface IGlobalFillingCardProps {
  cardInfo: Omit<ILoadingContainerCardInfo, 'type'>;
}

const GlobalFillingCard: React.FC<IGlobalFillingCardProps> = ({ cardInfo }) => {
  const {
    title,
    canShow,
    attributes,
    titleFontSize,
    loadingStarted,
    notLoadingWidth,
    customAttributes,
  } = cardInfo;

  if (!canShow) return null;
  return (
    <FillingCardContainer
      loadingStarted={loadingStarted}
      notLoadingWidth={notLoadingWidth}
    >
      <div style={{ padding: '0 25px' }}>
        <Typography
          variant="overline"
          style={{ color: 'var(--white)', fontSize: titleFontSize || '25px' }}
        >
          {title}
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {customAttributes
            ? customAttributes()
            : attributes && Object.keys(attributes).length > 0
            ? renderAttributes(attributes)
            : null}
        </div>
      </div>
    </FillingCardContainer>
  );
};

export default GlobalFillingCard;
