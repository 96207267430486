import { store } from '../store';
import { networkApi } from '../Common/axios';
import { fetchEC } from '../store/Instance';
import { fetchNetwork } from '../store/Network';
import authTokenHandler from './authTokenHandler';

export default () => {
  const interval = setInterval(() => {
    const lastCheck = new Date(
      `${sessionStorage.getItem('@GoFabric:lastCheck')}`,
    );
    // Moving the time to an hour after login, so that I can see if
    // the token is no longer valid. The token remains valid for one hour
    lastCheck.setTime(lastCheck.getTime() + 3600000);

    if (new Date() > lastCheck) {
      networkApi
        .get('/ping')
        .then(() => {
          authTokenHandler(true);
          // If the expiration time passed but somehow the token
          // remains valid, I'll update the lastCheck to the current date time
          sessionStorage.setItem(
            '@GoFabric:lastCheck',
            `${new Date().toJSON()}`,
          );

          store.dispatch(fetchEC());
          store.dispatch(fetchNetwork());
        })
        .catch((error) => {
          if (error?.response?.status !== 401) authTokenHandler(false, false);
        });
    }
  }, 60000);

  return interval;
};
