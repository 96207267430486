import styled from 'styled-components';
import { Paper as MPaper } from '@material-ui/core';

export const Container = styled.div`
  max-width: 95%;
  min-width: 700px;
  margin: 30px auto 0 auto;

  @media (max-width: 1450px) {
    max-width: 1200px;
  }
`;

export const Paper = styled(MPaper)`
  width: 100%;
  margin-bottom: 16px;
`;

export const ActionsTableCellContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
`;
