import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearRemovePeerNotifications } from '../store/RemovePeerNotifications';

import { Auth as ICAauth } from '../AppComponents/CAauth';

interface Peer {
  name: string;
  value: string;
}

export interface IMappedOrg {
  name: string;
  peers: Peer[];
  firstName: string;
}

interface RemovePeerContextData {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  peers0: string[];
  setPeers0: Dispatch<SetStateAction<string[]>>;
  CAauth: ICAauth;
  setCAauth: Dispatch<SetStateAction<ICAauth>>;
  sendingData: boolean;
  setSendingData: Dispatch<SetStateAction<boolean>>;
  channelName: string;
  setChannelName: Dispatch<SetStateAction<string>>;
  selectedOrgIdx: number;
  setSelectedOrgIdx: Dispatch<SetStateAction<number>>;
  selectedPeer: Peer;
  setSelectedPeer: Dispatch<SetStateAction<Peer>>;
  orgs: IMappedOrg[];
  setOrgs: Dispatch<SetStateAction<IMappedOrg[]>>;
  netStateOrgs: INetStateOrg[];
  setNetStateOrgs: Dispatch<SetStateAction<INetStateOrg[]>>;
  channelDefs: INetStateChannelDef[];
  setChannelDefs: Dispatch<SetStateAction<INetStateChannelDef[]>>;
  clearFormData(): void;
}

const RemovePeerContext = createContext<RemovePeerContextData>(
  {} as RemovePeerContextData,
);

const RemovePeerProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [peers0, setPeers0] = useState<string[]>([]);
  const [CAauth, setCAauth] = useState<ICAauth>({});
  const [channelName, setChannelName] = useState('');
  const [orgs, setOrgs] = useState<IMappedOrg[]>([]);
  const [sendingData, setSendingData] = useState(false);
  const [selectedOrgIdx, setSelectedOrgIdx] = useState(-1);
  const [netStateOrgs, setNetStateOrgs] = useState<INetStateOrg[]>([]);
  const [channelDefs, setChannelDefs] = useState<INetStateChannelDef[]>([]);
  const [selectedPeer, setSelectedPeer] = useState<Peer>({
    name: '',
    value: '',
  });

  const clearFormData = useCallback(() => {
    setLoading(false);
    setPeers0([]);
    setCAauth({});
    setSendingData(false);
    setChannelName('');
    setSelectedOrgIdx(-1);
    setSelectedPeer({
      name: '',
      value: '',
    });
    setOrgs([]);

    dispatch(clearRemovePeerNotifications());
  }, [dispatch]);

  return (
    <RemovePeerContext.Provider
      value={{
        loading,
        setLoading,
        peers0,
        setPeers0,
        CAauth,
        setCAauth,
        sendingData,
        setSendingData,
        channelName,
        setChannelName,
        selectedOrgIdx,
        setSelectedOrgIdx,
        selectedPeer,
        setSelectedPeer,
        orgs,
        setOrgs,
        netStateOrgs,
        setNetStateOrgs,
        channelDefs,
        setChannelDefs,
        clearFormData,
      }}
    >
      {children}
    </RemovePeerContext.Provider>
  );
};

function useRemovePeerForm(): RemovePeerContextData {
  const context = useContext(RemovePeerContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { RemovePeerProvider, useRemovePeerForm };
