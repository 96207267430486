import styled, { css } from 'styled-components';
import { Paper, Typography, ListItem } from '@material-ui/core';

export const PageContainer = styled.div`
  display: flex;
  padding: 50px 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const PageTitle = styled(Typography).attrs({
  variant: 'overline',
})`
  && {
    display: flex;
    margin: 20px 0;
    font-size: 25px;
    justify-content: center;
  }
`;

export const Card = styled(Paper)`
  && {
    width: 90%;
    padding: 20px;
    display: flex;
    margin: 0 auto;
    min-width: 700px;
    max-width: 850px;
    height: fit-content;
    flex-direction: column;
    background: whitesmoke;
    justify-content: space-between;

    @media (max-width: 1400px) {
      margin-bottom: 20px;
    }
  }
`;

export const Section = styled.div`
  width: 33%;
  margin: 20px;
`;

export const FormSectionTitle = styled(Typography)`
  font-size: 1.1rem;
`;

export const Message = styled(Typography)`
  && {
    font-size: 16px;
    word-wrap: break-word;
    margin: 0 auto 32px auto;
  }
`;

export const SelectListItem = styled(ListItem).attrs(
  (props: { disabled: boolean }) => ({
    button: true,
    divider: true,
    disabled: props.disabled,
  }),
)`
  && {
    margin: 8px 0;
    ${({ selected }) =>
      selected &&
      css`
        border-radius: 5px;
        color: var(--white);
        background-color: var(--darkBlue) !important;
      `};
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
