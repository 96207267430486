import React, { useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import {
  openEndorsement,
  clearEndorsement,
  changeEndorsementWithCallBack,
} from '../../store/Endorsement';
import RenderEndorsement from './RenderEndorsement';

import Tooltip from '../Tooltip';

interface IDefineEndorsementProps {
  endorsement: IEndorsement;
  onFinish: Function;
  disabled?: boolean;
}

const DefineEndorsement: React.FC<IDefineEndorsementProps> = ({
  endorsement,
  disabled,
  onFinish,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [clickedOnce, setClickedOnce] = useState(false);

  const isObjectEmpty = (object: object) =>
    Object.keys(object).length > 0 && object.constructor === Object;

  const showEndorsementPreview = () => {
    if (typeof endorsement === 'object' && endorsement !== null) {
      return isObjectEmpty(endorsement);
    }

    return endorsement;
  };

  const setEndorsementWithCallBack = useCallback(
    (value, cb) => {
      dispatch(changeEndorsementWithCallBack(value)).then(
        (endorsemen: IEndorsement) => cb && cb(endorsemen),
      );
    },
    [dispatch],
  );

  return (
    <div>
      <div>
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={() => dispatch(openEndorsement())}
        >
          <Trans>button.defineEndorsement</Trans>
        </Button>

        {endorsement ? (
          <Tooltip
            message={
              clickedOnce
                ? t('common.endorsement.doubleClickToRemove')
                : t('common.endorsement.clearTooltip')
            }
          >
            <IconButton
              onClick={() => {
                setClickedOnce(true);
                setTimeout(() => setClickedOnce(false), 5000);
              }}
              onDoubleClick={() => {
                dispatch(clearEndorsement());
                setEndorsementWithCallBack(
                  { endorsement: null, endorsementGUI: null },
                  onFinish,
                );
              }}
            >
              <Clear />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
      {showEndorsementPreview() ? (
        <RenderEndorsement endorsement={endorsement} />
      ) : null}
    </div>
  );
};

export default DefineEndorsement;
