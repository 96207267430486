import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Icon,
  List,
  Checkbox,
  Typography,
  ListItemText,
  FormControlLabel,
  Switch,
  Popper,
  ClickAwayListener,
  Box,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import {
  OptionsCard,
  AtLeastOneMsg,
  CardsContainer,
  SelectContainer,
  SelectedListItem,
  ComponentContainer,
} from '../styles';
import { IAssetProp, IAsset } from '../../../../store/TemplateCC';

import Tooltip from '../../../Tooltip';

interface IDefinePermissionsProps {
  orgs: string[];
  disabled?: boolean;
  parentAsset: IAsset;
  placeholder?: string;
  changeWritersAllEnabled: () => void;
  onChange: (writer: string) => void;
}

const DefineAllPropsWriters: React.FC<IDefinePermissionsProps> = ({
  orgs,
  disabled,
  onChange,
  placeholder,
  parentAsset,
  changeWritersAllEnabled,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const writersAllEnabled = useMemo(() => {
    return parentAsset?.props?.every((prop) => {
      return prop?.writersAllEnabled;
    });
  }, [parentAsset]);

  const isOrgDisabled = useCallback(
    (org: string) => {
      return parentAsset.privateData
        ? writersAllEnabled ||
            !parentAsset.readers?.some((item) => item === `${org}MSP`)
        : writersAllEnabled;
    },
    [writersAllEnabled, parentAsset],
  );

  const mustOrgHaveLineThrough = useCallback(
    (org: string) => {
      return (
        parentAsset.privateData &&
        writersAllEnabled &&
        !parentAsset.readers?.some((item) => item === `${org}MSP`)
      );
    },
    [writersAllEnabled, parentAsset.privateData, parentAsset.readers],
  );

  const isOrgSelected = useCallback(
    (org: string) => {
      // If the org is in the writers array of every prop of the asset, then the org is selected
      return parentAsset?.props?.every((prop) => {
        return prop?.writers?.includes(`${org}MSP`);
      });
    },
    [parentAsset],
  );

  return (
    <ComponentContainer ref={ref} disabled={disabled || false}>
      <Box
        display="flex"
        flexDirection="column"
        maxHeight="400px"
        overflow="auto"
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            style={{ margin: 0 }}
            label={t('common.chaincode.allEnabled')}
            onChange={() => changeWritersAllEnabled()}
            control={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography component="span" style={{ fontWeight: 'bold' }}>
                  <Trans>common.words.writers</Trans>
                </Typography>
                <Switch checked={Boolean(writersAllEnabled)} />
              </div>
            }
          />
        </div>

        <List
          disablePadding
          component="div"
          style={{ width: '100%', minWidth: '550px' }}
        >
          {orgs.map((org: string) => (
            <Tooltip
              key={`prop-writers-${org}`}
              canShow={
                writersAllEnabled
                  ? mustOrgHaveLineThrough(org)
                  : isOrgDisabled(org)
              }
              message={t('common.chaincode.noAccessToPrivateData')}
            >
              <SelectedListItem
                prop
                selected={isOrgSelected(org) as boolean}
                isDisabled={isOrgDisabled(org) as boolean}
                lineThrough={mustOrgHaveLineThrough(org)}
                onClick={() => {
                  if (!isOrgDisabled(org)) {
                    onChange(`${org}MSP`);
                  }
                }}
              >
                <Checkbox
                  style={{ pointerEvents: 'none' }}
                  disabled={isOrgDisabled(org)}
                  checked={
                    // this is a workaround for the problem with the checkbox
                    // that was not being checked (I couldn't find the cause)
                    // but for some reason this is working
                    isOrgSelected(org) ? isOrgSelected(org) : false
                  }
                />
                <ListItemText
                  primary={org}
                  style={{ wordBreak: 'break-word' }}
                />
              </SelectedListItem>
            </Tooltip>
          ))}
        </List>
      </Box>
    </ComponentContainer>
  );
};

DefineAllPropsWriters.defaultProps = {
  disabled: false,
};

export default DefineAllPropsWriters;
