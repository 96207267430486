import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../Tooltip';
// import { Status } from '../../../store/Reports';

import { Icon } from './styles';

type StatusMap = Record<string, JSX.Element | null>;

const ReportStatusIcon: React.FC<{ status: string }> = ({ status }) => {
  const { t } = useTranslation();
  console.log('stat', status);
  const [statusMap, setStatusMap] = useState<StatusMap>({
    success: null,
    idle: null,
    failed: null,
    stopped: null,
    running: null,
    undefined: null,
  });

  useEffect(() => {
    setStatusMap({
      success: (
        <Tooltip message={t('common.words.success')}>
          <Icon status="success">check_circle</Icon>
        </Tooltip>
      ),
      failed: (
        <Tooltip message={t('common.words.error')}>
          <Icon status="error">error</Icon>
        </Tooltip>
      ),
      stopped: (
        <Tooltip message={t('common.words.error')}>
          <Icon status="error">error</Icon>
        </Tooltip>
      ),
      idle: (
        <Tooltip message={t('asset.reports.notRun')}>
          <Icon status="notrun">cancel</Icon>
        </Tooltip>
      ),
      running: (
        <Tooltip message={t('asset.reports.notRun')}>
          <Icon status="notrun">cancel</Icon>
        </Tooltip>
      ),
      undefined: (
        <Tooltip message={t('asset.reports.notRun')}>
          <Icon status="notrun">cancel</Icon>
        </Tooltip>
      ),
    });
  }, [t]);

  return statusMap[status];
};

export default ReportStatusIcon;
