const DEBOUNCE_INTERVAL = 500;

export default (func: Function, interval = DEBOUNCE_INTERVAL) => {
  let timeout: number;

  return function lambda(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, interval);
  };
};
