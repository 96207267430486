import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const AdderCardInside = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PeerItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const PeerOptsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const PeerOpt = styled.div`
  display: flex;
  margin-right: 25px;
  align-items: start;
`;

export const AdderCardContainer = styled(Grid).attrs({
  tabIndex: 0,
  container: true,
})`
  width: 100%;
  display: flex;
  padding: 15px;
  border-radius: 5px;
  flex-direction: column;
  border: 1px solid #95a5a6;
`;

export const removeButton = {
  margin: '5px',
  padding: '5px',
  boxShadow: 'none',
  borderRadius: '50%',
  backgroundColor: 'transparent',
};
