import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PageTitle = styled(Typography).attrs({
  variant: 'overline',
})`
  && {
    margin: 20px 0;
    font-size: 25px;
  }
`;

export const Card = styled(Paper)`
  && {
    width: 400px;
    display: flex;
    padding: 20px;
    margin: 0 auto;
    height: fit-content;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1400px) {
      margin-bottom: 20px;
    }
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
