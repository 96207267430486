import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { networkApi } from '../../Common/axios';
import { openDialog } from '../../store/Dialog';

export const NetworksContextDefaultValues: NetworksContextType = {
  networks: [] as INetwork[],
  setNetworks: () => [],
  selectedNetwork: null,
  selectedChannel: '',
  setSelectedChannel: () => '',
  setSelectedNetwork: () => ({}),
  networkState: null,
  loadingNetState: false,
  currentDashboardView: 'generalDashboard',
  setCurrentDashboardView: () => ({}),
  getAllNetworks: async () => [],
  fetchNetworkState: () => ({}),
};

export const NetworksContext = createContext<NetworksContextType>(
  NetworksContextDefaultValues,
);

export const NetworksProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [networks, setNetworks] = useState<INetwork[]>([] as INetwork[]);
  const [selectedChannel, setSelectedChannel] = useState<string>('');
  const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);
  const [
    currentDashboardView,
    setCurrentDashboardView,
  ] = useState<DashboardView>('generalDashboard');

  const [networkState, setNetworkState] = useState<NetWorkState | null>(null);

  const [loadingNetState, setLoadingNetState] = useState(false);

  const getNetworks = useCallback(async () => {
    try {
      const response = await networkApi.get('/allnetworks');
      setNetworks(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return [] as INetwork[];
    }
  }, []);
  interface NetworkStatusResponse {
    response: Record<string, { [orgName: string]: boolean }>;
    status: number;
  }
  const updateNetworksStatus = useCallback(async (nets: INetwork[]) => {
    try {
      const response = await networkApi.get<NetworkStatusResponse>(
        '/allnetworkshealth',
      );
      const updatedNetworks = nets?.map((net) => {
        const networksNameArray = Object.values(
          response.data.response[net?.networkName],
        );
        const isOnline = networksNameArray?.every((value) => value === true);
        const isOffline = networksNameArray?.every((value) => value === false);

        if (isOnline) {
          Object.assign(net, { status: 'online' });
        } else if (isOffline) {
          Object.assign(net, { status: 'offline' });
        } else {
          Object.assign(net, { status: 'partial' });
        }
        return net;
      });
      setNetworks(updatedNetworks);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getAllNetworks = useCallback(async () => {
    const networksResponse = await getNetworks();
    await updateNetworksStatus(networksResponse);

    return networksResponse as INetwork[];
  }, [getNetworks, updateNetworksStatus]);

  useEffect(() => {
    getAllNetworks();
  }, [getAllNetworks]);

  const fetchNetworkState = useCallback(async () => {
    try {
      if (selectedNetwork?.networkName) {
        setLoadingNetState(true);

        const config = { params: { networkName: selectedNetwork.networkName } };
        const response = await networkApi.get<NetWorkState>(
          '/networkState',
          config,
        );
        console.log('REP', response);
        setNetworkState(response?.data);
      }
    } catch (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: t('common.words.error'),
          content: error.message?.includes('Request failed with status code')
            ? t('common.messages.failedToSelectNet')
            : error.message,
        }),
      );
    } finally {
      setLoadingNetState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedNetwork, t]); // Don't use getAllNetwork as deps

  useEffect(() => {
    fetchNetworkState();
  }, [fetchNetworkState, selectedNetwork]);

  useEffect(() => {
    if (isEqual(networkState, selectedNetwork)) return;

    setSelectedNetwork((networkState as unknown) as INetwork);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkState]);

  const values = useMemo(
    () => ({
      networks,
      setNetworks,
      selectedNetwork,
      setSelectedNetwork,
      selectedChannel,
      setSelectedChannel,
      networkState,
      loadingNetState,
      currentDashboardView,
      setCurrentDashboardView,
      getAllNetworks,
      fetchNetworkState,
    }),
    [
      currentDashboardView,
      getAllNetworks,
      fetchNetworkState,
      loadingNetState,
      networkState,
      networks,
      selectedChannel,
      selectedNetwork,
    ],
  );

  return (
    <NetworksContext.Provider value={values}>
      {children}
    </NetworksContext.Provider>
  );
};

export function useNetworks() {
  const context = useContext(NetworksContext);

  if (!context) {
    throw new Error('useNetworks must be used within a NetworksProvider');
  }

  return context;
}
