import React from 'react';
import { CSSObject } from 'styled-components';
import { withStyles, Tooltip as MaterialTooltip } from '@material-ui/core';

import { TooltipTitle } from './styles';

interface ITooltipProps {
  children: JSX.Element;
  type?: string;
  canShow?: boolean;
  fontColor?: string;
  interactive?: boolean;
  boldMessage?: boolean;
  customStyle?: CSSObject;
  fontSize?: string | number;
  message: string | JSX.Element;
}

const Tooltip: React.FC<ITooltipProps> = ({
  type,
  message,
  canShow,
  fontSize,
  children,
  fontColor,
  boldMessage,
  customStyle,
  interactive,
}) => {
  const getColor = () => {
    switch (type) {
      case 'error':
        return 'var(--error)';
      case 'warning':
        return 'var(--warning)';
      default:
        return '';
    }
  };

  const StyledTooltip = withStyles(() => ({
    tooltip: {
      ...customStyle,
      color: fontColor,
      backgroundColor: getColor(),
    },
  }))(MaterialTooltip);

  if (!canShow) return children;
  return (
    <StyledTooltip
      interactive={interactive}
      title={
        <TooltipTitle fontSize={fontSize} boldMessage={boldMessage}>
          {message}
        </TooltipTitle>
      }
    >
      {children}
    </StyledTooltip>
  );
};

Tooltip.defaultProps = {
  canShow: true,
  customStyle: {},
  fontSize: '16px',
  boldMessage: false,
  fontColor: 'var(--white)',
};

export default Tooltip;
