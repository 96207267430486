/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer as ReactToastifyContainer } from 'react-toastify';
import history from './history';
import { networkApi } from './Common/axios';
import { fetchEC } from './store/Instance';
import { fetchNetwork, INetworkState, FetchingNetwork } from './store/Network';

import Sidebar from './AppComponents/Sidebar';
import GlobalModal from './Screens/GlobalModal';
import AppHeader from './AppComponents/AppHeader';
import GlobalLoader from './Screens/GlobalLoader';
import PageNotFound from './AppComponents/PageNotFound';
import TourChapters from './AppComponents/TourChapters';
import ToastContainer from './AppComponents/ToastContainer';
import DeploySuccessModal from './Screens/Deploy/Components/SuccessModal';
import AddOrgSuccessModal from './Screens/Organization/AddOrgSuccessModal';

import { Routes, BeforeLoginRoutes } from './Routes';
import GlobalStyle, { RoutesContainer } from './styles/globals';

import { StoreState } from './store/types';

import keepOnPage from './utils/keepOnPage';
import verifyTokenScript from './utils/verifyToken';
import authTokenHandler from './utils/authTokenHandler';
import { DeployProvider } from './Hooks/deploy';

import Tour from './AppComponents/Tour';
import { OperationProvider } from './Contexts/Operation';
import { NetworksProvider } from './Contexts/Networks';
import { OPHistoryProvider } from './Contexts/OPHistory';
import { LicenseInfoProvider } from './Contexts/License';
import 'react-toastify/dist/ReactToastify.css';

const headerHeight = 65;
const sidebarWidth = 250;

let unlisten: Function = () => null;

const App: React.FC = () => {
  // remove console logs in production
  if (process.env.NODE_ENV !== 'development') {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
  }
  const pageOnTop = true;
  const [fetchingNetwork, setFetchingNetwork] = useState<FetchingNetwork>(
    'nothing',
  );

  const dispatch = useDispatch();

  const networkState: INetworkState = useSelector(
    (state: StoreState) => state.networkState,
  );

  const { isLogged, onLogout, language } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const changeIsLogged = (e: any) => {
    if (e.storageArea === sessionStorage && e.key === '@GoFabric:isLogged') {
      const logged = sessionStorage.getItem('@GoFabric:isLogged') === 'true';

      authTokenHandler(logged);
    }
  };

  const verifyToken = () => {
    networkApi
      .get('/ping')
      .then(() => {
        if (onLogout) authTokenHandler(true);

        dispatch(fetchEC());
        dispatch(fetchNetwork());
      })
      .catch((error) => {
        if (error?.response?.status !== 401)
          authTokenHandler(false, false, error?.response?.status);
      });
  };

  useEffect(() => {
    networkApi
      .get('/ping')
      .then(() => {
        verifyTokenScript();
        authTokenHandler(true);

        if (process.env.NODE_ENV !== 'development') {
          window.addEventListener('beforeunload', keepOnPage);
        }

        window.addEventListener('storage', changeIsLogged);

        unlisten = history.listen(() => {
          verifyToken();
        });

        dispatch(fetchNetwork());
        dispatch(fetchEC());
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          authTokenHandler(false, false, error?.response?.status);
        }

        if (
          window.location.pathname !== '/' &&
          window.location.pathname !== '/login' &&
          window.location.pathname !== '/signup' &&
          window.location.pathname !== '/otp' &&
          window.location.pathname !== '/forgot-password'
        ) {
          history.push('/');
        }
      });

    return () => {
      window.removeEventListener('beforeunload', keepOnPage);
      window.removeEventListener('storage', changeIsLogged);
      unlisten();
    };
    // I JUST WANT TO RUN IT ON MOUNT
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFetchingNetwork(networkState.fetchingNetwork);
  }, [networkState]);

  return (
    <>
      <ReactToastifyContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router history={history}>
        {!isLogged ? (
          <>
            <ToastContainer />

            <ToastContainer />
            <GlobalModal />
            <GlobalLoader />
            <GlobalStyle />
            <BeforeLoginRoutes />
          </>
        ) : (
          <>
            <OperationProvider>
              <Tour />

              <GlobalModal />
              <GlobalStyle />
              <TourChapters />
              <ToastContainer />
              <ReactToastifyContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />

              <GlobalLoader />
              {/* giving sidebar and deploy success modal access of deploy form state */}
              <DeployProvider>
                <NetworksProvider>
                  <OPHistoryProvider>
                    <LicenseInfoProvider>
                      <AppHeader pageOnTop={pageOnTop} />
                    </LicenseInfoProvider>

                    <Sidebar
                      sidebarWidth={sidebarWidth}
                      fetchingNetwork={fetchingNetwork}
                    />
                    <DeploySuccessModal />

                    <AddOrgSuccessModal />

                    <RoutesContainer
                      headerHeight={headerHeight}
                      sidebarWidth={sidebarWidth}
                    >
                      <div id="routes">
                        <Routes />
                      </div>

                      {document.getElementById('routes')?.children.length ===
                      0 ? (
                        <PageNotFound />
                      ) : null}
                    </RoutesContainer>
                  </OPHistoryProvider>
                </NetworksProvider>
              </DeployProvider>
            </OperationProvider>
          </>
        )}
      </Router>
    </>
  );
};

export default App;
