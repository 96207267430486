import React, { useState, useEffect, useRef } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, List, Divider, ListItemText } from '@material-ui/core';

import USFlag from '../../assets/en_flag.png';
import BRFlag from '../../assets/ptbr_flag.png';
import FlagIcon from '../../assets/flag-icon-24px.svg';

import { StoreState } from '../../store/types';
import { changeLanguageCallback } from '../../store/AppStatus';

import { Badge, FlagImg, DropDown, LanguagesCard, ListItem } from './styles';

const Languages: React.FC = () => {
  const { language, supportedLanguagesName, supportedLanguages } = useSelector(
    (state: StoreState) => state.appStatusState,
  );
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node))
      setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleChange = (lngIndex: number) => {
    const newLanguage = supportedLanguages[lngIndex];

    i18next.changeLanguage(newLanguage, (error, t) => {
      dispatch(changeLanguageCallback(newLanguage, error, t));
    });
  };

  const getFlagImage = (lng: string) => {
    switch (lng) {
      case 'english':
        return USFlag;
      case 'portuguese':
        return BRFlag;
      default:
        return FlagIcon;
    }
  };

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <Badge onClick={() => setOpen((prev) => !prev)}>
        <Icon>language</Icon>
      </Badge>

      {open ? (
        <DropDown onClick={() => setOpen((prev) => !prev)}>
          <LanguagesCard>
            <List disablePadding component="div" style={{ width: '100%' }}>
              {supportedLanguagesName.map((lng: string, idx: number) => (
                <div key={lng}>
                  <ListItem
                    selected={supportedLanguages[idx] === language}
                    onClick={() => handleChange(idx)}
                  >
                    <FlagImg
                      alt={supportedLanguages[idx]}
                      src={getFlagImage(lng)}
                    />
                    <ListItemText primary={lng} />
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </LanguagesCard>
        </DropDown>
      ) : null}
    </div>
  );
};

export default Languages;
