import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Dialog,
  Button,
  Checkbox,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyClipboardIcon from '../../assets/copy-to-clipboard.svg';
import { networkApi } from '../../Common/axios';

import Tooltip from '../Tooltip';
import { APIAuthList, MustAgreeMsg } from './styles';

interface IOrgApiAuth extends Record<string, string> {
  orgName: string;
  apiAuth: string;
}

// any because it may not be a string (undefined or null, for example)
const stringValid = (str: any) => str && str !== '';

interface ISuccessModalProps {
  opened: boolean;
  networkName: string;
  // one or two components
  children: JSX.Element | JSX.Element[];
  closeModal(): void;
}

const SuccessModal: React.FC<ISuccessModalProps> = ({
  opened,
  children,
  networkName,
  closeModal,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [orgsApiAuth, setOrgsApiAuth] = useState<IOrgApiAuth[]>([]);

  useEffect(() => {
    if (opened && stringValid(networkName)) {
      setLoading(true);

      networkApi
        .get('/apiauth', { params: { networkName } })
        .then((res: Record<string, any>) => {
          setOrgsApiAuth(
            Object.keys(res.data).map((org: string) => ({
              orgName: org,
              apiAuth: res.data[org],
            })),
          );
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [opened, networkName]);

  const handleClose = () => {
    closeModal();
  };

  return (
    <div>
      <Dialog open={opened} fullWidth maxWidth="sm">
        <DialogTitle
          style={{ color: 'var(--white)', backgroundColor: 'var(--primary)' }}
        >
          <Trans>common.words.success</Trans>
        </DialogTitle>

        <DialogContent
          style={{ padding: '20px 30px 30px 30px', maxHeight: 'fit-content' }}
        >
          {loading ? (
            <div style={{ textAlign: 'center', margin: '30px 0' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {React.Children.count(children) > 1
                ? (children as JSX.Element[])[0]
                : children}

              {React.Children.count(children) > 1
                ? (children as JSX.Element[])[1]
                : null}
            </>
          )}
        </DialogContent>

        <DialogActions style={{ padding: '0 30px 30px 30px' }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleClose}
          >
            <Trans>button.ok</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuccessModal;
