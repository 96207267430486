import styled, { CSSObject } from 'styled-components';

interface IContainerProps {
  style: CSSObject;
}

export const Container = styled.div<IContainerProps>`
  width: 80%;
  margin: 30px;
  display: flex;
  padding: 20px;
  border-radius: 20px;
  flex-direction: column;
  background-color: #2c3e50;

  ${({ style }) => style};
`;
