import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  Step,
  Stepper,
  StepLabel,
  IconButton,
  Button,
  AppBar,
  Box,
  Typography,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useTranslation, Trans } from 'react-i18next';
import { Container, StepperContainer, LoadContainer } from './styles';

import history from '../../../history';
import { networkApi } from '../../../Common/axios';

import LoadingScreen from '../../LoadingScreen';
import DefineOrganizations from './addOrg';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';
import buildStartNetData, {
  buildOrganizationData,
} from '../../../utils/network/buildStartNetData';
import { ValidateOrgs, validateChannels } from './utils';
import { IEndorsementEvent } from '../../../AppComponents/Endorsement/types';
import EndorsementModal from '../../../AppComponents/Endorsement/EndorsementModal';

import { useDeployForm } from '../../../Hooks/deploy';
import { openSuccessModal } from '../../../store/DeployStart';
import { openDialog, IDialogInfo } from '../../../store/Dialog';
import { addOperation, removeOperation } from '../../../store/AppStatus';
import CustomTimeoutModal from '../../../AppComponents/CustomTimeoutModal';
import { clearDeployNotifications } from '../../../store/DeployNotifications';
import { DefineChannels } from './defineChannels';
import { OperationStateCard } from '../../../AppComponents/OperationStateCard';
import { useOperation } from '../../../Contexts/Operation';
import { useNetworks } from '../../../Contexts/Networks';
import { useOPHistory } from '../../../Contexts/OPHistory';
import { useAddOrganizationForm } from '../../../Hooks/addOrganization';
import addOrganization from '../../../AppComponents/Notifications/operations/addOrganization';
import SelectNetwork from '../../../AppComponents/SelectNetwork';

const { CancelToken } = Axios;
let cancel: (hasDialog?: boolean) => void;

const AddOrganization: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { getAllNetworks, selectedNetwork, fetchNetworkState } = useNetworks();
  const addOrganizationForm = useAddOrganizationForm();

  const {
    activeStep,
    setActiveStep,
    isValid,
    setStarted,
    clearFormData,
    loading,
    setLoading,
  } = addOrganizationForm;

  const { addOrgState, clearOperationsState } = useOperation();

  const { getHistory } = useOPHistory();

  const steps = [
    t('asset.network.deploy.step1'),
    t('asset.network.deploy.step2'),
    'Add Organization',
  ];

  const sendData = useCallback(() => {
    // const canSend = verifyNetwork();
    if (!selectedNetwork?.networkName) return;
    try {
      console.log('ADD ORG CONTEXT DATA', addOrganizationForm);

      const formData = buildOrganizationData(
        addOrganizationForm,
        selectedNetwork?.networkName,
      );

      setStarted(true);
      setActiveStep((curValue) => curValue + 1);

      networkApi
        .post('/addOrg', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, 'Add organization', hasDialog);
            };

            cancel = withDialogCancel;
            dispatch(
              addOperation({
                title: 'Add organization',
                pathname: window.location.pathname,
                name: 'addorg',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();
          setStarted(false);

          await fetchNetworkState();
          dispatch(removeOperation('addorg', true));

          dispatch(
            openDialog({
              title: t('common.words.success'),
              type: 'success',
              content: 'Organization added successfully',
            }),
          );

          if (window.location.pathname === '/organization/add') {
            history.push('/dashboard');
          }
          clearOperationsState();
          setActiveStep(0);
          clearFormData();
        })
        .catch((error) => {
          dispatch(removeOperation('addorg', false));
          setStarted(false)

          dispatch(
            openDialog({
              title: t('common.words.error'),
              type: 'error',
              content: error,
            }),
          );
        })
        .finally(() => setStarted(false));
    } catch (error) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error?.message,
        }),
      );
    } finally {
      getHistory();
      //      setStarted(false);
    }
  }, [
    addOrganizationForm,
    dispatch,
    fetchNetworkState,
    getAllNetworks,
    getHistory,
    selectedNetwork,
    setActiveStep,
    clearOperationsState,
    setStarted,
    t,
  ]);

  const handleStepChange = useCallback(
    (type = 'next') => {
      let newActiveStep;

      if (type === 'back') {
        newActiveStep = activeStep - 1;
      } else {
        const isFormValid = ValidateOrgs(
          addOrganizationForm,
          (value: IDialogInfo) => dispatch(openDialog(value)),
          t,
        );

        if (!isFormValid) return;

        newActiveStep = activeStep + 1;
      }

      if (newActiveStep < 0 || newActiveStep > steps.length - 1) return;

      setActiveStep(newActiveStep);
    },
    [activeStep, addOrganizationForm, dispatch, setActiveStep, steps.length, t],
  );

  const getStepContent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return <DefineOrganizations handleStepChange={handleStepChange} />;
      case 1:
        return (
          <DefineChannels
            handleStepChange={handleStepChange}
            sendData={sendData}
          />
        );

      case 2:
        return (
            <Box marginTop="3rem" display="flex" width="100%">
              <OperationStateCard taskOperation={addOrgState} invisible={!addOrganizationForm.started} />
            </Box>
        );
      default:
        return null;
    }
  }, [activeStep, addOrgState, handleStepChange, sendData]);

  if (!selectedNetwork) return <SelectNetwork isNetSelected={false} />;

  return (
    <div style={{ width: '100%' }}>
      {/* <EndorsementModal
        orgs={deployStart.orgs}
        endorsementGUI={deployStart.endorsementGUI}
        currentEndorsement={deployStart.endorsement}
        onFinish={(value: IEndorsementEvent) => {
          setEndorsement(value.endorsement);
          setEndorsementGUI(value.endorsementGUI);
        }}
      />

      <CustomTimeoutModal
        hasChaincodeTimeout
        open={customTimeoutModalOpened}
        setOpen={setCustomTimeoutModalOpened}
        onSave={(v) => {
          setGrpcTimeout(v.grpcTimeout);
          setChaincodeTimeout(v.chaincodeTimeout);
        }}
      /> */}

      <StepperContainer>
        <Stepper
          activeStep={activeStep}
          className="deploy-steps"
          style={{ padding: '24px 24px 10px 24px', marginTop: '10px' }}
        >
          {steps.map((step, idx) => (
            <Step key={step} className={idx === 0 ? 'define-orgs-step' : ''}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <IconButton
            color="secondary"
            disabled={
              activeStep === 0 ||
              (activeStep === 2 && addOrganizationForm.started)
            }
            style={{ position: 'absolute', left: '12px' }}
            onClick={() => handleStepChange('back')}
          >
            <ArrowBack
              className="previous-step"
              style={{ width: '40px', height: '40px' }}
            />
          </IconButton>

          <IconButton
            color="primary"
            disabled={activeStep === steps.length - 1}
            style={{ position: 'absolute', right: '15px' }}
            onClick={() => handleStepChange()}
          >
            <ArrowForward
              className="next-step"
              style={{ width: '40px', height: '40px' }}
            />
          </IconButton>
        </div>
      </StepperContainer>

      <Container>
        {getStepContent()}
        <div
          style={{
            margin: '0 auto 50px auto',
            width: '100%',
            maxWidth: '500px',
          }}
        >
          {addOrganizationForm.started && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingScreen content={t('asset.organizations.addOrg.messages.loading')} />
              </div>

              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => cancel()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AddOrganization;
