import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import FillingCard from '../components/Card';
import { mountCardAttributes } from '../utils';
import { changeAddCCAPINotificationAttrs } from '../../../store/AddCCAPINotifications';

import { StoreState } from '../../../store/types';
import { ILoadingContainerCardInfo } from '../types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const { notification, notificationAttrs } = state.addCCAPINotificationsState;
  const { title, running, attributes, notLoadingWidth } = cardInfo;
  const { organization, channelName } = attributes || {};

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeAddCCAPINotificationAttrs(params));
  };

  const newCardInfo = {
    running,
    notLoadingWidth,
    changeNotification,
    notificationAttrs,
    loadingInfo: notification,
    canShow: organization && channelName,
    attributes: mountCardAttributes(attributes || {}, t),
    loadingStarted: notification ? Object.keys(notification).length > 0 : false,
  };

  return <FillingCard cardInfo={{ ...newCardInfo, title }} />;
};
