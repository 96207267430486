import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { openDialog } from '../Dialog';
import { getNetwork } from '../../utils/network';
import { fetchNetwork } from '../Network';

import { AppThunk } from '../../store';
import { TranslationFunction } from '../types';

export interface Operation extends Record<string, any> {
  name: string;
  title: string;
  status?: string;
  netName: string;
  pathname: string;
  pageLeft?: boolean;
}

export interface IAppStatusState extends Record<string, any> {
  language: string;
  isLogged: boolean;
  tourMode: boolean;
  onLogout: boolean;
  operations: Operation[];
  dashboardLoading: boolean;
  isOperationRunning: boolean;
  supportedLanguages: string[];
  supportedLanguagesName: string[];
  operationsWithErrorModal: string[];
  operationsWithSuccessModal: string[];
  operationsModifyBlockchain: string[];
  operationsSendNetNameParam: string[];
}

interface IAddOperationPayload extends Omit<Operation, 'netName'> {
  cancel(hasDialog: boolean): void;
}

const operationsWithSuccessRequestModal = [
  'setup',
  'addpeer',
  'addccapi',
  'removepeer',
  'addorderer',
  'joinchannel',
  'upgradeccapi',
  'addwebclient',
  'addchaincode',
  'createchannel',
  'backupnetwork',
  'restorenetwork',
  'updatepeerconfig',
  'upgradechaincode',
  'exporttemplatedef',
  'importtemplatedef',
  'addchannel',
  'addchaincode',
  'addextorganization',
];

const cancelOperationsFunctions: Record<string, Function> = {};

export const initialState: IAppStatusState = {
  // if the tour is running
  tourMode: false,
  // if the app is currently on logout proccess
  onLogout: false,
  // true if the dashboard page is loading
  dashboardLoading: false,
  isLogged: sessionStorage.getItem('@GoFabric:isLogged') === 'true',
  // the current app language
  language: 'en', // by default english
  // the supported langues
  supportedLanguages: ['en', 'ptbr'],
  supportedLanguagesName: ['english', 'portuguese'],
  // store all the running operations
  operations: [],
  // true if any operation is running
  isOperationRunning: false,
  // store all the operations that modify the blockchain
  operationsModifyBlockchain: [
    'addorg',
    'addpeer',
    'addccapi',
    'addorderer',
    'upgradeccapi',
    'startnetwork',
    'updatepeerconfig',
    'upgradechaincode',
    'restorenetwork',
    'removepeer',
    'createchannel',
    'joinchannel',
    'addextorganization',
  ],
  // store all operations that opens a modal after request
  operationsWithSuccessModal: [...operationsWithSuccessRequestModal],
  operationsWithErrorModal: [
    ...operationsWithSuccessRequestModal,
    'startnetwork',
    'addorg',
  ],
  operationsSendNetNameParam: [
    'addorg',
    'addpeer',
    'addorderer',
    'removepeer',
    'addwebclient',
    'upgradeccapi',
    'restorenetwork',
    'updatepeerconfig',
    'upgradechaincode',
    'addextorganization',
  ],
};

const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    changeIsLogged(state, action: PayloadAction<boolean>) {
      state.isLogged = action.payload;
    },
    changeAppLanguage(state, action: PayloadAction<string>) {
      const isValid = state.supportedLanguages.some(
        (lng) => lng === action.payload,
      );

      if (isValid) state.language = action.payload;
    },
    enterTourMode(state) {
      state.tourMode = true;
    },
    closeTourMode(state) {
      state.tourMode = false;
    },
    setDashboardLoading(state, action: PayloadAction<boolean>) {
      state.dashboardLoading = action.payload;
    },
    setOperations(state, action: PayloadAction<Operation[]>) {
      state.operations = action.payload;
      state.isOperationRunning = action.payload.length > 0;
    },
    changeOnLogout(state, action: PayloadAction<boolean>) {
      state.onLogout = action.payload;
    },
    cancelAllOperations: {
      reducer(state) {
        state.operations = [];
      },
      prepare() {
        Object.keys(cancelOperationsFunctions).forEach((opName) => {
          cancelOperationsFunctions[opName](false);
        });

        return { payload: null };
      },
    },
    clearCurrentOperation() {
      return initialState;
    },
  },
});

export const addOperation = (payload: IAddOperationPayload): AppThunk => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { operations } = state.appStatusState;
  const { selectedNetwork } = state.networkState;
  const { name, title, status, pathname, pageLeft, cancel } = payload;

  let newOperations = [...operations];
  const newOperation = {
    name,
    title,
    status,
    pathname,
    pageLeft,
    netName: selectedNetwork.name,
  };

  const opIndex = newOperations.findIndex((e) => e.name === name);

  if (opIndex >= 0) {
    cancelOperationsFunctions[opIndex] = cancel;

    newOperations[opIndex] = {
      ...newOperations[opIndex],
      ...newOperation,
    };
  } else {
    cancelOperationsFunctions[name] = cancel;

    newOperations = [...operations, newOperation];
  }

  dispatch(appStatusSlice.actions.setOperations(newOperations));
};

export const removeOperation = (
  operationName: string,
  success: boolean,
  netName?: string,
  channelName?: string,
): AppThunk => (dispatch, getState) => {
  const state = getState();

  const newOperations = [...state.appStatusState.operations];
  const toRemoveIndex = newOperations.findIndex(
    (e) => e.name === operationName,
  );

  if (toRemoveIndex >= 0) {
    newOperations.splice(toRemoveIndex, 1);
    delete cancelOperationsFunctions[operationName];

    dispatch(appStatusSlice.actions.setOperations(newOperations));
  }

  if (success) {
    const { networkState, appStatusState } = state;

    const networkName = networkState.selectedNetwork.name;
    const { operationsModifyBlockchain } = appStatusState;

    if (operationsModifyBlockchain.includes(operationName)) {
      if (operationName === 'startnetwork') {
        dispatch(fetchNetwork(netName || networkName));
        getNetwork(netName || networkName);
      } else {
        dispatch(fetchNetwork(networkName, channelName));
        getNetwork(networkName);
      }
    }
  }
};

export const changeLanguageCallback = (
  newLanguage: string,
  error: any,
  t: TranslationFunction,
): AppThunk => (dispatch) => {
  if (error) {
    dispatch(
      openDialog({
        type: 'error',
        title: t('common.words.error'),
        content: t('common.messages.errorLoadLanguage'),
      }),
    );
  }

  dispatch(appStatusSlice.actions.changeAppLanguage(newLanguage));
  localStorage.setItem('@GoFabric:appLanguage', newLanguage);
};

export const cancelOperation = (operationName: string, hasDialog = true) => {
  cancelOperationsFunctions[operationName](hasDialog);
};

export const {
  enterTourMode,
  closeTourMode,
  changeIsLogged,
  changeOnLogout,
  changeAppLanguage,
  setDashboardLoading,
  cancelAllOperations,
  clearCurrentOperation,
} = appStatusSlice.actions;

export default appStatusSlice.reducer;
