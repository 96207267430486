import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import debounce from '../utils/debounce';
import { Auth } from '../AppComponents/CAauth';
import { clearAddPeerNotifications } from '../store/AddPeerNotifications';

interface ISelectedOrg {
  ip: string;
  name: string;
  hsmEnabled: boolean;
}

type OrgsPeersMap = Record<string, boolean>;

export interface PeerContent {
  host: string;
  couchUsername?: string;
  couchPassword?: string;
  port?: number;
  operationPort?: number;
  hsm?: {
    user: string;
    pin: string;
  };
}
interface AddPeerContextData {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  peers: { [peer: string]: PeerContent };
  setPeers: Dispatch<SetStateAction<{ [peer: string]: PeerContent }>>;
  sendingData: boolean;
  setSendingData: Dispatch<SetStateAction<boolean>>;
  CAauth: Auth;
  port: number;
  setPort: Dispatch<SetStateAction<number>>;
  operationPort: number;
  setOperationPort: Dispatch<SetStateAction<number>>;
  setCAauth: Dispatch<SetStateAction<Auth>>;
  selectedOrg: ISelectedOrg;
  setSelectedOrg: Dispatch<SetStateAction<ISelectedOrg>>;
  peerAlreadyExists: boolean;
  setPeerAlreadyExists: Dispatch<SetStateAction<boolean>>;
  orgs: ISelectedOrg[];
  setOrgs: Dispatch<SetStateAction<ISelectedOrg[]>>;
  orgsPeers: OrgsPeersMap;
  setOrgsPeers: Dispatch<SetStateAction<OrgsPeersMap>>;
  channelName: string;
  setChannelName: Dispatch<SetStateAction<string>>;
  netStateOrgs: INetStateOrg[];
  setNetStateOrgs: Dispatch<SetStateAction<INetStateOrg[]>>;
  channelDefs: INetStateChannelDef[];
  setChannelDefs: Dispatch<SetStateAction<INetStateChannelDef[]>>;
  clearFormData(): void;
}

const AddPeerContext = createContext<AddPeerContextData>(
  {} as AddPeerContextData,
);

const AddPeerProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const [peers, setPeers] = useState({});
  const [loading, setLoading] = useState(false);
  const [channelName, setChannelName] = useState('');
  const [orgs, setOrgs] = useState<ISelectedOrg[]>([]);
  const [sendingData, setSendingData] = useState(false);
  const [CAauth, setCAauth] = useState<Auth>({} as Auth);
  const [orgsPeers, setOrgsPeers] = useState<OrgsPeersMap>({});
  const [peerAlreadyExists, setPeerAlreadyExists] = useState(false);
  const [netStateOrgs, setNetStateOrgs] = useState<INetStateOrg[]>([]);
  const [channelDefs, setChannelDefs] = useState<INetStateChannelDef[]>([]);
  const [selectedOrg, setSelectedOrg] = useState({
    ip: '',
    name: '',
  } as ISelectedOrg);

  const [port, setPort] = useState(3000);
  const [operationPort, setOperationPort] = useState(8000);

  const clearFormData = useCallback(() => {
    setLoading(false);
    setPeers({});
    setChannelName('');
    setSendingData(false);
    setCAauth({});
    setSelectedOrg({
      ip: '',
      name: '',
    });
    setOrgsPeers({});
    setPeerAlreadyExists(false);

    dispatch(clearAddPeerNotifications());
  }, [dispatch]);

  const validatePeerIP = useCallback(
    debounce((ip: string) => {
      setPeerAlreadyExists(orgsPeers[ip] || false);
    }),
    [selectedOrg],
  );

  useEffect(() => {
    if (Object?.keys(peers)?.length !== 0 && selectedOrg.name)
      validatePeerIP(peers);
  }, [peers, selectedOrg, validatePeerIP]);

  return (
    <AddPeerContext.Provider
      value={{
        loading,
        setLoading,
        peers,
        setPeers,
        sendingData,
        setSendingData,
        CAauth,
        port,
        setPort,
        operationPort,
        setOperationPort,
        setCAauth,
        selectedOrg,
        setSelectedOrg,
        peerAlreadyExists,
        setPeerAlreadyExists,
        orgsPeers,
        setOrgsPeers,
        channelName,
        setChannelName,
        orgs,
        setOrgs,
        netStateOrgs,
        setNetStateOrgs,
        channelDefs,
        setChannelDefs,
        clearFormData,
      }}
    >
      {children}
    </AddPeerContext.Provider>
  );
};

function useAddPeerForm(): AddPeerContextData {
  const context = useContext(AddPeerContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { AddPeerProvider, useAddPeerForm };
