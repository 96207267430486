import { Box, Button } from '@material-ui/core';
import React from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import * as S from './styles';
import { authAPI } from '../../Common/axios';

type PlanCardProps = Plan & {
  isSubscribed: boolean;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const PlanCard = (props: PlanCardProps) => {
  const { planName, planPrice, features, planId, isSubscribed } = props;

  const redirectToPortal = async () => {
    try {
      const response = await authAPI.get<string>('/checkout', {
        params: { plan: planId },
      });

      window.location.href = response.data;
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return (
    <S.PlanCard
      style={{
        borderColor: isSubscribed ? '#FF5117' : '#e0e0e0',
      }}
    >
      <S.Header>
        <S.Title>{planName}</S.Title>
        <S.Price>
          <p>{formatter.format(planPrice / 100)}</p> <span>/month</span>
        </S.Price>
      </S.Header>

      <S.Features>
        {features.map((feature, index) => (
          <Box key={index}>
            <CheckCircleRoundedIcon color="secondary" /> {feature}
          </Box>
        ))}
      </S.Features>

      <S.ChooseButton>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={isSubscribed ? () => undefined : redirectToPortal}
          style={{
            pointerEvents: isSubscribed ? 'none' : 'auto',
          }}
        >
          {isSubscribed ? 'Current Plan' : `Go ${planName}`}
        </Button>
      </S.ChooseButton>
    </S.PlanCard>
  );
};
