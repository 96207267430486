import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core';
import history from '../history';

const MenuButtons = () => {
  const { t } = useTranslation();

  const buttons = [
    { name: 'dashboard', link: '/dashboard' },
    {
      name: t('title.network.header'),
      link: '/network',
      target: 'network-app-header',
    },
    // { name: t('title.organizations.header'), link: '/organization' },
//    { name: t('title.nodes.header'), link: '/node' },
    {
      name: t('title.chaincodes.header'),
      link: '/chaincode',
      target: 'chaincodes-app-header',
    },
    { name: t('title.api.header'), link: '/api' },
    // { name: t('title.backup.header'), link: '/backup' },
    { name: t('title.setupMachines.header'), link: '/setupmachines' },
  ];

  return (
    <Box display="flex" alignItems="center" style={{ gap: '2rem' }}>
      {buttons.map((item) => (
        <Button
          key={item.name}
          className={item.target || ''}
          style={{ color: 'var(--white)', height: '70%' }}
          onClick={() => history.push(item.link)}
        >
          {item.name}
        </Button>
      ))}
    </Box>
  );
};

export default MenuButtons;
