import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';

export const Card = styled(Paper)`
  && {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100px + 5vw);
    height: calc(100px + 5vw);

    > span {
      font-size: calc(10px + 3vw);
    }
  }
`;

export const Text = styled(Typography)`
  && {
    margin: 5px;
    font-size: calc(18px + 0.1vw);
  }
`;
