import { ipPatt, fqdnPatt } from '../regexPatterns';

const getAllMachinesIPs = (org: INetStateOrg): string[] => {
  let ccRestHosts: string[] = [];
  const orgCCRestHost = org.ccRestHost || {};

  Object.keys(orgCCRestHost).forEach((channelName) => {
    if (!orgCCRestHost[channelName]) return;
    ccRestHosts = [
      ...ccRestHosts,
      ...orgCCRestHost[channelName].map((item: CCRestHost) => item.host),
    ];
  });

  let machinesIP = [
    ...ccRestHosts,
    ...Object.entries(org.orderers).map((item) => item[1]),
    ...Object.entries(org.peers).map((item) => item[1].host),
  ];

  machinesIP = machinesIP.filter((machine: string | undefined) => machine); // remove undefineds
  machinesIP = [...new Set(machinesIP)]; // remove duplicates

  return machinesIP;
};

export const parseNetStatePeers = (
  org: INetStateOrg,
  parsedOrg: IOrg,
  baseOpts: IPeerOpts,
) =>
  parsedOrg.peers.map((item) => {
    let newOpts = { ...baseOpts };
    let ccHost: CCRestHost | undefined;
    const orgCCRestHost = org.ccRestHost || {};
    const peersList = Object.entries(org.peers);
    const orderersList = Object.entries(org.orderers || {});

    if (item.value === org.ca.host) {
      // @ts-ignore
      newOpts = { ...newOpts, ca: true };
    }

    // if this node has a CC API for this org in any channel
    Object.keys(orgCCRestHost).forEach((channelName) => {
      if (!orgCCRestHost[channelName]) return;
      ccHost = orgCCRestHost[channelName].find(
        (i: CCRestHost) => i.host === item.value,
      );
    });

    if (ccHost) {
      // @ts-ignore
      newOpts = {
        ...newOpts,
        ccRestHost: true,
        ccWebClient: ccHost.webClient,
      };
    }

    if (peersList.some((i) => i[1].host === item.value)) {
      // @ts-ignore
      newOpts = { ...newOpts, peer: true };
    }

    if (orderersList.some((i) => i[1] === item.value)) {
      // @ts-ignore
      newOpts = { ...newOpts, orderer: true };
    }

    return { ...item, opts: newOpts };
  });

export const getUniqueRestHostsMap = (restHosts: { host: string }[]) => {
  const newRestHosts: Record<string, boolean> = {};
  const uniqueHosts = [...new Set(restHosts.map((item) => item.host))];

  uniqueHosts.forEach((host) => {
    newRestHosts[host] = true;
  });

  return newRestHosts;
};

export const parseNetStateOrgsInfo = (
  organizations: INetStateOrg[],
  getRestHosts = true,
): { parsedOrgs: IOrg[]; restHostsMap: Record<string, boolean> } => {
  const baseOpts: IPeerOpts = {
    ca: false,
    peer: false,
    orderer: false,
    ccWebClient: false,
    ccRestHost: false,
  };
  let restHosts: { host: string }[] = [];

  const parsedOrgs: IOrg[] = organizations.map(
    (org: INetStateOrg, id: number) => {
      const orgCCRestHost = org.ccRestHost || {};
      const machinesIP: string[] = getAllMachinesIPs(org);

      if (getRestHosts) {
        Object.keys(orgCCRestHost).forEach((channel) => {
          if (!orgCCRestHost[channel]) return;
          restHosts = [...restHosts, ...orgCCRestHost[channel]];
        });
      }

      let newOrg = {
        id,
        caIP: org.ca.host,
        ccapi: org.ccapi,
        orgName: org.orgName,
        orgDomainName: org.orgDomainName,
        user: org.ca.user ? org.ca.user : '',
        passwd: org.ca.passwd ? org.ca.passwd : '',
        confirmPasswd: org.ca.passwd ? org.ca.passwd : '',
        peers: machinesIP.map((machineIP, idx) => ({
          id: idx,
          opts: baseOpts,
          value: machineIP,
          valid: ipPatt.test(machineIP) || fqdnPatt.test(machineIP),
        })),
        authServer: org.authServer // change later
          ? org.authServer
          : {
              authMethod: '',
              authServer: 'none',
              oauthClientID: '',
              oauthClientSecret: '',
              oauthOpenID: '',
              oauthURL: '',
            },
        caPort: org?.caPort,
        caOperationPort: org?.caOperationPort,
      };

      const newPeers = parseNetStatePeers(org, newOrg, baseOpts);

      newOrg = { ...newOrg, peers: [...newPeers] };

      return newOrg;
    },
  );

  return {
    parsedOrgs,
    restHostsMap: getRestHosts ? getUniqueRestHostsMap(restHosts) : {},
  };
};

export const getRestHostsMap = (organizations: INetStateOrg[]) => {
  let restHosts: { host: string }[] = [];
  organizations.forEach((org) => {
    const orgCCRestHost = org.ccRestHost || {};

    Object.keys(orgCCRestHost).forEach((channel) => {
      if (!orgCCRestHost[channel]) return;
      restHosts = [...restHosts, ...orgCCRestHost[channel]];
    });
  });

  return getUniqueRestHostsMap(restHosts);
};
