import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDeployForm } from '../../../Hooks/deploy';

import {
  changeGlobalAttrs,
  changePeerAttrs,
} from '../../../store/DeployNotifications';

import DeployOrgCard from '../components/OrgCard';
import DeployGlobalCard from '../components/DeployGlobalCard';

import { IDeployOrgCardInfo, IDeployGlobalCardInfo } from '../types';
import { StoreState, WithOrgNotificationObject } from '../../../store/types';

export default () => {
  const deployStart = useDeployForm();

  const dispatch = useDispatch();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const deployNotifications = state.deployNotificationsState;

  const { orgs, started, channelName, networkName } = deployStart;

  const { peers, peersAttrs, global, globalAttrs } = deployNotifications;

  const changeGlobalNotification = (params: Record<string, any>) => {
    dispatch(changeGlobalAttrs(params));
  };

  const changePeerNotification = (params: Record<string, any>) => {
    dispatch(changePeerAttrs(params));
  };

  const newOrgs = orgs.map((item: IOrg) => {
    const peerNotificationIndex = peers.findIndex(
      (p: WithOrgNotificationObject) => p.orgName === item.orgName,
    );

    return {
      ...item,
      running: started,
      changeNotification: changePeerNotification,
      notificationAttrs: peersAttrs[peerNotificationIndex] || {},
      loadingInfo: peers.length ? peers[peerNotificationIndex] : [],
    } as IDeployOrgCardInfo;
  });

  const globalCardInfo: IDeployGlobalCardInfo = {
    channelName,
    networkName,
    running: started,
    loadingInfo: global,
    notificationAttrs: globalAttrs,
    changeNotification: changeGlobalNotification,
  };

  return (
    <>
      <DeployGlobalCard cardInfo={globalCardInfo} />

      {newOrgs.map((card, idx) => (
        <DeployOrgCard key={idx} cardInfo={card} />
      ))}
    </>
  );
};
