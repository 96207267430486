import React from 'react';
import { useSelector } from 'react-redux';
import { useTransition } from 'react-spring';

import Toast from './Toast';
import { StoreState } from '../../store/types';
import { Container } from './styles';

const ToastContainer: React.FC = () => {
  const appStatus = useSelector((state: StoreState) => state.appStatusState);
  const { operations } = appStatus;

  const toastsWithTransitions = useTransition(
    operations,
    (operation) => operation.name,
    {
      from: { right: '-120%', opacity: 0 },
      enter: { right: '0%', opacity: 1 },
      leave: { right: '-120%', opacity: 0 },
    },
  );

  return (
    <Container>
      {toastsWithTransitions.map(({ item, key }) => (
        <Toast key={key} message={item} />
      ))}
    </Container>
  );
};

export default ToastContainer;
