import styled, { css } from 'styled-components';
import { Restore } from '@material-ui/icons';
import { List, Paper, ListItem, Typography } from '@material-ui/core';

interface IOptionsListProps {
  optionsLength: number;
}

interface IOptionItemProps {
  selected: boolean;
}

export const SelectContainer = styled.div`
  padding: 0;
  width: 100%;
  height: 39px;
  display: flex;
  cursor: pointer;
  margin-top: 13px;
  position: relative;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`;

export const SelectContent = styled.div`
  z-index: 10;
  top: -16px;
  padding: 0;
  width: 100%;
  outline: none;
  max-height: 500px;
  position: absolute;
  height: fit-content;
`;

export const FloatLabel = styled(Typography)`
  && {
    top: -10px;
    font-size: 0.8rem;
    position: absolute;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const OptionsPaper = styled(Paper).attrs({ elevation: 4 })`
  && {
    width: 320px;
    display: flex;
    max-height: 500px;
    color: var(--black);
    align-items: center;
    height: fit-content;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const OptionsListContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 100%;
  flex-flow: row wrap;
`;

export const OptionsList = styled(List).attrs({
  component: 'div',
  disablePadding: true,
})<IOptionsListProps>`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    height: ${({ optionsLength }) => `${(optionsLength / 2) * 39}px`};
  }
`;

export const OptionItem = styled(ListItem).attrs({
  button: true,
})<IOptionItemProps>`
  && {
    margin: 0;
    height: 39px;

    ${({ selected }) =>
      selected &&
      css`
        border-radius: 2px;
        color: var(--white);
        background-color: rgba(38, 132, 255, 0.8) !important;
      `}
  }
`;

export const CustomOptionItem = styled(ListItem).attrs({
  button: true,
})<IOptionItemProps>`
  && {
    margin: 0;
    width: 100%;
    height: 39px;
    text-align: center;
    color: var(--white);
    background-color: rgba(39, 60, 117, 0.8) !important;

    ${({ selected }) =>
      selected &&
      css`
        border-radius: 2px;
        background-color: rgba(38, 132, 255, 0.8) !important;
      `};

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const RestoreSelectIcon = styled(Restore)`
  && {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;
