import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Icon,
  List,
  Divider,
  ListItem,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Box,
} from '@material-ui/core';
import history from '../../history';

import { clearDeployData } from '../../store/DeployStart';
import { clearDeployNotifications } from '../../store/DeployNotifications';
import { selectNetwork, FetchingNetwork } from '../../store/Network';

import { useNetworks } from '../../Contexts/Networks';

import { StoreState } from '../../store/types';

import Tooltip from '../Tooltip';
import NetworkList from './NetworkList';
import NetworkStatus from '../NetworkStatus';
import DeleteNetworkModal from '../DeleteNetworkModal';
import EditNetDisplayNameModal from '../EditNetDisplayNameModal';
import LaunchMoreLikeThisModal from '../LaunchMoreLikeThisModal';

import { SideBar } from './styles';
import BlockIcon from '../../assets/block.png';

const Sidebar: React.FC<{
  sidebarWidth: number;
  fetchingNetwork: FetchingNetwork;
}> = ({ sidebarWidth, fetchingNetwork }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //   const { selectedNetwork } = useSelector(
  //     (state: StoreState) => state.networkState,
  //   );

  const { selectedNetwork, getAllNetworks, fetchNetworkState } = useNetworks();
  const { operations } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const { pathname } = history.location;

  const background = (name: string) => {
    return {
      backgroundColor: selectedNetwork?.networkName === name ? '#344e8e' : '',
    };
  };

  return (
    <SideBar sidebarWidth={sidebarWidth}>
      <DeleteNetworkModal />
      <EditNetDisplayNameModal />
      <LaunchMoreLikeThisModal />

      {/* <UserInfo>
        <button
          type="button"
          onClick={() => {
            history.push('/profile');
          }}
        >
          <Avatar>{userName?.toUpperCase()[0]}</Avatar>
          <Typography
            variant="overline"
            style={{ marginLeft: '8px', color: 'var(--white)' }}
          >
            {userName}
          </Typography>
        </button>

        <Tooltip message={<Trans>common.words.logout</Trans>}>
          <IconButton onClick={logout}>
            <Icon style={{ color: 'var(--white)' }}>exit_to_app</Icon>
          </IconButton>
        </Tooltip>
      </UserInfo> */}

      <List
        style={{ width: '100%' }}
        subheader={
          <div
            style={{
              display: 'flex',
              marginBottom: '16px',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ListSubheader style={{ color: '#ffffff' }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ gap: '0.8rem' }}
                >
                  <img
                    src={BlockIcon}
                    width="20px"
                    height="20px"
                    alt="block icon"
                  />
                  <Trans>sidebar.gofabricNetworks</Trans>
                </Box>
              </ListSubheader>

              <Tooltip message={t('sidebar.fetchNetworks')}>
                <IconButton
                  onClick={async () => {
                    await getAllNetworks();

                    await fetchNetworkState();
                  }}
                >
                  <Icon style={{ color: 'var(--white)' }}>replay</Icon>
                </IconButton>
              </Tooltip>
            </div>

            <NetworkStatus fetchingNetwork={fetchingNetwork} />
          </div>
        }
      >
        <NetworkList />

        <Divider />

        <ListItem
          button
          style={pathname === '/network/deploy' ? background('new') : {}}
          disabled={operations.length !== 0}
          onClick={() => {
            dispatch(clearDeployData());
            dispatch(clearDeployNotifications());
            dispatch(selectNetwork({ type: 'new', name: '' }));
            history.push('/network/deploy');
          }}
        >
          <ListItemIcon>
            <Icon style={{ color: 'var(--white)' }}>add</Icon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ color: 'var(--white)' }}>
                <Trans>sidebar.newNet</Trans>
              </Typography>
            }
          />
        </ListItem>
      </List>
    </SideBar>
  );
};

export default Sidebar;
