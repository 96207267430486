import React from 'react';
import { withStyles, Tabs, Tab } from '@material-ui/core';

// The parent div must have a backgroundColor: var(--primary)
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props: any) => (
  <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />
));

const StyledTab = withStyles((theme: any) => ({
  root: {
    textTransform: 'none',
    color: 'var(--white)',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props: any) => <Tab disableRipple {...props} />);

export { StyledTab, StyledTabs };
