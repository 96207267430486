import React, { useEffect, useState, useCallback } from 'react';
import { getUnixTime, subHours, formatISO, fromUnixTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { Box, Typography } from '@material-ui/core';
import { networkApi } from '../../../Common/axios';
import { useInterval } from '../../../Hooks/useInterval';

interface IChartProps {
  networkName: string;
  chaincodeName: string;
}

interface IData {
  time: number;
  transactions: number;
  formattedTime: string;
}

const TxCountChart: React.FC<IChartProps> = ({
  networkName,
  chaincodeName,
}) => {
  const [data, setData] = useState<IData[]>([]);
  const [rangeVariation, setRangeVariation] = useState(0);
  const [callback, setCallback] = useState(false);
  const { t } = useTranslation();

  const setRange = useCallback((values) => {
    const transactions = values.map((value: IData) => value.transactions);
    const maxValue = Math.max(...transactions);
    setRangeVariation(Math.ceil(maxValue * 0.3));
  }, []);

  const getFormattedDate = useCallback((values) => {
    const newData = values;
    newData.forEach((_: IData, idx: number) => {
      // eslint-disable-next-line prefer-destructuring
      newData[idx].formattedTime = formatISO(fromUnixTime(newData[idx].time), {
        representation: 'time',
      }).split('-')[0];
    });

    setData(newData);
  }, []);

  useEffect(() => {
    const actualDate = new Date();
    const initialDate = subHours(actualDate, 1);
    const unixInitialDate = getUnixTime(initialDate);
    const unixAtualDate = getUnixTime(actualDate);

    networkApi
      .get(
        `/txcount?networkName=${networkName}&chaincodeName=${chaincodeName}&start=${unixInitialDate}&end=${unixAtualDate}`,
      )
      .then((res) => {
        setRange(res.data.values);
        getFormattedDate(res.data.values);
        setTimeout(() => setCallback(!callback), 7000);
      });
  }, [chaincodeName, networkName, setRange, getFormattedDate, callback]);

  return (
    <Box width="100%" marginTop="30px">
      <Typography variant="h5">Transactions</Typography>
      <Box marginTop="2rem">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 5, right: 30, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="formattedTime" />
            <YAxis
              allowDecimals={false}
              dataKey="transactions"
              domain={[
                `dataMin - ${rangeVariation}`,
                `dataMax + ${rangeVariation}`,
              ]}
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="transactions"
              strokeWidth="2"
              dot={false}
              stroke="#82ca9d"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>

      <strong style={{ color: 'gray ', marginLeft: '25px' }}>
        {t('asset.dashboard.chartSubtitle')}
      </strong>
    </Box>
  );
};

export default TxCountChart;
