import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Info } from '@material-ui/icons';
import { useDeployForm } from '../../../Hooks/deploy';
import CCDescriptionModal from '../../CCDescriptionModal';
import Select from '../../Select';
import Tooltip from '../../Tooltip';
import { NoPermissionMsg } from '../../../Screens/Deploy/styles';
import { StoreState } from '../../../store/types';
import { IAsset, ITemplate } from '../../../store/TemplateCC';
import DefinePermissions from '../../Template/DefinePermissions';
import { ITemplateResponse } from '../../Template/RenderOption';
import { useUpgradeChaincodeForm } from '../../../Hooks/UpgradeChaincode';
import { parseNetStateTemplate } from '../../../utils/template';
import { useNetworks } from '../../../Contexts/Networks';

type Operation =
  | 'startnetwork'
  | 'upgradechaincode'
  | 'addccapi'
  | 'upgradeapi';

interface IDeployTemplateOptionProps {
  loading: boolean;
  //   selectedTemplate: string;
  chaincode: IChaincodes | ChaincodesInNetwork[''];
  orgs: { orgName: string }[];
  preLoadedTemplates: ITemplateResponse;
  handleSelectTemplate: (
    ccName: string,
    item: ITemplateResponse['key'],
  ) => void;
  handleSelectTemplateDefinition: (v: any) => void;
  operation: Operation;
}

export const DeployTemplateOption: React.FC<IDeployTemplateOptionProps> = ({
  loading,
  preLoadedTemplates,
  chaincode,
  handleSelectTemplate,
  handleSelectTemplateDefinition,
  orgs,
  operation,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { netDefTemplate, setNetDefTemplate } = useUpgradeChaincodeForm();
  const { templateList }: { templateList: ITemplate[] } = useSelector(
    (state: StoreState) => state.templateCCState,
  );

  const { started, setChannels, channels } = useDeployForm();
  const { selectedNetwork } = useNetworks();
  const { selectedChaincode, setOrgs } = useUpgradeChaincodeForm();
  // // set define permissions state
  // useEffect(() => {
  //   if (
  //     chaincode.ccType === 'template' &&
  //     selectedNetwork?.chaincodes?.[selectedChaincode]
  //   ) {
  //     setNetDefTemplate(
  //       parseNetStateTemplate({
  //         chaincodeName: selectedChaincode as string,
  //         templateDef:
  //           (selectedNetwork?.chaincodes?.[selectedChaincode]
  //             ?.templateDef as ITemplate) ||
  //           selectedNetwork?.chaincodes?.[selectedChaincode]
  //             ?.templateDefinition,
  //       }),
  //     );
  //   }
  // }, [selectedChaincode]);

  const [open, setOpen] = useState(false);

  const changeDeployTemplate = (newAssets: IAsset[]) => {
    if (operation === 'upgradechaincode') {
      setNetDefTemplate({
        ...netDefTemplate,
        assets: newAssets,
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      chaincode.templateDefinition = {
        ...chaincode?.templateDefinition,
        assets: newAssets,
      };
      setChannels([...channels]);
    }
  };

  const chaincodeTemplateOptions = useMemo(() => {
    const chaincodeTemplates: ITemplateResponse = {};
    const userChaincodeTemplates: ITemplateResponse = {};
    console.log('teste', preLoadedTemplates);
    Object.keys(preLoadedTemplates).forEach((key) => {
      if (key.includes('/')) {
        userChaincodeTemplates[key] = preLoadedTemplates[key];
      } else {
        chaincodeTemplates[key] = preLoadedTemplates[key];
      }
    });

    return {
      chaincodeTemplates,
      userChaincodeTemplates,
    };
  }, [preLoadedTemplates]);

  console.log('CHAINCODE', chaincode);

  console.log(
    'preLoadedTemplates',
    chaincodeTemplateOptions?.chaincodeTemplates,
    selectedNetwork,
  );

  const templateDropdownOptions = () =>
    templateList.length > 0
      ? templateList.map((item) => ({
          label: item.name,
          value: item,
        }))
      : [{ label: t('common.chaincode.noTemplateFound'), value: null }];

  return (
    <>
      <div style={{ width: 'fit-content' }} className="base-templates-list">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">
            {t('common.forms.selectBaseTemplate')}
          </Typography>

          <Tooltip message={t('common.chaincode.templateInfo')}>
            <IconButton onClick={() => setOpen(true)}>
              <Info
                color="primary"
                fontSize="large"
                style={{ width: '25px', height: '25px', cursor: 'pointer' }}
              />
            </IconButton>
          </Tooltip>
        </div>

        {loading ? (
          <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ marginBottom: '20px' }}>
            {Object.entries(chaincodeTemplateOptions?.chaincodeTemplates).map(
              (item, idx: number) =>
                item[1].name && (
                  <Button
                    key={idx}
                    component="span"
                    style={{
                      margin: '5px 10px',
                      ...(chaincode?.ccBaseName === item[0] &&
                        operation === 'upgradechaincode' && {
                          backgroundColor: 'var(--primary)',
                        }),

                      ...(chaincode?.ccBaseName === item[0] &&
                        operation === 'upgradechaincode' && {
                          color: 'var(--white)',
                        }),
                    }}
                    disabled={started || operation === 'upgradechaincode'}
                    variant={
                      chaincode?.ccBaseName === item[0]
                        ? 'contained'
                        : 'outlined'
                    }
                    color={
                      chaincode?.ccBaseName === item[0] ? 'primary' : 'default'
                    }
                    onClick={() => {
                      if (operation !== 'upgradechaincode')
                        handleSelectTemplate(item[0], item[1]);
                    }}
                  >
                    {item[1].name}
                  </Button>
                ),
            )}
          </div>
        )}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">
            {t('common.forms.selectUserTemplate')}
          </Typography>

          <Tooltip message={t('common.chaincode.templateInfo')}>
            <IconButton onClick={() => setOpen(true)}>
              <Info
                color="primary"
                fontSize="large"
                style={{ width: '25px', height: '25px', cursor: 'pointer' }}
              />
            </IconButton>
          </Tooltip>
        </div>

        {loading ? (
          <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ marginBottom: '20px' }}>
            {Object.entries(
              chaincodeTemplateOptions?.userChaincodeTemplates,
            ).map(
              (item, idx: number) =>
                item[1].name && (
                  <Button
                    key={idx}
                    component="span"
                    style={{ margin: '5px 10px' }}
                    disabled={started || operation === 'upgradechaincode'}
                    variant={
                      chaincode?.ccBaseName === item[0]
                        ? 'contained'
                        : 'outlined'
                    }
                    color={
                      chaincode?.ccBaseName === item[0] ? 'primary' : 'default'
                    }
                    onClick={() => {
                      if (operation !== 'upgradechaincode')
                        handleSelectTemplate(item[0], item[1]);
                    }}
                  >
                    {item[1].name}
                  </Button>
                ),
            )}
          </div>
        )}
      </div>

      <Typography variant="h6" gutterBottom>
        {t('common.forms.addYourTemplate')}
      </Typography>

      <div style={{ width: '100%', margin: '0 auto' }}>
        <Select
          twoColumns
          disabled={started || operation === 'upgradechaincode'}
          className="deploy-template-select"
          placeholder={t('common.chaincode.selectTemplate')}
          defaultValue={{
            label:
              chaincode?.templateDef?.name ||
              chaincode?.templateDefinition?.name,
            value: chaincode?.templateDef || chaincode?.templateDefinition,
          }}
          options={templateDropdownOptions()}
          onChange={handleSelectTemplateDefinition}
          isDisabled={(option) =>
            option.value === null || operation === 'upgradechaincode'
          }
          // isDisabled={(option) => option.value === null}
          fixedOption={{
            label: t('asset.chaincodes.templates.manageTemplates.newTemplate'),
            action: () => history.push('/chaincode/templates/manage'),
            icon: 'add',
          }}
        />

        <Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>
          <Trans>common.words.permissions</Trans>
        </Typography>

        {chaincode?.templateDefinition || chaincode?.templateDef ? (
          <NoPermissionMsg className="template-permissions-explanation">
            <Info
              color="primary"
              fontSize="large"
              style={{ marginRight: '10px' }}
            />
            <Trans>common.chaincode.noPermissionWarning</Trans>
          </NoPermissionMsg>
        ) : null}

        <DefinePermissions
          changeDeployTemplate={changeDeployTemplate}
          deployTemplate={
            operation === 'upgradechaincode'
              ? netDefTemplate
              : chaincode?.templateDefinition
          }
          operation={operation}
          orgs={orgs}
        />
      </div>

      <CCDescriptionModal
        opened={open}
        closeModal={() => setOpen(!open)}
        templates={preLoadedTemplates}
      />
    </>
  );
};
