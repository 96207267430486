export interface ISelectedNodes extends Required<Omit<INode, 'name'>> {
  idx: number;
  peerName: string;
  ordererName: string;
}

export const getSelectedNodes = (org: IOrganization) => {
  const { peers, orderers } = org;
  const selectedNodes: ISelectedNodes[] = [];

  peers.forEach((peer, idx) => {
    if (peer.selected)
      selectedNodes.push({
        ...peer,
        type: 'p',
        idx,
        ordererName: '',
        peerName: `${peer.name}.${org.name}`,
      });
  });

  orderers.forEach((orderer, idx) => {
    if (orderer.selected) {
      const nodeIdx = selectedNodes.findIndex(
        (node) => node.value === orderer.value,
      );

      if (nodeIdx < 0)
        selectedNodes.push({
          ...orderer,
          type: 'o',
          idx,
          peerName: '',
          ordererName: `${orderer.name}.${org.name}`,
        });
      else
        selectedNodes[nodeIdx] = {
          ...selectedNodes[nodeIdx],
          ordererName: `${orderer.name}.${org.name}`,
          type: 'both',
          idx,
        };
    }
  });

  return selectedNodes;
};
