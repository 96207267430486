import styled, { css } from 'styled-components';
import { Paper, ListItem } from '@material-ui/core';

interface IListItemProps {
  selected: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  min-width: 700px;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  padding: 16px 0 24px 0;
  justify-content: center;
`;

export const Card = styled(Paper)`
  && {
    width: 100%;
    padding: 24px;
    display: flex;
    margin: 0 auto;
    height: fit-content;
    flex-direction: column;
    box-sizing: border-box;
  }
`;

export const OrgListItem = styled(ListItem).attrs(
  (props: { disabled: boolean }) => ({
    button: true,
    divider: true,
    disabled: props.disabled,
  }),
)<IListItemProps>`
  && {
    height: 42px;
    margin: 8px 0;

    ${({ selected }) =>
      selected &&
      css`
        && {
          border-radius: 5px;
          color: var(--white);
          background-color: var(--darkBlue);
        }
      `};
  }
`;

export const NodesContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  padding-left: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  && {
    .MuiCheckbox-colorSecondary {
      padding: 4px;
    }
  }
`;

export const NodesListContainer = styled.div`
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NodesList = styled.div`
  width: 100%;
  display: flex;
  max-width: 100%;
  max-height: 300px;
  padding-left: 8px;
  overflow-y: scroll;
  flex-flow: row wrap;

  label {
    margin: 0;
  }

  span {
    font-size: 14px;
    white-space: pre-line;
  }
`;
