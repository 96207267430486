import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WithOrgNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

export interface IBackupRestoreNotificationsState extends Record<string, any> {
  restore: WithOrgNotificationObject[];
  restoreAttrs: INotificationAttrs[];
}

export const initialState: IBackupRestoreNotificationsState = {
  restore: [],
  restoreAttrs: [],
};

const restoreNotificationsSlice = createSlice({
  name: 'restoreNotifications',
  initialState,
  reducers: {
    changeRestoreNotification(
      state,
      action: PayloadAction<WithOrgNotificationObject>,
    ) {
      const { orgName } = action.payload;
      const { restore, restoreAttrs } = state;

      const orgIndex = restore.findIndex(
        (org: { orgName: string }) => org.orgName === orgName,
      );

      if (orgIndex < 0) {
        restore.push(action.payload);
        restoreAttrs.push({
          orgName,
          failed: false,
          mainLoad: 0,
          fullyComplete: false,
        });
      } else {
        restore[orgIndex] = action.payload;
      }
    },
    changeRestoreNotificationAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      const { restoreAttrs } = state;
      const { orgName } = action.payload;

      const index = restoreAttrs.findIndex(
        (notAttr) => notAttr.orgName === orgName,
      );

      if (index >= 0) {
        restoreAttrs[index] = {
          ...restoreAttrs[index],
          ...action.payload,
        };
      }
    },
    clearRestoreNotification() {
      return initialState;
    },
  },
});

export const {
  clearRestoreNotification,
  changeRestoreNotification,
  changeRestoreNotificationAttrs,
} = restoreNotificationsSlice.actions;

export default restoreNotificationsSlice.reducer;
