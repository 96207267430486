import { createSlice } from '@reduxjs/toolkit';

export interface IApiAuthDialogState extends Record<string, any> {
  open: boolean;
}

export const initialState: IApiAuthDialogState = {
  open: false,
};

const apiAuthDialogSlice = createSlice({
  name: 'apiAuthDialog',
  initialState,
  reducers: {
    openApiAuthDialog(state) {
      state.open = true;
    },
    closeApiAuthDialog(state) {
      state.open = false;
    },
  },
});

export const {
  openApiAuthDialog,
  closeApiAuthDialog,
} = apiAuthDialogSlice.actions;

export default apiAuthDialogSlice.reducer;
