import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Title = ({ ...props }) => (
  <Typography variant="overline" style={{ fontSize: '30px' }}>
    {props.children}
  </Typography>
);

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  @media (min-width: 2000px) {
    justify-content: center;
  }
`;

export const EmptyRightSide = styled.div`
  width: 70%;
  padding: 20px;
  max-width: 700px;
`;
