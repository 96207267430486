import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 70%;
  padding: 20px;
  max-width: 700px;
`;

export const SPaper = ({ ...props }) => (
  <Paper style={{ margin: '20px 0', padding: '20px' }}>{props.children}</Paper>
);

export const Subtitle = ({ ...props }) => (
  <Typography variant="overline" style={{ fontSize: '22px' }}>
    {props.children}
  </Typography>
);

export const TemplateAttribute = ({ ...props }) => (
  <Typography variant="subtitle1" style={{ fontSize: '14px' }}>
    {props.children}
  </Typography>
);

export const AssetsTitle = ({ ...props }) => (
  <Typography variant="subtitle1" style={{ fontSize: '16px' }}>
    {props.children}
  </Typography>
);

export const BooleanAttrContainer = styled.div`
  margin: 0 5px;
`;

export const BooleanAttributes = ({ ...props }) => (
  <Typography variant="subtitle1" style={{ fontSize: '10px' }}>
    {props.children}
  </Typography>
);

export const SingleAssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SingleAssetMainInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
