import styled from 'styled-components';
import {
  Typography,
  Avatar,
  TextField,
  DialogContentText,
} from '@material-ui/core';

export const PageTitle = styled(Typography).attrs({
  variant: 'overline',
})`
  && {
    display: flex;
    margin: 20px 0;
    font-size: 25px;
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 3rem 0;
  width: 100%;
  height: 100%;
`;

export const ProfileForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0rem;
  padding-top: 0.25rem;
  margin-right: 3rem;
  border: 1px solid #e2e1e1;
  border-left: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    z-index: -1;
    width: 2px;
    height: 100%;
    background: #e2e1e1;
  }

  strong {
    font-size: 14px;
    color: var(--primary);
  }

  @media (max-width: 1200px) {
    padding: 1rem;
  }
`;

export const UserAvatar = styled(Avatar)`
  && {
    width: 50px;
    height: 50px;
    font-size: 18px;
    margin-bottom: 20px;
    background: var(--primary);
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentText = styled(DialogContentText)`
  && {
    margin: 0;
    display: flex;
    font-size: 16px;
    align-items: center;
  }
`;

export const PlanButton = styled.button`
  display: flex;
  align-items: flex-start;

  background: none;
  border: 0;
  padding: 0;
  outline: 0;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

export const TopicPoint = styled.div`
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 50%;
  background: var(--primary);
`;
