import {
  Typography,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { Person, AccountCircle, Email } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as S from '../styles';
import { networkApi } from '../../../Common/axios';
import { openDialog } from '../../../store/Dialog';

type FormValues = {
  name: string;
  email: string;
  username: string;
  plan: string;
};

export const ProfileForm = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: async () => {
      const res = await networkApi.get('/getprofile');
      return {
        name: res?.data?.name,
        email: res?.data?.email,
        plan: res?.data?.plantype,
        username: res?.headers?.username,
      };
    },
  });

  const onSubmit = (data: FormValues) => {
    console.log(data);
  };

  return (
    <S.ProfileForm
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: '385px' }}
    >
      <Typography style={{ fontSize: '18px' }} variant="overline">
        Edit Profile
      </Typography>

      <TextField
        fullWidth
        {...register('name', { required: 'Name is required' })}
        error={!!errors.name}
        helperText={errors.name?.message}
        label={t('asset.profile.name')}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Person style={{ color: 'gray' }} />
            </InputAdornment>
          ),
        }}
        size="small"
      />

      <TextField
        fullWidth
        {...register('username', { required: 'Username is required' })}
        error={!!errors.username}
        helperText={errors.username?.message}
        label={t('common.forms.userName')}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle style={{ color: 'gray' }} />
            </InputAdornment>
          ),
        }}
        size="small"
      />

      <TextField
        fullWidth
        {...register('email', { required: 'Email is required' })}
        error={!!errors.email}
        helperText={errors.email?.message}
        label={t('asset.profile.email')}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Email style={{ color: 'gray' }} />
            </InputAdornment>
          ),
        }}
        size="small"
      />

      <TextField
        fullWidth
        {...register('plan')}
        // error={!!errors.plan}
        // helperText={errors.plan?.message}
        label={t('asset.profile.plan')}
        variant="outlined"
        disabled
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle style={{ color: 'gray' }} />
            </InputAdornment>
          ),
        }}
        size="small"
      />

      <Button fullWidth variant="contained" color="primary" size="large">
        Save
      </Button>
    </S.ProfileForm>
  );
};
