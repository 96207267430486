import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface ITooltipTitle {
  boldMessage?: boolean;
  fontSize?: string | number;
}

export const TooltipTitle = styled(Typography).attrs({
  color: 'inherit',
})<ITooltipTitle>`
  && {
    text-align: center;
    white-space: pre-line;
    font-size: ${({ fontSize }) => fontSize || '16px'};
    font-weight: ${({ boldMessage }) => (boldMessage ? 'bold' : 'normal')};
  }
`;
