import React from 'react';
import {
  createStyles,
  InputBase,
  Select,
  Theme,
  withStyles,
} from '@material-ui/core';

interface ChaincodeSelectProps {
  value: string;
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode,
  ) => void;
  options: string[];
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'var(--primary)',
      borderBottom: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);
export const ChaincodeSelect = ({
  value,
  onChange,
  options,
}: ChaincodeSelectProps) => {
  return (
    <Select
      native
      value={value}
      onChange={onChange}
      color="primary"
      style={{ color: 'white' }}
      input={<BootstrapInput />}
    >
      {options.map((option) => (
        <option value={option}>{option}</option>
      ))}
    </Select>
  );
};
