import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  Icon,
  Button,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Close, Edit } from '@material-ui/icons';
import {
  ITemplate,
  deleteTemplate,
  getTemplateList,
  setEditedTemplate,
  setSelectedTemplate,
} from '../../../../../store/TemplateCC';

import { StoreState } from '../../../../../store/types';
import { openDialog } from '../../../../../store/Dialog';

import Tooltip from '../../../../../AppComponents/Tooltip';

import { Container, Subtitle, ManagementHeader } from './styles';

const ListTemplates: React.FC<{
  setViewOrCreate: (arg: 'view' | 'create' | 'edit') => void;
  viewOrCreate: 'view' | 'create' | 'edit' | null;
}> = ({ setViewOrCreate, viewOrCreate }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>();
  const {
    templateList,
    selectedTemplate,
  }: { templateList: ITemplate[]; selectedTemplate: ITemplate } = useSelector(
    (state: StoreState) => state.templateCCState,
  );

  const templateClick = useCallback(
    (template: ITemplate) => {
      setViewOrCreate('view');
      dispatch(setSelectedTemplate(template));
    },
    [setViewOrCreate, dispatch],
  );

  const templateToDelete = useCallback(
    async (template: ITemplate) => {
      setLoading(true);
      await dispatch(deleteTemplate(template.name));
      setLoading(false);
    },
    [dispatch],
  );

  const renderItem = useCallback(
    (item, idx) => (
      <div key={idx} style={{ display: 'flex' }}>
        <ListItem
          button
          divider
          onClick={() => templateClick(item)}
          style={{
            background:
              viewOrCreate === 'view' && item.name === selectedTemplate.name
                ? '#ddd'
                : 'var(--white)',
          }}
        >
          <ListItemText primary={`${item.name}`} />
        </ListItem>
        <Button
          onClick={() => {
            setViewOrCreate('edit');
            dispatch(setEditedTemplate(item));
          }}
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 0,
          }}
        >
          <Edit style={{ color: 'gray' }} />
        </Button>
        <Button
          onClick={() =>
            dispatch(
              openDialog({
                title: t(
                  'asset.chaincodes.templates.manageTemplates.deleteTemplate',
                ),
                type: 'question',
                content: t(
                  'asset.chaincodes.templates.manageTemplates.deleteTemplateMsg',
                  { templateName: item.name },
                ),
                confirmAction: () => templateToDelete(item),
              }),
            )
          }
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 0,
          }}
        >
          <Close style={{ color: 'red' }} />
        </Button>
      </div>
    ),
    [
      t,
      dispatch,
      viewOrCreate,
      templateClick,
      selectedTemplate,
      templateToDelete,
      setViewOrCreate,
    ],
  );

  useEffect(() => {
    dispatch(getTemplateList());
  }, [dispatch]);

  if (loading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  return (
    <Container className="template-management-container">
      <ManagementHeader>
        <Subtitle>
          <Trans>asset.chaincodes.templates.manageTemplates.templateList</Trans>
        </Subtitle>

        <Tooltip
          message={t(
            'asset.chaincodes.templates.manageTemplates.addNewTemplate',
          )}
        >
          <Button
            variant="outlined"
            className="add-new-template"
            onClick={() => setViewOrCreate('create')}
            style={{ width: '128px', padding: '6px 16px 6px 10px' }}
          >
            <Icon color="primary" style={{ marginRight: '6px' }}>
              add
            </Icon>

            <Trans>common.words.template</Trans>
          </Button>
        </Tooltip>
      </ManagementHeader>

      <List>{templateList.map((item, idx) => renderItem(item, idx))}</List>
    </Container>
  );
};

export default ListTemplates;
