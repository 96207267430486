import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import JsonInput from 'react-json-editor-ajrm';
import { Typography } from '@material-ui/core';

import FileInput from '../../../../AppComponents/FileInput';
import { useDeployForm } from '../../../../Hooks/deploy';
import { StoreState } from '../../../../store/types';

const AdvancedTab: React.FC = () => {
  //   const { t } = useTranslation();
  //   const deployStart = useDeployForm();
  //     const { setChaincode } = deployStart;
  //   const { json, jsonError } = useSelector(
  //     (state: StoreState) => state.networkState.selectedNetwork,
  //   );
  //   const handleChainCodeUpload = (files: FileList | null) => {
  //     if (files && files.length > 0) {
  //       setChaincode({
  //         exclusive: false,
  //         chaincodeFile: files[0],
  //         chaincodeName: files[0].name,
  //       });
  //     } else {
  //       setChaincode({
  //         exclusive: false,
  //         chaincodeFile: null,
  //         chaincodeName: '',
  //       });
  //     }
  //   };
  //   return (
  //     <div>
  //       <Typography>{jsonError}</Typography>
  //       <JsonInput
  //         id="json-input"
  //         confirmGood={false}
  //         placeholder={JSON.parse(json)}
  //         // we must see how we're going to do this
  //         // onChange={(e: any) =>
  //         //   e.error || !e.jsObject
  //         //     ? dispatch(handleChange({ type: 'json', value: {} }))
  //         //     : dispatch(handleChange({ type: 'json', value: e.jsObject }))
  //         // }
  //       />
  //       <div>
  //         <FileInput
  //           cloudIcon
  //           file={deployStart.chaincode.chaincodeFile}
  //           message={t('common.messages.chaincodeRequired')}
  //           customBtnStyle={{
  //             margin: '0 20px',
  //             height: '45px',
  //             width: '250px',
  //           }}
  //           disabled={deployStart.started}
  //           onChange={handleChainCodeUpload}
  //         />
  //       </div>
  //     </div>
  //   );
  return <div>TESTE CONTENT</div>;
};

export default AdvancedTab;
