import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Icon,
  IconButton,
  Link,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useNetworks } from '../../../Contexts/Networks';
import { useOPHistory } from '../../../Contexts/OPHistory';

export const Reports = () => {
  const { selectedNetwork } = useNetworks();

  const { operationsHistory, getHistory } = useOPHistory();

  const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <Box>
      <Badge color="secondary" onClick={handleClick}>
        <Icon style={{ color: 'white' }}>notifications</Icon>
      </Badge>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            elevation={4}
            style={{
              padding: '1.5rem',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ gap: '0.5rem', minWidth: '175px' }}
            >
              <Typography variant="overline">Operations</Typography>
              <Divider style={{ width: '100%' }} />

              <Box>
                {selectedNetwork ? (
                  <Button
                    variant="text"
                    onClick={() => {
                      getHistory('specificNetwork');
                      history.push('/operations-history');
                    }}
                    style={{ textTransform: 'capitalize' }}
                  >
                    View{' '}
                    <Typography
                      color="secondary"
                      style={{ margin: '0 0.3rem' }}
                    >
                      {selectedNetwork?.networkName}
                    </Typography>{' '}
                    reports
                  </Button>
                ) : (
                  'Select a Network to see its reports'
                )}
              </Box>

              <Divider style={{ width: '100%' }} />

              <Box
                onClick={() => {
                  getHistory('all');
                  history.push('/operations-history');
                }}
              >
                <Button variant="text">
                  {t('asset.operationsHistory.seeAllOperations')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
