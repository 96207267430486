import Axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  List,
  Button,
  Divider,
  Checkbox,
  Typography,
  ListItemText,
  FormControlLabel,
} from '@material-ui/core';

import history from '../../../history';
import { networkApi } from '../../../Common/axios';
import { useJoinChannelForm } from '../../../Hooks/joinChannel';

import { buildRequestPayload, validateFormData } from './utils';
import { getSelectedNodes } from '../utils';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';

import LoadingScreen from '../../LoadingScreen';
import Tooltip from '../../../AppComponents/Tooltip';
import CAauthForm, { Auth } from '../../../AppComponents/CAauth';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import LoadingContainer from '../../../AppComponents/Notifications';

import { StoreState } from '../../../store/types';
import { IDialogInfo, openDialog } from '../../../store/Dialog';
import { addOperation, removeOperation } from '../../../store/AppStatus';
import { clearJoinChannelNotifications } from '../../../store/JoinChannelNotifications';

import {
  Card,
  Container,
  NodesList,
  OrgListItem,
  NodesContainer,
  ContentContainer,
  NodesListContainer,
} from './styles';
import { useNetworks } from '../../../Contexts/Networks';

const { CancelToken } = Axios;
let cancel: (hasDialog?: boolean) => void;

const JoinChannel: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //   const { selectedNetwork } = useSelector(
  //     (state: StoreState) => state.networkState,
  //   );

  const { selectedNetwork, selectedChannel } = useNetworks();
  //   const { channelName } = selectedNetwork;

  const joinChannelForm = useJoinChannelForm();
  const {
    orgs,
    CAauth,
    setOrgs,
    sending,
    setCAauth,
    setSending,
    selectedOrgIdx,
    setSelectedOrgIdx,
    selectedNodes,
    setSelectedNodes,
    channelNodesMap,
    clearFormData,
  } = joinChannelForm;

  const selectedOrg: IOrganization | undefined = orgs[selectedOrgIdx];

  const send = () => {
    const formValid = validateFormData(
      joinChannelForm,
      (value: IDialogInfo) => dispatch(openDialog(value)),
      t,
    );

    if (formValid) {
      setSending(true);
      dispatch(clearJoinChannelNotifications());

      const formData = buildRequestPayload(
        joinChannelForm,
        selectedNetwork as INetwork,
        selectedNodes,
      );

      networkApi
        .post('/joinchannel', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, t('title.network.deploy'), hasDialog);
            };

            cancel = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.network.deploy'),
                pathname: window.location.pathname,
                name: 'joinchannel',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(() => {
          clearFormData();

          dispatch(
            removeOperation(
              'joinchannel',
              true,
              selectedNetwork?.networkName as string,
              selectedChannel,
            ),
          );

          if (window.location.pathname === '/channel/create')
            history.push('/dashboard');
        })
        .catch(() => dispatch(removeOperation('joinchannel', false)))
        .finally(() => setSending(false));
    }
  };

  const changeCAauth = useCallback(
    (newCAauth: Auth) =>
      setCAauth((prevCAauth) => ({ ...prevCAauth, ...newCAauth })),
    [setCAauth],
  );

  const changeNodeSelected = useCallback(
    (type: 'peers' | 'orderers', idx: number) => {
      const newOrgs = [...orgs];
      const nodeList = newOrgs[selectedOrgIdx][type];

      nodeList[idx].selected = !nodeList[idx].selected;

      setOrgs(newOrgs);
    },
    [orgs, setOrgs, selectedOrgIdx],
  );

  const getNodesList = useCallback(
    (list: INode[], type: 'peers' | 'orderers') =>
      list.map((node, idx) => {
        const alreadyIsMember: boolean =
          channelNodesMap[`${node.name}.${selectedOrg.name}`] || false;

        return (
          <Tooltip
            key={node.name}
            message={
              alreadyIsMember
                ? t('asset.nodes.joinChannel.alreadyIsMember')
                : node.value
            }
          >
            <FormControlLabel
              label={node.name}
              style={{ margin: 8 }}
              control={
                <Checkbox
                  checked={node.selected}
                  disabled={alreadyIsMember}
                  onChange={() => changeNodeSelected(type, idx)}
                />
              }
            />
          </Tooltip>
        );
      }),
    [t, selectedOrg, channelNodesMap, changeNodeSelected],
  );

  const listExistingNodes = useCallback(() => {
    const { peers, orderers } = selectedOrg || {};

    return [
      ...getNodesList(peers || [], 'peers'),
      ...getNodesList(orderers || [], 'orderers'),
    ];
  }, [selectedOrg, getNodesList]);

  const filterSelectedNodes = useCallback(
    () => getSelectedNodes(orgs[selectedOrgIdx]),
    [orgs, selectedOrgIdx],
  );

  useEffect(() => {
    if (selectedOrg) setSelectedNodes(filterSelectedNodes());
  }, [selectedOrg, setSelectedNodes, filterSelectedNodes]);

  if (!selectedNetwork?.networkName || !selectedChannel)
    return (
      <SelectNetwork
        isNetSelected={!!(selectedNetwork?.networkName as string)}
      />
    );

  return (
    <Container>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        <Trans>title.nodes.joinNodesToChannel</Trans>
      </Typography>

      <ContentContainer>
        <Card>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '30%' }}>
              <Typography style={{ fontSize: '1.1rem' }}>
                <Trans>common.words.organizations</Trans>
              </Typography>

              <List>
                {orgs.map((org, idx) => (
                  <OrgListItem
                    key={org.name}
                    disabled={sending}
                    selected={idx === selectedOrgIdx}
                    onClick={() => setSelectedOrgIdx(idx)}
                  >
                    <ListItemText primary={org.name} />
                  </OrgListItem>
                ))}
              </List>
            </div>

            <NodesContainer>
              <Typography style={{ fontSize: '1.1rem' }}>
                <Trans>common.words.nodes</Trans>
              </Typography>

              {selectedOrgIdx < 0 ? (
                <Typography style={{ marginTop: '16px', fontSize: '1.1rem' }}>
                  <Trans>common.messages.selectOneOrg</Trans>
                </Typography>
              ) : (
                <div style={{ width: '100%', height: '100%' }}>
                  <NodesListContainer>
                    <Typography variant="body1">
                      <Trans>asset.network.createChannel.existingNodes</Trans>
                    </Typography>

                    <NodesList>{listExistingNodes()}</NodesList>
                  </NodesListContainer>
                </div>
              )}
            </NodesContainer>
          </div>

          {selectedOrg ? <Divider style={{ margin: '16px 0 8px 0' }} /> : null}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography style={{ fontSize: '1.1rem', margin: '8px 0' }}>
              {selectedOrg ? (
                <Trans values={{ orgName: selectedOrg.firstName }}>
                  asset.nodes.joinChannel.orgCAInfo
                </Trans>
              ) : (
                <Trans>asset.organizations.addOrg.CAInfo</Trans>
              )}
            </Typography>

            <CAauthForm
              initialState={CAauth}
              callback={changeCAauth}
              disabled={sending || !selectedOrg}
              containerStyles={{ flexDirection: 'row', paddingLeft: '16px' }}
              userInputStyles={{ marginRight: '32px' }}
              passwordInputStyles={{}}
            />
          </div>

          {sending ? (
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingScreen
                  content={t('asset.nodes.joinChannel.loadingMessage')}
                />
              </div>

              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => cancel()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            </div>
          ) : (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              style={{ marginTop: '32px' }}
              onClick={() => send()}
            >
              <Trans>button.submit</Trans>
            </Button>
          )}
        </Card>

        <div style={{ margin: '50px auto' }}>
          <LoadingContainer
            cardInfo={{
              running: sending,
              type: 'joinchannel',
              canShow: selectedChannel !== '',
              title: t('common.words.global'),
              attributes: { selectedChannel },
            }}
          />
        </div>
      </ContentContainer>
    </Container>
  );
};

export default JoinChannel;
