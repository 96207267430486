import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--disabled);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  :root {
    --primary: #003F69;
    --primaryDark: #001D31;
    --secondary: #3498db;
    --lightGray: #c9c5c5;
    --lightText: #494747;
    --gray: gray;
    --darkGray: #433739;
    --white: #fff;
    --disabled: #888888;
    --backgroundPrimary: #ffffff;
    --backgroundSecondary: #f1f1f1;
    --black: #030517;
    --error: #f44336;
    --success: #07bc0c;
    --warning: #ffcc00;
    --errorBackground: #f97a81;
    --backgroundSuccess: #def0d8;
    --tourToastText: #ebf8ff;
    --tourToastButton: #34dbd1;
    --tourToastBackground: #3172b7;
    --reportNotRun: #e67e22;
    --lightPurple: #5c6bc0;
    --darkBlue: #3f51b5;
  }
`;

export const Colors: Record<string, string> = {
  primary: '#003F69',
  primaryDark: '#001D31',
  secondary: '#3498db',
  lightGray: '#c9c5c5',
  gray: 'gray',
  darkGray: '#433739',
  white: '#fff',
  disabled: '#888888',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#f1f1f1',
  black: '#030517',
  error: '#f44336',
  success: '#07bc0c',
  warning: '#ffcc00',
  errorBackground: '#f97a81',
  backgroundSuccess: '#def0d8',
  tourToastText: '#ebf8ff',
  tourToastButton: '#34dbd1',
  tourToastBackground: '#3172b7',
  reportNotRun: '#e67e22',
  lightPurple: '#5c6bc0',
  darkBlue: '#3f51b5',
};

type RoutesContainerProps = {
  headerHeight: number;
  sidebarWidth: number;
};

export const RoutesContainer = styled.div<RoutesContainerProps>`
  position: sticky;
  background: var(--white);
  margin-top: ${({ headerHeight }) => `${headerHeight}px`};
  margin-left: ${({ sidebarWidth }) => `${sidebarWidth}px`};

  @media (min-width: 1440px) {
    margin-left: ${({ sidebarWidth }) => `${sidebarWidth + 50}px`};
  }
`;
