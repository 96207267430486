import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

const GenericInput: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const { variant, style } = props;

  return (
    <TextField
      {...props}
      variant={variant || 'outlined'}
      InputLabelProps={{ shrink: true }}
      style={{ margin: '10px', ...style }}
    />
  );
};

export default GenericInput;
