import Axios from 'axios';
import React, { useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Step,
  Button,
  Stepper,
  StepLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import history from '../../../history';
import { networkApi } from '../../../Common/axios';
import { useBackupRestoreForm } from '../../../Hooks/backupRestore';

import {
  Operation,
  addOperation,
  removeOperation,
} from '../../../store/AppStatus';
import { INetwork } from '../../../store/Network';
import { StoreState } from '../../../store/types';
import { openDialog } from '../../../store/Dialog';
import { clearRestoreNotification } from '../../../store/BackupRestoreNotifications';

import LoadingScreen from '../../LoadingScreen';
import Tooltip from '../../../AppComponents/Tooltip';
import FileInput from '../../../AppComponents/FileInput';
import ProgressBar from '../../../AppComponents/ProgressBar';
import canRunOperation from '../../../utils/canRunOperation';
import LoadingContainer from '../../../AppComponents/Notifications';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';

import {
  Container,
  OrgFormCard,
  FirstStepCard,
  LoadingContent,
  OrgsFormContainer,
  FirstStepContainer,
  StepHeaderContainer,
  SecondStepContainer,
  SecondStepFormContainer,
} from './styles';

interface ICAauth extends Record<string, any> {
  user?: string;
  passwd?: string;
}

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const BackupRestore: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const { networks }: { networks: INetwork[] } = useSelector(
    (state: StoreState) => state.networkState,
  );

  const {
    loading,
    setLoading,
    activeStep,
    setActiveStep,
    users,
    setUsers,
    passwords,
    setPasswords,
    file,
    setFile,
    orgs,
    setOrgs,
    networkName,
    setNetworkName,
    loadingOrgs,
    setLoadingOrgs,
    progressUpload,
    setProgressUpload,
    clearFormData,
  } = useBackupRestoreForm();

  const steps = [
    t('asset.backup.restoreBackup.backupUpload'),
    t('asset.backup.restoreBackup.orgAuth'),
  ];

  const handleRestore = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      dispatch(clearRestoreNotification());

      const Caauth: ICAauth = {};

      if (Object.keys(users).length !== Object.keys(orgs).length)
        throw Error(t('common.messages.insertAllUsers'));

      if (Object.keys(passwords).length !== Object.keys(orgs).length)
        throw Error(t('common.messages.insertAllPasswords'));

      orgs.forEach((org, idx) => {
        if (users[idx].length === 0)
          throw Error(t('common.messages.insertAllUsers'));

        if (passwords[idx].length === 0)
          throw Error(t('common.messages.insertAllPasswords'));

        Caauth[org] = { user: users[idx], passwd: passwords[idx] };
      });

      const network = networks.find((net) => net.name === networkName);

      const data = {
        networkName,
        CAAuth: Caauth,
        // in multichannel created networks the channel name is not necessary but to
        // make it compatible with previous created networks we'll send the first channel
        channelName: network ? network.channels[0] : 'mainchannel',
      };

      const formData = new FormData();

      formData.append('restoreInfo', JSON.stringify(data));
      formData.append('backup', file as Blob);

      await networkApi.post('/restorenetwork', formData, {
        cancelToken: new CancelToken((c) => {
          const withDialogCancel = (hasDialog = true) => {
            cancelWithDialog(c, t('title.backup.restoreBackup'), hasDialog);
          };

          cancelRequest = withDialogCancel;
          dispatch(
            addOperation({
              title: t('title.backup.restoreBackup'),
              cancel: withDialogCancel,
              name: 'restorenetwork',
              pathname: window.location.pathname,
            }),
          );
        }),
      });

      dispatch(removeOperation('restorenetwork', true));
      clearFormData();
      if (window.location.pathname === '/backup/restore')
        history.push('/dashboard');
    } catch (error) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message || t('common.messages.requestCancelledByUser'),
        }),
      );
      dispatch(removeOperation('restorenetwork', false));
    } finally {
      setLoading(false);
    }
  }, [
    t,
    file,
    orgs,
    users,
    networks,
    dispatch,
    passwords,
    setLoading,
    networkName,
    clearFormData,
  ]);

  const getOrgs = useCallback(async () => {
    try {
      setLoadingOrgs(true);
      const formData = new FormData();
      let percent = 0;

      formData.append('backup', file as Blob);

      const options = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          percent = Math.floor((loaded * 100) / total);
          setProgressUpload(percent);
        },
      };

      const response = await networkApi.post(
        '/getbackuporgs',
        formData,
        options,
      );

      setOrgs(response.data.orgs);
      setNetworkName(response.data.networkName);
      setActiveStep(1);
    } catch (err) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: t('asset.backup.restoreBackup.failToGetOrgs'),
        }),
      );

      setProgressUpload(0);
    } finally {
      setLoadingOrgs(false);
    }
  }, [
    t,
    file,
    setOrgs,
    dispatch,
    setActiveStep,
    setNetworkName,
    setLoadingOrgs,
    setProgressUpload,
  ]);

  const handleStepBack = useCallback(() => {
    setActiveStep((state) => {
      if (state === 0) return state;

      return state - 1;
    });
  }, [setActiveStep]);

  const handleStepForward = useCallback(() => {
    if (file) {
      getOrgs();
    } else {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: t('asset.backup.restoreBackup.needToUploadFile'),
        }),
      );
    }
  }, [getOrgs, dispatch, t, file]);

  const cantRunOperation = useCallback(
    () => !canRunOperation(operations),
    [operations],
  );

  const getStepContent = useCallback(
    () => {
      switch (activeStep) {
        case 0:
          return (
            <FirstStepContainer>
              <FirstStepCard>
                <FileInput
                  cloudIcon
                  file={file}
                  accept=".gz,.xz,.tar"
                  message={t('asset.backup.restoreBackup.backupRequired')}
                  disabled={false}
                  onChange={(files: FileList | null) => {
                    if (files && files[0]) setFile(files[0]);
                    else setFile(null);
                    setProgressUpload(0);
                  }}
                />

                {loadingOrgs && <ProgressBar value={progressUpload} />}

                <Button
                  fullWidth
                  color="primary"
                  disabled={loadingOrgs}
                  variant="contained"
                  style={{ marginTop: '15px' }}
                  onClick={() => handleStepForward()}
                >
                  <Trans>button.nextStep</Trans>
                </Button>
              </FirstStepCard>
            </FirstStepContainer>
          );
        case 1:
          return (
            <SecondStepContainer>
              <SecondStepFormContainer>
                <OrgsFormContainer>
                  {orgs.map((item, idx) => (
                    <OrgFormCard key={idx}>
                      <Typography style={{ margin: '0 auto' }}>
                        {item}
                      </Typography>

                      <TextField
                        label={t('common.forms.CAUser')}
                        value={users[idx] || ''}
                        disabled={loading}
                        onChange={(e) => {
                          const newUsers = [...users];
                          newUsers[idx] = e.target.value;
                          setUsers(newUsers);
                        }}
                      />

                      <TextField
                        style={{ marginTop: '4px' }}
                        type="password"
                        label={t('common.forms.CAPassword')}
                        value={passwords[idx] || ''}
                        disabled={loading}
                        onChange={(e) => {
                          const newPasswords = [...passwords];
                          newPasswords[idx] = e.target.value;
                          setPasswords(newPasswords);
                        }}
                      />
                    </OrgFormCard>
                  ))}
                </OrgsFormContainer>

                {loading ? (
                  <LoadingContent>
                    <LoadingScreen
                      content={t('asset.backup.restoreBackup.loadingMessage')}
                    />

                    <Button
                      style={{ margin: '20' }}
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={() => cancelRequest()}
                    >
                      <Trans>button.cancel</Trans>
                    </Button>
                  </LoadingContent>
                ) : (
                  <Tooltip
                    canShow={cantRunOperation()}
                    message={t('common.messages.onlyOneOperation')}
                  >
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      disabled={loading || cantRunOperation()}
                      style={{ margin: '20', pointerEvents: 'all' }}
                      onClick={() => {
                        if (!loading || !cantRunOperation()) handleRestore();
                      }}
                    >
                      <Trans>button.restore</Trans>
                    </Button>
                  </Tooltip>
                )}
              </SecondStepFormContainer>

              {networkName ? (
                <div>
                  <LoadingContainer
                    cardInfo={{
                      running: loading,
                      type: 'restorenetwork',
                      title: t('common.words.restore'),
                      attributes: { networkName },
                    }}
                  />
                </div>
              ) : null}
            </SecondStepContainer>
          );
        default:
          return null;
      }
    },
    // It's not necessary to add the operations to the dependency array
    // eslint-disable-next-line
   [
      t,
      file,
      orgs,
      users,
      loading,
      getOrgs,
      setFile,
      setUsers,
      passwords,
      activeStep,
      loadingOrgs,
      networkName,
      setPasswords,
      handleRestore,
      progressUpload,
    ],
  );

  useEffect(() => {
    if (!loading) {
      if (activeStep > 0) {
        getOrgs();
      } else {
        dispatch(clearRestoreNotification());
        setUsers(['']);
        setPasswords(['']);
      }
    }
    // eslint-disable-next-line
  }, [dispatch, file, setPasswords, setUsers, getOrgs, activeStep]);

  useEffect(() => {
    if (!loading) clearFormData();

    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <StepHeaderContainer>
        <Stepper
          activeStep={activeStep}
          style={{ padding: '24px 24px 10px 24px' }}
        >
          {steps.map((step: string) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <IconButton
            color="secondary"
            disabled={activeStep === 0 || loading}
            style={{ position: 'absolute', left: '12px' }}
            onClick={() => handleStepBack()}
          >
            <ArrowBack style={{ width: '40px', height: '40px' }} />
          </IconButton>

          <IconButton
            color="primary"
            disabled={activeStep === steps.length - 1 || loadingOrgs}
            style={{ position: 'absolute', right: '15px' }}
            onClick={() => handleStepForward()}
          >
            <ArrowForward style={{ width: '40px', height: '40px' }} />
          </IconButton>
        </div>
      </StepHeaderContainer>

      <Container>{getStepContent()}</Container>
    </div>
  );
};

export default BackupRestore;
