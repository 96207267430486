import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getUnixTime, subHours } from 'date-fns';
import { AxiosResponse } from 'axios';
import { AppThunk } from '../../store';
import { networkApi } from '../../Common/axios';

export type FetchingNetwork = 'nothing' | 'loading' | 'success' | 'failure';

export type NetworkStatus = 'online' | 'partial' | 'offline';

export type TransactionsCount = {
  [channelName: string]: { txs: number | string };
};

export interface INetwork extends Record<string, any> {
  name: string;
  displayName: string;
  channels: string[];
  sdks: Record<string, { ip: string; channels: string[] }>;
  status: NetworkStatus;
}

export interface ISelectedNetwork extends INetwork {
  type: string;
  name: string;
  displayName: string;
  // the selected channel
  channelName: string;
  json: string; // network state object stringified
  status: NetworkStatus;
  jsonError: string | null;
}

export interface INetworkState extends Record<string, any> {
  networks: INetwork[] | null;
  selectedNetwork: ISelectedNetwork;
  fetchingNetwork: FetchingNetwork;
  deleteDialog: {
    open: boolean;
    networkName: string;
  };
  editDisplayNameDialog: {
    open: boolean;
    displayName: string;
    networkName: string;
  };
  launchMoreLikeThisDialog: {
    open: boolean;
    displayName: string;
    networkName: string;
  };
  transactionsCount: TransactionsCount | null;
  loadingTransactions: boolean;
}

interface IData {
  time: number;
  transactions: number;
  formattedTime: string;
}

export const changeTransactionsCount = createAsyncThunk<
  TransactionsCount,
  {
    channel: INetStateChannelDef;
    networkName: string;
  },
  {
    rejectValue: TransactionsCount;
  }
>(
  'network/changeTransactionsCount',
  async ({ channel, networkName }, { rejectWithValue }) => {
    try {
      const actualDate = new Date();
      const initialDate = subHours(actualDate, 1);
      const unixInitialDate = getUnixTime(initialDate);
      const unixAtualDate = getUnixTime(actualDate);

      const chaincode = channel.chaincodes[channel.chaincodes.length - 1];
      const chaincodeName =
        chaincode.ccType === 'template'
          ? chaincode.templateDef.name
          : chaincode.chaincodeName;

      const txs = await networkApi
        .get(
          `/txcount?networkName=${networkName}&chaincodeName=${chaincodeName}&start=${unixInitialDate}&end=${unixAtualDate}`,
        )
        .then((res) => {
          const transactions = res.data.values.map(
            (value: IData) => value.transactions,
          );

          return transactions.reduce(
            (sum: number, number: number) => sum + number,
            0,
          );
        });

      return { [channel.channelName]: { txs } };
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      return rejectWithValue({ [channel.channelName]: { txs: '-' } });
    }
  },
);

const initialState: INetworkState = {
  // selected network on dashboard
  selectedNetwork: {
    type: '',
    name: '',
    sdks: {},
    json: '{}',
    displayName: '',
    channels: [],
    channelName: '',
    jsonError: null,
    status: 'online',
  },
  networks: null,
  fetchingNetwork: 'nothing', // nothing, loading, success, failure
  deleteDialog: {
    // important data for delete network dialog
    open: false,
    networkName: '',
  },
  editDisplayNameDialog: {
    // important data for edit network display name dialog
    open: false,
    displayName: '',
    networkName: '',
  },
  launchMoreLikeThisDialog: {
    open: false,
    displayName: '',
    networkName: '',
  },
  transactionsCount: null,
  loadingTransactions: false,
};

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    changeSelectedNetInfo(
      state,
      action: PayloadAction<Record<keyof ISelectedNetwork, any>>,
    ) {
      state.selectedNetwork = { ...state.selectedNetwork, ...action.payload };
    },
    clearSelectedNetwork(state) {
      state.selectedNetwork = initialState.selectedNetwork;
    },
    setNetworks(state, action: PayloadAction<INetwork[] | null>) {
      state.networks = action.payload;
    },
    toggleNetworkFetch(state, action: PayloadAction<FetchingNetwork>) {
      state.fetchingNetwork = action.payload;
    },
    openDeleteNetworkDialog(state, action: PayloadAction<string>) {
      state.deleteDialog = {
        open: true,
        networkName: action.payload,
      };
    },
    closeDeleteNetworkDialog(state) {
      state.deleteDialog = initialState.deleteDialog;
    },
    openEditDisplayNameDialog(
      state,
      action: PayloadAction<
        Omit<INetworkState['editDisplayNameDialog'], 'open'>
      >,
    ) {
      state.editDisplayNameDialog = {
        open: true,
        ...action.payload,
      };
    },
    closeEditDisplayNameDialog(state) {
      state.editDisplayNameDialog = initialState.editDisplayNameDialog;
    },

    openLaunchMoreLikeThisDialog(
      state,
      action: PayloadAction<
        Omit<INetworkState['launchMoreLikeThisDialog'], 'open'>
      >,
    ) {
      state.launchMoreLikeThisDialog = {
        open: true,
        ...action.payload,
      };
    },
    closeLaunchMoreLikeThisDialog(state) {
      state.launchMoreLikeThisDialog = initialState.launchMoreLikeThisDialog;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeTransactionsCount.pending, (state) => {
      // state.transactionsCount = payload;
      state.loadingTransactions = true;
    });
    builder.addCase(changeTransactionsCount.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.transactionsCount = {
        ...state.transactionsCount,
        ...payload,
      };
      state.loadingTransactions = false;
    });
    builder.addCase(changeTransactionsCount.rejected, (state, { payload }) => {
      state.transactionsCount = {
        ...state.transactionsCount,
        ...payload,
      };
      state.loadingTransactions = false;
    });
  },
});

export const selectNetwork =
  (payload: Record<keyof ISelectedNetwork, any>): AppThunk =>
  (dispatch, getState) => {
    const { type } = payload;

    if (type === 'restore' || type === 'new')
      dispatch(
        networkSlice.actions.changeSelectedNetInfo({
          ...initialState.selectedNetwork,
          channelName: '',
          type,
        }),
      );

    const { networkState } = getState();
    const { networks } = networkState;

    const netIndex = networks?.findIndex(
      (net: INetwork) => net.name === payload.name,
    );

    if (
      netIndex !== undefined &&
      networks &&
      networks?.length > 0 &&
      netIndex >= 0
    ) {
      dispatch(
        networkSlice.actions.changeSelectedNetInfo({
          ...payload,
          ...networks[netIndex],
          channelName: '',
        }),
      );
    }
  };

export const changeNetworks =
  (
    newNetworks: Record<string, any>[] | null,
    toKeepStateStatus = false,
  ): AppThunk =>
  (dispatch, getState): Promise<INetwork[] | null> => {
    return new Promise((resolve, reject) => {
      try {
        const { networks } = getState().networkState;

        if (newNetworks) {
          const mappedNetworks = newNetworks.map((net, idx) => ({
            name: net.name,
            sdks: net.sdks,
            displayName: net.displayName,
            status:
              toKeepStateStatus && networks && networks[idx]
                ? networks[idx].status
                : net.status || 'online',
            channels: net.state
              ? net.state.networkDefs.channelDefs.map(
                  (channel: { channelName: string }) => channel.channelName,
                )
              : (networks && networks[idx]?.channels) || [],
          }));

          dispatch(networkSlice.actions.setNetworks(mappedNetworks));
          resolve(mappedNetworks);
        } else {
          dispatch(networkSlice.actions.setNetworks(null));
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

export const fetchNetwork =
  (networkName?: string, channelName?: string): AppThunk =>
  (dispatch) => {
    dispatch(networkSlice.actions.toggleNetworkFetch('loading'));

    return networkApi
      .get('/allnetworks')
      .then((res) => {
        dispatch(networkSlice.actions.toggleNetworkFetch('success'));

        if (networkName) {
          dispatch(changeNetworks(res.data, true));

          return dispatch(
            selectNetwork({ type: 'net', name: networkName, channelName }),
          );
        }

        return dispatch(changeNetworks(res.data, true));
      })
      .catch(() => {
        dispatch(networkSlice.actions.toggleNetworkFetch('failure'));
      });
  };

export const changeNetworkStatus =
  (networkName: string, status: NetworkStatus): AppThunk =>
  (dispatch, getState) => {
    const { networks } = getState().networkState;
    const newNetworks = [...(networks || [])];

    const netIdx = newNetworks.findIndex((net) => net.name === networkName);

    if (netIdx >= 0) {
      newNetworks[netIdx] = {
        ...newNetworks[netIdx],
        status,
      };

      dispatch(networkSlice.actions.setNetworks(newNetworks));
    }
  };

export const {
  toggleNetworkFetch,
  clearSelectedNetwork,
  changeSelectedNetInfo,
  openDeleteNetworkDialog,
  closeDeleteNetworkDialog,
  openEditDisplayNameDialog,
  closeEditDisplayNameDialog,
  openLaunchMoreLikeThisDialog,
  closeLaunchMoreLikeThisDialog,
} = networkSlice.actions;

export default networkSlice.reducer;
