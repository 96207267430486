import React, { useCallback, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TextField, List, Button, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import {
  ITemplate,
  IAsset,
  setNewTemplate,
  createTemplate,
} from '../../../../../store/TemplateCC';
import {
  Container,
  Subtitle,
  SPaper,
  AssetsTitle,
  AssetsTitleContainer,
} from './styles';

import { openDialog } from '../../../../../store/Dialog';
import { StoreState } from '../../../../../store/types';

import AssetFragment from './AssetFragment';
import { validateTemplate } from '../utils';
import { templateNamePatt } from '../../../../../utils/regexPatterns';
import { changeMountedComponents } from '../../../../../store/InitialTour';
import TemplateCopySelect from './TemplateCopySelect';

const CreateTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    newTemplate,
  }: {
    newTemplate: ITemplate;
  } = useSelector((state: StoreState) => state.templateCCState);

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const [loading, setLoading] = useState(false);

  const getCustomTypesList = () => {
    const { assets } = newTemplate;
    const customDataTypes: string[] = [];

    assets.forEach((asset) => {
      (asset.props || []).forEach((prop) => {
        if (prop.isCustom) customDataTypes.push(prop.dataType);
      });
    });

    return customDataTypes;
  };

  async function sendNewTemplate() {
    try {
      setLoading(true);

      if (!validateTemplate(newTemplate, t)) return;

      await dispatch(createTemplate(getCustomTypesList()));

      dispatch(
        openDialog({
          title: t('common.words.success'),
          type: 'success',
          content: t(
            'asset.chaincodes.templates.manageTemplates.createSuccess',
          ),
        }),
      );
    } catch (err) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: err.response.data,
        }),
      );
    } finally {
      setLoading(false);
    }
  }

  const addToAssetList = (asset?: IAsset) => {
    if (asset === undefined) {
      dispatch(
        setNewTemplate({
          ...newTemplate,
          assets: [
            ...newTemplate.assets,
            { tag: '', label: '', props: [], selectOpened: false },
          ],
        }),
      );
    } else {
      dispatch(
        setNewTemplate({
          ...newTemplate,
          assets: [...newTemplate.assets, { ...asset }],
        }),
      );
    }
  };

  const editAsset = useCallback(
    (asset: IAsset, idx: number) => {
      const newAssets = [...newTemplate.assets];
      newAssets[idx] = asset;
      dispatch(setNewTemplate({ ...newTemplate, assets: newAssets }));
    },
    [newTemplate, dispatch],
  );

  const removeAsset = useCallback(
    (idx: number) => {
      const assets = [...newTemplate.assets];
      assets.splice(idx, 1);
      dispatch(setNewTemplate({ ...newTemplate, assets }));
    },
    [newTemplate, dispatch],
  );

  const renderAssetList = useCallback(() => {
    return newTemplate.assets.map((asset, idx) => (
      <AssetFragment
        key={idx}
        asset={asset}
        edit={(toEditAsset) => editAsset(toEditAsset, idx)}
        remove={() => removeAsset(idx)}
      />
    ));
  }, [newTemplate.assets, editAsset, removeAsset]);

  const createTemplateFromAnother = (template: ITemplate) => {
    dispatch(setNewTemplate({ ...template }));
  };

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('templateCreate'));
    // eslint-disable-next-line
  }, []);

  if (loading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  return (
    <Container>
      <Subtitle>
        <Trans>asset.chaincodes.templates.manageTemplates.newTemplate</Trans>
      </Subtitle>

      <div style={{ width: '100%' }}>
        <TemplateCopySelect copyTemplate={createTemplateFromAnother} />
      </div>

      <SPaper>
        <div
          className="template-name-and-description"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <TextField
            label={t('common.words.name')}
            value={newTemplate.name}
            error={!templateNamePatt.test(newTemplate.name)}
            style={{ marginRight: '25px' }}
            onChange={(e) =>
              dispatch(setNewTemplate({ ...newTemplate, name: e.target.value }))
            }
          />

          <TextField
            label={t('common.words.description')}
            value={newTemplate.description}
            onChange={(e) =>
              dispatch(
                setNewTemplate({ ...newTemplate, description: e.target.value }),
              )
            }
          />
        </div>

        <AssetsTitleContainer>
          <AssetsTitle>
            <Trans>common.words.assets</Trans>
          </AssetsTitle>

          <Button
            variant="outlined"
            onClick={() => addToAssetList()}
            className="add-asset-to-template"
          >
            <Trans>button.createNew</Trans>
          </Button>
        </AssetsTitleContainer>

        <List>{renderAssetList()}</List>
      </SPaper>

      <Button
        color="primary"
        variant="outlined"
        onClick={sendNewTemplate}
        className="submit-template-btn"
      >
        <Trans>button.submit</Trans>
      </Button>
    </Container>
  );
};

export default CreateTemplate;
