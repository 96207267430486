import styled, { css } from 'styled-components';
import { Paper as MuiPaper } from '@material-ui/core';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 100px;
  width: 1024px;
  margin: 10px 20px;
`;

export const MainDiv = styled.div``;

export const Paper = styled(MuiPaper).attrs({
  elevation: 3,
})`
  display: flex;
  justify-content: center;
  height: fit-content;
  padding: 40px 30px;
  width: fit-content;
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-top: 2rem;

  span {
    font-size: 1rem;
    color: var(--gray);
  }
`;

export const CustomPaper = styled.div<{ hasHover?: boolean }>`
  ${({ hasHover }) => css`
    display: flex;
    flex-direction: column;
    width: fit-content;
    border: 1px solid var(--lightGray);
    padding: 3rem;
    gap: 3rem;
    & > span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 700;
      cursor: pointer;
    }
    box-shadow: rgba(0, 0, 0, 0.16) 4px 4px 4px;
    ${hasHover &&
    css`
      &:hover {
        transition: 0.3s all ease-in-out;
        cursor: pointer;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
          rgba(0, 0, 0, 0.63) 0px 8px 16px -8px;
      }
    `}

    background-image: linear-gradient(
      72.1deg,
      var(--primary) 30%,
      #005289 70%
    );
    color: var(--white);
  `}
`;

export const OrgPaper = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    width: fit-content;
    border: 1px solid var(--lightGray);
    padding: 2rem;
    gap: 2rem;
    & > span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      cursor: pointer;
    }
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  `}
`;

export const InfoGrid = styled.div`
  display: grid;
  font-size: 32px;
  span {
    font-size: 12px;
  }
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

export const InfoBoxHead = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid var(--white);
`;

export const InfoBox = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '250px')};
  border-radius: 10px;
  flex: ${({ fullWidth }) => (fullWidth ? '1 1 auto' : 'none')};
  /* background-color: var(--backgroundPrimary); */

  /* border: 1px solid var(--lightGray); */
  &:not(:first-child) {
  }

  & > span {
    /* color: var(--lightText); */

    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: ${({ fullWidth }) => (fullWidth ? '1rem' : '2rem')};
    font-weight: 600;
    margin: ${({ fullWidth }) => (fullWidth ? 'auto' : '0')};

    & > span {
      font-weight: 400;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }
`;

export const JoinedChannels = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 1rem;
  text-align: center;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
`;
