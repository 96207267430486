import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import { INetworkState, closeLaunchMoreLikeThisDialog } from '../store/Network';
import { StoreState } from '../store/types';
import { useDeployForm } from '../Hooks/deploy';
import { useNetworks } from '../Contexts/Networks';
import history from '../history';

const LaunchMoreLikeThisModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { networks, launchMoreLikeThisDialog } = useSelector(
    (state: StoreState) => state.networkState,
  ) as INetworkState;

  const { open, networkName, displayName } = launchMoreLikeThisDialog;
  const { selectedNetwork } = useNetworks();
  const [newDisplayName, setNewDisplayName] = useState('');
  const { setOrgs, setChannels, setNetworkName } = useDeployForm();

  const launchMoreLikeNetwork = (
    launchedNetwork: INetwork,
    newNetworkName: string,
  ) => {
    // const launchedNetwork = ({
    //   _id: 'network',
    //   displayName: 'network',
    //   networkName: 'network',
    //   chaincodes: {
    //     'goother-cc': {
    //       ccType: 'template',
    //       tarName: 'goother-cc.tar.gz',
    //       ccBaseName: 'template-cc',
    //       label: 'goother-cc_1.0',
    //       version: '1.0',
    //       sequence: 1,
    //       templateDef: {
    //         _id: 'goother-cc',
    //         name: 'goother-cc',
    //         assets: [
    //           {
    //             tag: 'goAsset',
    //             label: 'GoAsset',
    //             props: [
    //               {
    //                 tag: 'goProp',
    //                 label: 'GoProp',
    //                 required: false,
    //                 isKey: true,
    //                 readOnly: false,
    //                 dataType: 'string',
    //                 writers: null,
    //               },
    //             ],
    //             readers: null,
    //             description: '',
    //           },
    //         ],
    //         customDataTypes: [],
    //       },
    //       peers: {
    //         org2: ['peer0.org2.example.com'],
    //         org3: ['peer0.org3.example.com'],
    //       },
    //     },
    //     'gotemplate-cc': {
    //       ccType: 'template',
    //       tarName: 'gotemplate-cc.tar.gz',
    //       ccBaseName: 'template-cc',
    //       label: 'gotemplate-cc_1.0',
    //       version: '1.0',
    //       sequence: 1,
    //       templateDef: {
    //         _id: 'gotemplate-cc',
    //         name: 'gotemplate-cc',
    //         assets: [
    //           {
    //             tag: 'goAsset',
    //             label: 'GoAsset',
    //             props: [
    //               {
    //                 tag: 'goProp',
    //                 label: 'GoProp',
    //                 required: true,
    //                 isKey: true,
    //                 readOnly: false,
    //                 dataType: 'string',
    //                 writers: null,
    //               },
    //             ],
    //             readers: null,
    //             description: '',
    //           },
    //         ],
    //         customDataTypes: [],
    //       },
    //       peers: {
    //         org1: [
    //           /* 'peer0.org1.example.com' */
    //         ],
    //         org2: ['peer0.org2.example.com'],
    //       },
    //     },
    //   },
    //   organizations: {
    //     org1: {
    //       mspid: 'org1MSP',
    //       domain: 'example.com',
    //       ca: {
    //         containerName: 'ca_org1',
    //         host: '100.26.236.26',
    //         port: 7054,
    //         operationPort: 17054,
    //       },
    //       prometheus: { port: 9090 },
    //       grafana: { port: 1695 },
    //       netapi: { host: '100.26.236.26', port: 8000 },
    //       ccapi: [
    //         {
    //           host: '100.26.236.26',
    //           restPort: 80,
    //           tlsPort: 443,
    //           goinitus: { port: 8080 },
    //           chaincodeName: 'gotemplate-cc',
    //           channelName: 'mainchannel',
    //           requestTimeout: 0,
    //         },
    //       ],
    //       peers: [
    //         /* 'peer0.org1.example.com' */
    //       ],
    //       orderers: ['orderer0.org1.example.com'],
    //       couchs: ['couch0.org1.example.com'],
    //       users: [{ regType: 'admin', name: 'org1admin', password: '' }],
    //       policies: {
    //         Admins: { type: 'Signature', rule: "OR('org1MSP.admin')" },
    //         Endorsement: { type: 'Signature', rule: "OR('org1MSP.peer')" },
    //         Readers: {
    //           type: 'Signature',
    //           rule:
    //             "OR('org1MSP.admin','org1MSP.peer','org1MSP.client', 'org1MSP.orderer')",
    //         },
    //         Writers: {
    //           type: 'Signature',
    //           rule: "OR('org1MSP.admin','org1MSP.orderer','org1MSP.client')",
    //         },
    //       },
    //     },
    //     org2: {
    //       mspid: 'org2MSP',
    //       domain: 'example.com',
    //       ca: {
    //         containerName: 'ca_org2',
    //         host: '52.91.135.181',
    //         port: 7054,
    //         operationPort: 17054,
    //       },
    //       prometheus: { port: 9090 },
    //       grafana: { port: 1695 },
    //       netapi: { host: '52.91.135.181', port: 8000 },
    //       ccapi: [
    //         {
    //           host: '52.91.135.181',
    //           restPort: 80,
    //           tlsPort: 443,
    //           goinitus: { port: 8080 },
    //           chaincodeName: 'goother-cc',
    //           channelName: 'sidechannel',
    //           requestTimeout: 0,
    //         },
    //       ],
    //       peers: ['peer0.org2.example.com'],
    //       orderers: ['orderer0.org2.example.com'],
    //       couchs: ['couch0.org2.example.com'],
    //       users: [{ regType: 'admin', name: 'org2admin', password: '' }],
    //       policies: {
    //         Admins: { type: 'Signature', rule: "OR('org2MSP.admin')" },
    //         Endorsement: { type: 'Signature', rule: "OR('org2MSP.peer')" },
    //         Readers: {
    //           type: 'Signature',
    //           rule:
    //             "OR('org2MSP.admin','org2MSP.peer','org2MSP.client', 'org2MSP.orderer')",
    //         },
    //         Writers: {
    //           type: 'Signature',
    //           rule: "OR('org2MSP.admin','org2MSP.orderer','org2MSP.client')",
    //         },
    //       },
    //     },
    //     org3: {
    //       mspid: 'org3MSP',
    //       domain: 'example.com',
    //       ca: {
    //         containerName: 'ca_org3',
    //         host: '54.90.40.92',
    //         port: 7054,
    //         operationPort: 17054,
    //       },
    //       prometheus: { port: 9090 },
    //       grafana: { port: 1695 },
    //       netapi: { host: '54.90.40.92', port: 8000 },
    //       ccapi: [
    //         {
    //           host: '54.90.40.92',
    //           restPort: 80,
    //           tlsPort: 443,
    //           goinitus: { port: 8080 },
    //           chaincodeName: 'goother-cc',
    //           channelName: 'sidechannel',
    //           requestTimeout: 0,
    //         },
    //       ],
    //       peers: ['peer0.org3.example.com'],
    //       orderers: ['orderer0.org3.example.com'],
    //       couchs: ['couch0.org3.example.com'],
    //       users: [{ regType: 'admin', name: 'org3admin', password: '' }],
    //       policies: {
    //         Admins: { type: 'Signature', rule: "OR('org3MSP.admin')" },
    //         Endorsement: { type: 'Signature', rule: "OR('org3MSP.peer')" },
    //         Readers: {
    //           type: 'Signature',
    //           rule:
    //             "OR('org3MSP.admin','org3MSP.peer','org3MSP.client', 'org3MSP.orderer')",
    //         },
    //         Writers: {
    //           type: 'Signature',
    //           rule: "OR('org3MSP.admin','org3MSP.orderer','org3MSP.client')",
    //         },
    //       },
    //     },
    //   },
    //   peers: {
    //     // 'peer0.org1.example.com': {
    //     //   host: '100.26.236.26',
    //     //   port: 7051,
    //     //   operationPort: 17051,
    //     //   isAnchor: true,
    //     //   name: 'peer0',
    //     //   pw: '',
    //     //   couchUsername: '9638a709-ed04-4e3c-960a-8d46a68d0700',
    //     //   couchPassword: '',
    //     //   couchName: 'couch0.org1.example.com',
    //     //   endorsingPeer: { IsTrue: true, HasValue: false },
    //     //   chaincodeQuery: { IsTrue: true, HasValue: false },
    //     //   ledgerQuery: { IsTrue: true, HasValue: false },
    //     //   eventSource: { IsTrue: true, HasValue: false },
    //     // },
    //     'peer0.org2.example.com': {
    //       host: '52.91.135.181',
    //       port: 7051,
    //       operationPort: 17051,
    //       isAnchor: true,
    //       name: 'peer0',
    //       pw: '',
    //       couchUsername: '2eae3fd7-0864-49c4-838a-1ec752376b3e',
    //       couchPassword: '',
    //       couchName: 'couch0.org2.example.com',
    //       endorsingPeer: { IsTrue: true, HasValue: false },
    //       chaincodeQuery: { IsTrue: true, HasValue: false },
    //       ledgerQuery: { IsTrue: true, HasValue: false },
    //       eventSource: { IsTrue: true, HasValue: false },
    //     },
    //     'peer0.org3.example.com': {
    //       host: '54.90.40.92',
    //       port: 7051,
    //       operationPort: 17051,
    //       isAnchor: true,
    //       name: 'peer0',
    //       pw: '',
    //       couchUsername: '01797408-5717-445a-8835-f332403df090',
    //       couchPassword: '',
    //       couchName: 'couch0.org3.example.com',
    //       endorsingPeer: { IsTrue: true, HasValue: false },
    //       chaincodeQuery: { IsTrue: true, HasValue: false },
    //       ledgerQuery: { IsTrue: true, HasValue: false },
    //       eventSource: { IsTrue: true, HasValue: false },
    //     },
    //   },
    //   orderers: {
    //     'orderer0.org1.example.com': {
    //       name: 'orderer0',
    //       pw: '',
    //       host: '100.26.236.26',
    //       port: 7050,
    //       operationPort: 17050,
    //     },
    //     'orderer0.org2.example.com': {
    //       name: 'orderer0',
    //       pw: '',
    //       host: '52.91.135.181',
    //       port: 7050,
    //       operationPort: 17050,
    //     },
    //     'orderer0.org3.example.com': {
    //       name: 'orderer0',
    //       pw: '',
    //       host: '54.90.40.92',
    //       port: 7050,
    //       operationPort: 17050,
    //     },
    //   },
    //   couchs: {
    //     'couch0.org1.example.com': {
    //       username: '9638a709-ed04-4e3c-960a-8d46a68d0700',
    //       password: '',
    //       port: 5984,
    //     },
    //     'couch0.org2.example.com': {
    //       username: '2eae3fd7-0864-49c4-838a-1ec752376b3e',
    //       password: '',
    //       port: 5984,
    //     },
    //     'couch0.org3.example.com': {
    //       username: '01797408-5717-445a-8835-f332403df090',
    //       password: '',
    //       port: 5984,
    //     },
    //   },
    //   ordererTX: {
    //     ordererType: 'etcdraft',
    //     consenters: {
    //       'orderer0.org1.example.com': {
    //         clientTLSCert: 'org1/orderer0.org1.example.com/tls-server.crt',
    //         serverTLSCert: 'org1/orderer0.org1.example.com/tls-server.crt',
    //       },
    //       'orderer0.org2.example.com': {
    //         clientTLSCert: 'org2/orderer0.org2.example.com/tls-server.crt',
    //         serverTLSCert: 'org2/orderer0.org2.example.com/tls-server.crt',
    //       },
    //       'orderer0.org3.example.com': {
    //         clientTLSCert: 'org3/orderer0.org3.example.com/tls-server.crt',
    //         serverTLSCert: 'org3/orderer0.org3.example.com/tls-server.crt',
    //       },
    //     },
    //     batchTimeout: '2s',
    //     maxMessageCount: 50,
    //     absoluteMaxBytes: '50 MB',
    //     preferredMaxBytes: '2 MB',
    //     policies: {
    //       Admins: { type: 'ImplicitMeta', rule: 'MAJORITY Admins' },
    //       BlockValidation: { type: 'ImplicitMeta', rule: 'ANY Writers' },
    //       Readers: { type: 'ImplicitMeta', rule: 'ANY Readers' },
    //       Writers: { type: 'ImplicitMeta', rule: 'ANY Writers' },
    //     },
    //   },
    //   channels: {
    //     mainchannel: {
    //       chaincodes: ['gotemplate-cc'],
    //       peers: {
    //         org1: [
    //           /* 'peer0.org1.example.com' */
    //         ],
    //         org2: ['peer0.org2.example.com'],
    //       },
    //       policies: {
    //         queryChannelConfig: {
    //           minResponses: 1,
    //           maxTargets: 1,
    //           retryOpts: {
    //             attempts: 5,
    //             initialBackoff: '500ms',
    //             maxBackoff: '5s',
    //             backoffFactor: 2,
    //           },
    //         },
    //       },
    //     },
    //     sidechannel: {
    //       chaincodes: ['goother-cc'],
    //       peers: {
    //         org2: ['peer0.org2.example.com'],
    //         org3: ['peer0.org3.example.com'],
    //       },
    //       policies: {
    //         queryChannelConfig: {
    //           minResponses: 1,
    //           maxTargets: 1,
    //           retryOpts: {
    //             attempts: 5,
    //             initialBackoff: '500ms',
    //             maxBackoff: '5s',
    //             backoffFactor: 2,
    //           },
    //         },
    //       },
    //     },
    //   },
    // } as unknown) as INetwork;

    const networkChannels = Object.entries(launchedNetwork.channels).map(
      ([channelName, channelInfo]) => {
        const chaincodes = channelInfo.chaincodes.map((chaincodeName) => {
          return {
            chaincodeName,
            tarName: launchedNetwork?.chaincodes?.[chaincodeName]?.tarName,
            ccType: launchedNetwork?.chaincodes?.[chaincodeName]?.ccType,
            ccBaseName:
              launchedNetwork?.chaincodes?.[chaincodeName]?.ccBaseName,
            templateDefinition:
              launchedNetwork?.chaincodes?.[chaincodeName]?.templateDef,
            endorsement:
              launchedNetwork?.chaincodes?.[chaincodeName]?.endorsement,
            endorsementGUI:
              launchedNetwork?.chaincodes?.[chaincodeName]?.endorsementGUI,
          };
        });

        const peersArray = Object.entries(channelInfo.peers);
        const peersInChannel = {} as Record<string, string[]>;
        peersArray.forEach(([orgName, peersName]) => {
          const peerIp = peersName.map(
            (peer) => launchedNetwork?.peers[peer]?.host,
          );
          peersInChannel[orgName] = peerIp;
        });

        return { channelName, chaincodes, peers: peersInChannel };
      },
    ) as Channel[];

    const networkOrgs = Object.entries(launchedNetwork.organizations).map(
      ([orgName, organizationInfo], index) => {
        const peers = organizationInfo?.peers?.map((peer, index) => {
          return {
            value: launchedNetwork.peers[peer].host,
            opts: {
              peer: true,
              orderer: Object.values(launchedNetwork.orderers).some(
                (orderer) => orderer.host === launchedNetwork.peers[peer].host,
              ),
            },
            valid: true,
            id: index,
          } as IPeer;
        });

        function isOrdererFromOrg(orderer) {
          const startIndex = orderer.indexOf('.') + 1;
          const endIndex = orderer.indexOf('.', startIndex);
          const name = orderer.substring(startIndex, endIndex);
          return orgName === name;
        }

        const orderers = organizationInfo?.orderers
          ?.filter((orderer) => isOrdererFromOrg(orderer))
          .map((orderer, index) => {
            return {
              value: launchedNetwork.orderers[orderer].host,
              opts: {
                peer: Object.values(launchedNetwork.peers).some(
                  (peer) =>
                    peer.host === launchedNetwork.orderers[orderer].host,
                ),
                orderer: true,
              },
              valid: true,
              id: index,
            } as IPeer;
          });

        const ids = new Set(peers?.map((peer) => peer.value));
        const mergedPeers = [
          ...peers,
          ...orderers.filter((orderer) => !ids.has(orderer.value)),
        ];

        const ccapi = organizationInfo?.ccapi?.map((ccapiInfo) => {
          return {
            host: ccapiInfo.host,
            chaincodeName: ccapiInfo.chaincodeName,
            channelName: ccapiInfo.channelName,
            ...(ccapiInfo?.restPort && { restPort: ccapiInfo?.restPort }),
            ...(ccapiInfo?.tlsPort && { tlsPort: ccapiInfo?.tlsPort }),
            ...(ccapiInfo.goinitus &&
              Object.keys(ccapiInfo.goinitus) && {
                goinitus: { ...ccapiInfo.goinitus },
              }),
          };
        });

        return {
          orgName,
          orgDomainName: organizationInfo?.domain,
          peers: mergedPeers,
          ccapi,
          caIP: organizationInfo?.ca?.host,
          user: '',
          passwd: '',
          confirmPasswd: '',
          id: index,
        };
      },
    );

    setNetworkName(newNetworkName);
    setChannels(networkChannels);
    setOrgs((networkOrgs as unknown) as IOrg[]);
  };

  const launchNewNetwork = () => {
    launchMoreLikeNetwork(selectedNetwork as INetwork, newDisplayName);
    dispatch(closeLaunchMoreLikeThisDialog());
    setNewDisplayName('');
    history.push('/network/deploy');
  };

  const closeModal = () => {
    setNewDisplayName('');
    dispatch(closeLaunchMoreLikeThisDialog());
  };

  const isDisplayNameInvalid = useCallback(
    () =>
      newDisplayName !== '' &&
      (networks?.some(
        (net) =>
          (net.name === newDisplayName || net.displayName === newDisplayName) &&
          net.name !== networkName,
      ) as boolean),
    [networks, networkName, newDisplayName],
  );

  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="xs" onClose={closeModal}>
        <DialogTitle style={{ backgroundColor: 'var(--backgroundSuccess)' }}>
          <Trans>sidebar.launchMoreLikeThis.title</Trans>
        </DialogTitle>

        <DialogContent>
          <DialogContentText style={{ textAlign: 'justify' }}>
            <Trans>sidebar.launchMoreLikeThis.explanation</Trans>
            <br />
            <br />
            <Trans>sidebar.launchMoreLikeThis.withDisplayName</Trans>
            <b> {displayName}</b>?
          </DialogContentText>

          <TextField
            fullWidth
            autoFocus
            margin="dense"
            value={newDisplayName}
            error={isDisplayNameInvalid()}
            label={t('common.forms.networkName')}
            helperText={
              isDisplayNameInvalid()
                ? t('sidebar.launchMoreLikeThis.invalidNameMsg')
                : ''
            }
            onChange={(event) => setNewDisplayName(event.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" onClick={closeModal}>
            <Trans>button.cancel</Trans>
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={launchNewNetwork}
            disabled={newDisplayName === '' || isDisplayNameInvalid()}
          >
            <Trans>button.add</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LaunchMoreLikeThisModal;
