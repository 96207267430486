import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { networkApi } from '../../Common/axios';
import { useNetworks } from '../Networks';

export type OPHistoryContextType = {
  operationsHistory: OPHistory[];
  currentHistoryView: CurrentHistoryView;
  setCurrentHistoryView: React.Dispatch<
    React.SetStateAction<CurrentHistoryView>
  >;
  getHistory: (type?: HistoryType) => void;
  historyIsInDatabase: (history: OPHistory) => boolean;
};

export const OPHistoryContextDefaultValues: OPHistoryContextType = {
  operationsHistory: [],
  currentHistoryView: 'generalView',
  setCurrentHistoryView: () => 'generalView',
  getHistory: () => ({}),
  historyIsInDatabase: () => true,
};

export const OPHistoryContext = createContext<OPHistoryContextType>(
  OPHistoryContextDefaultValues,
);

export const OPHistoryProvider: React.FC = ({ children }) => {
  const [operationsHistory, setOperationsHistory] = useState<OPHistory[]>([]);
  const [
    currentHistoryView,
    setCurrentHistoryView,
  ] = useState<CurrentHistoryView>('generalView');

  const { selectedNetwork } = useNetworks();

  const getHistory = useCallback(
    async (type: HistoryType = 'all') => {
      try {
        const response = await networkApi.get<
          (OPHistory & { message: string })[]
        >(type === 'specificNetwork' ? '/getHistory' : '/getAllHistory', {
          params: { networkName: selectedNetwork?.networkName },
        });

        console.log('HISTORY ', response?.data);

        const decodedData = response.data.map((op) => ({
          ...op,
          message: JSON.parse(decodeURIComponent(atob(op.message))),
        }));

        console.log('DECODED ', decodedData);

        setOperationsHistory(decodedData);
        return decodedData;
      } catch (error) {
        console.log(error);
        return [] as OPHistory[];
      }
    },
    [selectedNetwork],
  );

  useEffect(() => {
    if (selectedNetwork) getHistory('specificNetwork');
  }, [getHistory, selectedNetwork]);

  const historyIsInDatabase = (history: OPHistory) => {
    if (history.operation === 'startnetwork' && !history?.success) return false;

    return true;
  };
  const values = useMemo(
    () => ({
      operationsHistory,
      currentHistoryView,
      setCurrentHistoryView,
      getHistory,
      historyIsInDatabase,
    }),
    [currentHistoryView, getHistory, operationsHistory],
  );

  return (
    <OPHistoryContext.Provider value={values}>
      {children}
    </OPHistoryContext.Provider>
  );
};

export function useOPHistory() {
  const context = useContext(OPHistoryContext);

  if (!context) {
    throw new Error('useOPHistory must be used within a OPHistoryProvider');
  }

  return context;
}
