import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  List,
  Icon,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Button,
  Collapse,
} from '@material-ui/core';
import { Close, ExpandLess, ExpandMore } from '@material-ui/icons';

import { IAsset, IAssetProp } from '../../../../../../store/TemplateCC';
import { changeMountedComponents } from '../../../../../../store/InitialTour';

import Tooltip from '../../../../../../AppComponents/Tooltip';
import { StoreState } from '../../../../../../store/types';

import PropFragment from './PropFragment';
import { FieldsTitle, AssetFormContainer, AssetFormInfo } from './styles';
import { assetAndPropTagPatt } from '../../../../../../utils/regexPatterns';

const AssetFragment: React.FC<{
  asset: IAsset;
  remove: () => void;
  edit: (asset: IAsset) => void;
}> = ({ asset, edit, remove }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const { mustOpenAssetCollapse } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const newTemplateAssets: IAsset[] = useSelector(
    (state: StoreState) => state.templateCCState.newTemplate?.assets,
  );

  const [open, setOpen] = useState(newTemplateAssets.length === 1);

  const changeLabel = (e: { target: { value: string } }) => {
    edit({ ...asset, label: e.target.value });
  };

  const changeKey = (e: { target: { value: string } }) => {
    edit({ ...asset, tag: e.target.value });
  };

  const changeProp = (prop: IAssetProp, propIdx: number) => {
    if (asset.props) {
      const newProps = [...asset.props];

      newProps[propIdx] = prop;

      edit({ ...asset, props: newProps });
    }
  };

  const addProp = () => {
    if (!asset.props) return;

    const newProps = [...asset.props];

    newProps.push({
      id: uuidv4(),
      label: '',
      tag: '',
      dataType: '',
      readOnly: false,
      isKey: false,
      required: false,
      selectOpened: false,
    });

    edit({ ...asset, props: newProps });
  };

  const deleteProp = (propIdx: number) => {
    if (!asset.props) return;

    const newProps = [...asset.props];

    newProps.splice(propIdx, 1);

    edit({ ...asset, props: newProps });
  };

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('templateAssetFragment'));
    // eslint-disable-next-line
  }, []);

  // open asset collapse on initial tour
  useEffect(() => {
    if (tourMode && !open && mustOpenAssetCollapse) setOpen(true);
    // eslint-disable-next-line
  }, [mustOpenAssetCollapse]);

  return (
    <ListItem divider>
      <AssetFormContainer>
        <AssetFormInfo>
          <div style={{ width: 'fit-content' }} className="asset-label-and-tag">
            <TextField
              label={t('common.words.label')}
              value={asset.label}
              onChange={changeLabel}
              style={{ marginRight: '25px' }}
            />

            <TextField
              label={t('common.words.tag')}
              value={asset.tag}
              error={!assetAndPropTagPatt.test(asset.tag)}
              onChange={changeKey}
            />
          </div>

          {open ? (
            <Button onClick={() => setOpen(false)}>
              <ExpandLess />
            </Button>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <ExpandMore />
            </Button>
          )}

          <Tooltip message={t('common.messages.doubleClickToRemove')}>
            <Button
              onDoubleClick={remove}
              className="remove-asset-from-template"
            >
              <Close />
            </Button>
          </Tooltip>
        </AssetFormInfo>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <FieldsTitle>
            <Trans>common.words.fields</Trans>
          </FieldsTitle>

          <List>
            {asset.props?.map((prop, idx) => (
              <PropFragment
                key={prop.id}
                asset={asset}
                index={idx}
                prop={prop}
                edit={edit}
                changeProp={changeProp}
                deleteProp={deleteProp}
              />
            ))}
          </List>

          <ListItem
            divider
            button
            onClick={() => addProp()}
            className="add-asset-prop-btn"
          >
            <ListItemText>
              <Trans>button.add</Trans>
            </ListItemText>

            <ListItemIcon>
              <Icon color="primary">add</Icon>
            </ListItemIcon>
          </ListItem>
        </Collapse>
      </AssetFormContainer>
    </ListItem>
  );
};

export default AssetFragment;
