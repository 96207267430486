import React from 'react';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import { Trans, useTranslation } from 'react-i18next';
import { Button, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { networkApi } from '../../../Common/axios';
import { useBackupCreateForm } from '../../../Hooks/backupCreate';

import { StoreState } from '../../../store/types';
import { openDialog } from '../../../store/Dialog';
import { addOperation, removeOperation } from '../../../store/AppStatus';

import LoadingScreen from '../../LoadingScreen';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import LoadingContainer from '../../../AppComponents/Notifications';
import cancelWithDialog from '../../../utils/cancelRequestWithModal';

import { Container, PageTitle, Card, LoadContainer } from './styles';

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const BackupCreate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedNetwork } = useSelector(
    (state: StoreState) => state.networkState,
  );

  const { sending, setSending, clearFormData } = useBackupCreateForm();

  const handleSuccessOnBackup = (responseData: any) => {
    try {
      const blob = new Blob([responseData], {
        type: 'application/octet-stream',
      });

      saveAs(blob, `${selectedNetwork.name}-backup.tar.gz`);
    } catch (error) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: t('asset.backup.createBackup.downloadFailure'),
        }),
      );
    }
  };

  const send = async () => {
    try {
      clearFormData();
      setSending(true);

      const data = {
        networkName: selectedNetwork.name,
        // in multichannel created networks the channel name is not necessary but to
        // make it compatible with previous created networks we'll send the first channel
        channelName: selectedNetwork.channels[0],
      };

      const res = await networkApi.post('/backupnetwork', data, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'application/json' },
        cancelToken: new CancelToken((c) => {
          const withDialogCancel = (hasDialog = true) => {
            cancelWithDialog(c, t('title.backup.createBackup'), hasDialog);
          };

          cancelRequest = withDialogCancel;
          dispatch(
            addOperation({
              title: t('title.backup.createBackup'),
              cancel: withDialogCancel,
              name: 'backupnetwork',
              pathname: window.location.pathname,
            }),
          );
        }),
      });

      handleSuccessOnBackup(res.data);
      dispatch(removeOperation('backupnetwork', true));
    } catch {
      dispatch(removeOperation('backupnetwork', false));
    } finally {
      setSending(false);
    }
  };

  if (!selectedNetwork.name) return <SelectNetwork />;

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <PageTitle>
        <Trans>title.backup.createBackup</Trans>
      </PageTitle>

      <Container>
        <Card>
          <TextField
            disabled
            value={selectedNetwork.name}
            label={t('common.forms.networkName')}
            style={{ marginBottom: !sending ? '32px' : '0' }}
          />

          {sending ? (
            <LoadContainer>
              <LoadingScreen
                content={t('asset.backup.createBackup.loadingMessage')}
              />

              <Button
                fullWidth
                color="secondary"
                variant="contained"
                style={{ display: 'block' }}
                onClick={() => cancelRequest()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            </LoadContainer>
          ) : (
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={send}
            >
              <Trans>button.send</Trans>
            </Button>
          )}
        </Card>

        {selectedNetwork.name ? (
          <div style={{ margin: '0 auto' }}>
            <LoadingContainer
              cardInfo={{
                running: sending,
                type: 'backupnetwork',
                title: t('common.words.backup'),
                attributes: { network: selectedNetwork.name },
              }}
            />
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default BackupCreate;
