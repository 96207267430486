import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AppThunk } from '../../store';
import { networkApi } from '../../Common/axios';

export interface IAsset extends Record<string, any> {
  tag: string;
  label: string;
  readers?: string[];
  props?: IAssetProp[];
  privateData?: boolean;
  selectOpened: boolean;
}

export interface IAssetProp extends Record<string, any> {
  id?: string;
  tag: string;
  label: string;
  isKey: boolean;
  dataType: string;
  isCustom?: boolean;
  required: boolean;
  readOnly: boolean;
  selectOpened: boolean;
  writers?: string[];
  writersAllEnabled?: boolean;
}

export interface ITemplate extends Record<string, any> {
  name: string;
  assets: IAsset[];
  description?: string;
  customDataTypes?: string[];
}

export interface ITemplateOption extends Record<string, any> {
  exclusive: boolean;
  chaincodeBase: string;
  usingTemplate: boolean;
  chaincodeFile: File | null;
  selectedTemplate: ITemplate;
}

export interface ICloudChaincodeOption {
  exclusive: boolean;
  usingCloudChaincode: boolean;
  selectedCloudChaincode: string;
}

export interface ITemplateCCState extends Record<string, any> {
  newAsset: IAsset;
  assetList: IAsset[];
  editedAsset: IAsset;
  templateList: ITemplate[];
  selectedAsset: IAsset | null;
  newTemplate: ITemplate | null;
  templateOption: ITemplateOption;
  editedTemplate: ITemplate | null;
  selectedTemplate: ITemplate | null;
  cloudChaincodeOption: ICloudChaincodeOption;
}

const initialState: ITemplateCCState = {
  assetList: [],
  selectedAsset: null,
  newAsset: {
    tag: '',
    label: '',
    props: [],
    selectOpened: false,
  },
  editedAsset: {
    tag: '',
    label: '',
    props: [],
    selectOpened: false,
  },
  templateList: [],
  selectedTemplate: {
    name: '',
    assets: [],
    description: '',
  },
  newTemplate: {
    name: '',
    description: '',
    assets: [],
  },
  editedTemplate: {
    name: '',
    description: '',
    assets: [],
    customDataTypes: [],
  },
  templateOption: {
    exclusive: false,
    chaincodeBase: '',
    chaincodeFile: null,
    usingTemplate: false,
    selectedTemplate: {
      name: '',
      assets: [],
      description: '',
    },
  },
  cloudChaincodeOption: {
    exclusive: false,
    usingCloudChaincode: false,
    selectedCloudChaincode: '',
  },
};

const templateCCSlice = createSlice({
  name: 'templateCC',
  initialState,
  reducers: {
    setSelectedTemplate(state, action: PayloadAction<ITemplate | null>) {
      state.selectedTemplate = action.payload;
    },
    setNewTemplate(state, action: PayloadAction<ITemplate | null>) {
      state.newTemplate = action.payload;
    },
    setEditedTemplate(state, action: PayloadAction<ITemplate | null>) {
      state.editedTemplate = action.payload;
    },
    setTemplateList(state, action: PayloadAction<ITemplate[]>) {
      state.templateList = action.payload;
    },
    changeTemplateOption(
      state,
      action: PayloadAction<{
        type: keyof ITemplateOption | 'spread';
        value: any;
      }>,
    ) {
      const { type, value } = action.payload;

      state.templateOption = {
        ...state.templateOption,
        ...(type === 'spread' && value),
        ...(type !== 'spread' && { [type]: value }),
      };
    },
    changeCloudChaincodeOption(
      state,
      action: PayloadAction<ICloudChaincodeOption>,
    ) {
      state.cloudChaincodeOption = action.payload;
    },
    clearTemplateCCState() {
      return initialState;
    },
  },
});

export const getTemplateList = (): AppThunk => async (dispatch) => {
  try {
    const { data }: { data: ITemplate[] } = await networkApi.get(
      '/getAllTemplateDefs',
    );

    if (!data) return;

    // Add ids to prop identification
    const dataWithPropIds = data.map((item) => {
      const newAssets = item.assets.map((assetItem) => {
        const newProps = assetItem.props?.map((propItem) => ({
          ...propItem,
          id: uuidv4(),
        }));

        return { ...assetItem, props: newProps };
      });

      return { ...item, assets: newAssets };
    });

    dispatch(templateCCSlice.actions.setTemplateList(dataWithPropIds));
  } catch (error) {
    console.log(error);
  }
};

export const createTemplate = (customDataTypes: string[]): AppThunk => async (
  dispatch,
  getState,
) => {
  const { newTemplate } = getState().templateCCState;

  const result = await networkApi.post('/addTemplateDef', {
    ...newTemplate,
    customDataTypes,
  });

  dispatch(getTemplateList());
  dispatch(
    templateCCSlice.actions.setNewTemplate({
      name: '',
      description: '',
      assets: [],
    }),
  );

  return result;
};

export const editTemplate = (customDataTypes: string[]): AppThunk => async (
  dispatch,
  getState,
) => {
  const { editedTemplate } = getState().templateCCState;

  const result = await networkApi.post('/editccdef', {
    ...editedTemplate,
    customDataTypes,
  });

  dispatch(getTemplateList());

  return result;
};

export const deleteTemplate = (ccName: string): AppThunk => async (
  dispatch,
) => {
  const result = await networkApi.delete(`/deleteccdef`, {
    params: { ccName },
  });

  dispatch(getTemplateList());

  return result;
};

export const {
  setNewTemplate,
  setTemplateList,
  setEditedTemplate,
  setSelectedTemplate,
  clearTemplateCCState,
  changeTemplateOption,
  changeCloudChaincodeOption,
} = templateCCSlice.actions;

export default templateCCSlice.reducer;
