import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Icon,
  List,
  Checkbox,
  Typography,
  ListItemText,
  FormControlLabel,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import { Info, Warning } from '@material-ui/icons';
import {
  OptionsCard,
  AtLeastOneMsg,
  SelectContainer,
  SelectedListItem,
  ComponentContainer,
} from '../styles';
import Tooltip from '../../../Tooltip';
import { IAsset } from '../../../../store/TemplateCC';

interface IDefinePermissionsProps {
  asset: IAsset;
  orgs: string[];
  disabled?: boolean;
  placeholder?: string;
  changeOpen: (prev: boolean) => void;
  onChange: (value: string[]) => void;
  changePrivateData: (value: boolean) => void;
  disableUpdatePrivateData: boolean;
  isOrgSelectedInOriginalNetwork?: (org: string) => boolean | undefined;
}

const DefinePermissions: React.FC<IDefinePermissionsProps> = ({
  orgs,
  asset,
  disabled,
  onChange,
  changeOpen,
  placeholder,
  changePrivateData,
  disableUpdatePrivateData,
  isOrgSelectedInOriginalNetwork,
}) => {
  const { readers, privateData, selectOpened } = asset;

  console.log('ASSET', asset);
  const { t } = useTranslation();
  const [label, setLabel] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const [showAtLeastOneOrgMsg, setShowAtLeastOneOrgMsg] = useState(false);
  const [labelMaxLength, setLabelMaxLabel] = useState(
    window.innerWidth >= 1050 ? 60 : 40,
  );

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     selectOpened &&
  //     ref.current &&
  //     !ref.current.contains(event.target as Node)
  //   )
  //     changeOpen(false);
  // };

  const handleScreenResize = () => {
    setLabelMaxLabel(window.innerWidth >= 1200 ? 60 : 40);
  };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // });

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  });

  useEffect(() => {
    if (privateData && (!readers || readers.length <= 0)) {
      setShowAtLeastOneOrgMsg(true);
    }
  }, [privateData, readers]);

  const cutLabel = useCallback(() => {
    if (label.length >= labelMaxLength) {
      return `${label.substring(0, labelMaxLength)}...`;
    }

    return label;
  }, [label, labelMaxLength]);

  const changeLabel = useCallback(() => {
    if (readers === undefined && !privateData) {
      setLabel(placeholder || '');
    } else {
      let readersPart = !privateData ? t('common.words.all') : '';

      if (privateData) {
        (readers || []).forEach((org, index) => {
          readersPart = `${readersPart}${index === 0 ? '' : ', '}${org.replace(
            'MSP',
            '',
          )}`;
        });
      }
      setLabel(readersPart);
    }
  }, [t, readers, placeholder, privateData]);

  useEffect(() => {
    changeLabel();
  }, [changeLabel, readers, privateData]);

  const removeOrAddArray = (array: string[], element: string) => {
    const newArray = [...array];
    const elIndex = newArray.findIndex((itm) => itm === element);
    if (elIndex < 0) return [...newArray, element];

    return newArray.filter((itm) => itm !== element);
  };

  const handleSelection = useCallback(
    (org: string) => {
      if (selectOpened && !disabled) {
        const newReaders = removeOrAddArray(readers || [], `${org}MSP`);

        onChange(newReaders);
        if (newReaders.length <= 0) {
          setShowAtLeastOneOrgMsg(true);

          setTimeout(() => setShowAtLeastOneOrgMsg(false), 10000);
        } else {
          setShowAtLeastOneOrgMsg(false);
        }
      }
    },
    [disabled, onChange, readers, selectOpened],
  );

  const handlePrivateDataChange = useCallback(() => {
    if (!privateData) onChange(orgs.map((org) => `${org}MSP`));
    // it can be [] or null
    else onChange([]);

    changePrivateData(!privateData);
  }, [orgs, onChange, privateData, changePrivateData]);

  const isOrgSelected = useCallback(
    (org: string) => readers?.some((itm) => itm === `${org}MSP`),
    [readers],
  );

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const anchorEl = useRef<HTMLDivElement>(null);

  const handlePopperClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      // setAnchorEl(anchorEl ? null : event.currentTarget);
      changeOpen(!selectOpened);
    }
  };

  const handleClickAway = () => {
    if (selectOpened) changeOpen(false);
  };

  const open = Boolean(anchorEl) && selectOpened;
  const id = open ? 'simple-popper' : undefined;

  console.log('readers', readers);

  return (
    <ComponentContainer ref={ref} disabled={disabled || false}>
      <Tooltip message={label} canShow={label.length >= labelMaxLength}>
        <SelectContainer
          open={selectOpened}
          onClick={handlePopperClick}
          aria-describedby={id}
          ref={anchorEl}
        >
          <Typography>{cutLabel()}</Typography>

          <Icon style={{ cursor: 'pointer' }}>keyboard_arrow_down</Icon>
        </SelectContainer>
      </Tooltip>

      <Popper id={id} open={open} anchorEl={anchorEl?.current}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <OptionsCard>
            <List
              disablePadding
              component="div"
              style={{ width: '100%', minWidth: '500px' }}
              subheader={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    style={{ margin: '0 auto' }}
                    onChange={() => {
                      if (disableUpdatePrivateData) return;
                      handlePrivateDataChange();
                    }}
                    control={
                      <Checkbox
                        checked={privateData}
                        disabled={disableUpdatePrivateData}
                      />
                    }
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <b>
                          <Trans>common.chaincode.privateData</Trans>
                        </b>

                        <Tooltip
                          message={t('common.chaincode.privateDataTooltip')}
                        >
                          <Info
                            style={{
                              marginLeft: '10px',
                              color: 'var(--primary)',
                            }}
                          />
                        </Tooltip>
                      </div>
                    }
                  />

                  {showAtLeastOneOrgMsg ? (
                    <AtLeastOneMsg>
                      <Warning style={{ marginRight: '5px' }} />
                      <Trans>common.chaincode.atLeastOneOrg</Trans>
                    </AtLeastOneMsg>
                  ) : null}
                </div>
              }
            >
              {orgs.map((org: string) => (
                <SelectedListItem
                  key={`asset-readers-${org}`}
                  isDisabled={!privateData as boolean}
                  selected={isOrgSelected(org) as boolean}
                  onClick={() => {
                    if (
                      !disableUpdatePrivateData &&
                      !isOrgSelectedInOriginalNetwork?.(org)
                    )
                      handleSelection(org);
                  }}
                >
                  <Checkbox
                    // this is a workaround for the problem with the checkbox
                    // that was not being checked (I couldn't find the cause)
                    // but for some reason this is working
                    checked={isOrgSelected(org) ? isOrgSelected(org) : false}
                    disabled={
                      disableUpdatePrivateData &&
                      isOrgSelectedInOriginalNetwork?.(org)
                    }
                  />
                  <ListItemText
                    primary={org}
                    style={{ wordBreak: 'break-word' }}
                  />
                </SelectedListItem>
              ))}
            </List>
          </OptionsCard>
        </ClickAwayListener>
      </Popper>
    </ComponentContainer>
  );
};

DefinePermissions.defaultProps = {
  disabled: false,
};

export default DefinePermissions;
