import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearChaincodeNotifications } from '../store/ChaincodeNotifications';

import { ITemplate } from '../store/TemplateCC';
import { ElementType } from '../AppComponents/Endorsement/types';
import { useNetworks } from '../Contexts/Networks';

export interface IData {
  exclusive: boolean;
  chaincodeName: string;
  chaincodeVersion?: string;
  chaincodeFile: null | { key: File; keyName: string };
}

// DELETE THIS
export interface PeerDelete extends Record<string, any> {
  name: string;
  ccApi: boolean;
  checked: boolean;
  publicIP: string;
}

// DELETE THIS
export interface Org extends Record<string, any> {
  peers: PeerDelete[];
  orgName: string;
  restHost: string;
}

export interface Peer {
  [orgName: string]: string[];
}

interface UpgradeChaincodeContextData {
  ccType: string;
  setCcType: Dispatch<SetStateAction<string>>;
  users: string[];
  setUsers: Dispatch<SetStateAction<string[]>>;
  orgs: Org[];
  setOrgs: Dispatch<SetStateAction<Org[]>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  sending: boolean;
  setSending: Dispatch<SetStateAction<boolean>>;
  passwords: string[];
  setPasswords: Dispatch<SetStateAction<string[]>>;
  endorsement: IEndorsement | {} | null;
  setEndorsement: Dispatch<SetStateAction<IEndorsement | {} | null>>;
  chaincodeList: { name: string; version: string }[];
  setChaincodeList: Dispatch<
    SetStateAction<{ name: string; version: string }[]>
  >;
  hasRestSelected: boolean;
  setHasRestSelected: Dispatch<SetStateAction<boolean>>;
  CCNotification: string;
  setCCNotification: Dispatch<SetStateAction<string>>;
  netDefCCName: string;
  setNetDefCCName: Dispatch<SetStateAction<string>>;
  netDefTemplate: IGlobalTemplate | undefined;
  setNetDefTemplate: Dispatch<SetStateAction<IGlobalTemplate | undefined>>;
  endorsementGUI: ElementType[];
  setEndorsementGUI: Dispatch<SetStateAction<ElementType[]>>;
  data: IData;
  setData: Dispatch<SetStateAction<IData>>;
  customTimeoutModalOpened: boolean;
  setCustomTimeoutModalOpened: Dispatch<SetStateAction<boolean>>;
  grpcTimeout: number;
  setGrpcTimeout: Dispatch<SetStateAction<number>>;
  clearFormData(): void;

  ///
  selectedChannel: string;
  setSelectedChannel: React.Dispatch<React.SetStateAction<string>>;
  selectedChaincode: string;
  setSelectedChaincode: React.Dispatch<React.SetStateAction<string>>;
  version: string;
  setVersion: React.Dispatch<React.SetStateAction<string>>;
  peers: Peer;
  setPeers: React.Dispatch<React.SetStateAction<Peer>>;
  ccFile: File | null;
  setCcFile: React.Dispatch<React.SetStateAction<File | null>>;
  selectedNetwork: INetwork | null;
  setSelectedNetwork: React.Dispatch<React.SetStateAction<INetwork | null>>;
}

const UpgradeChaincodeContext = createContext<UpgradeChaincodeContextData>(
  {} as UpgradeChaincodeContextData,
);

const UpgradeChaincodeProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [ccType, setCcType] = useState('');
  const [users, setUsers] = useState(['']);
  const [orgs, setOrgs] = useState<Org[]>([]);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [passwords, setPasswords] = useState(['']);
  const [grpcTimeout, setGrpcTimeout] = useState(0);
  const [netDefCCName, setNetDefCCName] = useState('');
  const [CCNotification, setCCNotification] = useState('');
  const [hasRestSelected, setHasRestSelected] = useState(false);
  const [customTimeoutModalOpened, setCustomTimeoutModalOpened] = useState(
    false,
  );
  const [endorsement, setEndorsement] = useState<IEndorsement | {} | null>(
    null,
  );
  const [chaincodeList, setChaincodeList] = useState<
    { name: string; version: string }[]
  >([]);
  const [netDefTemplate, setNetDefTemplate] = useState<
    IGlobalTemplate | undefined
  >(undefined);
  const [endorsementGUI, setEndorsementGUI] = useState<ElementType[]>(
    {} as ElementType[],
  );
  const [data, setData] = useState<IData>({
    exclusive: false,
    chaincodeName: '',
    chaincodeFile: null,
    chaincodeVersion: '',
  });

  /// /////////
  const [version, setVersion] = useState('');
  const [peers, setPeers] = useState<Peer>({});
  const [selectedChaincode, setSelectedChaincode] = useState('');
  const [selectedChannel, setSelectedChannel] = useState('');
  const [ccFile, setCcFile] = useState<File | null>(null);
  const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);

  // useEffect(() => {
  //   if (originalSelectNetwork) {
  //     setSelectedNetwork(originalSelectNetwork);
  //   }
  // }, [originalSelectNetwork]);

  const clearFormData = useCallback(() => {
    setCcType('');
    setUsers(['']);
    setOrgs([]);
    setLoading(false);
    setSending(false);
    setPasswords(['']);
    setEndorsement(null);
    setChaincodeList([]);
    setHasRestSelected(false);
    setCCNotification('');
    setNetDefCCName('');
    setNetDefTemplate(undefined);
    setGrpcTimeout(0);
    setCustomTimeoutModalOpened(false);
    setEndorsementGUI({} as ElementType[]);
    setData({
      exclusive: false,
      chaincodeName: '',
      chaincodeVersion: '',
      chaincodeFile: null,
    });

    dispatch(clearChaincodeNotifications());
  }, [dispatch]);

  return (
    <UpgradeChaincodeContext.Provider
      value={{
        ccType,
        setCcType,
        users,
        setUsers,
        orgs,
        setOrgs,
        loading,
        setLoading,
        sending,
        setSending,
        passwords,
        setPasswords,
        endorsement,
        setEndorsement,
        chaincodeList,
        setChaincodeList,
        netDefCCName,
        setNetDefCCName,
        hasRestSelected,
        setHasRestSelected,
        CCNotification,
        setCCNotification,
        netDefTemplate,
        setNetDefTemplate,
        endorsementGUI,
        setEndorsementGUI,
        data,
        setData,
        customTimeoutModalOpened,
        setCustomTimeoutModalOpened,
        grpcTimeout,
        setGrpcTimeout,
        clearFormData,
        ///
        selectedChannel,
        setSelectedChannel,
        selectedChaincode,
        setSelectedChaincode,
        version,
        setVersion,
        peers,
        setPeers,
        ccFile,
        setCcFile,
        selectedNetwork,
        setSelectedNetwork,
      }}
    >
      {children}
    </UpgradeChaincodeContext.Provider>
  );
};

function useUpgradeChaincodeForm(): UpgradeChaincodeContextData {
  const context = useContext(UpgradeChaincodeContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { UpgradeChaincodeProvider, useUpgradeChaincodeForm };
