import { AppBar, Box, Button, Tooltip, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { StyledTab, StyledTabs } from '../../AppComponents/StyledTabs';
import SelectNetwork from '../../AppComponents/SelectNetwork';
import { useAddChannel } from '../../Contexts/AddChannel';
import { useNetworks } from '../../Contexts/Networks';
import * as S from './styles';
import { ChannelForm } from './Components/ChannelForm';
import { networkApi } from '../../Common/axios';
import { addOperation, removeOperation } from '../../store/AppStatus';
import { useOperation } from '../../Contexts/Operation';
import cancelWithDialog from '../../utils/cancelRequestWithModal';
import { openDialog } from '../../store/Dialog';
import { OperationStateCard } from '../../AppComponents/OperationStateCard';

const AddChannel: React.FC = ({ children }) => {
  const { selectedNetwork, fetchNetworkState, getAllNetworks } = useNetworks();
  const { channels, setChannels, started, setStarted } = useAddChannel();

  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const { addChannelState } = useOperation();

  //   const channelsInNetwork = useMemo(() => {
  //     if (!selectedNetwork?.channels) return [];
  //     return Object.keys(selectedNetwork.channels)?.map((channel) => {
  //       return channel;
  //     });
  //   }, [selectedNetwork]);

  const removeChannel = useCallback(
    (channelName: string) => {
      if (channels.length === 1) return;

      setChannels(
        channels.filter((channel) => channel.channelName !== channelName),
      );
    },
    [channels, setChannels],
  );

  const renderTabLabel = useCallback(
    (channelName: string) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{channelName || 'unnamedChannel'}</Typography>
        {channels.length > 1 && (
          <Tooltip
            disableHoverListener={channels.length === 1}
            title="Delete tooltip"
          >
            <Clear
              onDoubleClick={() => {
                if (!started) {
                  removeChannel(channelName);
                  if (value >= 1) setValue(value - 1);
                }
              }}
              style={{ fontSize: '15px', opacity: '0.5', marginLeft: '15px' }}
            />
          </Tooltip>
        )}
      </Box>
    ),
    [channels, removeChannel, started, value],
  );
  const addChannel = useCallback(() => {
    const name = '';

    channels.push({
      channelName: name,
      peers: {},
      chaincodes: [],
    });

    setChannels(channels);

    setValue(channels.length - 1);
  }, [channels, setChannels]);

  const dispatch = useDispatch();
  const { CancelToken } = Axios;
  let cancelRequest: (hasDialog?: boolean) => void;

  const onSubmit = () => {
    if (!selectedNetwork) return;

    setStarted(true);
    try {
      const formData = new FormData();
      const payload = {};
      channels.forEach((mappedChannel) => {
        const obj = {
          [mappedChannel?.channelName]: {
            peers: mappedChannel?.peers,
            chaincodes: mappedChannel?.chaincodes,
          },
        };
        Object.assign(payload, obj);
      });

      console.log('form', payload);
      formData.append('payload', JSON.stringify(payload));
      formData.append('networkName', selectedNetwork?.networkName);

      networkApi
        .post('/addChannel', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, 'Add Channel', hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: 'Add Channel',
                pathname: window.location.pathname,
                name: 'addchannel',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(async () => {
          await getAllNetworks();

          await fetchNetworkState();

          dispatch(
            openDialog({
              title: 'Success',
              type: 'success',
              content: 'Channel added successfully',
            }),
          );
          dispatch(removeOperation('addchannel', true));
        })
        .catch((error) => {
          console.log('addchannel error: ', error);
          dispatch(removeOperation('addchannel', false));
          dispatch(
            openDialog({
              title: t('common.words.error'),
              type: 'error',
              content: error,
            }),
          );
        })
        .finally(() => {
          setStarted(false);
        });
    } catch (error) {
      console.log('error: ', error);
      dispatch(removeOperation('addchannel', false));
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error,
        }),
      );
    }
  };

  if (!selectedNetwork?.networkName)
    return (
      <SelectNetwork
        isNetSelected={!!(selectedNetwork?.networkName as string)}
      />
    );

  return (
    <S.PageContainer>
      <Box
        display="flex"
        flexDirection="column"
        width="1024px"
        margin="0 auto"
        marginTop="2rem"
      >
        <AppBar
          style={{ backgroundColor: 'var(--primary)', marginBottom: '1rem' }}
          position="static"
        >
          <StyledTabs
            value={value}
            scrollButtons="on"
            variant="scrollable"
            onChange={(_: any, v: any) => setValue(v)}
          >
            {Boolean(channels?.length) &&
              channels.map(({ channelName }, index) => (
                <StyledTab key={index} label={renderTabLabel(channelName)} />
              ))}
          </StyledTabs>
          <Button
            variant="outlined"
            onClick={addChannel}
            disabled={started}
            style={{ color: 'var(--white)' }}
          >
            <Trans>button.addChannel</Trans>
          </Button>
        </AppBar>
        {Boolean(channels?.length) &&
          channels.map((ch, chIndex) => {
            return (
              <div
                key={chIndex}
                style={{
                  width: '100%',
                  display: value === channels.indexOf(ch) ? 'block' : 'none',
                }}
              >
                <ChannelForm channelIndex={chIndex} />
              </div>
            );
          })}
      </Box>
      <Box display="flex" width="100%" marginTop="2rem" flexDirection="column">
        {started && (
          <Button
            color="secondary"
            variant="contained"
            style={{ width: '45%', margin: '0 auto', pointerEvents: 'all' }}
            onClick={() => cancelRequest()}
          >
            <Trans>button.cancel</Trans>
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          className="deploy-start-btn"
          disabled={started || !selectedNetwork}
          style={{ width: '45%', margin: '1rem auto', pointerEvents: 'all' }}
          onClick={onSubmit}
        >
          <Trans>button.start</Trans>
        </Button>
      </Box>
      <Box marginTop="2rem">
        <OperationStateCard taskOperation={addChannelState} />
      </Box>
    </S.PageContainer>
  );
};

export default AddChannel;
