import React, { useCallback } from 'react';
import { ILoadingContainerCardInfo } from '../../types';

import addPeer from '../../operations/addPeer';
import addCCAPI from '../../operations/addCCAPI';
import addOrderer from '../../operations/addOrderer';
import updatePeer from '../../operations/updatePeer';
import removePeer from '../../operations/removePeer';
import joinChannel from '../../operations/joinChannel';
import addWebClient from '../../operations/addWebClient';
import startNetwork from '../../operations/startNetwork';
import upgradeCCAPI from '../../operations/upgradeCCAPI';
import backupNetwork from '../../operations/backupNetwork';
import setupMachines from '../../operations/setupMachines';
import createChannel from '../../operations/createChannel';
import restoreNetwork from '../../operations/restoreNetwork';
import addOrganization from '../../operations/addOrganization';
import upgradeChaincode from '../../operations/upgradeChaincode';

// Component responsible for render notifications cards by the operation
const RenderNotification: React.FC<{ cardInfo: ILoadingContainerCardInfo }> = ({
  cardInfo,
}) => {
  const renderCards = useCallback((): JSX.Element | null => {
    switch (cardInfo.type) {
      case 'startnetwork':
        return startNetwork();
      case 'addorg':
        return addOrganization(cardInfo);
      case 'addpeer':
        return addPeer(cardInfo);
      case 'addorderer':
        return addOrderer(cardInfo);
      case 'addwebclient':
        return addWebClient(cardInfo);
      case 'addccapi':
        return addCCAPI(cardInfo);
      case 'upgradechaincode':
        return upgradeChaincode(cardInfo);
      case 'upgradeccapi':
        return upgradeCCAPI(cardInfo);
      case 'backupnetwork':
        return backupNetwork(cardInfo);
      case 'restorenetwork':
        return restoreNetwork(cardInfo);
      case 'setup':
        return setupMachines(cardInfo);
      case 'updatepeer':
        return updatePeer(cardInfo);
      case 'removepeer':
        return removePeer(cardInfo);
      case 'createchannel':
        return createChannel(cardInfo);
      case 'joinchannel':
        return joinChannel(cardInfo);
      default:
        return null;
    }
  }, [cardInfo]);

  return renderCards();
};

export default RenderNotification;
