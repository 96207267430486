import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Websocket from 'react-websocket';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { uuid } from '../Common/axios';

import { Operation } from '../store/AppStatus';
import {
  changeDeployNotificationsGlobal,
  changeDeployNotificationsPeers,
  clearDeployNotifications,
} from '../store/DeployNotifications';
import { changeSetupNotifications } from '../store/SetupMachinesNotifications';
import { changeChaincodeNotification } from '../store/ChaincodeNotifications';
import { changeUpgradeCCApi } from '../store/UpgradeCCApiNotifications';
import {
  changeBackupGlobal,
  changeBackupPeers,
} from '../store/BackupNotifications';
import { changeAddOrgNotification } from '../store/AddOrgNotifications';
import { changeAddPeerNotification } from '../store/AddPeerNotifications';
import { changeAddCCAPINotification } from '../store/AddCCAPINotifications';
import { changeRestoreNotification } from '../store/BackupRestoreNotifications';
import { changeRemovePeerNotification } from '../store/RemovePeerNotifications';
import { changeAddOrdererNotification } from '../store/AddOrdererNotifications';
import { changeUpdatePeerNotification } from '../store/UpdatePeerNotifications';
import { changeJoinChannelNotification } from '../store/JoinChannelNotifications';
import { changeAddWebClientNotification } from '../store/AddWebClientNotifications';
import { changeCreateChannelNotification } from '../store/CreateChannelNotifications';
import {
  StoreState,
  CommonNotificationObject,
  WithOrgNotificationObject,
} from '../store/types';

export const SocketHandler = () => {
  const { userName } = useSelector((state: StoreState) => state.userState);
  const state = useSelector((state: StoreState) => state);

  const baseURL = (): string => {
    const URL = process.env.REACT_APP_API_URL || '';
    const sslEnable = process.env.REACT_APP_SSL_ENABLE === 'TRUE';
    // if not set then call api on same url as root page
    let requestURL = `ws${sslEnable ? 's' : ''}://`;

    if (URL === '') {
      requestURL += window.location.hostname;
    } else {
      requestURL += URL;
    }

    requestURL += `/report?uuid=${uuid}`;
    return requestURL;
  };

  const url = baseURL();
  const [connected, setConnected] = useState(false);
  const {
    sendMessage,
    lastJsonMessage,
    lastMessage,
    readyState,
    sendJsonMessage,
  } = useWebSocket(url, {
    onOpen: () => sendMessage('ping'),
    reconnectInterval: 3000,
    retryOnError: true,
    shouldReconnect: (closeEvent) => {
      console.log('reconnect', lastJsonMessage);
      setConnected(true);

      return lastJsonMessage?.data !== 'pong';
    },
  });
//  console.log('url', url, uuid);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    console.log('connectionStatus', connectionStatus);
  }, [connectionStatus]);

  useEffect(() => {
    console.log('lastMessage', lastJsonMessage, lastMessage);
  }, [lastJsonMessage, lastMessage]);

  return { lastJsonMessage, sendJsonMessage, sendMessage };
};
