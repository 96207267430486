import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Divider } from '@material-ui/core';

import Attribute from '../Attribute';
import Loader from '../Loader';

import { useDeployForm } from '../../../../Hooks/deploy';
import { IDeployGlobalCardInfo } from '../../types';

import { Container, TitleLoader } from './styles';

const DeployGlobalCard: React.FC<{
  cardInfo: IDeployGlobalCardInfo;
}> = ({ cardInfo }) => {
  const {
    running,
    chaincode,
    channelName,
    networkName,
    loadingInfo,
    notificationAttrs,
    changeNotification,
  } = cardInfo;

  const { t } = useTranslation();

  const {
    usingTemplate,
    template,
    usingCloudChaincode,
    cloudChaincode,
  } = useDeployForm();

  const canShow = channelName && networkName;

  if (!canShow) return null;
  return (
    <Container>
      <div style={{ padding: '0 25px' }}>
        <TitleLoader>
          <Typography
            variant="overline"
            style={{ color: 'var(--white)', fontSize: '25px' }}
          >
            <Trans>common.words.global</Trans>
          </Typography>
          <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
            <Loader
              type="main"
              list={loadingInfo}
              running={running}
              notificationAttrs={notificationAttrs}
              changeNotification={changeNotification}
            />
          </div>
        </TitleLoader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Attribute
            name={t('common.words.channel')}
            value={channelName}
            size={13}
          />

          <Attribute
            name={t('common.words.chaincode')}
            value={chaincode?.chaincodeName}
            size={13}
          />

          {!usingTemplate && !usingCloudChaincode ? (
            chaincode?.chaincodeFile ? (
              <Attribute
                name={t('common.words.file')}
                value={chaincode?.chaincodeFile.name}
                size={13}
              />
            ) : (
              <Typography style={{ color: 'var(--white)' }}>
                <Trans>common.messages.insertCCFile</Trans>
              </Typography>
            )
          ) : usingTemplate && !template ? (
            <Typography style={{ color: 'var(--white)' }}>
              <Trans>common.chaincode.selectTemplate</Trans>
            </Typography>
          ) : !usingCloudChaincode ? (
            <Attribute
              name={t('common.words.template')}
              value={template?.name}
              size={13}
            />
          ) : !cloudChaincode ? (
            <Typography style={{ color: 'var(--white)' }}>
              <Trans>common.chaincode.selectTemplate</Trans>
            </Typography>
          ) : (
            <Attribute
              name={t('common.words.template')}
              value={cloudChaincode}
              size={13}
            />
          )}
        </div>
        <Divider />
        <Loader
          list={loadingInfo}
          running={running}
          notificationAttrs={notificationAttrs}
          changeNotification={changeNotification}
        />
      </div>
    </Container>
  );
};

export default DeployGlobalCard;
