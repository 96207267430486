import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

import { canShowCards } from '../utils';
import { DefOrgsStepContainer } from '../styles';

import AdvancedTab from './Contents/Advanced';
import { useDeployForm } from '../../../Hooks/deploy';
import OrganizationsTab from './Contents/Organizations';

import { StoreState } from '../../../store/types';
import { changeMountedComponents } from '../../../store/InitialTour';
import { NetworkNameInput } from '../../../AppComponents/NetworkNameInput';

const DefineOrganizations: React.FC<{
  handleStepChange: (type?: string) => void;
}> = ({ handleStepChange }) => {
  const { orgs, clearFormData } = useDeployForm();
  const [advanced, setAdvanced] = useState(false);

  const dispatch = useDispatch();
  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);
  const { chapterToChange } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('deployFirstStep'));
    // eslint-disable-next-line
  }, [chapterToChange]);

  return advanced ? (
    <div style={{ width: '1024px', margin: '10px 20px' }}>
      <Button onClick={() => setAdvanced(!advanced)}>
        <Trans>common.words.basic</Trans>
      </Button>

      <Typography style={{ fontSize: '25px' }} variant="overline">
        JSON
      </Typography>

      <AdvancedTab />
    </div>
  ) : (
    <DefOrgsStepContainer hasCards={canShowCards(orgs)}>
      {/* When we decide to use the advanced again this should be reviewed */}
      {/* <Button onClick={() => setAdvanced(!advanced)}>
        <Trans>common.words.advanced</Trans>
      </Button> */}
      <div>
        <Box display="flex" flexDirection="column" marginBottom={5}>
          <Typography variant="overline" style={{ fontSize: '25px' }}>
            <Trans>common.forms.networkName</Trans>
          </Typography>

          <Box display="flex" justifyContent="space-between">
            <NetworkNameInput />
            <Button
              onClick={() => clearFormData()}
              variant="outlined"
              color="primary"
            >
              <Trans>common.forms.clearForm</Trans>
            </Button>
          </Box>
        </Box>
        <Typography style={{ fontSize: '25px' }} variant="overline">
          <Trans>common.words.organizations</Trans>
        </Typography>

        <OrganizationsTab />
      </div>

      <Button
        className="deploy-next-step-btn"
        fullWidth
        color="primary"
        variant="contained"
        style={{ margin: '50px auto' }}
        onClick={() => handleStepChange()}
      >
        <Trans>button.nextStep</Trans>
      </Button>
    </DefOrgsStepContainer>
  );
};

export default DefineOrganizations;
