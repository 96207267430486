import { store } from '../../store';
import { networkApi } from '../../Common/axios';

import {
  changeNetworkStatus,
  changeSelectedNetInfo,
  NetworkStatus,
} from '../../store/Network';

export const getNetwork = (networkName: string): Promise<any> => {
  try {
    const config = { params: { networkName } };
    const promises: Promise<any>[] = [];

    promises.push(
      networkApi
        .get(`/networkstate`, config)
        .then((res) => {
          store.dispatch(
            changeSelectedNetInfo({ json: JSON.stringify(res.data) }),
          );
        })
        .catch((error) => console.log(error)),
    );

    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then((values) => resolve(values))
        .catch((error) => {
          store.dispatch(changeSelectedNetInfo({ status: 'offline' }));
          reject(error);
        });
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};
