import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Paper, ListItemText } from '@material-ui/core';
import {
  Label,
  CloseIcon,
  TemplateList,
  LabelContainer,
  OptionsContainer,
  SelectedListItem,
  ComponentContainer,
} from './styles';

import { StoreState } from '../../../../../../store/types';
import { ITemplate } from '../../../../../../store/TemplateCC';

interface ITemplateCopySelectProps {
  copyTemplate(template: ITemplate): void;
}

const TemplateCopySelect: React.FC<ITemplateCopySelectProps> = ({
  copyTemplate,
}) => {
  const {
    newTemplate,
    templateList,
  }: { newTemplate: ITemplate; templateList: ITemplate[] } = useSelector(
    (state: StoreState) => state.templateCCState,
  );

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleClickOutside = (event: MouseEvent) => {
    if (open && ref.current && !ref.current.contains(event.target as Node))
      setOpen(false);
  };

  const handleSelection = (option: ITemplate) => {
    setSelectedOption(option.name);
    copyTemplate({
      ...newTemplate,
      assets: option.assets,
    });
    setOpen(false);
  };

  const getListMaxHeight = useCallback(
    () => `${templateList.length > 18 && ((templateList.length + 1) * 48) / 2}`,
    [templateList.length],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (
      selectedOption &&
      !templateList.some((item) => item.name === selectedOption)
    ) {
      setSelectedOption('');
      copyTemplate({ name: '', description: '', assets: [] });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateList]);

  return (
    <ComponentContainer ref={ref} twocolumns={`${templateList.length > 9}`}>
      <LabelContainer>
        <Label onClick={() => setOpen((prev) => !prev)}>
          {selectedOption ? (
            <Trans values={{ templateName: selectedOption }}>
              asset.chaincodes.templates.manageTemplates.createdFrom
            </Trans>
          ) : (
            <Trans>
              asset.chaincodes.templates.manageTemplates.createTemplateFromAnother
            </Trans>
          )}
        </Label>

        {selectedOption ? (
          <CloseIcon
            onClick={() => handleSelection({ name: '', assets: [] })}
          />
        ) : null}
      </LabelContainer>

      {open ? (
        <OptionsContainer>
          <Paper elevation={4}>
            <TemplateList maxheight={getListMaxHeight()}>
              {templateList.map((option) => (
                <SelectedListItem
                  key={option.name}
                  twocolumns={`${templateList.length > 9}`}
                  selected={selectedOption === option.name}
                  onClick={() => handleSelection(option)}
                >
                  <ListItemText primary={option.name} />
                </SelectedListItem>
              ))}
            </TemplateList>
          </Paper>
        </OptionsContainer>
      ) : null}
    </ComponentContainer>
  );
};

export default TemplateCopySelect;
