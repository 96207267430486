import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import * as S from '../styles';
import { authAPI } from '../../../Common/axios';

interface FormValues {
  previousPassword: string;
  proposedPassword: string;
  confirmPassword: string;
}
export const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const [showPreviousPassword, setShowPreviousPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPreviousPassword = () => {
    setShowPreviousPassword(!showPreviousPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = useCallback(async (data: FormValues) => {
    try {
      await authAPI.post('/changepw', data);
      toast.success('Password changed successfully');
    } catch (err) {
      const regex = /:(.*?)\n/;
      const error = err as AxiosError;
      toast.error(`${error.response?.data.match(regex)?.[1]?.trim()}`);
    }
  }, []);

  return (
    <S.ProfileForm
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: '385px' }}
    >
      <Typography style={{ fontSize: '18px' }} variant="overline">
        Change Password
      </Typography>

      <FormControl
        variant="outlined"
        error={!!errors.previousPassword}
        fullWidth
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-prev">
          Current Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-prev"
          {...register('previousPassword', {
            required: 'Password is required',
          })}
          error={!!errors.previousPassword}
          type={showPreviousPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPreviousPassword}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPreviousPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={130}
        />
        <FormHelperText error>
          {errors.previousPassword?.message}
        </FormHelperText>
      </FormControl>

      <FormControl
        variant="outlined"
        fullWidth
        error={!!errors.proposedPassword}
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password">
          New Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          {...register('proposedPassword', {
            required: 'Password is required',
            // password validation with capital letter, number and special character
            pattern: {
              value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
              message:
                'Password must have at least one capital letter, number and special character',
            },
            validate: (value) =>
              value !== getValues('confirmPassword')
                ? 'Password does not match'
                : true,
            minLength: {
              value: 6,
              message: 'Password must have at least 6 characters',
            },
          })}
          error={!!errors.proposedPassword}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={108}
        />
        <FormHelperText error>
          {errors.proposedPassword?.message}
        </FormHelperText>
      </FormControl>

      <FormControl
        variant="outlined"
        fullWidth
        error={!!errors.confirmPassword}
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-confirm">
          Confirm New Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-confirm"
          {...register('confirmPassword', {
            required: 'Password is required',
            deps: ['proposedPassword'],
            minLength: {
              value: 6,
              message: 'Password must have at least 6 characters',
            },
          })}
          error={!!errors.confirmPassword}
          type={showConfirmPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={170}
        />
        <FormHelperText error>{errors.confirmPassword?.message}</FormHelperText>
      </FormControl>

      <Button
        size="large"
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        {isSubmitting ? (
          <CircularProgress size={26} color="secondary" />
        ) : (
          'Update Password'
        )}
      </Button>
    </S.ProfileForm>
  );
};
