import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearSetupMachinesNotifications } from '../store/SetupMachinesNotifications';

interface SetupMachinesContextData {
  userName: string;
  setUserName: Dispatch<SetStateAction<string>>;
  sending: boolean;
  setSending: Dispatch<SetStateAction<boolean>>;
  machines: string[];
  setMachines: Dispatch<SetStateAction<string[]>>;
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  cleanFormData(): void;
}

const SetupMachinesContext = createContext<SetupMachinesContextData>(
  {} as SetupMachinesContextData,
);

const SetupMachinesProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [sending, setSending] = useState(false);
  const [machines, setMachines] = useState(['']);
  const [file, setFile] = useState<File | null>(null);

  const cleanFormData = useCallback(() => {
    setUserName('');
    setSending(false);
    setMachines(['']);
    setFile(null);

    dispatch(clearSetupMachinesNotifications());
  }, [dispatch]);

  return (
    <SetupMachinesContext.Provider
      value={{
        userName,
        setUserName,
        sending,
        setSending,
        machines,
        setMachines,
        file,
        setFile,
        cleanFormData,
      }}
    >
      {children}
    </SetupMachinesContext.Provider>
  );
};

function useSetupMachinesForm(): SetupMachinesContextData {
  const context = useContext(SetupMachinesContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { SetupMachinesProvider, useSetupMachinesForm };
