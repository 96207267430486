import React, { useState } from 'react';
import {
  ListItem,
  TextField,
  Button,
  Collapse,
  List,
  ListItemText,
  ListItemIcon,
  Icon,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Close } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { IAsset } from '../../../../../../store/TemplateCC';
import Tooltip from '../../../../../../AppComponents/Tooltip';
import PropFragment from './PropFragment';

import { AssetFormContainer, AssetFormInfo, FieldsTitle } from './styles';
import { assetAndPropTagPatt } from '../../../../../../utils/regexPatterns';

const AssetFragment: React.FC<{
  asset: IAsset;
  remove: () => void;
  editedTemplateAssets: IAsset[];
  edit: (asset: IAsset) => void;
}> = ({ asset, edit, remove, editedTemplateAssets }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const changeLabel = (e: { target: { value: string } }) => {
    edit({ ...asset, label: e.target.value });
  };

  const changeKey = (e: { target: { value: string } }) => {
    edit({ ...asset, tag: e.target.value });
  };

  const addProp = () => {
    if (!asset.props) return;

    const newProps = [...asset.props];

    newProps.push({
      id: uuidv4(),
      label: '',
      tag: '',
      dataType: '',
      readOnly: false,
      isKey: false,
      required: false,
      selectOpened: false,
    });

    edit({ ...asset, props: newProps });
  };

  return (
    <ListItem divider>
      <AssetFormContainer>
        <AssetFormInfo>
          <TextField
            label={t('common.words.label')}
            value={asset.label}
            onChange={changeLabel}
          />

          <TextField
            label={t('common.words.tag')}
            value={asset.tag}
            error={!assetAndPropTagPatt.test(asset.tag)}
            onChange={changeKey}
          />

          {open ? (
            <Button onClick={() => setOpen(false)}>
              <ExpandLess />
            </Button>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <ExpandMore />
            </Button>
          )}

          <Tooltip message={t('common.messages.doubleClickToRemove')}>
            <Button onDoubleClick={remove}>
              <Close />
            </Button>
          </Tooltip>
        </AssetFormInfo>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <FieldsTitle>
            <Trans>common.words.fields</Trans>
          </FieldsTitle>

          <List>
            {asset.props?.map((prop, idx) => (
              <PropFragment
                prop={prop}
                index={idx}
                key={prop.id ? prop.id : idx}
                editedTemplateAssets={editedTemplateAssets}
                asset={asset}
                edit={edit}
              />
            ))}
          </List>

          <ListItem divider button onClick={addProp}>
            <ListItemText>
              <Trans>button.add</Trans>
            </ListItemText>

            <ListItemIcon>
              <Icon color="primary">add</Icon>
            </ListItemIcon>
          </ListItem>
        </Collapse>
      </AssetFormContainer>
    </ListItem>
  );
};

export default AssetFragment;
