import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const AttributeTypography = styled(Typography).attrs({
  variant: 'overline',
})`
  color: var(--white);
  overflow-wrap: break-word;
  font-size: ${(props: { fontSize: string | number }) => `${props.fontSize}px`};
`;
