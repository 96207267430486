import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  flex: 1;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StepHeaderContainer = styled.div`
  width: 60%;
  display: flex;
  margin: 0 auto;
  padding-bottom: 30px;
  flex-direction: column;
`;

export const FirstStepContainer = styled.div`
  flex: 1;
  display: flex;
  margin-top: 5%;
  justify-content: center;
`;

export const FirstStepCard = styled(Paper)`
  width: 350px;
  display: flex;
  padding: 20px;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
`;

export const SecondStepContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const SecondStepFormContainer = styled.div`
  display: flex;
  max-width: 800px;
  flex-direction: column;
`;

export const OrgsFormContainer = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
`;

export const OrgFormCard = styled(Paper)`
  margin: 10px;
  padding: 20px;
  display: flex;
  background: #fff;
  flex-direction: column;
`;

export const LoadingContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;
