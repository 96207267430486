import React, { useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import * as S from './styles';
import { authAPI } from '../../Common/axios';

interface FormValues {
  name: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
}
export const SignupForm = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const history = useHistory();
  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        await authAPI.post('/signup', data);
        toast.success('Signup successful, verify your email to continue');
        history.push('/otp', { username: data?.username });
      } catch (err) {
        const regex = /:(.*?)\n/;
        const error = err as AxiosError;
        toast.error(
          `Signup failed: ${error.response?.data.match(regex)?.[1]?.trim()}`,
        );
      }
    },
    [history],
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <S.SignupForm onSubmit={handleSubmit(onSubmit)}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        style={{ gap: '1.5rem' }}
      >
        <TextField
          size="small"
          variant="outlined"
          label="Username"
          {...register('username', { required: 'Username is required' })}
          error={!!errors.username}
          helperText={errors.username?.message}
        />
        <TextField
          size="small"
          variant="outlined"
          label="Name"
          {...register('name', { required: 'Name is required' })}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          id="standard-adornment-password"
          size="small"
          variant="outlined"
          label="Email"
          {...register('email', { required: 'Email is required' })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <FormControl variant="outlined" size="small" error={!!errors.password}>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            {...register('password', {
              required: 'Password is required',
              // password validation with capital letter, number and special character
              pattern: {
                value: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                message:
                  'Password must have at least one capital letter, number and special character',
              },
              validate: (value) =>
                value !== getValues('confirmPassword')
                  ? 'Password does not match'
                  : true,
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters',
              },
            })}
            error={!!errors.password}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          <FormHelperText error>{errors.password?.message}</FormHelperText>
        </FormControl>

        <FormControl
          variant="outlined"
          size="small"
          error={!!errors.confirmPassword}
        >
          <InputLabel htmlFor="outlined-adornment-confirm">
            Confirm Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-confirm"
            {...register('confirmPassword', {
              required: 'Password is required',
              deps: ['password'],
              minLength: {
                value: 6,
                message: 'Password must have at least 6 characters',
              },
            })}
            error={!!errors.confirmPassword}
            type={showConfirmPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={130}
          />
          <FormHelperText error>
            {errors.confirmPassword?.message}
          </FormHelperText>
        </FormControl>

        <S.FormActions>
          <Button
            size="large"
            style={{ margin: '0' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {isSubmitting ? (
              <CircularProgress size={26} color="secondary" />
            ) : (
              'Sign Up'
            )}
          </Button>
        </S.FormActions>
      </Box>
    </S.SignupForm>
  );
};
