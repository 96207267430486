import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { AppBar, Typography, Button, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { StyledTab, StyledTabs } from '../../../../AppComponents/StyledTabs';
import OrganizationForm from '../../../../Forms/OrganizationForm';

import { TabLabelContainer } from '../../styles';
import { useDeployForm } from '../../../../Hooks/deploy';

const OrganizationsTab: React.FC = () => {
  const [value, setValue] = useState(0);
  const deployStart = useDeployForm();

  const { setOrgs } = deployStart;

  const handleOrgChange = (val: any, org: IOrg, field: string) => {
    const newOrgs = [...deployStart.orgs];
    const idx: number = newOrgs.findIndex(
      (obj) => obj.id === org.id && obj.orgName === org.orgName,
    );
    newOrgs[idx][field] = val;
    setOrgs(newOrgs);
  };

  const addOrg = () => {
    const { orgs } = deployStart;
    const newId = orgs.length === 0 ? 0 : orgs[orgs.length - 1].id + 1;

    orgs.push({
      id: newId,
      orgDomainName: '',
      orgName: '',
      authServer: {
        authMethod: 'none',
        oauthURL: '',
        oauthOpenID: '',
        oauthClientID: '',
        oauthClientSecret: '',
      },
      peers: [],
      ccapi: [],
      host: '',
      user: '',
      passwd: '',
      confirmPasswd: '',
      caIP: '',
      caPort: undefined,
      caOperationPort: undefined,
    });

    setOrgs(orgs);

    if (orgs.length === 1) setValue(0);
    else setValue(orgs.length - 1);
  };

  const removeOrg = (org: IOrg) => {
    let newOrgs = [...deployStart.orgs];

    if (org.orgName === '') {
      if (deployStart.orgs.length === 1) return;

      const toRemoveIndex = newOrgs.findIndex(
        (item) => item.orgName === org.orgName,
      );

      newOrgs.splice(toRemoveIndex, 1);
    } else {
      newOrgs = newOrgs.filter(
        (item) =>
          `${item.orgName}.${item.orgDomainName}` !==
          `${org.orgName}.${org.orgDomainName}`,
      );
    }

    newOrgs = newOrgs.map((item, id) => ({ ...item, id }));

    // Check is org peers are connected to any channel and remove them
    const { channels } = deployStart;
    const newChannels = channels.map((channel) => {
      const tempChannel = { ...channel };
      delete tempChannel.peers[org.orgName];

      return tempChannel;
    });

    deployStart.setChannels(newChannels);
    setOrgs(newOrgs);
  };

  const renderTabLabel = (org: IOrg) => (
    <TabLabelContainer>
      <Typography>
        {org.orgName ? (
          org.orgName
        ) : (
          <Trans>asset.network.deploy.unnamedOrg</Trans>
        )}
      </Typography>
      <Tooltip title={<Trans>asset.network.deploy.deleteOrgTooltip</Trans>}>
        <Clear
          onDoubleClick={() => {
            if (!deployStart.started) {
              removeOrg(org);
              if (value >= 1) setValue(value - 1);
            }
          }}
          style={{ fontSize: '15px', opacity: '0.5', marginLeft: '15px' }}
        />
      </Tooltip>
    </TabLabelContainer>
  );

  return (
    <div>
      <AppBar style={{ backgroundColor: 'var(--primary)' }} position="static">
        <StyledTabs
          value={value}
          scrollButtons="on"
          variant="scrollable"
          onChange={(_: any, v: any) => setValue(v)}
        >
          {deployStart.orgs.map((org) => (
            <StyledTab key={org.id} label={renderTabLabel(org)} />
          ))}
        </StyledTabs>

        <Button
          variant="outlined"
          onClick={addOrg}
          className="add-new-org-tab"
          disabled={deployStart.started}
          style={{ color: 'var(--white)' }}
        >
          <Trans>button.addMore</Trans>
        </Button>
      </AppBar>

      {deployStart.orgs.map((org) => (
        <div
          key={org.id}
          style={{ display: value === org.id ? 'block' : 'none' }}
        >
          <OrganizationForm
            org={org}
            hasCCRest
            handleChange={(val: any, field: string) =>
              handleOrgChange(val, org, field)
            }
            disableInputs={deployStart.started}
          />
        </div>
      ))}
    </div>
  );
};

export default OrganizationsTab;
