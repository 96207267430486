import i18n from 'i18next';
import { store } from '../../store';
import { openDialog } from '../../store/Dialog';
import { getUniqueRestHostsMap } from './parseNetworkState';

type GetOrgObjFunc = (
  stateOrg: INetStateOrg,
  chOrg: INetStateChannelDefOrg,
) => Record<string, any>;

type GetOrgPeerObjFunc = (
  stateOrg: INetStateOrg,
  chPeer: INetStateChannelDefOrg['Peers'][0],
) => Record<string, any>;

type GetOrgOrdererObjFunc = (
  stateOrg: INetStateOrg,
  chOrderer: INetStateChannelDefOrg['Orderers'][0],
) => Record<string, any>;

type MapChannelOrgsParam = {
  chOrgs: INetStateChannelDefOrg[];
  organizations: INetStateOrg[];
  getOrgObj: GetOrgObjFunc;
  getOrgPeerObj?: GetOrgPeerObjFunc;
  getOrgOrdererObj?: GetOrgOrdererObjFunc;
  separateNodesTypes?: boolean;
  uniqueHosts?: boolean;
  ipKey?: string;
};

export const mapChannelOrgNodes = (
  chDefOrg: INetStateChannelDefOrg,
  stateOrg: INetStateOrg,
  getOrgPeerObj: GetOrgPeerObjFunc,
  getOrgOrdererObj: GetOrgOrdererObjFunc,
  uniqueHosts = true,
  ipKey?: string,
) => {
  let mappedNodes: Record<string, any>[] = [];

  if (uniqueHosts && !ipKey)
    throw Error('If you want unique hosts you should pass the ipKey param');

  mappedNodes = chDefOrg.Peers.map((peer) => getOrgPeerObj(stateOrg, peer));

  chDefOrg.Orderers.forEach((orderer) => {
    if (uniqueHosts && ipKey) {
      const alreadyExists = mappedNodes.some(
        (item) => item[ipKey] === stateOrg.orderers[orderer],
      );

      if (!alreadyExists) mappedNodes.push(getOrgOrdererObj(stateOrg, orderer));
    } else {
      mappedNodes.push(getOrgOrdererObj(stateOrg, orderer));
    }
  });

  return mappedNodes;
};

export const mapChannelOrgs = ({
  chOrgs,
  organizations,
  getOrgObj,
  getOrgPeerObj,
  getOrgOrdererObj,
  separateNodesTypes = false,
  uniqueHosts = false,
  ipKey,
}: MapChannelOrgsParam) => {
  if (separateNodesTypes && !getOrgPeerObj && !getOrgOrdererObj)
    throw Error(
      'If you want separate nodes types you should pass one or both getOrgPeerObj and getOrgOrdererObj',
    );

  if (uniqueHosts && !ipKey)
    throw Error('If you want unique hosts you should pass the ipKey param');

  let restHosts: { host: string }[] = [];
  const mapNodes = getOrgPeerObj || getOrgOrdererObj;

  // using any because the destructured keys from getOrgObj are not insert in the type
  const mappedOrgs: any[] = chOrgs.map((chOrg) => {
    const stateOrg = organizations.find(
      (item) => item.orgName === chOrg.OrgName,
    );

    try {
      if (!stateOrg)
        throw Error(
          i18n.t('asset.chaincodes.upgradeChaincode.inconsistentOrgs'),
        );
    } catch (error) {
      store.dispatch(
        openDialog({
          type: 'error',
          title: i18n.t('common.words.error'),
          content: error.message,
        }),
      );

      return { mappedOrgs: [], restHosts: {} };
    }

    const stateOrgCCRestHost = stateOrg.ccRestHost || {};

    Object.keys(stateOrg.ccRestHost || {}).forEach((channel) => {
      if (!stateOrgCCRestHost[channel]) return;
      restHosts = [...restHosts, ...stateOrgCCRestHost[channel]];
    });

    if (!mapNodes) return getOrgObj(stateOrg, chOrg);

    if (separateNodesTypes)
      return {
        ...getOrgObj(stateOrg, chOrg),
        ...(getOrgPeerObj && {
          peers: chOrg.Peers.map((peer) => getOrgPeerObj(stateOrg, peer)),
        }),
        ...(getOrgOrdererObj && {
          orderers: chOrg.Orderers.map((orderer) =>
            getOrgOrdererObj(stateOrg, orderer),
          ),
        }),
      };

    if (!getOrgPeerObj && !getOrgOrdererObj)
      return { ...getOrgObj(stateOrg, chOrg) };

    if (!getOrgPeerObj || !getOrgOrdererObj)
      throw Error(
        'If you dont want separated nodes you either want to map only the org info (so dont pass getOrgPeerObj and getOrgOrdererObj) or the org info with nodes, containing both peers and orderers together (in that case pass both getPeerObj and getOrdererObj)',
      );

    return {
      ...getOrgObj(stateOrg, chOrg),
      nodes: mapChannelOrgNodes(
        chOrg,
        stateOrg,
        getOrgPeerObj,
        getOrgOrdererObj,
        uniqueHosts,
        ipKey,
      ),
    };
  });

  return { mappedOrgs, restHosts: getUniqueRestHostsMap(restHosts) };
};
