import styled from 'styled-components';
import { Paper, Container } from '@material-ui/core';

export const ComponentContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  svg {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const ArrowContainer = styled(Container)`
  && {
    height: 24px;
    width: fit-content;
    padding: 0 !important;

    svg {
      color: var(--white);
    }
  }
`;

export const ActionsContainer = styled(Container)`
  && {
    top: 32px;
    padding: 0;
    left: auto;
    z-index: 10;
    right: -12px;
    outline: none;
    width: fit-content;
    position: absolute;
    height: fit-content;
    transition: {
      duration: 300;
    }
  }
`;

export const ActionsCard = styled(Paper).attrs({
  elevation: 4,
})`
  && {
    width: 100%;
    display: flex;
    min-width: 56px;
    color: var(--black);
    height: fit-content;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    svg {
      padding: 8px;
    }
  }
`;
