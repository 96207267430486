import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { networkApi } from '../../Common/axios';
import { useNetworks } from '../Networks';

export type LicenseInfo = {
  cus_email: string;
  cus_id: string;
  exp: EpochTimeStamp;
  iat: EpochTimeStamp;
  iss: string;
  num_apis: number;
  num_nets: number;
  num_ords: number;
  num_peers: number;
  res_email: string;
  res_name: string;
  sub: string;
  version: string;
  license: string;
};

export type LicenseContextType = {
  licenseInfo: LicenseInfo | null;
  getLicenseInfo: () => Promise<LicenseInfo | null>;
};

export const LicenseContextDefaultValues: LicenseContextType = {
  licenseInfo: null,
  getLicenseInfo: async () => null,
};

export const LicenseContext = createContext<LicenseContextType>(
  LicenseContextDefaultValues,
);

export const LicenseInfoProvider: React.FC = ({ children }) => {
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfo | null>(null);

  const getLicenseInfo = useCallback(async () => {
    try {
      const response = await networkApi.get('/licenseInfo');

      const decodedLicenseInfo: LicenseInfo = JSON.parse(atob(response.data));
      setLicenseInfo(decodedLicenseInfo);
      return decodedLicenseInfo;
    } catch (error) {
      console.log('getLisenceInfo error:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    getLicenseInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = useMemo(
    () => ({
      licenseInfo,
      getLicenseInfo,
    }),
    [getLicenseInfo, licenseInfo],
  );

  return (
    <LicenseContext.Provider value={values}>{children}</LicenseContext.Provider>
  );
};

export function useLicense() {
  const context = useContext(LicenseContext);

  if (!context) {
    throw new Error('useLicense must be used within a LicenseInfoProvider');
  }

  return context;
}
