import React from 'react';
import { CSSProperties } from 'styled-components';
import RenderNotification from './components/RenderNotification';
import { ILoadingContainerCardInfo } from './types';

interface ILoadingContainerProps {
  customStyle?: object;
  cardInfo: ILoadingContainerCardInfo;
}

/*
  To add new notifications for an operation just insert a new case in the
  RenderNotification component, and create a new file for the operation
  in the operations folder and then pass the cardInfo prop in your component
  the "type" key must be the same of the RenderNotification switch/case
  It's very flexible, and if you want to define a different
  manner to list attributes you can use the components and utils available
  and/or add a customAttributes function in cardInfo FillingCard prop
  that will take the customAttributes that you made and render them
  instead of the normal attributes render. Choose the better way for you
*/

/*
  To modify the attribute size on the card you just
  have to pass the same name with size on the end
  Example: to modify the attribute peerIP size, pass another attribute peerIPSize

  Same way to attribute name
  Example: to modify the attribute peerIP name, pass another attribute peerIPAttrName

  The default attribute name is the attribute key
  separated with spaces and become the card attribute name
  Example:
    attributes: { channelName: 'mainchannel' }
    It will render as "Channel name: mainchannel"

  It also search the translation JSON under the common.notifications for translations
  using the attribute object key, if it doesn't exist the default attribute name is used
*/

// Container responsible to create the filling/loading Cards
const LoadingContainer: React.FC<ILoadingContainerProps> = ({
  customStyle,
  cardInfo,
}) => (
  // If you want a different style for the container, just pass it as prop
  <div style={(customStyle as CSSProperties) || { overflowY: 'auto' }}>
    <RenderNotification cardInfo={cardInfo} />
  </div>
);

export default LoadingContainer;
