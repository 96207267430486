import {
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useMemo } from 'react';
import { useAddOrganizationForm } from '../../../../Hooks/addOrganization';
import { OrgInChannel } from './orgInChannel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 220,
      minWidth: 200,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

interface OrgPeerAdderProps {
  channelIndex: number;
}

export const OrgPeerAdder: React.FC<OrgPeerAdderProps> = ({ channelIndex }) => {
  const { orgs, channels, setChannels } = useAddOrganizationForm();

  const channel = useMemo(() => channels?.[channelIndex], [
    channelIndex,
    channels,
  ]);

  const classes = useStyles();

  const handleAddPeer = useCallback(
    (org: IOrg, peer: IPeer) => () => {
      const newPeers = new Set(channel.peers[org.orgName]);

      newPeers.add(peer.value);
      channel.peers = {
        ...channel.peers,
        [org.orgName]: Array.from(newPeers),
      };

      channels[channelIndex] = channel;

      setChannels([...channels]);
    },
    [channel, channelIndex, channels, setChannels],
  );

  return (
    <Grid container direction="row" spacing={10}>
      <Grid direction="column" container item xs={5}>
        <Typography variant="subtitle2">Peers Available</Typography>
        <List className={classes.root} subheader={<li />}>
          {orgs.map((org, index) => {
            return (
              // <ListSubheader>{org.orgName}</ListSubheader>
              <>
                <li className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader>{org.orgName}</ListSubheader>
                    {org.peers
                      .filter(
                        (peer) =>
                          channel?.peers &&
                          !Object.values(channel.peers)
                            .flat()
                            .includes(peer.value),
                      )
                      .map((peer) => {
                        const labelId = `checkbox-list-label-${org.id}`;
                        return (
                          <ListItem key={org.id} role={undefined} dense>
                            <ListItemText
                              id={labelId}
                              primary={`${peer.value}`}
                            />

                            <ListItemSecondaryAction>
                              <IconButton
                                size="small"
                                color="primary"
                                edge="end"
                                onClick={handleAddPeer(org, peer)}
                              >
                                <AddIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                  </ul>
                </li>
              </>
            );
          })}
        </List>
      </Grid>

      <Grid direction="column" container item xs={5}>
        <OrgInChannel channelIndex={channelIndex} />
      </Grid>
    </Grid>
  );
};
