import styled from 'styled-components';
import { lighten } from 'polished';

export const SigninForm = styled.form`
  width: 100%;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  a {
    color: ${lighten(0.2, '#003F69')};
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
