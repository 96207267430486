import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { TitleGroup, ContentText } from './styles';

export interface ITemplates {
  [index: string]: {
    name: string;
    description: Record<string, string>;
  };
}

interface ICCDescriptionModalProps {
  opened: boolean;
  closeModal(): void;
  templates: ITemplates;
  selectedIndex?: number;
}

const CCDescriptionModal: React.FC<ICCDescriptionModalProps> = ({
  opened,
  closeModal,
  templates,
  selectedIndex,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [templateSelectList, setTemplateSelectList] = useState<string[]>([]);
  const [index, setIndex] = useState(selectedIndex || 0);

  useEffect(() => {
    if (typeof selectedIndex === 'number') {
      setIndex(selectedIndex);
    }
  }, [selectedIndex, opened]);

  // useEffect(() => {
  //   Object.keys(templates).map((item) =>
  //     setTemplateSelectList((state) => [...state, item]),
  //   );
  // }, [templates]);

  const handleForward = useCallback(
    () =>
      setIndex((state) => {
        if (state + 1 > templateSelectList.length - 1) {
          return 0;
        }
        return state + 1;
      }),
    [templateSelectList],
  );

  const handleBack = useCallback(
    () =>
      setIndex((state) => {
        if (state - 1 < 0) {
          return templateSelectList.length - 1;
        }
        return state - 1;
      }),
    [templateSelectList],
  );

  const getDescription = useCallback(() => {
    if (templates[templateSelectList[index]]) {
      if (templates[templateSelectList[index]]?.description?.[language]) {
        return templates[templateSelectList[index]]?.description?.[language];
      }
    }
    return t('common.forms.noCCDescription');
  }, [index, templates, templateSelectList, language, t]);

  const getName = useCallback(() => {
    if (templates[templateSelectList[index]].name) {
      return templates[templateSelectList[index]].name;
    }
    return templateSelectList[index];
  }, [index, templates, templateSelectList]);

  return (
    <div>
      <Dialog
        open={opened}
        onClose={() => closeModal()}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{ color: 'var(--white)', backgroundColor: 'var(--primary)' }}
        >
          <TitleGroup>
            <IconButton onClick={handleBack}>
              <KeyboardArrowLeft style={{ color: 'var(--white)' }} />
            </IconButton>
            <Typography>{templateSelectList[index] && getName()}</Typography>
            <IconButton onClick={handleForward}>
              <KeyboardArrowRight style={{ color: 'var(--white)' }} />
            </IconButton>
          </TitleGroup>
        </DialogTitle>

        <DialogContent
          style={{ padding: '20px 30px 30px 30px', maxHeight: 'fit-content' }}
        >
          {/* <ContentText>{getDescription()}</ContentText> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CCDescriptionModal;
