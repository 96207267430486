import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

const GenericInput: React.FC<TextFieldProps> = (props) => (
  <TextField
    style={{ width: '256px' }}
    variant="outlined"
    InputLabelProps={{ shrink: true }}
    {...props}
  />
);

export default GenericInput;
