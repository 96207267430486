import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import { StoreState } from '../store/types';

const GlobalLoader = () => {
  const { open } = useSelector((state: StoreState) => state.globalLoadingState);

  return (
    <Dialog open={open} style={{ opacity: 0.8 }}>
      <DialogContent style={{ display: 'flex', height: '110px' }}>
        <CircularProgress size={80} style={{ margin: '10px' }} />
      </DialogContent>
    </Dialog>
  );
};

export default GlobalLoader;
