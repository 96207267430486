import React, { useCallback } from 'react';
import {
  Button,
  CircularProgress,
  Box,
  TextField,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { authAPI } from '../../Common/axios';
import { openDialog } from '../../store/Dialog';
import { closeLoading, openLoading } from '../../store/GlobalLoading';

interface FormValues {
  username: string;
  password: string;
}
export const SigninForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //   const login = () => {
  //     try {
  //       dispatch(openLoading());

  //       loginAPI('/signin', { redirect: 'manual' })
  //         .then((res: { url?: string }) => {
  //           window.location.href = res.url ? res.url : window.location.href;

  //           sessionStorage.setItem(
  //             '@GoFabric:lastCheck',
  //             `${new Date().toJSON()}`,
  //           );
  //         })
  //         .catch(() => {
  //           dispatch(
  //             openDialog({
  //               title: t('common.words.error'),
  //               type: 'error',
  //               content: t('asset.login.loginError'),
  //             }),
  //           );
  //         })
  //         .finally(() => dispatch(closeLoading()));
  //     } catch (error) {
  //       dispatch(closeLoading());
  //       dispatch(
  //         openDialog({
  //           title: t('common.words.error'),
  //           type: 'error',
  //           content: error.message,
  //         }),
  //       );
  //     }
  //   };

  const onSubmit = useCallback(async (data: FormValues) => {
    try {
      await authAPI.post('/login', data);
      toast.success('Welcome!');
      sessionStorage.setItem('@GoFabric:lastCheck', `${new Date().toJSON()}`);
      window.location.href = '/';
    } catch (err) {
      const regex = /:(.*?)\n/;
      const error = err as AxiosError;
      toast.error(
        `Signin failed: ${error.response?.data.match(regex)?.[1]?.trim()}`,
      );
    }
  }, []);

  return (
    <S.SigninForm onSubmit={handleSubmit(onSubmit)}>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        style={{ gap: '1.5rem' }}
      >
        <TextField
          size="small"
          variant="outlined"
          label="Username"
          {...register('username')}
        />

        <FormControl variant="outlined" size="small" error={!!errors.password}>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            {...register('password', {
              required: 'Password is required',
            })}
            error={!!errors.password}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
          <FormHelperText error>{errors.password?.message}</FormHelperText>
        </FormControl>
        <S.FormActions>
          <Button
            size="large"
            style={{ margin: '0' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            {isSubmitting ? <CircularProgress size={26} /> : 'Sign In'}
          </Button>
          <Link to="/forgot-password">Forgot your password?</Link>
        </S.FormActions>
      </Box>
    </S.SigninForm>
  );
};
