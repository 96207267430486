import styled, { css } from 'styled-components';

interface IBlockContainerProps {
  size: number;
  borderColor: string;
}

interface IStyledBlockProps {
  color: string;
}

export const BlockContainer = styled.div<IBlockContainerProps>`
  cursor: pointer;
  border: 2px solid;
  position: absolute !important;

  ${(props) => css`
    width: ${`${props.size}px`};
    height: ${`${props.size}px`};
    border-color: ${props.borderColor};
    z-index: ${Math.trunc(10002 + 10000 / props.size)};
  `};
`;

export const StyledBlock = styled.div<IStyledBlockProps>`
  height: 40px;
  display: flex;
  align-items: center;
  color: var(--white);
  justify-content: space-between;
  background: ${({ color }) => `${color} !important`};
`;
