import React, { useMemo } from 'react';
import {
  TextField,
  InputAdornment,
  Typography,
  TextFieldProps,
} from '@material-ui/core';
import { Error as Warning } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { useDeployForm } from '../../Hooks/deploy';

export interface ChaincodeNameInputProps {
  channelIndex: number;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  value: string;
}
export const ChaincodeNameInput = ({
  channelIndex,
  onNameChange,
  disabled,
  value,
}: ChaincodeNameInputProps) => {
  const { t } = useTranslation();

  const { channels, chaincodeIndex, started, setIsValid } = useDeployForm();
  const channel = useMemo(() => channels?.[channelIndex], [
    channelIndex,
    channels,
  ]);

  const validateChaincodeName = () => {
    const chaincodeName = channel?.chaincodes[chaincodeIndex]?.chaincodeName;
    let chaincodeNameRegex = /^[a-zA-Z0-9-]+$/;

    const allChaincodes = channels.map((ch) => ch.chaincodes).flat();

    const chaincodeAlreadyExists = allChaincodes.filter(
      (cc) => cc.chaincodeName === chaincodeName,
    );

    if (channel?.chaincodes[chaincodeIndex]?.ccType === 'aws-bucket')
      chaincodeNameRegex = /^[a-zA-Z0-9-/_]+$/;

    if (!chaincodeName) return false;
    const isNotValid =
      !chaincodeNameRegex.test(chaincodeName) ||
      chaincodeAlreadyExists?.length > 1;

    if (isNotValid) setIsValid(false);
    else setIsValid(true);

    return isNotValid;
  };

  return (
    <TextField
      label={t('common.forms.chaincodeName')}
      disabled={disabled || started}
      className="chaincode-name-input"
      value={value || ''}
      defaultValue="''"
      style={{ width: '256px' }}
      onChange={onNameChange}
      {...(validateChaincodeName() && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="start">
              <Warning style={{ color: '#ffcc00' }} />
            </InputAdornment>
          ),
        },
        helperText: (
          <Typography
            component="span"
            style={{
              color: '#ffcc00',
              fontSize: '12px',
              fontWeight: 700,
            }}
          >
            <Trans
              values={{
                netName: channel?.chaincodes[chaincodeIndex]?.chaincodeName,
              }}
            >
              {t('asset.network.createChannel.duplicatedTemplateNameMsg')}
            </Trans>
          </Typography>
        ),
      })}
    />
  );
};
