import styled from 'styled-components';
import { Typography as MTypography } from '@material-ui/core';

export const Typography = styled(MTypography)``;

export const Container = styled.div`
  padding: 15px;
  border: 1px solid #d6d6d6;
  border-right: none;
  height: fit-content;
  background-image: linear-gradient(72.1deg, var(--primary) 30%, #005289 70%);

  ${Typography} {
    color: var(--white);
  }
`;
