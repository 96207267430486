import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  left: 16px;
  bottom: 30px;
  z-index: 1350;
  position: fixed;
  overflow: hidden;
`;

export const Card = styled(Paper).attrs({ elevation: 4 })<{ open: boolean }>`
  && {
    display: flex;
    padding: 16px;
    flex-direction: column;
    width: ${({ open }) => (open ? '250px' : '186px')};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
`;
