import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 70%;
  padding: 20px;
  max-width: 700px;
`;

export const SPaper = ({ ...props }) => (
  <Paper
    style={{
      padding: '20px',
      display: 'flex',
      margin: '20px 0',
      flexDirection: 'column',
    }}
  >
    {props.children}
  </Paper>
);

export const Subtitle = ({ ...props }) => (
  <Typography variant="overline" style={{ fontSize: '22px' }}>
    {props.children}
  </Typography>
);

export const AssetsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const AssetsTitle = ({ ...props }) => (
  <Typography variant="subtitle1" style={{ fontSize: '20px' }}>
    {props.children}
  </Typography>
);

export const NewPropContainer = styled.div`
  display: flex;
`;

export const CheckboxContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
`;
