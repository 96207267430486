import styled, { css, CSSObject } from 'styled-components';
import { Paper, Container, List, ListItem } from '@material-ui/core';

interface IListItemProps {
  itemheight: number;
  isdisabled: string;
  selected: boolean;
  maxheight: number;
}

interface IOptionsListProps {
  maxheight: number;
}

interface ISelectContainerProps {
  open: boolean;
  width: string;
  styles: CSSObject;
}

export const ComponentContainer = styled.div`
  position: relative;
  height: fit-content;
  background-color: ${(props: { disabled: boolean }) =>
    props.disabled ? 'rgb(239, 239, 239, 0.6)' : ''};
`;

export const SelectContainer = styled(Container)<ISelectContainerProps>`
  && {
    height: 39px;
    display: flex;
    padding: 18px;
    border-radius: 4px;
    align-items: center;
    min-width: fit-content;
    justify-content: space-between;
    width: ${(props: ISelectContainerProps) => props.width};
    border: ${(props: ISelectContainerProps) =>
      props.open ? '2px solid #2384FF' : '1px solid rgba(0, 0, 0, 0.23)'};
    ${({ styles }) => styles};
  }
`;

export const OptionsContainer = styled(Container)`
  && {
    top: 50px;
    padding: 0;
    z-index: 10;
    outline: none;
    position: absolute;
    height: fit-content;
    min-width: fit-content;
    width: ${(props: { width: string }) => props.width};
    transition: {
      duration: 300;
    }
  }
`;

export const OptionsCard = styled(Paper).attrs({
  elevation: 4,
})`
  && {
    width: 100%;
    display: flex;
    color: var(--black);
    align-items: center;
    min-height: fit-content;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const OptionsList = styled(List).attrs({
  disablePadding: true,
  component: 'div',
})<IOptionsListProps>`
  && {
    width: 100%;
    max-height: fit-content;
  }

  ${({ maxheight }) =>
    maxheight > 0 &&
    css`
      && {
        display: flex;
        flex-flow: column wrap;
        max-height: ${`${maxheight}px`};
      }
    `};
`;

export const SelectedListItem = styled(ListItem).attrs({
  button: true,
})<IListItemProps>`
  && {
    margin: 0;

    ${({ itemheight, maxheight }) => css`
      height: ${`${itemheight}px`};
      max-width: ${maxheight > 0 ? '50%' : '100%'};
    `};

    /* Disabled CSS */
    ${({ isdisabled }) =>
      isdisabled === 'true' &&
      css`
        && {
          color: var(--disabled);
          cursor: default;
          text-decoration: none;
          background-color: transparent;
        }
      `}
    /* Selected CSS */
    ${({ selected }) =>
      selected &&
      css`
        && {
          color: var(--white);
          border-radius: 2px;
          background-color: var(--secondary);
        }
      `}
  }
`;

export const FixedOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 0.2rem;
  color: var(--secondary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--backgroundSecondary);
  }
`;
