import {
  Box,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import type { LicenseInfo } from '../../Contexts/License';

interface LicenseModalProps {
  license: LicenseInfo | null;
  isOpen: boolean;
  onClose: () => void;
}

export const LicenseModal = (props: LicenseModalProps) => {
  const { license, isOpen, onClose } = props;

  if (
    !license ||
    process.env?.REACT_APP_SHOW_LICENSE?.toLowerCase?.() === 'false'
  )
    return null;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="simple-dialog-title">License details</DialogTitle>
      <List component="div" aria-label="license">
        <Box display="flex">
          <ListItem
            style={{
              width: 'auto',
            }}
          >
            <ListItemText primary={license?.sub} secondary="Subject" />
          </ListItem>

          <ListItem
            style={{
              width: 'auto',
            }}
          >
            <ListItemText primary={license?.iss} secondary="Issuer" />
          </ListItem>
        </Box>

        <Box display="flex">
          <ListItem>
            <ListItemText
              primary={license?.cus_email}
              secondary="Customer Email"
            />
          </ListItem>

          <ListItem>
            <ListItemText primary={license?.cus_id} secondary="Customer ID" />
          </ListItem>
        </Box>

        <Box
          display="flex"
          style={{
            gap: '0.5rem',
          }}
        >
          <ListItem>
            <ListItemText
              primary={
                license?.iat
                  ? new Date(license.iat * 1000).toLocaleString()
                  : ''
              }
              secondary="Issued date"
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                license?.exp
                  ? new Date(license.exp * 1000).toLocaleString()
                  : ''
              }
              secondary="Expiration date"
            />
          </ListItem>
        </Box>
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1))',
          }}
        >
        <ListItem>
          <ListItemText
            primary={license?.license}
            secondary="License key"
          />
        </ListItem>
        </Box>

        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
          }}
        >
          <ListItem>
            <ListItemText
              primary={license?.version}
              secondary="Current Version"
            />
          </ListItem>

        </Box>
      </List>
    </Dialog>
  );
};
