const getRandomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const getColor = () => {
  const h = getRandomInt(90, 240);
  const l = getRandomInt(0, 50);
  const s = Math.floor(Math.random() * 100);

  return `hsl(${h}, ${s}%, ${l}%)`;
};

export default getColor;
