import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import { changeMountedComponents } from '../../../store/InitialTour';
import { StoreState } from '../../../store/types';

import MenuPage from '../../MenuPage';

const TemplateCCMainPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const options = [
    {
      link: '/chaincode/templates/manage',
      target: 'manage-templates',
      text: t('title.templates.manageTemplates'),
      icon: 'folder',
    },
    {
      link: '/chaincode/templates/export',
      text: t('title.templates.exportTemplate'),
      icon: 'backup',
    },
    {
      link: '/chaincode/templates/import',
      text: t('title.templates.importTemplate'),
      icon: 'restore',
    },
  ];

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('chaincodeTemplatesMain'));
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justify="center">
      <div style={{ textAlign: 'center' }}>
        <Typography style={{ fontSize: '32px' }} variant="overline">
          <Trans>title.templates.title</Trans>
        </Typography>
      </div>
      <Grid item xs={12}>
        <MenuPage options={options} />
      </Grid>
    </Grid>
  );
};

export default TemplateCCMainPage;
