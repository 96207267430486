import React from 'react';
import { Trans } from 'react-i18next';

const PlanExpiredMessage: React.FC = () => (
  <>
    <Trans>common.messages.planExpiredExplanation</Trans>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://gofabric.io/pricing"
    >
      https://gofabric.io/pricing
    </a>
  </>
);

export default PlanExpiredMessage;
