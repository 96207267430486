import styled from 'styled-components';
import Logo from '../../assets/GoFabric_white.png';

interface IContainerProps {
  headerHeight: string;
  pageOnTop: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: fixed;
  top: 0;
  display: grid;
  grid-template-columns: 250px 1fr auto;
  width: 100%;

  height: ${({ headerHeight }) => headerHeight};
  background-color: ${({ pageOnTop }) =>
    pageOnTop ? 'var(--primary)' : 'var(--primaryDark)'};
  transition: all 0.3s ease-in-out;
  z-index: 99;
`;

export const LogoImg = styled.img.attrs({ src: Logo, alt: 'Goledger logo' })`
  height: 55px;
  z-index: 1001;
`;
