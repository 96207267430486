import React from 'react';
import { Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import * as S from './styles';
import { ForgotPasswordForm } from '../../AppComponents/ForgotPasswordForm';
import { authAPI } from '../../Common/axios';

export const ForgotPassword = () => {
  return (
    <S.Auth>
      <ForgotPasswordForm />
    </S.Auth>
  );
};
