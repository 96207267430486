import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  TextField,
  Button,
  Icon,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { IAsset, IAssetProp } from '../../../../../../../store/TemplateCC';
import Tooltip from '../../../../../../../AppComponents/Tooltip';
import DataTypeSelect from '../../../../../../../AppComponents/Template/DataTypeSelect';
import { assetAndPropTagPatt } from '../../../../../../../utils/regexPatterns';

import { FieldsForm, FieldsFormCheckBoxes, Prop } from './styles';

interface PropFragmentProps {
  prop: IAssetProp;
  index: number;
  editedTemplateAssets: IAsset[];
  asset: IAsset;
  edit: (asset: IAsset) => void;
}

const PropFragment: React.FC<PropFragmentProps> = ({
  prop,
  index,
  editedTemplateAssets,
  asset,
  edit,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>({} as HTMLDivElement);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CARD', id: index, assetLabel: asset.label },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const moveProps = useCallback(
    (from: number, to: number) => {
      if (!asset.props) return;

      const newProps = [...asset.props];
      const dragged = newProps[from];

      newProps.splice(from, 1);

      newProps.splice(to, 0, dragged);

      edit({ ...asset, props: newProps });
    },
    [edit, asset],
  );

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover: (
      item: { type: string; id: number; assetLabel: string },
      monitor,
    ) => {
      const draggedIndex = item.id;
      const draggedAsset = item.assetLabel;
      const targetAsset = asset.label;
      const targetIndex = index;

      const isSameProp = draggedIndex === targetIndex;
      const isNotSameAsset = draggedAsset !== targetAsset;

      if (isSameProp || isNotSameAsset) return;

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;

      const draggedOffset = monitor.getClientOffset();

      if (draggedOffset) {
        const draggedTop = draggedOffset?.y - targetSize.top;

        const isTopAndBeforeCenter =
          draggedIndex < targetIndex && draggedTop < targetCenter;

        const isBottomAndAfterCenter =
          draggedIndex > targetIndex && draggedTop > targetCenter;

        if (isTopAndBeforeCenter || isBottomAndAfterCenter) return;

        if (asset.props) moveProps(draggedIndex, targetIndex);

        // eslint-disable-next-line no-param-reassign
        item.id = targetIndex;
      }
    },
  });

  dragRef(dropRef(ref));

  const changeProp = useCallback(
    (item: IAssetProp, propIdx: number) => {
      if (asset.props) {
        const newProps = [...asset.props];
        newProps[propIdx] = item;
        edit({ ...asset, props: newProps });
      }
    },
    [asset, edit],
  );

  const deleteProp = (propIdx: number) => {
    if (!asset.props) return;

    const newProps = [...asset.props];

    newProps.splice(propIdx, 1);

    edit({ ...asset, props: newProps });
  };

  const handleChangeDataType = useCallback(
    (value, item, id, isCustom?: boolean) => {
      let dataType = value.type;

      if (value.isAsset && !value.isList) {
        dataType = `->${value.type}`;
      }

      if (value.isAsset && value.isList) {
        const splitedType = value.type.split('[]');
        dataType = `[]->${splitedType[1]}`;
      }
      return changeProp({ ...item, dataType, isCustom }, id);
    },
    [changeProp],
  );

  return (
    <Prop ref={ref} isDragging={isDragging}>
      <FieldsForm>
        <div>
          <TextField
            label={t('common.words.label')}
            value={prop.label}
            onChange={(e) =>
              changeProp({ ...prop, label: e.target.value }, index)
            }
          />

          <TextField
            label={t('common.words.tag')}
            value={prop.tag}
            error={!assetAndPropTagPatt.test(prop.tag)}
            onChange={(e) =>
              changeProp({ ...prop, tag: e.target.value }, index)
            }
          />

          <DataTypeSelect
            placeholder={t('asset.chaincodes.templates.common.dataType')}
            assetOptions={editedTemplateAssets.map((ass: IAsset) => ass.tag)}
            value={prop.dataType}
            onChange={(value, isCustom?: boolean) =>
              handleChangeDataType(value, prop, index, isCustom)
            }
          />
        </div>
        <FieldsFormCheckBoxes>
          <FormControlLabel
            control={
              <Checkbox
                checked={prop.isKey}
                onChange={() =>
                  changeProp({ ...prop, isKey: !prop.isKey }, index)
                }
              />
            }
            label={t('asset.chaincodes.templates.common.isKey')}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={prop.required}
                onChange={() =>
                  changeProp({ ...prop, required: !prop.required }, index)
                }
              />
            }
            label={t('common.words.required')}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={prop.readOnly}
                onChange={() =>
                  changeProp({ ...prop, readOnly: !prop.readOnly }, index)
                }
              />
            }
            label={t('asset.chaincodes.templates.common.readOnly')}
          />
        </FieldsFormCheckBoxes>

        <Tooltip message={t('common.messages.doubleClickToRemove')}>
          <Button onDoubleClick={() => deleteProp(index)}>
            <Icon>close</Icon>
          </Button>
        </Tooltip>
      </FieldsForm>
    </Prop>
  );
};

export default PropFragment;
