import React, {
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';

type TimeoutValues = {
  grpcTimeout: number;
  chaincodeTimeout: number;
};

const timeoutPatt = new RegExp(/^[0-9]*$/);

const CustomTimeoutModal: React.FC<{
  open: boolean;
  hasGrpcTimeout?: boolean;
  hasChaincodeTimeout?: boolean;
  onSave(values: TimeoutValues): void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({
  open,
  setOpen,
  onSave,
  hasGrpcTimeout = true,
  hasChaincodeTimeout = false,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const [grpcTimeout, setGrpcTimeout] = useState('0');
  const [chaincodeTimeout, setChaincodeTimeout] = useState('0');

  const closeModal = () => {
    setOpen(false);
  };

  const isValueInvalid = (value: string) =>
    !value ||
    value.indexOf(',') >= 0 ||
    value.indexOf('.') >= 0 ||
    Number.isNaN(parseInt(value, 10));

  const handleSave = () => {
    onSave({
      grpcTimeout: parseInt(grpcTimeout.replace(',', '.'), 10),
      chaincodeTimeout: parseInt(chaincodeTimeout.replace(',', '.'), 10),
    });

    setOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node))
      setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Dialog ref={ref} open={open} fullWidth maxWidth="xs" onClose={closeModal}>
      <DialogTitle
        style={{ color: 'var(--white)', backgroundColor: 'var(--primary)' }}
      >
        <Trans>asset.network.deploy.customTimeoutModal.title</Trans>
      </DialogTitle>

      <DialogContent>
        {hasChaincodeTimeout ? (
          <TextField
            fullWidth
            autoFocus
            type="number"
            margin="normal"
            value={chaincodeTimeout}
            error={!timeoutPatt.test(`${chaincodeTimeout}`)}
            label={t(
              'asset.network.deploy.customTimeoutModal.chaincodeTimeout',
            )}
            onChange={(event) => setChaincodeTimeout(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t('asset.network.deploy.customTimeoutModal.seconds')}
                </InputAdornment>
              ),
            }}
          />
        ) : null}

        {hasGrpcTimeout ? (
          <TextField
            fullWidth
            autoFocus
            type="number"
            margin="normal"
            value={grpcTimeout}
            error={!timeoutPatt.test(`${grpcTimeout}`)}
            label={t('asset.network.deploy.customTimeoutModal.grpcTimeout')}
            onChange={(event) => setGrpcTimeout(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t('asset.network.deploy.customTimeoutModal.milliseconds')}
                </InputAdornment>
              ),
            }}
          />
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          <Trans>button.close</Trans>
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={
            isValueInvalid(grpcTimeout) || isValueInvalid(chaincodeTimeout)
          }
          onClick={handleSave}
        >
          <Trans>button.save</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomTimeoutModal;
