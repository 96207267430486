import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import debounce from '../utils/debounce';
import { Auth } from '../AppComponents/CAauth';
import { INetwork } from '../store/Network';
import { clearAddOrdererNotifications } from '../store/AddOrdererNotifications';

export interface ISelectedOrg {
  ip: string;
  name: string;
}

export type OrgsOrderersMap = Record<string, boolean>;

interface AddOrdererContextData {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  host: string;
  setHost: Dispatch<SetStateAction<string>>;
  sendingData: boolean;
  setSendingData: Dispatch<SetStateAction<boolean>>;
  CAauth: Auth;
  setCAauth: Dispatch<SetStateAction<Auth>>;
  selectedOrg: ISelectedOrg;
  setSelectedOrg: Dispatch<SetStateAction<ISelectedOrg>>;
  ordererAlreadyExists: boolean;
  setOrdererAlreadyExists: Dispatch<SetStateAction<boolean>>;
  selectedNet: INetwork;
  setSelectedNet: Dispatch<SetStateAction<INetwork>>;
  orgsOrderers: OrgsOrderersMap;
  setOrgsOrderers: Dispatch<SetStateAction<OrgsOrderersMap>>;
  orgs: ISelectedOrg[];
  setOrgs: Dispatch<SetStateAction<ISelectedOrg[]>>;
  netStateOrgs: INetStateOrg[];
  setNetStateOrgs: Dispatch<SetStateAction<INetStateOrg[]>>;
  clearFormData(): void;
}

const AddOrdererContext = createContext<AddOrdererContextData>(
  {} as AddOrdererContextData,
);

const AddOrdererProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [host, setHost] = useState('');
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState<ISelectedOrg[]>([]);
  const [sendingData, setSendingData] = useState(false);
  const [CAauth, setCAauth] = useState<Auth>({} as Auth);
  const [netStateOrgs, setNetStateOrgs] = useState<INetStateOrg[]>([]);
  const [orgsOrderers, setOrgsOrderers] = useState<OrgsOrderersMap>({});
  const [ordererAlreadyExists, setOrdererAlreadyExists] = useState(false);
  const [selectedNet, setSelectedNet] = useState<INetwork>({} as INetwork);
  const [selectedOrg, setSelectedOrg] = useState({
    name: '',
    ip: '',
  } as ISelectedOrg);

  const clearFormData = useCallback(() => {
    setLoading(false);
    setHost('');
    setSendingData(false);
    setCAauth({});
    setSelectedOrg({
      name: '',
      ip: '',
    });
    setOrdererAlreadyExists(false);
    setSelectedNet({} as INetwork);
    setOrgsOrderers({});

    dispatch(clearAddOrdererNotifications());
  }, [dispatch]);

  const validateOrdererIP = useCallback(
    debounce((ip: string) => {
      setOrdererAlreadyExists(orgsOrderers[ip] || false);
    }),
    [selectedOrg],
  );

  useEffect(() => {
    if (host !== '' && selectedOrg.name) validateOrdererIP(host);
  }, [host, orgsOrderers, selectedOrg, validateOrdererIP]);

  return (
    <AddOrdererContext.Provider
      value={{
        loading,
        setLoading,
        host,
        setHost,
        sendingData,
        setSendingData,
        CAauth,
        setCAauth,
        selectedOrg,
        setSelectedOrg,
        ordererAlreadyExists,
        setOrdererAlreadyExists,
        selectedNet,
        setSelectedNet,
        orgsOrderers,
        setOrgsOrderers,
        orgs,
        setOrgs,
        netStateOrgs,
        setNetStateOrgs,
        clearFormData,
      }}
    >
      {children}
    </AddOrdererContext.Provider>
  );
};

function useAddOrdererForm(): AddOrdererContextData {
  const context = useContext(AddOrdererContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { AddOrdererProvider, useAddOrdererForm };
