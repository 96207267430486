import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  AppBar,
  Tooltip,
  Card,
  CardContent,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { changeMountedComponents } from '../../../../store/InitialTour';

import canRunOperation from '../../../../utils/canRunOperation';

import { Operation } from '../../../../store/AppStatus';
import { StyledTab, StyledTabs } from '../../../../AppComponents/StyledTabs';
import { default as CustomTooltip } from '../../../../AppComponents/Tooltip';
import { IDialogInfo, openDialog } from '../../../../store/Dialog';
import { useAddOrganizationForm } from '../../../../Hooks/addOrganization';
import { useNetworks } from '../../../../Contexts/Networks';
import { StoreState } from '../../../../store/types';
import { ChannelForm } from './channelForm';

interface IOption {
  name: string;
  option: string;
}

export const DefineChannels: React.FC<{
  handleStepChange: (type?: string) => void;
  sendData: () => void;
}> = ({ handleStepChange, sendData }) => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const { selectedNetwork } = useNetworks();
  const { channels } = useAddOrganizationForm();
  console.log('print', channels);
  const dispatch = useDispatch();
  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);

  const { chapterToChange } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const { operations }: { operations: Operation[] } = useSelector(
    (state: StoreState) => state.appStatusState,
  );

  const cantRunOperation = useCallback(() => !canRunOperation(operations), [
    operations,
  ]);

  // warn that the component already mount for the initial tour
  useEffect(() => {
    if (tourMode) dispatch(changeMountedComponents('deploySecondStep'));
    // eslint-disable-next-line
  }, [chapterToChange]);

  const renderTabLabel = useCallback(
    (channelName: string) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{channelName || 'unnamedChannel'}</Typography>
      </Box>
    ),
    [],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="1024px"
      margin="0 auto"
      marginTop="2rem"
    >
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        Channels
      </Typography>
      <AppBar style={{ backgroundColor: 'var(--primary)' }} position="static">
        <StyledTabs
          value={value}
          scrollButtons="on"
          variant="scrollable"
          onChange={(_: any, v: any) => setValue(v)}
        >
          {channels.map(({ channelName }, index) => (
            <StyledTab key={index} label={renderTabLabel(channelName)} />
          ))}
        </StyledTabs>
      </AppBar>

      <Card style={{ background: 'linear-gradient(45deg, whitesmoke, snow)' }}>
        <CardContent style={{ display: 'flex', margin: '20px' }}>
          {channels.map((channel, index) => {
            return (
              <div
                key={index}
                style={{
                  width: '100%',
                  display:
                    value === channels.indexOf(channel) ? 'block' : 'none',
                }}
              >
                <ChannelForm channelIndex={index} />
              </div>
            );
          })}
        </CardContent>
      </Card>

      <div style={{ display: 'flex', width: '100%' }}>
        <Button
          color="secondary"
          variant="contained"
          style={{ width: '45%', margin: '50px auto' }}
          onClick={() => handleStepChange('back')}
        >
          <Trans>button.back</Trans>
        </Button>

        <Button
          className="deploy-next-step-btn"
          fullWidth
          color="primary"
          variant="contained"
          style={{ width: '45%', margin: '50px auto', pointerEvents: 'all' }}
          onClick={() => {
            sendData();
          }}
        >
          <Trans>Add Organization</Trans>
        </Button>
      </div>
    </Box>
  );
};
