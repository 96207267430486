import React from 'react';

import { RemoveIcon } from './styles';

const RemovePeerButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <RemoveIcon
    onMouseEnter={(e: any) => {
      e.target.style.backgroundColor = 'rgb(255, 0, 0, 0.5)';
      e.target.style.color = 'var(--white)';
    }}
    onMouseLeave={(e: any) => {
      e.target.style.backgroundColor = 'transparent';
      e.target.style.color = 'var(--black)';
    }}
    onClick={onClick}
  />
);

export default RemovePeerButton;
