import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import { useNetworks } from '../../Contexts/Networks';
import GenericInput from '../../AppComponents/Channel/GenericInput';
import { networkApi } from '../../Common/axios';
import { OperationStateCard } from '../../AppComponents/OperationStateCard';
import { useOperation } from '../../Contexts/Operation';
import cancelWithDialog from '../../utils/cancelRequestWithModal';
import { openDialog } from '../../store/Dialog';
import { addOperation, removeOperation } from '../../store/AppStatus';
import { useAddExternalOrganization } from '../../Contexts/AddExternalOrganization';
import SelectNetwork from '../../AppComponents/SelectNetwork';
import FileInput from '../../AppComponents/FileInput';

import * as S from './styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRight: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 500,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const AddExternalOrganization = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addCCAPIState } = useOperation();

  const { selectedNetwork, fetchNetworkState } = useNetworks();
  const {
    selectedChannel,
    setSelectedChannel,
    orgName,
    setOrgName,
    orgMSP,
    setOrgMSP,
    started,
    caFile,
    setCAFile,
    tlsFile,
    setTlsFile,
  } = useAddExternalOrganization();
  const channels = useMemo(() => {
    if (!selectedNetwork?.channels) return [];
    return Object.keys(selectedNetwork.channels);
  }, [selectedNetwork]);
  useEffect(() => {
    if (selectedNetwork) {
      fetchNetworkState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetwork]);

  const onSubmit = useCallback(async () => {
    if (!selectedNetwork || !selectedChannel) return;

    setIsSubmitting(true);
    try {
      const formData = new FormData();

      formData.append('channelName', selectedChannel);
      formData.append('networkName', selectedNetwork?.networkName);
      formData.append('orgName', orgName);
      formData.append('orgMsp', orgMSP);
      formData.append('tls', tlsFile as Blob);
      formData.append('ca', caFile as Blob);

      networkApi
        .post('/addExtOrg', formData, {
          responseType: 'blob',

          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, 'Add External Organization', hasDialog);
            };

            cancelRequest = withDialogCancel;
            dispatch(
              addOperation({
                title: 'Add External Organization',
                pathname: window.location.pathname,
                name: 'addextorganization',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then((response) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(response.data);
          link.download = `${selectedChannel}.block`;
          link.click();

          fetchNetworkState();

          dispatch(
            openDialog({
              title: 'Success',
              type: 'success',
              content: 'Chaincode API added successfully',
            }),
          );
          dispatch(removeOperation('addextorganization', true));
        })
        .catch((error) => {
          console.log('addextorganization error: ', error);
          dispatch(removeOperation('addextorganization', false));
          // dispatch(
          //   openDialog({
          //     title: t('common.words.error'),
          //     type: 'error',
          //     content: error?.message,
          //   }),
          // );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      console.log('error: ', error);
      dispatch(removeOperation('addextorganization', false));
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error?.message,
        }),
      );
    }
  }, [
    caFile,
    dispatch,
    fetchNetworkState,
    orgMSP,
    orgName,
    selectedChannel,
    selectedNetwork,
    t,
    tlsFile,
  ]);

  const handleChooseChannel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedChannel(event?.target?.value);
    },
    [setSelectedChannel],
  );

  if (!selectedNetwork?.networkName)
    return (
      <SelectNetwork
        isNetSelected={!!(selectedNetwork?.networkName as string)}
      />
    );

  return (
    <div style={{ margin: '0 auto', textAlign: 'center' }}>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        <Trans>title.organizations.addExtOrg</Trans>
      </Typography>

      <S.PageContainer>
        <Box display="flex" flexDirection="column" alignItems="center">
          <S.Card>
            <Grid
              container
              justify="center"
              style={{
                width: 'fit-content',
                gap: '4rem',
                margin: '0 auto',
                paddingBottom: '2rem',
              }}
            >
              <Grid item xs={3}>
                <FormControl>
                  <Typography>Channels</Typography>
                  <FormLabel
                    component="legend"
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      height: '24px',
                    }}
                  />
                  <RadioGroup
                    aria-label="channel"
                    name="channel"
                    value={selectedChannel}
                    onChange={handleChooseChannel}
                  >
                    {channels.map((channel) => {
                      return (
                        <FormControlLabel
                          value={channel}
                          control={<Radio />}
                          label={channel}
                          //   checked={}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ gap: '1rem' }}
                >
                  <Typography>Org Config</Typography>
                  <GenericInput
                    value={orgName}
                    variant="standard"
                    disabled={isSubmitting}
                    onChange={(e) => setOrgName(e.target.value)}
                    label="Org Name"
                    style={{
                      margin: '0',
                    }}
                  />
                  <GenericInput
                    value={orgMSP}
                    variant="standard"
                    disabled={isSubmitting}
                    onChange={(e) => setOrgMSP(e.target.value)}
                    label="Org MSP"
                    style={{
                      margin: '0',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ gap: '1rem' }}
                >
                  <Typography>Files</Typography>

                  <FileInput
                    cloudIcon
                    accept=".cer, .pem"
                    disabled={started}
                    name="tlsFile"
                    file={tlsFile}
                    customBtnStyle={S.fileInputCustomBtnStyle}
                    containerStyle={{
                      padding: 0,
                    }}
                    message="TLS File"
                    onChange={(files) => {
                      if (files && files[0]) setTlsFile(files[0]);
                      else setTlsFile(null);
                    }}
                  />

                  <FileInput
                    cloudIcon
                    accept=".cer, .pem"
                    name="caFile"
                    disabled={started}
                    file={caFile}
                    customBtnStyle={S.fileInputCustomBtnStyle}
                    onChange={(files) => {
                      if (files && files[0]) setCAFile(files[0]);
                      else setCAFile(null);
                    }}
                    containerStyle={{
                      padding: 0,
                    }}
                    message="CA File"
                  />
                </Box>
              </Grid>
            </Grid>

            {isSubmitting && (
              <Button
                color="secondary"
                variant="contained"
                style={{ width: '45%', margin: '0 auto', pointerEvents: 'all' }}
                onClick={() => cancelRequest()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            )}

            <Button
              color="primary"
              variant="contained"
              className="deploy-start-btn"
              disabled={isSubmitting || !selectedChannel}
              style={{
                width: '45%',
                margin: '1rem auto',
                pointerEvents: 'all',
              }}
              onClick={onSubmit}
            >
              <Trans>button.start</Trans>
            </Button>

            <OperationStateCard taskOperation={addCCAPIState} />
          </S.Card>
        </Box>
      </S.PageContainer>
    </div>
  );
};

export default AddExternalOrganization;
