import styled from 'styled-components';

export const ChannelInfo = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 8px;
`;

export const BlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const ChaincodeBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: white;
  background-color: var(--primary);
  border-radius: 8px;
`;
