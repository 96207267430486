import {
  Box,
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { BackspaceOutlined } from '@material-ui/icons';
import React, { useCallback, useMemo } from 'react';
import { useNetworks } from '../../../../Contexts/Networks';
import { useAddOrganizationForm } from '../../../../Hooks/addOrganization';
import { clearSelectedNetwork } from '../../../../store/Network';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 220,
      minWidth: 200,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

interface OrgInChannelProps {
  channelIndex: number;
}

export const OrgInChannel: React.FC<OrgInChannelProps> = ({ channelIndex }) => {
  const { selectedNetwork } = useNetworks();
  const { channels, setChannels, orgs } = useAddOrganizationForm();

  const channel = useMemo(() => channels?.[channelIndex], [
    channelIndex,
    channels,
  ]);

  const classes = useStyles();
  const handleRemovePeer = useCallback(
    (orgName: string, peerToRemove: string) => () => {
      channel.peers[orgName] = channel.peers?.[orgName].filter(
        (peer) => peer !== peerToRemove,
      );

      if (channel.peers[orgName].length === 0) {
        delete channel.peers[orgName];
      }

      channels[channelIndex] = channel;

      setChannels([...channels]);
    },
    [channel, channelIndex, channels, setChannels],
  );
  return (
    <Box>
      <Typography variant="subtitle2">Peers in Channel</Typography>
      <List className={classes.root} subheader={<li />}>
        {channel?.peers &&
          Object.entries(channel.peers).map(([orgName, peers]) => {
            return (
              <li key={`section-${orgName}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader>{`${orgName}`}</ListSubheader>

                  {peers.map((peer, key) => {
                    const peerHost = peer.startsWith('peer')
                      ? selectedNetwork?.peers[peer]?.host
                      : peer;
                    return (
                      <ListItem
                        key={`item-${orgName}-${peer}`}
                        role={undefined}
                        dense
                      >
                        <ListItemText
                          id={`checkbox-list-label-${key}`}
                          primary={`${peerHost}`}
                        />
                        {!peer.startsWith('peer') && (
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={handleRemovePeer(orgName, peer)}
                              size="small"
                              color="secondary"
                              edge="end"
                            >
                              <BackspaceOutlined />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    );
                  })}
                </ul>
              </li>
            );
          })}
      </List>
    </Box>
  );
};
