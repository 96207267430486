import styled, { css } from 'styled-components';
import {
  Paper,
  Container,
  Badge as MBadge,
  ListItem as MListItem,
} from '@material-ui/core';

interface IListItemProps {
  selected: boolean;
}

export const DropDown = styled(Container)`
  && {
    top: 65px;
    right: 2%;
    padding: 0;
    z-index: 10;
    width: 150px;
    outline: none;
    position: fixed;
    max-height: 450px;
    height: fit-content;
  }
`;

export const LanguagesCard = styled(Paper).attrs({ elevation: 4 })`
  && {
    display: flex;
    max-height: 450px;
    align-items: center;
    color: var(--black);
    height: fit-content;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const FlagImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const Badge = styled(MBadge).attrs({ color: 'secondary' })`
  && {
    cursor: pointer;
    color: var(--white);
  }
`;

export const ListItem = styled(MListItem).attrs({
  button: true,
})<IListItemProps>`
  && {
    margin: 4px 0;

    ${({ selected }) =>
      selected &&
      css`
        && {
          border-radius: 2px;
          color: var(--white);
          background-color: var(--primary);
        }
      `};
  }
`;
