import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import FillingCard from '../components/Card';
import { mountCardAttributes } from '../utils';
import { changeCreateChannelNotificationAttrs } from '../../../store/CreateChannelNotifications';

import { StoreState } from '../../../store/types';
import { ILoadingContainerCardInfo } from '../types';

export default (cardInfo: ILoadingContainerCardInfo) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const state: StoreState = useSelector((reduxState: StoreState) => reduxState);

  const createChannelNotifications = state.createChannelNotificationsState;

  const { notification, notificationAttrs } = createChannelNotifications;

  const { title, running, canShow, attributes } = cardInfo;

  const changeNotification = (params: Record<string, any>) => {
    dispatch(changeCreateChannelNotificationAttrs(params));
  };

  const newCardInfo = {
    title,
    canShow,
    running,
    notificationAttrs,
    changeNotification,
    loadingInfo: notification,
    attributes: mountCardAttributes(attributes || {}, t),
  };

  return <FillingCard cardInfo={newCardInfo} />;
};
