import styled from 'styled-components';
import { Typography } from '@material-ui/core';

interface IContainerProps {
  windowHeight: number;
}

interface ISelectNetMsgProps {
  selected?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: ${({ windowHeight }) => `${windowHeight - 65}px`};
`;

export const SelectNetMsg = styled(Typography).attrs({
  variant: 'overline',
})<ISelectNetMsgProps>`
  && {
    font-size: 22px;
    text-align: center;
    word-wrap: break-word;
    max-width: ${({ selected }) => (selected ? '350px' : '450px')};
  }
`;
