import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

interface ICreateChannelNotificationsReducerState extends Record<string, any> {
  notificationAttrs: INotificationAttrs;
  notification: CommonNotificationObject;
}

export const initialState: ICreateChannelNotificationsReducerState = {
  notification: {},
  notificationAttrs: {} as INotificationAttrs,
};

const createChannelNotificationsSlice = createSlice({
  name: 'createChannelNotifications',
  initialState,
  reducers: {
    changeCreateChannelNotification(
      state,
      action: PayloadAction<CommonNotificationObject>,
    ) {
      state.notification = action.payload;

      if (Object.keys(state.notificationAttrs).length <= 0) {
        state.notificationAttrs = {
          mainLoad: 0,
          failed: false,
          fullyComplete: false,
        };
      }
    },
    changeCreateChannelNotificationAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      state.notificationAttrs = {
        ...state.notificationAttrs,
        ...action.payload,
      };
    },
    clearCreateChannelNotifications() {
      return initialState;
    },
  },
});

export const {
  changeCreateChannelNotification,
  clearCreateChannelNotifications,
  changeCreateChannelNotificationAttrs,
} = createChannelNotificationsSlice.actions;

export default createChannelNotificationsSlice.reducer;
