import React from 'react';
import { useHistory } from 'react-router';
import { Icon, ButtonBase } from '@material-ui/core';

import { Card, Text } from './styles';

export interface IMenuPageOption {
  link: string;
  text: string;
  icon: string;
  target?: string;
}

interface IMenuPageProps {
  options: IMenuPageOption[];
}

const MenuPage: React.FC<IMenuPageProps> = ({ options }) => {
  const history = useHistory();

  return (
    <div style={{ textAlign: 'center', padding: '1vw' }}>
      {options.map((option) => (
        <ButtonBase
          focusRipple
          key={option.link}
          style={{ margin: '1vw' }}
          className={option.target || ''}
          onClick={() => history.push(option.link)}
        >
          <Card>
            <Icon>{option.icon}</Icon>

            <Text>{option.text}</Text>
          </Card>
        </ButtonBase>
      ))}
    </div>
  );
};

export default MenuPage;
