/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { useCallback } from 'react';
import { useDeployForm } from './deploy';

const keyWhiteList = [
  'id',
  'user',
  'passwd',
  'confirmPasswd',
  'peer',
  'orderer',
];

type ReturnType = {
  portInUse: boolean;
  info: {
    org?: string;
    portName?: string;
    peer?: string;
  };
};

export const useValidatePort = () => {
  const { orgs } = useDeployForm();

  /**
   * Validate port
   *
   * @param port Port to validate
   * @param orgName Organization name to ignore validation for, as it may be the same as the one being edited
   * @param portName Port name to ignore validation for, as it may be the same as the one being edited
   */
  const validatePort = useCallback(
    (
      port: number | string,
      orgName: string,
      portName: string,
      ip: string,
    ): ReturnType => {
      if (!port)
        return {
          portInUse: false,
          info: {},
        };

      const info = {
        org: '',
        portName: '',
        peer: '',
      };

      return {
        portInUse: orgs?.some((org) => {
          info.org = org.orgName;
          return (
            Object.entries(org).some(([key, value]) => {
              // Ignore the organization/port being edited
              if (
                (org.orgName === orgName && key === portName) ||
                keyWhiteList.includes(key)
              )
                return false;

              if (key === 'ccapi') {
                const isInUse = (value as IOrg['ccapi']).some((ccapi) => {
                  return Object.entries(ccapi).some(([k, v]) => {
                    // Ignore the ccapi/port being edited
                    if (
                      (org.orgName === orgName && k === portName) ||
                      keyWhiteList.includes(key)
                    )
                      return false;

                    if (k === 'goinitus') {
                      info.portName = 'Interface Port';
                      info.peer = ccapi?.host;
                      return (
                        // eslint-disable-next-line eqeqeq
                        (v as IOrg['ccapi'][number]['goinitus'])?.port ==
                          port && ccapi.host === ip
                      );
                    }

                    // eslint-disable-next-line eqeqeq
                    if (v == port && ccapi.host === ip) {
                      info.portName = k;
                      info.peer = ccapi?.host;
                      return true;
                    }

                    return false;
                  });
                });
                return isInUse;
              }

              // eslint-disable-next-line eqeqeq
              if (value == port && org.caIP === ip) {
                info.portName = key;
                return true;
              }
              return false;
            }) ||
            org.peers.some((peer) =>
              Object.entries(peer.opts).some(([key, value]) => {
                // Ignore the peer/port being edited
                if (
                  (peer.value === orgName && key === portName) ||
                  keyWhiteList.includes(key)
                )
                  return false;
                // @ts-ignore
                // eslint-disable-next-line eqeqeq
                if (value == port && peer.value === ip) {
                  info.portName = key;
                  info.peer = peer.value;
                  return true;
                }
                return false;
              }),
            )
          );
        }),
        info,
      };
    },
    [orgs],
  );

  return validatePort;
};
