import React from 'react';
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Modal,
  Paper,
} from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as S from '../styles';
import { useNetworks } from '../../../Contexts/Networks';
import SelectNetwork from '../../../AppComponents/SelectNetwork';
import { peersInChannelCounter } from '../utils';

const ShowPin: React.FC<{ pin: string }> = ({ pin }) => {
  const [show, setShow] = React.useState(false);

  return (
    <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
      <Typography>{show ? pin : '***'} </Typography>
      <IconButton onClick={() => setShow(!show)}>
        <Tooltip title="Show PIN">
          {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export const GeneralDashboard: React.FC = () => {
  const {
    networkState,
    selectedNetwork,

    setSelectedChannel,
    setCurrentDashboardView,
  } = useNetworks();

  const [open, setOpen] = React.useState(false);
  const [selectedOrg, setSelectedOrg] = React.useState<string>('');
  const handleOpen = (orgName: string) => {
    setOpen(true);
    setSelectedOrg(orgName);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const countOrgOrderers = (org: any) => {
    let ordCount = 0;
    org?.orderers?.forEach((ord: any) => {
      const orgName: string = org.mspid.replace('MSP', '');
      if (ord.indexOf(`${orgName}.${org.domain}`) >= 0) ordCount++;
    });

    return ordCount;
  };

  const getJoinedChannels = (
    channels: NetWorkState['channels'],
    orgName: string,
  ) => {
    const joinedChannels = Object.entries(channels).filter(
      ([channelName, channelData]) => channelData.peers?.[orgName]?.length > 0,
    );

    return joinedChannels.map((ch) => ch[0]);
  };

  if (!selectedNetwork?.networkName)
    return (
      <SelectNetwork isNetSelected={selectedNetwork?.networkName !== ''} />
    );

  if (!networkState) return <></>;

  return (
    <S.DashboardContainer>
      <Typography variant="overline" style={{ fontSize: '25px' }}>
        {networkState?.networkName}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ gap: '1rem' }}
      >
        <Typography variant="overline" style={{ fontSize: '18px' }}>
          Channels
        </Typography>

        <Grid container xs="auto" spacing={10} justify="center">
          {networkState?.channels &&
            Object.entries(networkState.channels).map(
              ([channelName, channelData]) => (
                <Grid item>
                  <S.CustomPaper
                    hasHover
                    onMouseEnter={() => setSelectedChannel(channelName)}
                    onClick={() => setCurrentDashboardView('channelDashboard')}
                  >
                    <S.InfoBoxHead>
                      <Typography>{channelName}</Typography>
                    </S.InfoBoxHead>
                    <Box
                      textAlign="center"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <S.InfoGrid>
                        <S.InfoBox>
                          <span>Orgs</span>
                          {Object?.keys(channelData?.peers)?.length}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>Peers</span>
                          {peersInChannelCounter(channelData?.peers)}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>Orderers</span>
                          {Object?.keys(networkState?.orderers)?.length}
                        </S.InfoBox>
                      </S.InfoGrid>
                    </Box>
                  </S.CustomPaper>
                </Grid>
              ),
            )}
        </Grid>
        <Typography variant="overline" style={{ fontSize: '18px' }}>
          Organizations
        </Typography>

        <Grid container xs="auto" spacing={10} justify="center">
          {networkState?.organizations &&
            Object.entries(networkState?.organizations)?.map(
              ([orgName, org]) => (
                <Grid item>
                  <S.CustomPaper>
                    <S.InfoBoxHead>
                      <Typography>{`${orgName}.${org.domain}`}</Typography>
                      {org?.hsm?.enabled && (
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="flex-end"
                        >
                          <Tooltip title="See HSM information">
                            <IconButton
                              color="inherit"
                              onClick={() => handleOpen(orgName)}
                            >
                              <VpnKeyIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </S.InfoBoxHead>
                    <Box
                      textAlign="center"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <S.InfoGrid>
                        <S.InfoBox>
                          <span>Peers</span>
                          {(Boolean(org?.peers) &&
                            Object.keys(org?.peers)?.length) ||
                            0}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>Orderers</span>
                          {countOrgOrderers(org)}
                        </S.InfoBox>
                        <S.InfoBox>
                          <span>APIs</span>
                          {org?.ccapi?.length || 0}
                        </S.InfoBox>
                      </S.InfoGrid>
                    </Box>
                    {getJoinedChannels(networkState?.channels, orgName)
                      ?.length > 0 && (
                      <S.JoinedChannels>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Joined Channels
                        </Typography>
                        {getJoinedChannels(networkState?.channels, orgName).map(
                          (channel) => (
                            <li>{channel}</li>
                          ),
                        )}
                      </S.JoinedChannels>
                    )}
                  </S.CustomPaper>
                </Grid>
              ),
            )}
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          elevation={5}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '2rem',
            width: 'fit-content',
            margin: '0 auto',
            marginTop: '10rem',
            minWidth: '400px',
          }}
        >
          <Typography variant="h5">
            HSM Information for {selectedOrg}
          </Typography>
          <Box display="flex" style={{ gap: '1rem' }}>
            <Box display="flex" flexDirection="column">
              <Typography variant="caption">HSM IP</Typography>
              <Typography style={{ fontWeight: 500 }}>
                {networkState?.organizations[selectedOrg]?.hsm?.ip}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="caption">HSM Label </Typography>
              <Typography style={{ fontWeight: 500 }}>
                {networkState?.organizations[selectedOrg]?.hsm?.label}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography variant="h5">Nodes</Typography>
          </Box>

          <Box border="1px solid #d9d9d9" padding="1rem">
            <Box
              display="grid"
              gridColumnGap="2rem"
              gridRowGap="1rem"
              gridTemplateColumns="1fr auto auto"
              alignItems="center"
            >
              <Typography variant="caption">Node name</Typography>
              <Typography variant="caption">User</Typography>
              <Typography variant="caption">Pin</Typography>

              <>
                <Typography style={{ fontWeight: '500' }}>
                  CA{' '}
                  {networkState?.organizations[selectedOrg]?.ca?.containerName}
                </Typography>
                <Typography>
                  {networkState?.organizations[selectedOrg]?.ca?.hsm?.user}
                </Typography>

                <ShowPin
                  pin={networkState?.organizations[selectedOrg]?.ca?.hsm?.pin}
                />
              </>
              {networkState?.organizations[selectedOrg]?.peers?.map(
                (peerName) =>
                  networkState?.peers[peerName]?.hsm?.user ? (
                    <>
                      <Typography style={{ fontWeight: '500' }}>
                        {peerName}
                      </Typography>
                      <Typography>
                        {networkState?.peers[peerName]?.hsm?.user}
                      </Typography>

                      <ShowPin pin={networkState?.peers[peerName]?.hsm?.pin} />
                    </>
                  ) : null,
              )}

              {networkState?.organizations[selectedOrg]?.orderers?.map(
                (ordererName) =>
                  networkState?.orderers[ordererName]?.hsm?.user ? (
                    <>
                      <Typography style={{ fontWeight: '500' }}>
                        {ordererName}
                      </Typography>
                      <Typography>
                        {networkState?.orderers[ordererName]?.hsm?.user}
                      </Typography>
                      <ShowPin
                        pin={networkState?.orderers[ordererName]?.hsm?.pin}
                      />
                    </>
                  ) : null,
              )}
            </Box>
          </Box>
        </Paper>
      </Modal>
    </S.DashboardContainer>
  );
};
