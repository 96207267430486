import {
  createStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useMemo } from 'react';
import { useAddChannel } from '../../../../Contexts/AddChannel';
import { useNetworks } from '../../../../Contexts/Networks';
import { PeerInChannel } from './PeerInChannel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 240,
      minWidth: 350,
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);

interface ChannelPeerAdderProps {
  channelIndex: number;
}

export const ChannelPeerAdder: React.FC<ChannelPeerAdderProps> = ({
  channelIndex,
}) => {
  const { selectedNetwork } = useNetworks();
  const { channels, setChannels } = useAddChannel();

  const orgs = useMemo(() => {
    if (!selectedNetwork?.organizations) return [];
    return Object?.entries(selectedNetwork?.organizations);
  }, [selectedNetwork]);
  const channel = useMemo(() => channels?.[channelIndex], [
    channelIndex,
    channels,
  ]);

  const classes = useStyles();

  const handleAddPeer = useCallback(
    (orgName: string, peer: string) => () => {
      const newPeers = new Set(channel.peers[orgName]);
      newPeers.add(peer);
      channel.peers = {
        ...channel.peers,
        [orgName]: Array.from(newPeers),
      };

      channels[channelIndex] = channel;

      setChannels([...channels]);
    },
    [channel, channelIndex, channels, setChannels],
  );

  return (
    <Grid container direction="row" spacing={10}>
      <Grid direction="column" container item xs={5}>
        <Typography variant="subtitle2">Peers Available</Typography>
        <List className={classes.root} subheader={<li />}>
          {Boolean(orgs?.length) &&
            orgs?.map(([orgName, orgData], index) => {
              // const isOrderer = !org.peers.some((peer) => peer.opts?.peer);
              // console.log('isOrderer', isOrderer);

              return (
                // <ListSubheader>{org.orgName}</ListSubheader>
                <>
                  <li className={classes.listSection}>
                    <ul className={classes.ul}>
                      <ListSubheader>{orgName}</ListSubheader>
                      {Boolean(orgData.peers) &&
                        orgData.peers.map((peer, keyIndex) => {
                          const labelId = `checkbox-list-label-${keyIndex}`;

                          return (
                            <ListItem key={keyIndex} role={undefined} dense>
                              <ListItemText
                                id={labelId}
                                primary={`${selectedNetwork?.peers[peer]?.host}`}
                              />

                              <ListItemSecondaryAction>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  edge="end"
                                  onClick={handleAddPeer(orgName, peer)}
                                >
                                  <AddIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                    </ul>
                  </li>
                </>
              );
            })}
        </List>
      </Grid>

      <Grid direction="column" container item xs={5}>
        <PeerInChannel channelIndex={channelIndex} />
      </Grid>
    </Grid>
  );
};
