import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#003F69',
      },
      secondary: {
        main: '#FF5117',
      },
    },
  },
  {
    overrides: {
      MuiButton: {
        root: {},
        containedPrimary: {
          '&:hover': {},
        },
      },
    },
  },
);

export default theme;
