import styled from 'styled-components';
import { Remove } from '@material-ui/icons';
import { Paper as MPaper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  padding: 20px;
  margin: 0 auto;
  justify-content: center;

  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

export const Paper = styled(MPaper)`
  && {
    margin: 0 auto;
    min-width: 680px;
    max-width: 800px;
    height: fit-content;
    padding: 20px 36px 20px 20px;
  }
`;

export const Inputs = styled.div`
  display: flex;
  margin-left: 16px;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  padding: 16px 0 16px 0;
`;

export const Peers = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  @media (max-width: 1094px) {
    justify-content: space-between;
  }
`;

export const Actions = styled.div`
  margin: 20px 0;
  padding: 16px 0 16px 16px;
`;

export const RemoveIcon = styled(Remove)`
  && {
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    box-shadow: none;
    border-radius: 50%;
    background-color: transparent;
  }
`;
