import styled from 'styled-components';

export const PlanCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  padding: 1.5rem;
  border-radius: 12px;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 0 1px rgb(0 0 0/7%), 0 2px 4px rgb(0 0 0/5%),
    0 12px 24px rgb(0 0 0/5%);

  color: #121212;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
`;

export const Price = styled.div`
  display: flex;
  align-items: flex-end;

  font-size: 2rem;
  font-weight: 700;

  p {
    margin: 0;
    line-height: 2rem;
  }

  span {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.75rem;
    margin-left: 0.25rem;
    color: #999;
  }
`;

export const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  svg {
    /* color: var(--success); */
  }
`;

export const ChooseButton = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  border-top: 1px solid #eeeeee;

  button {
    font-weight: 600;
    box-shadow: none;
    border-radius: 9999px;
  }
`;
