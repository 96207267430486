import styled, { css } from 'styled-components';
import { ListItem, Paper } from '@material-ui/core';

export const PageContainer = styled.div`
  display: flex;
  padding: 24px 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const Section = styled.div`
  width: 33%;
  margin: 20px;
`;

export const Card = styled(Paper)`
  && {
    width: 100%;
    padding: 20px;
    display: flex;
    margin: 0 auto;
    max-width: 1000px;
    height: fit-content;
    flex-direction: column;
    background: whitesmoke;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 1400px) {
      margin-bottom: 20px;
    }
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectListItem = styled(ListItem).attrs(
  (props: { disabled: boolean }) => ({
    button: true,
    divider: true,
    disabled: props.disabled,
  }),
)`
  && {
    margin: 8px 0;
    ${({ selected }) =>
      selected &&
      css`
        && {
          color: var(--white);
          border-radius: 5px;
          background-color: var(--darkBlue);
        }
      `}
  }
`;
