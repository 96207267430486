import React from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import '../Resize.css';
import { Clear, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Button, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ElementType } from '../../types';

import { BlockContainer, StyledBlock } from './styles';

interface IBlockProps {
  block: ElementType;
  removeItem: (element: ElementType) => void;
  changeContent: (element: ElementType) => void;
  setPosition: (data: DraggableData) => void;
  setSize: (data: ResizeCallbackData) => void;
  checkParent: (element: ElementType, data: DraggableData) => void;
}

const Block: React.FC<IBlockProps> = (props) => {
  const { t } = useTranslation();
  const {
    block,
    setPosition,
    setSize,
    checkParent,
    removeItem,
    changeContent,
  } = props;
  return (
    <Draggable
      key={block.id}
      axis="both"
      handle={`.block-${block.id}`}
      position={block.position}
      grid={[25, 25]}
      scale={1}
      bounds="parent"
      onDrag={(_, data) => setPosition(data)}
      onStop={(_, data) => {
        checkParent(block, data);
      }}
    >
      <Resizable
        width={block.size}
        height={block.size}
        resizeHandles={['se']}
        onResize={(_, data) => setSize(data)}
        minConstraints={[150, 150]}
        maxConstraints={[600, 600]}
      >
        <BlockContainer size={block.size} borderColor={block.borderColor}>
          <StyledBlock color={block.color} className={`block-${block.id}`}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ButtonBase
                onClick={() =>
                  changeContent({
                    ...block,
                    content: parseInt(`${block.content}`, 10) + 1,
                  })
                }
              >
                <KeyboardArrowUp />
              </ButtonBase>
              <ButtonBase
                onClick={() =>
                  block.content > 1
                    ? changeContent({
                        ...block,
                        content: parseInt(`${block.content}`, 10) - 1,
                      })
                    : null
                }
              >
                <KeyboardArrowDown />
              </ButtonBase>
            </div>

            <div style={{ margin: '0 auto 0 10px', fontSize: '20px' }}>
              {` ${block.content} ${t('common.endorsement.of')} ${
                block.elements.length
              }`}
            </div>

            <Button onClick={() => removeItem(block)}>
              <Clear style={{ color: 'var(--white)' }} />
            </Button>
          </StyledBlock>
        </BlockContainer>
      </Resizable>
    </Draggable>
  );
};

export default Block;
