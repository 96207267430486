import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import userSlice from './store/User';
import dialogSlice from './store/Dialog';
import addOrgSlice from './store/AddOrg';
import networkSlice from './store/Network';
import instanceSlice from './store/Instance';
import appStatusSlice from './store/AppStatus';
import templateCCSlice from './store/TemplateCC';
import deployStartSlice from './store/DeployStart';
import EndorsementSlice from './store/Endorsement';
import initialTourSlice from './store/InitialTour';
import apiAuthDialogSlice from './store/ApiAuthDialog';
import globalLoadingSlice from './store/GlobalLoading';
// notifications
import addOrgNotificationsSlice from './store/AddOrgNotifications';
import backupNotificationsSlice from './store/BackupNotifications';
import deployNotificationsSlice from './store/DeployNotifications';
import addPeerNotificationsSlice from './store/AddPeerNotifications';
import addCCAPINotificationsSlice from './store/AddCCAPINotifications';
import chaincodeNotificationsSlice from './store/ChaincodeNotifications';
import addOrdererNotificationsSlice from './store/AddOrdererNotifications';
import removePeerNotificationsSlice from './store/RemovePeerNotifications';
import updatePeerNotificationsSlice from './store/UpdatePeerNotifications';
import joinChannelNotificationsSlice from './store/JoinChannelNotifications';
import addWebClientNotificationsSlice from './store/AddWebClientNotifications';
import upgradeCCApiNotificationsSlice from './store/UpgradeCCApiNotifications';
import backupRestoreNotificationsSlice from './store/BackupRestoreNotifications';
import setupMachinesNotificationsSlice from './store/SetupMachinesNotifications';
import createChannelNotificationsSlice from './store/CreateChannelNotifications';

export const store = configureStore({
  reducer: {
    userState: userSlice,
    addOrgState: addOrgSlice,
    dialogState: dialogSlice,
    networkState: networkSlice,
    instanceState: instanceSlice,
    appStatusState: appStatusSlice,
    templateCCState: templateCCSlice,
    deployStartState: deployStartSlice,
    endorsementState: EndorsementSlice,
    initialTourState: initialTourSlice,
    globalLoadingState: globalLoadingSlice,
    apiAuthDialogState: apiAuthDialogSlice,
    // notifications reducers
    addOrgNotificationsState: addOrgNotificationsSlice,
    deployNotificationsState: deployNotificationsSlice,
    backupNotificationsState: backupNotificationsSlice,
    addPeerNotificationsState: addPeerNotificationsSlice,
    addCCAPINotificationsState: addCCAPINotificationsSlice,
    chaincodeNotificationsState: chaincodeNotificationsSlice,
    removePeerNotificationsState: removePeerNotificationsSlice,
    addOrdererNotificationsState: addOrdererNotificationsSlice,
    updatePeerNotificationsState: updatePeerNotificationsSlice,
    joinChannelNotificationsState: joinChannelNotificationsSlice,
    upgradeCCApiNotificationsState: upgradeCCApiNotificationsSlice,
    addWebClientNotificationsState: addWebClientNotificationsSlice,
    setupMachinesNotificationsState: setupMachinesNotificationsSlice,
    backupRestoreNotificationsState: backupRestoreNotificationsSlice,
    createChannelNotificationsState: createChannelNotificationsSlice,
  },
});

export type AppThunk = ThunkAction<
  void,
  ReturnType<typeof store.getState>,
  unknown,
  Action<string>
>;

export default store;
