import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';

type UpdatedCCAPIs = Record<
  string,
  {
    ca: {
      username: string;
      password: string;
    };
    ccApis: CCAPIOrganization[];
  }
>;

interface UpgradeAPIContextData {
  updatedCCAPIs: UpdatedCCAPIs;
  setUpdatedCCAPIs: Dispatch<SetStateAction<UpdatedCCAPIs>>;
  addCCAPI: (orgName: string, ccapi: CCAPIOrganization) => void;
  removeCCAPI: (orgName: string, host: string) => void;
  setCACredentials: (
    orgName: string,
    credentials: { username?: string; password?: string },
  ) => void;
  clearForm: () => void;
}

const UpgradeAPIContext = createContext<UpgradeAPIContextData>(
  {} as UpgradeAPIContextData,
);

const UpgradeAPIProvider: React.FC = ({ children }) => {
  const [updatedCCAPIs, setUpdatedCCAPIs] = useState<UpdatedCCAPIs>({});

  console.log('updatedCCAPIs: ', updatedCCAPIs);

  const addCCAPI = useCallback(
    (orgName: string, ccapi: CCAPIOrganization) => {
      const { host } = ccapi;
      // if peer is already selected, do nothing
      if (updatedCCAPIs[orgName]?.ccApis?.find?.((peer) => peer.host === host))
        return;

      console.log('addCCAPI: ', orgName, host);

      setUpdatedCCAPIs((state) => ({
        ...state,
        [orgName]: {
          ...state[orgName],
          ca: {
            username: state[orgName]?.ca?.username || '',
            password: state[orgName]?.ca?.password || '',
          },
          ccApis: [
            ...(state?.[orgName]
              ? state[orgName]?.ccApis?.filter?.((peer) => peer.host !== host)
              : []),
            {
              ...ccapi,
            } as CCAPIOrganization,
          ],
        },
      }));
    },
    [updatedCCAPIs],
  );

  const removeCCAPI = useCallback(
    (orgName: string, host: string) => {
      const newPeers = updatedCCAPIs[orgName]?.ccApis?.filter?.(
        (peer) => peer.host !== host,
      );

      // if newPeers is empty, remove it from the object
      if (newPeers.length === 0) {
        const { [orgName]: _, ...rest } = updatedCCAPIs;
        setUpdatedCCAPIs(rest);

        return;
      }

      setUpdatedCCAPIs((state) => ({
        ...state,
        [orgName]: {
          ...state[orgName],
          ccApis: state[orgName]?.ccApis?.filter?.(
            (peer) => peer.host !== host,
          ),
        },
      }));
    },
    [updatedCCAPIs],
  );

  const setCACredentials = useCallback(
    (
      orgName: string,
      credentials: { username?: string; password?: string },
    ) => {
      setUpdatedCCAPIs((state) => ({
        ...state,
        [orgName]: {
          ...state[orgName],
          ca: {
            username: credentials?.username || state[orgName]?.ca?.username,
            password: credentials?.password || state[orgName]?.ca?.password,
          },
        },
      }));
    },
    [],
  );

  const clearForm = useCallback(() => {
    setUpdatedCCAPIs({});
  }, []);

  return (
    <UpgradeAPIContext.Provider
      value={{
        updatedCCAPIs,
        setUpdatedCCAPIs,
        addCCAPI,
        removeCCAPI,
        setCACredentials,
        clearForm,
      }}
    >
      {children}
    </UpgradeAPIContext.Provider>
  );
};

function useUpgradeAPIForm(): UpgradeAPIContextData {
  const context = useContext(UpgradeAPIContext);

  if (!context) {
    throw new Error('Hook must be used within a Provider');
  }

  return context;
}

export { UpgradeAPIProvider, useUpgradeAPIForm };
