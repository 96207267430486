import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonNotificationObject, WithOrgNotificationObject } from '../types';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';

export interface IBackcupNotificationsState extends Record<string, any> {
  backupGlobal: CommonNotificationObject;
  backupGlobalAttrs: INotificationAttrs;
  backupPeersAttrs: INotificationAttrs[];
  backupPeers: WithOrgNotificationObject[];
}

export const initialState: IBackcupNotificationsState = {
  backupPeers: [],
  backupGlobal: {},
  backupPeersAttrs: [],
  backupGlobalAttrs: {} as INotificationAttrs,
};

const backupNotificationsSlice = createSlice({
  name: 'backupNotifications',
  initialState,
  reducers: {
    changeBackupGlobal(state, action: PayloadAction<CommonNotificationObject>) {
      state.backupGlobal = action.payload;

      if (Object.keys(state.backupGlobalAttrs).length <= 0) {
        state.backupGlobalAttrs = {
          mainLoad: 0,
          failed: false,
          fullyComplete: false,
        };
      }
    },
    changeBackupGlobalAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      state.backupGlobalAttrs = {
        ...state.backupGlobalAttrs,
        ...action.payload,
      };
    },
    changeBackupPeers(state, action: PayloadAction<WithOrgNotificationObject>) {
      const { backupPeers, backupPeersAttrs } = state;
      const { orgName } = action.payload;

      const orgIndex = backupPeers.findIndex(
        (org: { orgName: string }) => org.orgName === orgName,
      );

      if (orgIndex < 0) {
        backupPeers.push(action.payload);
        backupPeersAttrs.push({
          orgName,
          failed: false,
          mainLoad: 0,
          fullyComplete: false,
        });
      } else {
        backupPeers[orgIndex] = action.payload;
      }
    },
    changeBackupPeersAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      const { backupPeersAttrs } = state;
      const { orgName } = action.payload;

      const index = backupPeersAttrs.findIndex(
        (notAttr) => notAttr.orgName === orgName,
      );

      if (index >= 0) {
        backupPeersAttrs[index] = {
          ...backupPeersAttrs[index],
          ...action.payload,
        };
      }
    },
    clearBackupNotifications() {
      return initialState;
    },
  },
});

export const {
  changeBackupPeers,
  changeBackupGlobal,
  changeBackupPeersAttrs,
  changeBackupGlobalAttrs,
  clearBackupNotifications,
} = backupNotificationsSlice.actions;

export default backupNotificationsSlice.reducer;
