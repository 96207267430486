import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationAttrs } from '../../AppComponents/Notifications/types';
import { CommonNotificationObject, WithOrgNotificationObject } from '../types';

export interface IDeployNotificationsState extends Record<string, any> {
  peers: WithOrgNotificationObject[];
  peersAttrs: INotificationAttrs[];
  global: CommonNotificationObject;
  globalAttrs: INotificationAttrs;
}

export const initialState: IDeployNotificationsState = {
  peers: [],
  peersAttrs: [],
  globalAttrs: {} as INotificationAttrs,
  global: {} as CommonNotificationObject,
};

const deployNotificationsSlice = createSlice({
  name: 'deployNotifications',
  initialState,
  reducers: {
    changeDeployNotificationsGlobal(
      state,
      action: PayloadAction<CommonNotificationObject>,
    ) {
      state.global = action.payload;

      if (Object.keys(state.globalAttrs).length <= 0) {
        state.globalAttrs = {
          mainLoad: 0,
          failed: false,
          fullyComplete: false,
        };
      }
    },
    changeGlobalAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      state.globalAttrs = {
        ...state.globalAttrs,
        ...action.payload,
      };
    },
    changeDeployNotificationsPeers(
      state,
      action: PayloadAction<WithOrgNotificationObject>,
    ) {
      const { peers, peersAttrs } = state;
      const peerKey: string = action.payload.orgName;

      const index = peers.findIndex((item) => item.orgName === peerKey);

      if (index < 0) {
        peers.push(action.payload);

        peersAttrs.push({
          orgName: action.payload.orgName,
          failed: false,
          mainLoad: 0,
          fullyComplete: false,
        });
      } else {
        peers[index] = action.payload;
      }
    },
    changePeerAttrs(
      state,
      action: PayloadAction<Record<keyof INotificationAttrs, any>>,
    ) {
      const { peersAttrs } = state;
      const { orgName } = action.payload;

      const index = peersAttrs.findIndex(
        (notAttr) => notAttr.orgName === orgName,
      );

      if (index >= 0) {
        peersAttrs[index] = {
          ...peersAttrs[index],
          ...action.payload,
        };
      }
    },
    clearDeployNotifications() {
      return initialState;
    },
  },
});

export const {
  changePeerAttrs,
  changeGlobalAttrs,
  clearDeployNotifications,
  changeDeployNotificationsPeers,
  changeDeployNotificationsGlobal,
} = deployNotificationsSlice.actions;

export default deployNotificationsSlice.reducer;
