import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../../store';
import { ElementType } from '../../AppComponents/Endorsement/types';

export interface IIdentitie extends Record<string, any> {
  role: { mspId: string; name: string };
}

export interface IEndorsementState extends Record<string, any> {
  dialog: boolean;
  elements: ElementType[];
  endorsement: IEndorsement | {};
}

const initialState: IEndorsementState = {
  endorsement: {},
  elements: [],
  dialog: false,
};

const endorsementSlice = createSlice({
  name: 'endorsement',
  initialState,
  reducers: {
    changeEndorsement(state, action: PayloadAction<IEndorsement>) {
      state.endorsement = action.payload;
    },
    changeElements(state, action: PayloadAction<ElementType[]>) {
      state.elements = action.payload;
    },
    openEndorsement(state) {
      state.dialog = true;
    },
    closeEndorsement(state) {
      state.dialog = false;
    },
    clearEndorsement() {
      return initialState;
    },
  },
});

export const changeEndorsementWithCallBack =
  (payload: IEndorsement): AppThunk =>
  (dispatch, getState) => {
    dispatch(endorsementSlice.actions.changeEndorsement(payload));

    return Promise.resolve(getState().endorsementState.endorsement);
  };

export const {
  changeElements,
  openEndorsement,
  closeEndorsement,
  clearEndorsement,
  changeEndorsement,
} = endorsementSlice.actions;

export default endorsementSlice.reducer;
