import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CSSObject } from 'styled-components';
import { Container } from './styles';

export type Auth = { user?: string; passwd?: string };

type CAauthType = React.FC<{
  disabled: boolean;
  initialState?: Auth;
  containerStyles?: CSSObject;
  userInputStyles?: CSSObject;
  passwordInputStyles?: CSSObject;
  callback: (auth: Auth) => void;
}>;

const CAauth: CAauthType = ({
  disabled,
  callback,
  initialState,
  containerStyles,
  userInputStyles,
  passwordInputStyles,
}) => {
  const { t } = useTranslation();
  const [auth, setAuth] = useState(initialState || { user: '', passwd: '' });
  const { user, passwd } = auth;

  const handleChange = useCallback(
    (key: string): React.ChangeEventHandler<HTMLInputElement> => ({
      target: { value },
    }) => {
      const newAuth = { ...auth, [key]: value };
      setAuth(newAuth);
      callback(newAuth);
    },
    [auth, callback],
  );

  return (
    <Container styles={containerStyles}>
      <TextField
        value={user || ''}
        disabled={disabled}
        label={t('common.forms.CAUser')}
        onChange={handleChange('user')}
      />

      <TextField
        type="password"
        disabled={disabled}
        value={passwd || ''}
        label={t('common.forms.CAPassword')}
        onChange={handleChange('passwd')}
      />
    </Container>
  );
};

CAauth.defaultProps = {
  containerStyles: {
    margin: 0,
  },
  userInputStyles: {
    width: '100%',
    margin: '16px 0 8px',
  },
  passwordInputStyles: {
    width: '100%',
    margin: '16px 0 8px',
  },
};

export default CAauth;
