import styled from 'styled-components';

export const LoadingNumber = styled.div`
  top: 85px;
  left: 20px;
  font-size: 40px;
  position: relative;
  color: var(--white);
`;

export const LoadingsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
`;

export const LoadingItem = styled.div`
  margin: 5px;
  height: 20px;
  width: 180px;
  display: flex;
  align-items: center;
`;
