import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import { saveAs } from 'file-saver';
import { networkApi } from '../../../../Common/axios';
import { addOperation, removeOperation } from '../../../../store/AppStatus';
import { getTemplateList, ITemplate } from '../../../../store/TemplateCC';

import { StoreState } from '../../../../store/types';
import Select from '../../../../AppComponents/Select';
import LoadingScreen from '../../../LoadingScreen';
import cancelWithDialog from '../../../../utils/cancelRequestWithModal';

import { PageTitle, Container, Card, LoadContainer } from './styles';

const { CancelToken } = Axios;
let cancelRequest: (hasDialog?: boolean) => void;

const TemplateExport: React.FC = () => {
  const { t } = useTranslation();
  const {
    templateList,
  }: {
    templateList: ITemplate[];
  } = useSelector((state: StoreState) => state.templateCCState);
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(
    null,
  );

  const handleTemplateSelection = useCallback(
    (v: any) => setSelectedTemplate(v.value),
    [],
  );

  const handleExport = useCallback(() => {
    setSending(true);

    networkApi
      .get('/exporttemplatedef', {
        params: { templateName: selectedTemplate?.name },
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'application/json' },
        cancelToken: new CancelToken((c) => {
          const withDialogCancel = (hasDialog = true) => {
            cancelWithDialog(c, 'exporttemplatedef', hasDialog);
          };

          cancelRequest = withDialogCancel;
          dispatch(
            addOperation({
              title: t('title.templates.exportTemplate'),
              cancel: withDialogCancel,
              name: 'exporttemplatedef',
              pathname: window.location.pathname,
            }),
          );
        }),
      })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/octet-stream',
        });

        saveAs(blob, `${selectedTemplate?.name}-backup.tar.gz`);
        dispatch(removeOperation('exporttemplatedef', true));
      })
      .catch(() => dispatch(removeOperation('exporttemplatedef', false)))
      .finally(() => {
        setSending(false);
      });
  }, [selectedTemplate, dispatch, t]);

  useEffect(() => {
    dispatch(getTemplateList());
  }, [dispatch]);

  return (
    <div style={{ margin: '0 auto' }}>
      <PageTitle>
        <Trans>title.templates.exportTemplate</Trans>
      </PageTitle>

      <Container>
        <Card>
          <Typography variant="h6" gutterBottom>
            {t('asset.exportTemplate.selectTemplateExport')}
          </Typography>

          <div style={{ width: '100%' }}>
            <Select
              disabled={sending}
              placeholder={t('common.chaincode.selectTemplate')}
              options={templateList.map((template) => ({
                label: template.name,
                value: template,
              }))}
              onChange={handleTemplateSelection}
            />

            {sending ? (
              <LoadContainer>
                <LoadingScreen
                  content={t('asset.exportTemplate.loadingMessage')}
                />

                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  style={{ display: 'block' }}
                  onClick={() => cancelRequest()}
                >
                  <Trans>button.cancel</Trans>
                </Button>
              </LoadContainer>
            ) : (
              <Button
                fullWidth
                disabled={!selectedTemplate}
                style={{ marginTop: '30px' }}
                color="primary"
                variant="contained"
                onClick={handleExport}
              >
                <Trans>button.send</Trans>
              </Button>
            )}
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default TemplateExport;
