import React, { useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';

import { StoreState } from '../../store/types';
import { goToChapter } from '../../store/InitialTour';

import { getChapters, ITourChapter } from '../../utils/tour/FirstTimeUsers';

import { Card, Container, CardHeader } from './styles';

const ChaptersContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { tourMode } = useSelector((state: StoreState) => state.appStatusState);
  const { run, stepIndex } = useSelector(
    (state: StoreState) => state.initialTourState,
  );

  const [open, setOpen] = useState(true);
  const [chapters, setChapters] = useState<ITourChapter[]>([]);

  const handleChapterSelection = (chapterIdx: number) => {
    dispatch(goToChapter(chapterIdx, chapters[chapterIdx].stepRange[0]));
  };

  const isTourOnChapterRange = useCallback(
    (chapter: ITourChapter) =>
      stepIndex >= chapter.stepRange[0] && stepIndex <= chapter.stepRange[1],
    [stepIndex],
  );

  useEffect(() => {
    if (tourMode) setChapters(getChapters(t));
  }, [t, tourMode]);

  useEffect(() => {
    if (!run || !tourMode) setOpen(true);
  }, [run, tourMode]);

  if (!tourMode || !run) return null;

  return (
    <Container>
      <Card open={open}>
        <CardHeader>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            <Trans>tour.chapters.title</Trans>
          </Typography>

          {open ? (
            <ExpandMore onClick={() => setOpen(false)} />
          ) : (
            <ExpandLess onClick={() => setOpen(true)} />
          )}
        </CardHeader>

        {open ? (
          <List>
            {chapters.map((chapter, idx) => (
              <ListItem
                button
                key={chapter.name}
                style={{ padding: '0 8px' }}
                selected={isTourOnChapterRange(chapter)}
                onClick={() => handleChapterSelection(idx)}
              >
                <ListItemText primary={`${idx + 1}. ${chapter.name}`} />
              </ListItem>
            ))}
          </List>
        ) : null}
      </Card>
    </Container>
  );
};

export default ChaptersContainer;
