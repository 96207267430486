import { ISelectedNodes } from '../utils';
import { IDialogInfo } from '../../../store/Dialog';
import { ISelectedNetwork } from '../../../store/Network';
import { JoinChannelContextData } from '../../../Hooks/joinChannel';

// True if the string is invalid
const isStrInvalid = (value: any) =>
  !value || value === '' || value.indexOf(' ') >= 0;

export const validateFormData = (
  joinChannelForm: JoinChannelContextData,
  openDialog: (p: IDialogInfo) => void,
  t: (a: string, b?: object) => string,
) => {
  try {
    const { orgs, selectedOrgIdx, CAauth, selectedNodes } = joinChannelForm;

    if (!orgs[selectedOrgIdx]) throw Error(t('common.messages.selectOneOrg'));

    if (selectedNodes.length <= 0)
      throw Error(t('asset.nodes.joinChannel.atLeastOneNode'));

    if (isStrInvalid(CAauth.user) || isStrInvalid(CAauth.passwd))
      throw Error(t('common.messages.addCredentials'));

    return true;
  } catch (error) {
    openDialog({
      title: t('common.words.error'),
      type: 'error',
      content: error.message,
    });

    return false;
  }
};

export const buildRequestPayload = (
  joinChannelForm: JoinChannelContextData,
  selectedNetwork: INetwork | null,
  selectedNodes: ISelectedNodes[],
) => {
  const { orgs, selectedOrgIdx, CAauth } = joinChannelForm;
  const orderers: string[] = [];
  const peers: string[] = [];

  selectedNodes.forEach((node) => {
    if (node.type === 'p' || node.type === 'both') peers.push(node.peerName);

    if (node.type === 'o' || node.type === 'both')
      orderers.push(node.ordererName);
  });

  return {
    CAauth,
    peers,
    orderers,
    networkName: selectedNetwork?.networkName,
    orgName: orgs[selectedOrgIdx].firstName,
    channelName: '', // TODO
  };
};
