import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  Step,
  Stepper,
  StepLabel,
  IconButton,
  Button,
  AppBar,
  Box,
  Typography,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useTranslation, Trans } from 'react-i18next';
import { Container, StepperContainer } from './styles';

import history from '../../history';
import { networkApi } from '../../Common/axios';

import LoadingScreen from '../LoadingScreen';
import DefineChaincodes from './Steps/DefineChaincodes';
import LoadingContainer from '../../AppComponents/Notifications';
import DefineOrganizationsStep from './Steps/DefineOrganizations';
import cancelWithDialog from '../../utils/cancelRequestWithModal';
import buildStartNetData from '../../utils/network/buildStartNetData';
import { canShowCards, ValidateOrgs, validateChannels } from './utils';
import { IEndorsementEvent } from '../../AppComponents/Endorsement/types';
import EndorsementModal from '../../AppComponents/Endorsement/EndorsementModal';

import { useDeployForm } from '../../Hooks/deploy';
import { openSuccessModal } from '../../store/DeployStart';
import { openDialog, IDialogInfo } from '../../store/Dialog';
import { addOperation, removeOperation } from '../../store/AppStatus';
import CustomTimeoutModal from '../../AppComponents/CustomTimeoutModal';
import { clearDeployNotifications } from '../../store/DeployNotifications';
import { DefineChannels } from './Steps/DefineChannels';
import { OperationStateCard } from '../../AppComponents/OperationStateCard';
import { useOperation } from '../../Contexts/Operation';
import { useNetworks } from '../../Contexts/Networks';
import { useOPHistory } from '../../Contexts/OPHistory';

const { CancelToken } = Axios;
let cancel: (hasDialog?: boolean) => void;

const DeployStart: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    getAllNetworks,
    setSelectedNetwork,
    setSelectedChannel,
    networks,
  } = useNetworks();
  const deployStart = useDeployForm();

  const {
    channels,
    setStarted,
    setActiveStep,
    clearFormData,
    setEndorsement,
    setGrpcTimeout,
    setEndorsementGUI,
    setChaincodeTimeout,
    customTimeoutModalOpened,
    setCustomTimeoutModalOpened,
    isValid,
    chaincodeIndex,
  } = deployStart;
  const { getHistory } = useOPHistory();
  const { activeStep } = deployStart;
  const steps = [
    t('asset.network.deploy.step1'),
    t('asset.network.deploy.step2'),
    t('asset.network.deploy.step3'),
    t('asset.network.deploy.step4'),
  ];

  const { clearOperationsState } = useOperation();

  //   const verifyNetwork = () => {
  //     // const canSend = ValidateNetDefs(
  //     //   deployStart,
  //     //   (value: IDialogInfo) => dispatch(openDialog(value)),
  //     //   t,
  //     // );

  //     const canSend = true;

  //     const networkExists = networks.some(
  //       (net: { name: string }) => net.name === deployStart.networkName,
  //     );

  //     if (networkExists && canSend) {
  //       dispatch(
  //         openDialog({
  //           title: t('common.words.error'),
  //           type: 'error',
  //           content: `${t('asset.network.deploy.messages.networkNameUsed')}. ${t(
  //             'asset.network.deploy.messages.eitherDeleteOrRename',
  //           )}`,
  //           unmountCallback: () => {
  //             window.scrollTo(0, 0);
  //           },
  //         }),
  //       );
  //     } else if (!networkExists && canSend) {
  //       return true;
  //     }

  //     return false;
  //   };

  const sendData = () => {
    // const canSend = verifyNetwork();
    try {
      clearOperationsState();
      console.log('DEPLOY START', deployStart);
      if (!isValid)
        throw new Error(
          t('asset.network.createChannel.duplicatedTemplateNameMsg'),
        );
      const formData = buildStartNetData(deployStart);

      setStarted(true);
      dispatch(clearDeployNotifications());
      setActiveStep((curValue) => curValue + 1);

      networkApi
        .post('/startnetwork', formData, {
          cancelToken: new CancelToken((c) => {
            const withDialogCancel = (hasDialog = true) => {
              cancelWithDialog(c, t('title.network.deploy'), hasDialog);
            };

            cancel = withDialogCancel;
            dispatch(
              addOperation({
                title: t('title.network.deploy'),
                pathname: window.location.pathname,
                name: 'startnetwork',
                cancel: withDialogCancel,
              }),
            );
          }),
        })
        .then(() => {
          const filePath =
            process.env.NODE_ENV === 'development'
              ? '/applause.wav'
              : '/success.wav';

          const successAudio = new Audio(
            `${process.env.PUBLIC_URL}${filePath}`,
          );

          successAudio.play();

          dispatch(openSuccessModal());
          dispatch(
            removeOperation('startnetwork', true, deployStart.networkName),
          );

          clearFormData();

          getAllNetworks().then((allNetworks) => {
            const newNetwork = allNetworks?.find?.(
              (net) => net?.displayName === deployStart?.networkName,
            );

            if (!newNetwork) {
              if (window.location.pathname === '/network/deploy')
                history.push('/dashboard');

              return;
            }

            const firstChannel = Object?.keys(newNetwork?.channels)?.[0];
            setSelectedNetwork(newNetwork);
            setSelectedChannel(firstChannel);
            clearFormData();

            if (window.location.pathname === '/network/deploy')
              history.push('/dashboard');
          });
        })
        .catch((error) => {
          dispatch(removeOperation('startnetwork', false));
        })
        .finally(() => setStarted(false));
    } catch (error) {
      dispatch(
        openDialog({
          title: t('common.words.error'),
          type: 'error',
          content: error.message,
        }),
      );
    } finally {
      getHistory();
    }
  };

  const handleStepChange = (type = 'next') => {
    let newActiveStep;

    if (type === 'back') {
      newActiveStep = activeStep - 1;
    } else {
      let isFormValid = ValidateOrgs(
        deployStart,
        networks,
        (value: IDialogInfo) => dispatch(openDialog(value)),
        t,
      );

      if (!isFormValid) return;

      if (activeStep === 1) {
        isFormValid = validateChannels(
          channels,
          (message: IDialogInfo) => dispatch(openDialog(message)),
          t,
        );
        if (!isFormValid) return;
      }

      newActiveStep = activeStep + 1;
    }

    if (newActiveStep < 0 || newActiveStep > steps.length - 1) return;

    setActiveStep(newActiveStep);
  };

  const { deployState } = useOperation();

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <DefineOrganizationsStep handleStepChange={handleStepChange} />;
      case 1:
        return <DefineChannels handleStepChange={handleStepChange} />;
      case 2:
        return (
          <DefineChaincodes
            sendData={sendData}
            handleStepChange={handleStepChange}
          />
        );
      case 3:
        return (
          <Box marginTop="3rem" display="flex" width="100%">
            <OperationStateCard
              taskOperation={deployState}
              invisible={!deployStart.started}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <CustomTimeoutModal
        hasChaincodeTimeout
        open={customTimeoutModalOpened}
        setOpen={setCustomTimeoutModalOpened}
        onSave={(v) => {
          setGrpcTimeout(v.grpcTimeout);
          setChaincodeTimeout(v.chaincodeTimeout);
        }}
      />

      <StepperContainer>
        <Stepper
          activeStep={activeStep}
          className="deploy-steps"
          style={{ padding: '24px 24px 10px 24px', marginTop: '10px' }}
        >
          {steps.map((step, idx) => (
            <Step key={step} className={idx === 0 ? 'define-orgs-step' : ''}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <IconButton
            color="secondary"
            disabled={
              activeStep === 0 || (activeStep === 2 && deployStart.started)
            }
            style={{ position: 'absolute', left: '12px' }}
            onClick={() => handleStepChange('back')}
          >
            <ArrowBack
              className="previous-step"
              style={{ width: '40px', height: '40px' }}
            />
          </IconButton>

          <IconButton
            color="primary"
            disabled={activeStep === steps.length - 1}
            style={{ position: 'absolute', right: '15px' }}
            onClick={() => handleStepChange()}
          >
            <ArrowForward
              className="next-step"
              style={{ width: '40px', height: '40px' }}
            />
          </IconButton>
        </div>
      </StepperContainer>

      <Container>
        {getStepContent()}
        <div
          style={{
            margin: '0 auto 50px auto',
            width: '100%',
            maxWidth: '500px',
          }}
        >
          {deployStart.started && (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingScreen content="Deploying..." />
              </div>

              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => cancel()}
              >
                <Trans>button.cancel</Trans>
              </Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default DeployStart;
