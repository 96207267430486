import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding: 8px 0 24px 0;
  justify-content: center;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;
