import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDeployStartState extends Record<string, any> {
  mustClearForm: boolean;
  successModalOpened: boolean;
}

export const initialState: IDeployStartState = {
  mustClearForm: false,
  successModalOpened: false,
};

const deployStartSlice = createSlice({
  name: 'deployStart',
  initialState,
  reducers: {
    clearDeployData: {
      reducer(state, action: PayloadAction<boolean>) {
        state.mustClearForm = action.payload;
      },
      prepare(payload = true) {
        return { payload: payload || false };
      },
    },
    openSuccessModal(state) {
      state.successModalOpened = true;
    },
    closeSuccessModal(state) {
      state.successModalOpened = false;
    },
  },
});

export const {
  clearDeployData,
  openSuccessModal,
  closeSuccessModal,
} = deployStartSlice.actions;

export default deployStartSlice.reducer;
