import React from 'react';

import Attribute from './components/Attribute';

export const renderAttributes = (attributes: Record<string, any>) =>
  Object.keys(attributes).map((key) => {
    const { name, size, value } = attributes[key];

    return <Attribute key={name} name={name} value={value} size={size} />;
  });

export const checkForTranslation = (key: string, t: (v: string) => string) => {
  const trans = t(`common.notifications.${key}`);
  const transFail = trans.includes('common.notifications');

  if (trans && !transFail && typeof trans === 'string') return trans;

  return false;
};

export const camelCaseToPhrase = (string: string) =>
  string.replace(/[\w]([A-Z])/g, (match) => `${match[0]} ${match[1]}`);

export const mountCardAttributes = (
  attributes: Record<string, any>,
  t: (v: string) => string,
) => {
  const cardAttributes: Record<string, any> = {};
  const entries = Object.entries(attributes);

  entries.forEach((item) => {
    const key = item[0];
    const value = item[1];
    const trans = checkForTranslation(key, t);

    const valueSize = attributes[`${key}Size`];
    const attrName = attributes[`${key}AttrName`];
    const canAdd =
      !key.includes('Size', key.length - 5) &&
      attributes[key.replace('Size', '')] &&
      !key.includes('AttrName', key.length - 9) &&
      attributes[key.replace('AttrName', '')];

    if (canAdd) {
      cardAttributes[key] = {
        value,
        size: valueSize || '13',
        name: attrName || trans ? trans : camelCaseToPhrase(key),
      };
    }
  });

  return cardAttributes;
};
