import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import Lottie from 'lottie-react-web';

import ErrorAnimation from '../Notifications/assets/error.json';
import LoadingAnimation from '../Notifications/assets/loading.json';
import MinorSuccessAnimation from '../Notifications/assets/minorSuccess.json';

export interface TaskContentProps {
  subtasks: Task[];
  taskLevel?: number;
}

const TaskStatus = (status: TaskStatus) => {
  switch (status) {
    case 'idle':
      return <div style={{ width: 20 }} />;
    case 'success':
      return (
        <Lottie
          options={{
            animationData: MinorSuccessAnimation,
            loop: false,
          }}
          width={22}
        />
      );

    case 'running':
      return (
        <Lottie
          options={{
            animationData: LoadingAnimation,
            loop: true,
          }}
          width={22}
        />
      );

    case 'failed':
    case 'stopped':
      return (
        <Lottie
          options={{
            animationData: ErrorAnimation,
            loop: false,
          }}
          width={22}
        />
      );

    default:
      return <div />;
  }
};

const TaskContent = ({ subtasks, taskLevel = 0 }: TaskContentProps) => {
  const labelColor = (status: TaskStatus) => {
    switch (status) {
      case 'idle':
        return '#878787';
      case 'success':
        return '#303030';
      case 'running':
        return '#878787';
      case 'failed':
      case 'stopped':
        return '#c23737';
      default:
        return '#878787';
    }
  };

  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Box ref={divRef} paddingLeft={`${taskLevel * 16}px`} marginBottom="3rem">
      {subtasks.map((subtask, index) => {
        return (
          <Box
            display="flex"
            borderLeft={taskLevel > 0 ? '1px solid #e6e2d8' : ''}
            paddingLeft="0.25rem"
          >
            <Box display="flex" alignItems="flex-start">
              <Box marginRight="0.5rem">{TaskStatus(subtask.status)}</Box>
              <Box width="25ch">
                <Typography
                  variant="overline"
                  style={{
                    lineHeight: 1,
                    color: labelColor(subtask.status),
                  }}
                >
                  {subtask.label}
                </Typography>
              </Box>
            </Box>
            <Box>
              {subtask?.subtasks?.length ? (
                <TaskContent
                  subtasks={subtask?.subtasks}
                  taskLevel={taskLevel + 1}
                />
              ) : null}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
export interface OperationStateCardPros {
  taskOperation: Task;
  invisible?: boolean;
}
export const OperationStateCard = ({
  taskOperation,
  invisible,
}: OperationStateCardPros) => {
  if (!taskOperation?.status) return null;
  return (
    <Box padding="2rem" boxShadow={2} margin="0 auto">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginBottom="2rem"
      >
        <Box marginRight="0.5rem">{TaskStatus(taskOperation?.status)}</Box>
        <Box>
          <Typography variant="h4">{taskOperation?.label}</Typography>
        </Box>
      </Box>

      {!invisible ? (
        <Box>
          {taskOperation.subtasks?.length ? (
            <TaskContent subtasks={taskOperation?.subtasks} />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};
