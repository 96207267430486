import React from 'react';
import { Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import * as S from './styles';
import { OTPForm } from '../../AppComponents/OTPForm';
import { authAPI } from '../../Common/axios';

interface ResendResponse {
  codeDeliveryDetails: {
    AttributeName: 'email';
    deliveryMedium: 'EMAIL';
    destination: string;
  };
}
export const ConfirmAccount = () => {
  const location = useLocation<{ username: string }>();
  const resendCode = async () => {
    try {
      await authAPI.post<string>('/resend', {
        username: location.state.username,
      });
      toast.info(`Code sent to your email address`);
    } catch (err) {
      const regex = /:(.*?)\n/;
      const error = err as AxiosError;
      toast.error(error.response?.data.match(regex)?.[1]?.trim());
    }
  };
  return (
    <S.Auth>
      <S.Box>
        <S.Logo to="/">
          <img
            src="/GoLedger.png"
            width={110}
            height={110}
            alt="Goledger Logo"
          />
        </S.Logo>
        <Typography color="textSecondary">
          Please enter the code sent to your email address
        </Typography>
        <OTPForm />
      </S.Box>
      <S.RedirectLink>
        <Typography variant="overline">
          Didn&apos;t receive the code?
        </Typography>
        <Link onClick={() => resendCode()}>
          <Typography variant="overline"> Resend</Typography>
        </Link>
      </S.RedirectLink>
    </S.Auth>
  );
};
