import React, { useCallback, useEffect, useState } from 'react';
import {
  Typography,
  Box,
  AppBar,
  TextField,
  Checkbox,
  List,
  ListSubheader,
  ListItem,
  makeStyles,
  createStyles,
  Theme,
  InputAdornment,
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { Warning } from '@material-ui/icons';
import { useNetworks } from '../Contexts/Networks';
import { StyledTab, StyledTabs } from '../AppComponents/StyledTabs';
import { useUpgradeChaincodeForm } from '../Hooks/UpgradeChaincode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #e0e0e0',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
  }),
);
export const UpgradeCCForm: React.FC = () => {
  const { t } = useTranslation();
  const [chaincodeValue, setChaincodeValue] = useState(0);
  const [autoUpgrade, setAutoUpgrade] = useState(false);
  const { selectedChannel } = useNetworks();
  const {
    selectedChaincode,
    setSelectedChaincode,
    peers,
    setPeers,
    version,
    setVersion,
    selectedNetwork,
    setSelectedNetwork,
  } = useUpgradeChaincodeForm();
  const renderChaincodeTabLabel = useCallback(
    (chaincodeName: string) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{chaincodeName || 'unnamedChaincode'}</Typography>
      </Box>
    ),
    [],
  );

  const setAutoVersion = useCallback(() => {
    const currentVersion = selectedNetwork?.chaincodes?.[
      selectedChaincode
    ]?.version?.split('.')?.[0];
    if (currentVersion) setVersion(`${parseInt(currentVersion, 10) + 1}.0`);
    setAutoUpgrade(true);
  }, [selectedChaincode, selectedNetwork, setVersion]);

  useEffect(() => {
    setAutoVersion();
  }, [setAutoVersion]);

  useEffect(() => {
    if (selectedNetwork) {
      setSelectedChaincode(
        selectedNetwork?.channels?.[selectedChannel]?.chaincodes[0] as string,
      );
      setChaincodeValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const peersChecked = selectedNetwork?.channels?.[selectedChannel]?.peers;
    if (peersChecked) {
      setPeers({ ...peersChecked });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel]);

  const classes = useStyles();

  const peerIsChecked = useCallback(
    (peer: string) => {
      const iSchecked = Object.entries(peers).some(([orgName, peerArray]) =>
        peerArray.includes(peer),
      );

      return iSchecked;
    },
    [peers],
  );
  return (
    <form>
      <Box display="flex" flexDirection="column">
        <AppBar
          style={{ backgroundColor: 'var(--primary)', marginBottom: '1rem' }}
          position="static"
        >
          <StyledTabs
            value={chaincodeValue}
            scrollButtons="on"
            variant="scrollable"
            onChange={(_: any, v: any) => setChaincodeValue(v)}
          >
            {selectedNetwork?.channels?.[selectedChannel]?.chaincodes?.map(
              (chaincode, index) => (
                <StyledTab
                  key={index}
                  onClick={() => setSelectedChaincode(chaincode)}
                  label={renderChaincodeTabLabel(chaincode)}
                />
              ),
            )}
          </StyledTabs>
        </AppBar>
        <Box margin="0 auto">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '1024px',
              minWidth: '430px',
              margin: '2rem 0',
            }}
          >
            {selectedChannel && selectedChaincode && (
              <Box
                display="flex"
                width="100%"
                alignItems="start"
                style={{ gap: '4rem' }}
              >
                <Box>
                  <TextField
                    style={{ margin: '0 0 16px', width: '256px' }}
                    label={t('common.forms.chaincodeVersion')}
                    defaultValue={
                      selectedNetwork?.chaincodes?.[selectedChaincode]?.version
                    }
                    value={version}
                    onChange={(ev) => {
                      setVersion(ev.target.value);
                    }}
                    {...((selectedNetwork?.chaincodes?.[selectedChaincode]
                      ?.version === version ||
                      !version) && {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="start">
                            <Warning style={{ color: '#ffcc00' }} />
                          </InputAdornment>
                        ),
                      },
                      helperText: (
                        <Typography
                          component="span"
                          style={{
                            color: '#ffcc00',
                            fontSize: '12px',
                            fontWeight: 700,
                          }}
                        >
                          <Trans>common.messages.sameVersion</Trans>
                        </Typography>
                      ),
                    })}
                  />
                  <Box marginLeft="auto">
                    <Checkbox
                      color="primary"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAutoVersion();
                        } else {
                          if (!selectedNetwork?.chaincodes?.[selectedChaincode])
                            return;
                          setVersion(
                            selectedNetwork?.chaincodes?.[selectedChaincode]
                              ?.version as string,
                          );
                          setAutoUpgrade(false);
                        }
                      }}
                      checked={autoUpgrade}
                    />

                    <Typography variant="overline" style={{ fontSize: '10px' }}>
                      auto version upgrade
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="overline"
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    Channel peers upgrade
                  </Typography>
                  <Box
                    style={{
                      overflowY: 'auto',
                      height: 'fit-content',
                      maxHeight: '220px',
                    }}
                  >
                    <List className={classes.root} subheader={<li />}>
                      {selectedNetwork?.channels &&
                        selectedChannel &&
                        selectedNetwork?.channels?.[selectedChannel]?.peers &&
                        Object.entries(
                          selectedNetwork?.channels?.[selectedChannel]?.peers,
                        ).map(([orgName, peersInChannel]) => {
                          return (
                            <li
                              key={`section-${orgName}`}
                              className={classes.listSection}
                            >
                              <ul className={classes.ul}>
                                <ListSubheader>{`${orgName}`}</ListSubheader>

                                {peersInChannel.map((peer, key) => {
                                  return (
                                    <ListItem
                                      key={`item-${orgName}-${selectedNetwork?.peers[peer]?.host}`}
                                      role={undefined}
                                      dense
                                    >
                                      <Checkbox
                                        onChange={() => {
                                          if (!peers?.[orgName])
                                            peers[orgName] = [];

                                          const findPeer = peers[orgName]?.find(
                                            (p) => p === peer,
                                          );
                                          if (!findPeer) {
                                            peers[orgName].push(peer);

                                            setPeers({
                                              ...peers,
                                            });
                                          } else {
                                            peers[orgName] = peers[
                                              orgName
                                            ].filter((p) => p !== peer);
                                            setPeers({ ...peers });
                                          }
                                        }}
                                        checked={peerIsChecked(peer)}
                                      />
                                      {peer} -{' '}
                                      {selectedNetwork?.peers[peer]?.host}
                                    </ListItem>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                    </List>
                  </Box>
                </Box>
              </Box>
            )}
          </div>
        </Box>
      </Box>
    </form>
  );
};
