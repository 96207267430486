import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import MenuPage from '../MenuPage';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  fabGreen: {
    marginLeft: '10%',
    color: theme.palette.common.white,
    backgroundColor: green[500],
    boxShadow: 'none',
  },
});

const OrganizationMain: React.FC<{ classes: any }> = ({ classes }) => {
  const { t } = useTranslation();

  const options = [
    {
      icon: 'library_add',
      text: t('title.organizations.addOrg'),
      link: '/organization/add',
    },
    {
      icon: 'library_add',
      text: t('title.organizations.addExtOrg'),
      link: '/organization/addExt',
    },
  ];

  return (
    <Grid
      container
      justify="center"
      className={[classes.root, classes.centerContainer].join(' ')}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography style={{ fontSize: '32px' }} variant="overline">
          <Trans>title.organizations.header</Trans>
        </Typography>
      </div>
      <Grid item xs={12}>
        <MenuPage options={options} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(OrganizationMain);
