import styled from 'styled-components';
import {
  Button,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

export const ModalTitle = styled(DialogTitle)`
  && {
    margin: 0;
    padding: 16px;
    background: var(--darkBlue);
  }
`;

export const CloseButton = styled(IconButton)`
  && {
    top: 8px;
    right: 8px;
    position: absolute;
    color: var(--white);
  }
`;

export const ModalContent = styled(DialogContent)`
  && {
    margin: 0;
    padding: 16px;
    display: flex;
    justify-content: space-around;
  }
`;

export const ModalActions = styled(DialogActions)`
  && {
    margin: 0;
    padding: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

export const ContentHeader = styled.div`
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const CanvasHeader = styled.div`
  height: 40px;
  display: flex;
  color: var(--white);
  align-items: center;
  background: var(--secondary);
`;

export const CanvasContainer = styled.div`
  height: 100%;
  position: relative;
  border: 1px solid var(--black);
`;

export const ClearButton = styled(Button).attrs({
  variant: 'text',
})`
  && {
    font-size: 16px;
    margin-right: 30px;
    color: var(--white);
  }
`;
