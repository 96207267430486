import React from 'react';
import { Trans } from 'react-i18next';
import { Check, Close } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { FetchingNetwork } from '../../store/Network';

import { Container } from './styles';

const NetworkStatus: React.FC<{ fetchingNetwork: FetchingNetwork }> = ({
  fetchingNetwork,
}) => {
  switch (fetchingNetwork) {
    case 'nothing':
      return null;
    case 'success':
      return (
        <Container>
          <Check />

          <Trans>header.networkStatus.successTooltip</Trans>
        </Container>
      );
    case 'loading':
      return (
        <Container>
          <CircularProgress
            style={{ width: '32px', height: '32px', marginRight: '8px' }}
          />

          <Trans>header.networkStatus.loadingTooltip</Trans>
        </Container>
      );
    case 'failure':
      return (
        <Container>
          <Close />

          <Trans>header.networkStatus.failureTooltip</Trans>
        </Container>
      );
    default:
      return null;
  }
};

export default NetworkStatus;
